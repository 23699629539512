config = require '../config'
{ underscoreParams } = require '../lib/utils'

RemindersAPI =
  index: (scope, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.reminders.index
      type: 'GET'
      data: underscoreParams(scope)
      success: successCallback
      error: errorCallback
      timeout: 5000

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.reminders.create
      data: JSON.stringify(underscoreParams(attributes))
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: (id, attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.reminders.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  destroy: (id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.reminders.destroy(id)
      contentType: 'application/json'
      data: JSON.stringify({ id: id })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

module.exports = RemindersAPI
