ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'
TAKE_NEW_COUNT_CLICK = 50

actionsMap =
  "#{ActionTypes.ANOMALY_TAKE_NEW_CLICK}": (date) ->
    @takeNewClicks.push(date)
    if @takeNewClicks.length > TAKE_NEW_COUNT_CLICK
      @takeNewClicks = []
    @emitChange()

class AnomalyStore extends BaseStore
  constructor: ->
    super()
    @takeNewClicks = []
    @registerActions()

  _registerToActions: ({type, payload}) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new AnomalyStore()
