moment = require 'moment'

BaseComponent = require 'components/BaseComponent'
CustomerTicketsStore = require './CustomerTicketsStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
TabOptionsComponent = require '../TabOptionsComponent'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
{ crmPluginEnabled } = require 'root/config'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
CustomerTicketsActionCreator = require './CustomerTicketsActionCreator'

styles = require './customer-tickets.styl'

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs

TICKET_TYPES_MAP =
  estimation: 'Estimation'
  cl_request: 'CL request'
  no_writer: 'No writer'
  missed_ddl: 'missed DDL'
  plagiarism: 'Plagiarism'
  retraction: 'Retraction'
  revision: 'Revision'
  refund: 'Refund'
  poor_quality: 'Poor quality'
  not_confirmed: 'Not confirmed'
  force_action: 'Force action'

class CustomerTicketsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @withContext = props.conversation?.status != CONVERSATION_STATUS.CLOSED
    if props.externalClientId
      { getTickets } = CustomerTicketsActionCreator
      setTimeout(
        -> getTickets props.externalClientId
        15
      )
      @externalClientId = props.externalClientId
    @initComponent()

  dependsOnStores: [CustomerTicketsStore]

  getState: ->
    loading: @props.loading || CustomerTicketsStore.loading
    tickets: CustomerTicketsStore.tickets
    error: @props.error || CustomerTicketsStore.error

  componentWillReceiveProps: (nextProps) =>
    @withContext = nextProps.conversation?.status != CONVERSATION_STATUS.CLOSED
    if nextProps.externalClientId && nextProps.externalClientId != @externalClientId
      { getTickets } = CustomerTicketsActionCreator
      setTimeout(
        -> getTickets nextProps.externalClientId
        15
      )
      @externalClientId = nextProps.externalClientId

  onTicketClick: (e) =>
    if crmPluginEnabled
      ticketId = e.currentTarget.dataset.id
      config = pluginConfigs.TicketPlugin({ ticketId })
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-ticket-#{ticketId}", @withContext
      )

  onCreateTicketClick: =>
    if crmPluginEnabled
      config = pluginConfigs.CreateTicketPlugin()
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-newTicket", @withContext
      )

  onAllTicketsClick: =>
    if crmPluginEnabled
      config = [{
        plugins:
          EntityTicketsPlugin:
            data: role: 'client', roleId: @props.externalClientId
      }]
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-allTickets", @withContext
      )

  renderData: ->
    if @state.error
      React.createElement("p", {"className": (styles.error)}, (@state.error))
    else unless (@state.tickets || []).length
      React.createElement("p", {"className": (styles.no_data)}, "No data")
    else
      ticketsCount = @state.tickets.length
      onTicketClick = @onTicketClick
      React.createElement("ul", {"className": (styles.tickets)},
        (@state.tickets.map (ticket, i) ->
          React.createElement("li", {"key": (ticket.id)},
            React.createElement("div", {  \
              "className": (styles.ticket_container),   \
              "onClick": (onTicketClick),   \
              "data-id": (ticket.id)
            },
              React.createElement("div", {"className": (styles.ticket_type)},
                (React.createElement("span", {"className": (styles.indicator)}) if ticket.priority == 'high'),
                React.createElement("span", null,
                  React.createElement("b", null, (TICKET_TYPES_MAP[ticket.ticket_type]), ": "),
                  (ticket.subtype)
                )
              ),
              React.createElement("div", {"className": (styles.reference)},
                (ticket.reference), """
                 
""", React.createElement("span", {"className": (styles.assignee_group)}, """
                  (""", (ticket.assignee_group.name), """)
""")
              ),
              React.createElement("div", {"className": (styles.status)},
                (moment(ticket.deadline).format('MMM D, HH:MM A')),
                React.createElement("span", null, " \x2F "),
                (ticket.aasm_state)
              ),
              (React.createElement("div", {"className": (styles.divider)}) if i < ticketsCount - 1)
            )
          )
        )
      )

  render: ->
    React.createElement(LoadableWrapperComponent, {"loading": (@state.loading)},
      (if !@state.error
        React.createElement(TabOptionsComponent, { \
          "all": (
            text: 'All tickets'
            onClick: @onAllTicketsClick
          )
        })
      ),
      (@renderData())
    )

module.exports = CustomerTicketsComponent
