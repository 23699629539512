import uuid from 'uuid';

import {
  mainHubActions,
  pluginConfigs,
} from '@verdaccio/crminfo';

import ActionTypes from 'constants/ActionTypes';
import { localStorageKey } from 'constants/CRMPlugin';

import ConversationsAPI from 'api/ConversationsAPI.coffee';

import Dispatcher from '../dispatcher/Dispatcher';
import { store } from '../AppRouteComponent';

const CRMPluginActionCreator = {
  setConfig(config, entityKey, configKey = uuid.v4(), withContext, isRestore = false) {
    if (withContext) {
      let allConfigs = localStorage.getItem(localStorageKey);
      try {
        allConfigs = JSON.parse(allConfigs) || {};
      } catch (err) {
        allConfigs = {};
      }
      if (!allConfigs[entityKey]) {
        allConfigs[entityKey] = {};
      }
      allConfigs[entityKey][configKey] = config;
      localStorage.setItem(localStorageKey, JSON.stringify(allConfigs));
    }
    store.dispatch(mainHubActions.clearConfigKey());
    config.forEach(c => {
      const { plugins = {} } = c;
      const pluginsValues = Object.values(plugins)[0];
      
      if (!pluginsValues) return;

      pluginsValues.data.isRestore = isRestore;
    });
    setTimeout(() => {
      store.dispatch(mainHubActions.addNewConfig(config, configKey, true));
    }, 1);
  },

  setAdditionalPlugin(additionalPlugin, additionalPluginKey = uuid.v4()) {
    store.dispatch(mainHubActions.openAdditionalPlugin(additionalPlugin, additionalPluginKey, true));
  },

  setForceCloseProperty() {
    store.dispatch(mainHubActions.clearConfigKey());
    store.dispatch(mainHubActions.forceCLoseAllPlugin());
  }
};

CRMPluginActionCreator.openFilePreview = (id, url, menuOptions, clientId) => {
  Dispatcher.dispatch({
    type: ActionTypes.GET_FILE_URLS_REQUESTING
  });
  return ConversationsAPI.urls(id,
    data => {
      if (data.urls && (data.urls.length > 0)) {
        const {
          urls,
        } = data;
        let index = 0;
        urls.forEach((u, i) => {
          if (decodeURIComponent(u.real_location) === url) {
            index = i;
          }
          u.name = decodeURIComponent(u.name);
        });
        const config = pluginConfigs.PreviewFilesPlugin({
          parentType: "conversations",
          parentId: id,
          selectedFile: index,
          staticHeaderText: `Files conversations ${id}`,
          shortHeaderText: `Files ${id}`,
          previewFiles: urls,
          options: {
            isFullScreenView: true,
            isDarkMode: true,
            editHeader: true,
            hideAttachIcon: true,
            isSMSPreviewFile: true,
            isResizable: true
          },
          menuOptions,
          menuItems: ['download', 'copyto'],
          clientId,
        });
        return CRMPluginActionCreator.setConfig(config, id, `${id}-conversations-${uuid.v4()}`, true);
      } else {
        return Dispatcher.dispatch({
          type: ActionTypes.GET_FILE_URLS_ERROR
        });
      }
    });
};

export default CRMPluginActionCreator;
