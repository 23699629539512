BaseStore = require './BaseStore'
ActionTypes = require 'constants/ActionTypes'
{ QUALITY } = require 'constants/Ranking'

timeoutId = null

setEstimationData = (conversation) ->
  @estimation =
    conversation: conversation
    messageId: (conversation.approve_data || {}).messageId
    messageType: (conversation.approve_data || {}).messageType
    user: (conversation.approve_data || {}).user
    isChanged: false
  @isSelectable = !!(conversation.approve_data || {}).messageId

actionsMap =
  "#{ActionTypes.RANKING_OPEN_CONVERSATION}": (payload) ->
    conversationIndex = @conversationsForRanking.indexOf payload
    if conversationIndex == -1
      @conversationsForRanking.push(payload)
      setEstimationData.call @, payload if @isRankingStart && !@estimation.conversation
      @emitChange()
  "#{ActionTypes.RANKING_CLOSE_CONVERSATION}": (payload) ->
    conversationIndex = -1
    @conversationsForRanking.forEach (conversation, i) ->
      conversationIndex = i if conversation.id == payload.id
    if conversationIndex != -1
      @conversationsForRanking.splice conversationIndex, 1
      @emitChange()
  "#{ActionTypes.RANKING_START}": ->
    @isRankingStart = true
    conversation = @conversationsForRanking[@conversationsForRanking.length - 1]
    setEstimationData.call @, conversation if conversation
    @error = ''
    @success = ''
    @emitChange()
  "#{ActionTypes.RANKING_CHANGE_ESTIMATION}": (payload) ->
    @isSelectable = payload.isSelectable
    if !@isSelectable
      @estimation.messageId = undefined
      @estimation.messageType = undefined
    @estimation.isChanged = true
    @success = ''
    @emitChange()
  "#{ActionTypes.RANKING_RETURN_TO_SAVED}": (payload) ->
    @isSelectable = payload.isSelectable
    @estimation.isChanged = false
    @estimation.messageId = @estimation.conversation.approve_data.messageId
    @estimation.messageType = @estimation.conversation.approve_data.messageType
    @emitChange()
  "#{ActionTypes.RANKING_SELECT_MESSAGE}": (payload) ->
    @estimation.messageId = payload.messageId
    @estimation.messageType = payload.messageType
    @success = ''
    @emitChange()
  "#{ActionTypes.RANKING_STOP}": ->
    @estimation = {}
    @isRankingStart = false
    @emitChange()
  "#{ActionTypes.RANKING_SUBMIT_REQUESTING}": ->
    @error = ''
    @success = ''
    @emitChange()
  "#{ActionTypes.RANKING_SUBMIT_SUCCESS}": (payload) ->
    if @estimation.conversation
      @estimation.conversation.approve_data = payload
    @estimation.isChanged = false
    @error = ''
    @success = 'Estimation is saved'
    clearTimeout timeoutId
    timeoutId = setTimeout(
      _clearSuccessField.bind @
      3000
    )
    @emitChange()
  "#{ActionTypes.RANKING_SUBMIT_FAILURE}": (payload) ->
    @error = payload
    @estimation.isChanged = false
    @success = ''
    @emitChange()

  "#{ActionTypes.RANKING_CLOSE_ALL_CONVERSATION}": ->
    @conversationsForRanking = []
    @emitChange()

_clearSuccessField = () ->
  @success = ''
  @emitChange()

class RankingStore extends BaseStore

  constructor: ->
    super()
    @conversationsForRanking = []
    @estimation = {}
    @error = ''
    @success = ''
    @isSelectable = false
    @isRankingStart = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new RankingStore()
