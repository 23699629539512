classnames = require 'classnames'
moment = require 'moment'

conversationStyles = require 'components/conversations/ConversationComponent/conversation'
ConversationStore = require 'components/conversations/ConversationComponent/ConversationStore'
BaseComponent = require 'components/BaseComponent'
BaseInfoComponent = require 'components/customers/BaseInfoComponent'
ConversationItemUtils = require 'lib/ConversationItemUtils'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
RankingHeaderComponent = require '../RankingHeaderComponent'
PreviousConversationComponent = require 'components/conversations/PreviousConversationComponent/PreviousConversationComponent'
RankingStore = require 'stores/RankingStore'
RankingActionCreator = require 'actions/RankingActionCreator'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
{ conversationIsClosed, conversationIsNotClosed } = require 'lib/utils.coffee'
AuthStore = require 'stores/AuthStore'
{ menus, customerModules } = require 'root/config'
ConversationItemsStore = require 'stores/ConversationItemsStore'
ConversationItemsActionCreator = require 'actions/ConversationItemsActionCreator'
PreviousConversationsBlockComponent = require 'components/conversations/PreviousConversationsBlockComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
ConversationsActionCreator = new ConversationsActionCreator()

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'ranking'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

rankingCustomerModules = Object.assign 'qa-info': true, customerModules

class RankingConversationComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @isEditable = false
    if accessConfig.toEdit
      if accessConfig.toEdit.indexOf(AuthStore.user.role) != -1
        @isEditable = true

    @forceUpdate = R.bind(@forceUpdate, @)
    @setState = R.bind(@setState, @)
    @data = {}
    @state =
      selectedConversation: null
      foundMessagesPosition: 1
      opened:
        customerInfo: true
    @personalStore = ConversationStore
    @actionCreator = ConversationStore.actionCreator
    @initComponent()

  dependsOnStores: [RankingStore, ConversationItemsStore, ConversationStore]

  getState: ->
    selectedConversation = @personalStore.conversation
    {
      selectedConversation
      ready:
        conversationItems: false
        customerInfo: false
    }

  componentWillReceiveProps: (nextProps) ->
    if nextProps.match.params.id != @props.match.params.id
      @conversationId = parseInt nextProps.match.params.id, 10
      @downloadInitialData(@conversationId)
      @setState(searchEnabled: false)
      RankingActionCreator.closeAllConversation()

  toggleCustomerInfo: () =>
    @setState R.assocPath(['opened', 'customerInfo'],
      !@state.opened.customerInfo, @state)

  scrollToElement: (el) =>
    parent = @refs.conversationItemsScroll
    parent.scrollTop = el.offsetTop - 10

  scrollToElementEnd: (el) =>
    parent = @refs.conversationItemsScroll
    parent.scrollTop = el.offsetTop + el.clientHeight - parent.clientHeight

  onMessagesSearch: (query, searchType) =>
    @actionCreator.searchMessages(@conversationId, query, searchType) if query
    @setState(searchEnabled: query?, foundMessagesPosition: 1)

  stepFoundMessage: (step) =>
    nextStep = @state.foundMessagesPosition + step
    length = @personalStore.foundMessages.length
    nextStep = length if nextStep < 1
    nextStep = 1 if nextStep > length
    @setState foundMessagesPosition: nextStep

  getFoundMessage: =>
    { foundMessages } = @personalStore
    return null if R.isEmpty(foundMessages)
    foundMessages[@state.foundMessagesPosition - 1]

  onItemElementSelect: (el) ->
    if el
      el.firstChild.scrollIntoViewIfNeeded()

  componentWillMount: ->
    @conversationId = parseInt @props.match.params.id, 10
    @downloadInitialData @conversationId

  downloadInitialData: (conversationId) =>
    setTimeout(
      =>
        @actionCreator.getClosedConversations conversationId
        ConversationsActionCreator.get conversationId
      10
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ->
        RankingActionCreator.closeAllConversation()
      5
    )
    setTimeout(
      ->
        ConversationItemsActionCreator.clearStore()
      10
    )

  render: ->
    loading = @personalStore.loading.closedLoading
    selectedConversationId = parseInt @props.match.params.id, 10
    { selectedConversation } = @state

    className = classnames(
      conversationStyles.container,
      "#{conversationStyles.show}": @state.show,
      "#{conversationStyles.unassigned_folder_container}": !@state.opened.customerInfo && !@personalStore.rankingShow
    )

    scrollClassName = classnames(
      conversationStyles.scroll
    )

    foundMessage = if @state.searchEnabled then @getFoundMessage() else null
    foundMessages = if @state.searchEnabled then @personalStore.foundMessages else []
    foundMessagesIndex = R.indexBy(ConversationItemUtils.getTypeIdIdentifier, foundMessages)
    infoPanel = null
    if @state.opened.customerInfo && selectedConversation?.customer?
      infoPanel = React.createElement("div", {"className": (conversationStyles.customer_info)},
        React.createElement(BaseInfoComponent, { \
          "key": (@conversationId),  \
          "customerId": (selectedConversation.customer.id),  \
          "onAllCollapse": (@toggleCustomerInfo),  \
          "customerModules": (rankingCustomerModules),  \
          "conversation": (selectedConversation),  \
          "customerLink": (true),  \
          "anyOpen": true
        })
      )
    else
      infoPanel = React.createElement("div", {"className": (conversationStyles.customer_info_hidden)},
        React.createElement("div", {"className": (conversationStyles.burger), "onClick": (@toggleCustomerInfo)})
      )

    conversationItemsScrollElement = @refs.conversationItemsScroll
    { getClosedConversationsForPreview } = @actionCreator
    { conversationId, scrollToElement, scrollToElementEnd, onItemElementSelect } = @

    React.createElement("div", null,
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        React.createElement(RankingHeaderComponent, { \
          "conversation": (selectedConversation),  \
          "toggleCustomerInfoHandler": (@toggleCustomerInfo),  \
          "onMessagesSearch": (@onMessagesSearch),  \
          "searchEnabled": (@state.searchEnabled),  \
          "foundMessages": (foundMessages),  \
          "searchLoading": (@personalStore.loading.searchMessages),  \
          "searchStepCallback": (@stepFoundMessage),  \
          "searchPosition": (@state.foundMessagesPosition)
        }),

        React.createElement("div", {"className": (className)},
          React.createElement("div", {"className": (scrollClassName), "id": 'itemsScroll', "ref": 'conversationItemsScroll'},
            (@personalStore.groupedPreviews.map (preview) ->
              if preview.conversations
                preview.conversations.map (conversation, index) =>
                  selectedMessage = null
                  selectedMessage = foundMessage if foundMessage?.conversation_id == conversation.id
                  createdDate = moment(conversation.created_at)
                  previewCreated = moment(preview.conversations[index - 1]?.created_at)
                  isOpened = conversation.id == conversationId
                  devider = null
                  if index == 0 || (createdDate.date() != previewCreated.date()) || (createdDate.month() != previewCreated.month())
                    devider = React.createElement("div", {"className": (conversationStyles.divider)},
                      (createdDate.format('D MMMM, YYYY'))
                    )
                  React.createElement("div", {"key": (conversation.id)},
                    (devider),
                    React.createElement(PreviousConversationComponent, { \
                      "conversation": (conversation),  \
                      "onTop": (scrollToElement),  \
                      "onBottom": (scrollToElementEnd),  \
                      "scrollElement": (conversationItemsScrollElement),  \
                      "opened": (isOpened),  \
                      "itemsListProps": (
                        itemsHighLightingIndex: foundMessagesIndex
                        selectedItem: selectedMessage
                        onItemSelect: onItemElementSelect
                      ),  \
                      "items": (ConversationItemsStore.conversationItems[conversation.id]),  \
                      "loading": (ConversationItemsStore.loadings[conversation.id]),  \
                      "error": (ConversationItemsStore.errors[conversation.id]),  \
                      "rankable": true
                    })
                  )
              else
                React.createElement(PreviousConversationsBlockComponent, { \
                  "key": (preview.date),  \
                  "title": (preview.date),  \
                  "count": (preview.total),  \
                  "onClick": (getClosedConversationsForPreview.bind null, conversationId, preview.date)
                })
            )
          )
        ),
        (infoPanel)
      )
    )

module.exports = RankingConversationComponent
