{ underscoreParams } = require '../../lib/utils'

DEFAULT_TIMEOUT = 5000

class CrudAPI
  constructor: (@urls) ->

  index: (scope, success, error) ->
    $.ajax {
      url: @urls.index
      data: underscoreParams(scope)
      type: 'GET'
      timeout: DEFAULT_TIMEOUT
      success
      error
    }

  create: (attributes, success, error) ->
    $.ajax {
      url: @urls.create
      data: JSON.stringify(underscoreParams(attributes))
      contentType: 'application/json'
      type: 'POST'
      timeout: DEFAULT_TIMEOUT
      success
      error
    }

  get: (id, success, error) ->
    $.ajax {
      url: @urls.get(id)
      contentType: 'application/json'
      type: 'GET'
      timeout: DEFAULT_TIMEOUT
      success
      error
    }

  update: (id, attributes, success, error) ->
    $.ajax {
      url: @urls.update(id)
      contentType: 'application/json'
      data: JSON.stringify(underscoreParams(attributes))
      type: 'PUT'
      timeout: DEFAULT_TIMEOUT
      success
      error
    }

  batchDestroy: (ids, success, error) ->
    $.ajax {
      url: @urls.batchDestroy
      contentType: 'application/json'
      data: JSON.stringify({ids})
      type: 'DELETE'
      timeout: DEFAULT_TIMEOUT
      success
      error
    }

module.exports = CrudAPI