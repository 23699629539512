ReactPaginate = require 'react-paginate'
classnames = require 'classnames'

styles = require './ranked-agents-list'
paginationStyles = require '../../shared/styles/pagination.styl'

propTypes = require 'prop-types'

class RankedAgentsListComponent extends React.Component

  @propTypes:
    allAgents: propTypes.array
    selectedAgents: propTypes.array
    pageCount: propTypes.number
    page: propTypes.number
    onAgentClick: propTypes.func
    onApplyAgentsFilter: propTypes.func
    onPageChange: propTypes.func

  onAgentClick: (agent) ->
    @props.onAgentClick agent

  render: ->
    selectedAgents = @props.selectedAgents
    that = @
    React.createElement("div", {"className": (styles.search), "id": 'agents'},
      React.createElement("div", {"className": (styles.search__agents)},
        (@props.allAgents.map (agent, i) ->
          selected = false
          selectedAgents.forEach (selectedAgent) ->
            selected = true if selectedAgent.id == agent.id

          React.createElement("div", { \
            "key": (i), "className": (styles.search__agent),  \
            "onClick": (that.onAgentClick.bind(that, agent))
          },
            React.createElement("i", { \
              "className": (classnames styles['search__agent-icon'],
                "#{styles['search__agent-icon_active']}": selected
            )}),
            React.createElement("span", {"className": (classnames styles['search__agent-label'],
              "#{styles['search__agent-label_active']}": selected
            )},
              ("#{agent.first_name} #{agent.last_name}")
            ),
            React.createElement("img", { \
              "className": (styles['search__agent-image']),  \
              "src": (agent.image || require '../../shared/images/unknown.png')
            })
          )
        ),
        (if @props.pageCount > 1
          React.createElement("div", {"className": (styles.search__pagination)},
            React.createElement(ReactPaginate.default, { \
              "previousLabel": 'previous',  \
              "nextLabel": 'next',  \
              "pageCount": (@props.pageCount),  \
              "marginPagesDisplayed": (1),  \
              "pageRangeDisplayed": (2),  \
              "onPageChange": (@props.onPageChange),  \
              "initialPage": (@props.page),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container),  \
              "breakClassName": (paginationStyles.page)
            })
          )
        )
      ),
      React.createElement("button", { \
        "onClick": (@props.onApplyAgentsFilter),  \
        "className": (styles.search__apply)
      }, """
        Apply
""")
    )

module.exports = RankedAgentsListComponent
