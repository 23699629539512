classnames = require 'classnames'

BaseComponent = require '../../../BaseComponent'
ChartComponent = require '../../ChartComponent/ChartComponent'
{buildOptions, getShiftStartTime} = require '../../../../lib/dashboard-utils'
{MILISECONDS_IN_HOUR} = require '../../../../constants/base'
styles = require '../dashboard.styl'
ConversationsEachHourStore = require './ConversationsEachHourStore'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'

class ConversationsEachHourComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()
  
  getPersonalStoreClass: -> ConversationsEachHourStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  getConversationsCountInEachHour: =>
    xAxis:
      type: 'datetime'
      labels:
        overflow: 'justify'
    series: @personalStore.data
    chart: type: 'area'
    plotOptions:
      area:
        stacking: 'normal'
        lineColor: '#666666'
        lineWidth: 1
        marker: enabled: false
        pointInterval: MILISECONDS_IN_HOUR # one hour
        pointStart: @personalStore.interval
    title: text: 'Active conversation each hour'
    yTitle: 'Conversations'

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'ConversationsCountInEachHour',  \
                  "options": (buildOptions @getConversationsCountInEachHour())
                })
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )

module.exports = ConversationsEachHourComponent
