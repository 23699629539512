browserHistory = require './../../../history'

BaseComponent = require '../../BaseComponent'
ConversationsAllComponent = require '../ConversationsAllComponent/ConversationsAllComponent'
ConversationsMyComponent = require '../ConversationsMyComponent/ConversationsMyComponent'
ConversationsUnassignedComponent = require '../ConversationsUnassignedComponent/ConversationsUnassignedComponent'
{ menus } = require 'root/config'
AuthStore = require 'stores/AuthStore'
{ ROLES } = require 'constants/Agents'
{ FOLDERS } = require 'constants/conversations'
{ initMyConversations } = require 'lib/introjs'

accessConfig = menus.reduce(
  (result, item) ->
    if item.key in FOLDERS
      result["#{item.key}"] = item.accessToPage
    result
  {}
)

componentsMap =
  unassigned: ConversationsUnassignedComponent
  my_conversations: ConversationsMyComponent
  all_conversations: ConversationsAllComponent

class WrappedConversationsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  checkAccess: ({ id, folder }) ->
    if accessConfig[folder] &&
    accessConfig[folder].indexOf(ROLES[AuthStore.user.role].value) == -1
      url = "/conversations/my_conversations/#{id || ''}"
      setTimeout(
        -> browserHistory.default.push url
        15
      )

  componentDidMount: ->
    super()
    if @props.match.params.folder == 'my_conversations' &&
       !@props.match.params.id &&
       AuthStore.user.role == 'newbie_agent'
      initMyConversations()

  componentWillMount: -> @checkAccess(@props.match.params)

  componentWillReceiveProps: (nextProps) -> @checkAccess(nextProps.match.params)

  render: =>
    { folder } = @props.match.params
    Component = componentsMap[folder]
    return throw new Error("Unexpected value '#{folder}' for WrappedConversationsComponent") unless Component

    React.createElement(Component, {"pageSize": (10)})

module.exports = WrappedConversationsComponent
