moment = require 'moment'
countdown = require 'countdown'
DateTimePicker = require 'react-widgets/lib/DateTimePicker'
Button = require 'components/shared/Button'

styles = require './scheduled-message-item.styl'

class ScheduledMessageItemComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      isEdit: false
      message: props.message.text
      date: moment(props.message.time).toDate()
    }

  countdown: (time) ->
    countdown.setLabels(
      ' millisecond| second| minute| hour| day| week| month| year| decade| century| millennium',
      ' milliseconds| seconds| minutes| hours| days| weeks| months| years| decades| centuries| millennia',
      ' and ',
      ', ',
      ''
    )
    units = countdown.DAYS|countdown.HOURS|countdown.MINUTES
    (countdown(new Date(), time, units).toString() || '< 1 minute') + " left"

  onEditClick: =>
    @setState isEdit: true
    @props.onEditClick()

  onDeleteClick: =>
    if confirm('Are you sure you want to delete message from schedule?')
      @setState isEdit: false
      @props.onDelete(@props.message)

  onMessageChange: (e) => @setState message: e.target.value

  onCancelEdit: =>
    @setState {
      isEdit: false
      message: @props.message.text
      date: moment(@props.message.time).toDate()
    }
    @props.onCancelEdit()

  onUpdate: =>
    @setState { isEdit: false }
    @props.onEditSubmit @props.message.id, { text: @state.message, time: @state.date, uuid: @props.message.uuid }

  renderReadMode: ->
    { message } = @props
    messageTime = moment(message.time)
    remineTime = @countdown(messageTime.toDate())
    React.createElement("article", {"className": (styles.container)},
      React.createElement("header", {"className": (styles.header)},
        React.createElement("h3", {"className": (styles.main_title)}, (messageTime.format 'D MMMM YYYY / h:mm A')),
        (if remineTime
          React.createElement("span", {"className": (styles.subtitle)}, """
            (""", (remineTime), """)
""")
        ),

        (unless @props.disabled
          React.createElement("div", {"className": (styles.hidden_controls)},
            React.createElement("span", {"onClick": (@onDeleteClick), "className": (styles.delete)}, "Delete"), """
            •
""", React.createElement("span", {"onClick": (@onEditClick), "className": (styles.edit)}, "Edit")
          )
        )
      ),

      React.createElement("p", {"className": (styles.message)},
        (message.text)
      ),
      (React.createElement("div", {"className": (styles.disabled_overlay)}) if @props.disabled)
    )

  renderEditMode: ->
    React.createElement("article", {"className": (styles.container)},
      React.createElement("textarea", { \
        "placeholder": "Add text of your message here",  \
        "className": (styles.edit_message),  \
        "onChange": (@onMessageChange),  \
        "value": (@state.message)
      }),
      React.createElement("div", {"className": (styles.controls)},
        React.createElement("div", {"className": (styles.datepicker_container)},
          React.createElement(DateTimePicker, { \
            "containerClassName": (styles.datepicker),  \
            "placeholder": "Set date",  \
            "time": (false),  \
            "onChange": (@onSetDate),  \
            "value": (@state.date)
          })
        ),
        React.createElement("div", {"className": (styles.timepicker_container)},
          React.createElement(DateTimePicker, { \
            "containerClassName": (styles.datepicker),  \
            "placeholder": "Set time",  \
            "date": (false),  \
            "onChange": (@onSetTime),  \
            "value": (@state.date)
          })
        ),
        React.createElement(Button, { \
          "title": "Save",  \
          "disabled": (!@state.message),  \
          "className": (styles.submit),  \
          "onClick": (@onUpdate)
        }),
        React.createElement(Button, { \
          "title": "Cancel",  \
          "className": (styles.cancel),  \
          "onClick": (@onCancelEdit),  \
          "white": true
        })
      )
    )

  setNewDateTime: (date, time) =>
    datePart = moment(date).format('YYYY-MM-DD')
    timePart = moment(time).format('HH:mm Z')
    @setState date: moment("#{datePart} #{timePart}").toDate()

  onSetDate: (date) =>
    @setNewDateTime(date, @state.date)

  onSetTime: (time) =>
    @setNewDateTime(@state.date, time)


  render: ->
    if @state.isEdit
      @renderEditMode()
    else
      @renderReadMode()

module.exports = ScheduledMessageItemComponent
