moment = require 'moment'
BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
{ updateItems, destroyItem } = require 'lib/admin-utils'

timeToDay = (time) ->
  moment(time).format 'YYYY-MM-DD'

actionMap =
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_REQUESTING}": ->
    @loading = true
    @loadingByDay = {}
    @scheduledMessages = new Map()
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_SUCCESS}": (messages) ->
    @loading = false
    @scheduledMessages = new Map(messages.map((message) -> Object.entries(message)[0]))
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_REQUESTING}": (day) ->
    @loadingByDay[day] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_SUCCESS}": ({day, data}) ->
    @loadingByDay[day] = false
    @scheduledMessages.set(Object.keys(data[0])[0], Object.values(data[0])[0])
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_FAILURE}": ({day, error}) ->
    @loadingByDay[day] = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_REQUESTING}": (message) ->
    @loadingByDay[timeToDay(message.time)] = true
    @error = null
    @updatableScheduledMessage = message
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_SUCCESS}": ({time, data}) ->
    @loadingByDay[timeToDay(time)] = false
    @scheduledMessages = updateItems(
      @scheduledMessages
      'scheduled_messages'
      @updatableScheduledMessage
      data
    )
    @updatableScheduledMessage = {}
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_FAILURE}": ({time, error}) ->
    @loadingByDay[timeToDay(time)] = false
    @error = error
    @updatableScheduledMessage = {}
    @emitChange()

  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_REQUESTING}": (day) ->
    @loadingByDay[day] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_SUCCESS}": ({day, id}) ->
    @loadingByDay[day] = false
    @scheduledMessages = destroyItem(
      @scheduledMessages
      'scheduled_messages'
      day
      id
    )
    @emitChange()
  "#{ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_FAILURE}": ({day, error}) ->
    @loadingByDay[day] = false
    @error = error
    @emitChange()

class ScheduledMessagesStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @loadingByDay = {}
    @updatableScheduledMessage = {}
    @scheduledMessages = new Map()
    @error = null
    @registerActions()

  _registerToActions: ({type, payload}) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new ScheduledMessagesStore()
