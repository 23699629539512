config = require '../config'

GROUP_PER_PAGE = 50

TagsAPI =
  getList: (successCallback, errorCallback, tag_type) ->
    $.ajax
      url: config.urls.tags.getList(tag_type)
      type: 'GET'
      success: successCallback
      error: errorCallback
      timeout: 5000

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.tags.create
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: (id, attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.tags.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  destroy: (id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.tags.destroy(id)
      contentType: 'application/json'
      data: JSON.stringify({ id: id })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

  getGeneralList: (data, success, error) ->
    $.ajax {
      url: config.urls.tags.generalList
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }

  getGroupList: (data, success, error) ->
    url = config.urls.tags.tagsGroups + "?page=0&per_page=#{GROUP_PER_PAGE}"
    if data.sourceType && data.type
      url += "&source_type=#{data.sourceType}&type=#{data.type}"
    if data.all
      url += "&all=true"
    $.ajax {
      url: url
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  createGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.tags.tagsGroups
      contentType: 'application/json'
      type: 'POST'
      data: JSON.stringify(data)
      success
      error
    }

  updateGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.tags.tagsGroup(data.id)
      contentType: 'application/json'
      type: 'PUT'
      data: JSON.stringify(data)
      success
      error
    }

  deleteGroup: (id, success, error) ->
    $.ajax {
      url: config.urls.tags.tagsGroup(id)
      contentType: 'application/json'
      type: 'DELETE'
      success
      error
    }

  getTagsByGroup: (id, data, success, error) ->
    $.ajax {
      url: config.urls.tags.tagsByGroup(id)
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }

  getTagsForOrderGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.tags.orderTags
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }
  getPreselectedTagsForConversation: (success, error) ->
    $.ajax {
      url: config.urls.tags.conversationTags
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

module.exports = TagsAPI
