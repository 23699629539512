classnames = require 'classnames'

styles = require './loader'

class LoaderComponent extends React.Component
  @propTypes:
    className: require('prop-types').string

  render: ->
    React.createElement("div", {"className": (classnames(@props.className, styles.container))},
      React.createElement("img", {"className": (styles.loader), "src": (require("root/assets/images/loader.svg"))})
    )

module.exports = LoaderComponent
