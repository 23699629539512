React = require 'react'
moment = require 'moment'
classnames = require 'classnames'

calendarCalculator = require '../../../lib/calendar-calculator'
styles = require './calendar.styl'
MultistateSwitchComponent = require '../MultistateSwitchComponent'
{ CALENDAR_RANGE_TYPES, CALENDAR_TWO_RANGE_TYPES } = require '../../../constants/calendar'

propTypes = require 'prop-types'

nullRange =
  startDate: null
  endDate: null

typesHandlers =
  daily:
    currentRange: (currentDate) -> startDate: currentDate, endDate: currentDate
    receiveProps: (startDate, _skip, _s, endDate) ->
      @setState
        type: 'daily'
        currentRange:
          if startDate
          then startDate: moment(startDate), endDate: moment(endDate || startDate)
          else nullRange
    onBackDateClick: -> @onBackDayClick
    onForwardDateClick: -> @onForwardDayClick
    showRange: (range) ->
      _fstartDate = if range.startDate then range.startDate.format 'DD MMMM YYYY'
      _fendDate = if range.endDate then range.endDate.format 'DD MMMM YYYY'
      if range.startDate && range.endDate && _fstartDate != _fendDate
        _fstartDate + ' - ' + _fendDate
      else if range.startDate
        _fstartDate
      else 
        'Choose date'
    generateNewRange: (date, _skip, _s, dailyType, beginDate, endDate) ->
      _startDate = if dailyType && dailyType == 'endDate' && beginDate then beginDate else date
      _endDate = if dailyType && dailyType == 'beginDate' && endDate then endDate else date
      startDate: new moment _startDate
      endDate: new moment _endDate
  weekly:
    currentRange: (currentDate, isShiftsMode, isUnlimit) ->
      if currentDate
      then calendarCalculator.getWeekByDate currentDate, isShiftsMode, isUnlimit
      else nullRange
    receiveProps: (startDate, isShiftsMode, isUnlimit) ->
      @setState
        type: 'weekly'
        currentRange:
          if startDate
          then calendarCalculator.getWeekByDate startDate, isShiftsMode, isUnlimit
          else nullRange
    onBackDateClick: -> @onBackWeekClick
    onForwardDateClick: -> @onForwardWeekClick
    showRange: (range) ->
      if range.startDate && range.endDate
      then (
        React.createElement("div", null,
          (range.startDate.format 'DD MMMM YYYY'),
          React.createElement("span", null, " - "),
          (range.endDate.format 'DD MMMM YYYY')
        )
      )
      else 'Choose date'
    generateNewRange: (date, isShiftsMode, isUnlimit) ->
      calendarCalculator.getWeekByDate date, isShiftsMode, isUnlimit
  weekly2:
    currentRange: (currentDate, isShiftsMode, isUnlimit) ->
      if currentDate
      then calendarCalculator.getTwoWeekByDate currentDate, isShiftsMode, isUnlimit
      else nullRange
    receiveProps: (startDate, isShiftsMode, isUnlimit) ->
      @setState
        type: 'weekly2'
        currentRange:
          if startDate
          then calendarCalculator.getTwoWeekByDate startDate, isShiftsMode, isUnlimit
          else nullRange
    onBackDateClick: -> @onBackTwoWeekClick
    onForwardDateClick: -> @onForwardTwoWeekClick
    showRange: (range) ->
      if range.startDate && range.endDate
      then (
        React.createElement("div", null,
          (range.startDate.format 'DD MMMM YYYY'),
          React.createElement("span", null, " - "),
          (range.endDate.format 'DD MMMM YYYY')
        )
      )
      else 'Choose date'
    generateNewRange: (date, isShiftsMode, isUnlimit) ->
      calendarCalculator.getTwoWeekByDate date, isShiftsMode, isUnlimit
  monthly:
    currentRange: (currentDate) ->
      if currentDate
      then startDate: currentDate.startOf 'month', endDate: currentDate.endOf 'month'
      else nullRange
    receiveProps: (startDate) ->
      @setState
        type: 'monthly'
        currentRange:
          if startDate
          then calendarCalculator.getMonthByDate startDate, @props.isShiftsMode
          else nullRange
    onBackDateClick: -> @onBackMonthClick
    onForwardDateClick: -> @onForwardMonthClick
    showRange: (range) ->
      if range.startDate && range.endDate
      then (
        React.createElement("div", null,
          (range.startDate.format 'DD MMMM YYYY'),
          React.createElement("span", null, " - "),
          (range.endDate.format 'DD MMMM YYYY')
        )
      )
      else 'Choose date'
    generateNewRange: (date, isShiftsMode = false) ->
      calendarCalculator.getMonthByDate date, isShiftsMode

class CalendarComponent extends React.Component
  @propTypes:
    beginDate: propTypes.oneOfType(
      [propTypes.instanceOf(moment), propTypes.string]
    )
    endDate: propTypes.oneOfType(
      [propTypes.instanceOf(moment), propTypes.string]
    )
    onSelectHandler: propTypes.func
    type: propTypes.oneOf([CALENDAR_RANGE_TYPES..., CALENDAR_TWO_RANGE_TYPES...]).isRequired
    onChangeTypeHandler: propTypes.func.isRequired
    disableRangeChanging: propTypes.bool
    isShiftsMode: propTypes.bool
    isUnlimit: propTypes.bool

  constructor: (props) ->
    super props
    currentDate = props.beginDate || props.endDate
    currentDate = if currentDate then new moment currentDate else null
    today = new moment
    currentRange = typesHandlers[props.type].currentRange(currentDate, props.isShiftsMode, props.isUnlimit)
    CALENDARE_RANGE = if props.weeksTwo then CALENDAR_TWO_RANGE_TYPES else CALENDAR_RANGE_TYPES
    @state =
      currentRange: currentRange
      openCalendar: false
      currentMonth: calendarCalculator.generateMonthDates today
      types: props.types || CALENDARE_RANGE
      dailyType: props.dailyType || null
      type: 'daily'

  componentWillReceiveProps: (props) ->
    typesHandlers[props.type].receiveProps.call(
      @
      props.beginDate || props.endDate
      props.isShiftsMode
      props.isUnlimit
      props.endDate
    )

  onBackWeekClick: =>
    newState = {}
    newDate = new moment(@state.currentRange.startDate).subtract 1, 'w'
    newState.currentRange = calendarCalculator.getWeekByDate newDate
    if newState.currentRange.startDate.diff(@state.currentMonth[0][0], 'd') < 0
      newState.currentMonth = calendarCalculator.generateMonthDates(
        newState.currentRange.startDate
      )
    @props.onSelectHandler(newState.currentRange) if @props.onSelectHandler
    @setState newState

  onForwardWeekClick: =>
    newState = {}
    newDate = new moment(@state.currentRange.endDate).add 1, 'w'
    newState.currentRange = calendarCalculator.getWeekByDate newDate
    lastMonthDay = @state.currentMonth[@state.currentMonth.length - 1][6]
    if lastMonthDay.diff(newState.currentRange.endDate, 'd') < 0
      newState.currentMonth = calendarCalculator.generateMonthDates(
        newState.currentRange.endDate
      )
    @props.onSelectHandler(newState.currentRange) if @props.onSelectHandler
    @setState newState

  onBackTwoWeekClick: =>
    newState = {}
    newDate = new moment(@state.currentRange.startDate).subtract 2, 'w'
    newState.currentRange = calendarCalculator.getTwoWeekByDate newDate
    if newState.currentRange.startDate.diff(@state.currentMonth[0][0], 'd') < 0
      newState.currentMonth = calendarCalculator.generateMonthDates(
        newState.currentRange.startDate
      )
    @props.onSelectHandler(newState.currentRange) if @props.onSelectHandler
    @setState newState

  onForwardTwoWeekClick: =>
    newState = {}
    newDate = new moment(@state.currentRange.endDate).add 1, 'w'
    newState.currentRange = calendarCalculator.getTwoWeekByDate newDate
    lastMonthDay = @state.currentMonth[@state.currentMonth.length - 1][6]
    if lastMonthDay.diff(newState.currentRange.endDate, 'd') < 0
      newState.currentMonth = calendarCalculator.generateMonthDates(
        newState.currentRange.endDate
      )
    @props.onSelectHandler(newState.currentRange) if @props.onSelectHandler
    @setState newState

  onBackDayClick: =>
    newState = {}
    newState.currentRange = new moment(@state.currentRange.endDate).subtract 1, 'd'
    if @state.currentMonth[0][6].month() != newState.currentRange.month()
      newState.currentMonth = calendarCalculator.generateMonthDates newState.currentRange
    @props.onSelectHandler({
      startDate: newState.currentRange
      endDate: newState.currentRange
    }) if @props.onSelectHandler
    @setState newState

  onForwardDayClick: =>
    newState = {}
    newState.currentRange = new moment(@state.currentRange.endDate).add 1, 'd'
    if @state.currentMonth[@state.currentMonth.length - 1][0].month() != newState.currentRange.month()
      newState.currentMonth = calendarCalculator.generateMonthDates newState.currentRange
    @props.onSelectHandler({
      startDate: newState.currentRange
      endDate: newState.currentRange
    }) if @props.onSelectHandler
    @setState newState

  onBackMonthClick: =>
    date = @state.currentRange.startDate.subtract(1, 'week')
    newState = {
      currentRange: calendarCalculator.getMonthByDate date, @props.isShiftsMode
      currentMonth: calendarCalculator.generateMonthDates date
    }
    @props.onSelectHandler({
      startDate: newState.currentRange.startDate
      endDate: newState.currentRange.endDate
    }) if @props.onSelectHandler
    @setState newState

  onForwardMonthClick: =>
    date = @state.currentRange.startDate.add(1, 'months').add(1, 'week')
    newState = {
      currentRange: calendarCalculator.getMonthByDate date, @props.isShiftsMode
      currentMonth: calendarCalculator.generateMonthDates date
    }
    @props.onSelectHandler({
      startDate: newState.currentRange.startDate
      endDate: newState.currentRange.endDate
    }) if @props.onSelectHandler
    @setState newState

  onDateClick: =>
    @setState openCalendar: true
    document.addEventListener 'click', @blurCalendar
    document.addEventListener 'keyup', (e) =>
      # check 'escape' pressed
      if e.keyCode == 27
        @blurCalendar(e)

  blurCalendar: (e) =>
    e.stopPropagation()
    unless $(e.target).parents('#calendar').length || $(e.target).parents('[data-calendar]').length
      @setState openCalendar: false
      document.removeEventListener 'click', @blurCalendar
      document.removeEventListener 'keyup', @blurCalendar

  backMonth: =>
    newDate = new moment @state.currentMonth[1][0]
    newDate.subtract 1, 'months'
    newMonth = calendarCalculator.generateMonthDates newDate
    @setState currentMonth: newMonth

  forwardMonth: =>
    newDate = new moment @state.currentMonth[1][0]
    newDate.add 1, 'months'
    newMonth = calendarCalculator.generateMonthDates newDate
    @setState currentMonth: newMonth

  selectNewRange: (e) =>
    { date } = e.target.dataset
    range = typesHandlers[@props.type].generateNewRange date, @props.isShiftsMode, @props.isUnlimit, @props.dailyType, @props.beginDate, @props.endDate
    @props.onSelectHandler(range) if @props.onSelectHandler
    newState =
      highlightedRange: null
      currentRange: range
      openCalendar: false
    newState.currentMonth = calendarCalculator.generateMonthDates range.startDate
    @setState newState

  onChangeTypeHandler: (type) =>
    @setState
      type: type
    @props.onChangeTypeHandler type

  generateCalendar: =>
    if @state.openCalendar
      dateInCurrentMonth = @state.currentMonth[1][0]
      currentRange = @state.currentRange
      selectNewRange = @selectNewRange
      that = @
      isMonthlyType = that.props.type == 'monthly'
      isTwoWeeklyType = that.props.type == 'weekly2'
      React.createElement("div", {"className": (styles.calendar)},
        (if !@props.hideArrow 
          React.createElement("span", {"className": (styles.calendar_arrow)})
        ),
        (unless @props.disableRangeChanging
          React.createElement("div", {"className": (styles.calendar__toggle)},
            React.createElement(MultistateSwitchComponent, { \
              "onToggle": (@props.onChangeTypeHandler),  \
              "values": (@state.types),  \
              "defaultValue": (@state.type),  \
              "fontStyle": (styles['calendar__toggle-range-label'])
            })
          )
        ),
        React.createElement("div", {"className": (styles.calendar__data)},
          React.createElement("div", {"className": (styles['calendar__data-controls'])},
            React.createElement("button", { \
              "ref": "backMonth",  \
              "className": (styles['calendar__data-button']),  \
              "onClick": (@backMonth)
            },
              React.createElement("span", null)
            ),
            React.createElement("span", {"className": (styles['calendar__data-month'])},
              (dateInCurrentMonth.format 'MMMM YYYY')
            ),
            React.createElement("button", { \
              "className": (classnames(
                styles['calendar__data-button'], styles['calendar__data-button_forward']
              )),  \
              "ref": "forwardMonth",  \
              "onClick": (@forwardMonth)
            },
              React.createElement("span", null)
            )
          ),
          React.createElement("div", {"className": (styles.calendar__table)},
            React.createElement("div", null,
              React.createElement("p", {"className": (styles['calendar__table-head'])},
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Mo"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Tu"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "We"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Th"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Fr"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Sa"),
                React.createElement("span", {"className": (styles['calendar__table-head-item'])}, "Su")
              )
            ),
            React.createElement("div", null,
              (@state.currentMonth.map (week, i) ->
                React.createElement("p", { \
                  "key": (i),  \
                  "className": (styles['calendar__table-body-row'])
                },
                  (week.map (day, j) ->
                    colored = that.checkPlaceInRange(
                      day, currentRange
                    )
                    hoverColored = that.checkPlaceInRange(
                      day, that.state.highlightedRange
                    )
                    isNotColored = !colored.isBegin &&
                      !colored.isMiddle && !colored.isEnd
                    React.createElement("span", { \
                      "key": (j),  \
                      "className": (classnames styles['calendar__table-body-item'],
                        "#{styles['calendar__table-body-item_gray']}": day.month() != dateInCurrentMonth.month()
                        "#{styles.range_date_begin}": colored.isBegin ||
                          hoverColored.isBegin
                        "#{styles.range_date_middle}": colored.isMiddle ||
                          hoverColored.isMiddle
                        "#{styles.range_date_end}": colored.isEnd ||
                          hoverColored.isEnd
                        "#{styles.range_date_begin_several_lines}": (colored.isBegin || hoverColored.isBegin) && (isMonthlyType || isTwoWeeklyType)
                        "#{styles.range_date_end_several_lines}": (colored.isEnd || hoverColored.isEnd) && (isMonthlyType || isTwoWeeklyType)
                      ),  \
                      "data-date": (day.format 'YYYY-MM-DD'),  \
                      "onClick": (selectNewRange),  \
                      "onMouseEnter": (that.onDayHover if isNotColored),  \
                      "onMouseLeave": (that.onDayBlur if isNotColored)
                    },
                      (day.format 'D')
                    )
                  )
                )
              )
            )
          )
        ),
        (if @props.isResetable
          React.createElement("button", { \
            "className": (styles.reset_button),  \
            "onClick": (@onResetCalendar)
          }, """
            Reset calendar
""")
        )
      )
    else
      null

  onDayHover: (e) =>
    { date } = e.target.dataset
    range = typesHandlers[@props.type].generateNewRange date, @props.isShiftsMode, @props.isUnlimit
    @setState highlightedRange: range

  onDayBlur: =>
    @setState highlightedRange: null

  onResetCalendar: =>
    @props.onSelectHandler(nullRange) if @props.onSelectHandler
    newState =
      highlightedRange: null
      currentRange: nullRange
      openCalendar: false
    newState.currentMonth = calendarCalculator.generateMonthDates new moment
    @setState newState

  checkPlaceInRange: (date, dateRange) ->
    return {} unless dateRange

    isBegin = date.isSame(dateRange.startDate, 'day')
    isEnd = date.isSame(dateRange.endDate, 'day')
    isMiddle = date.isAfter(dateRange.startDate, 'day') &&
      date.isBefore(dateRange.endDate, 'day')

    {
      isBegin
      isEnd
      isMiddle
    }

  render: ->
    <>
    {
      if @props.children
        <>{React.cloneElement(@props.children, { onDateClick: @onDateClick })}</>

      else
        React.createElement("div", {"id": 'calendar', "className": (styles.content)},
          React.createElement("div", { \
                "className": (classnames styles.slider,
                  "#{styles.slider_active}": @state.openCalendar
                )
              },
                React.createElement("button", { \
                  "ref": "backRangeButton",  \
                  "className": (styles.slider__controls),  \
                  "disabled": (!@state.currentRange.startDate),  \
                  "onClick": (
                    typesHandlers[@props.type].onBackDateClick.call @
                  )
                },
                  React.createElement("span", {"className": (styles['slider__controls-label'])})
                ),
                React.createElement("span", { \
                  "ref": "calendarTrigger",  \
                  "className": (styles.slider__date),  \
                  "onClick": (@onDateClick)
                },
                  (
                    typesHandlers[@props.type].showRange @state.currentRange
                  )
                ),
                React.createElement("button", { \
                  "ref": "forwardRangeButton",  \
                  "className": (classnames(
                    styles.slider__controls,
                    styles.slider__controls_forward
                  )),  \
                  "disabled": (!@state.currentRange.startDate),  \
                  "onClick": (
                    typesHandlers[@props.type].onForwardDateClick.call @
                  )
                },
                  React.createElement("span", { \
                    "className": (classnames(
                      styles['slider__controls-label']
                      styles['slider__controls-label_forward']
                    ))
                  })
                )
            ),
            (@generateCalendar())
        )
    }
    {if @props.children then @generateCalendar()}
    </>

module.exports = CalendarComponent
