pluralize = require 'pluralize'
moment = require 'moment'

{STATISTIC_DAYS, MILISECONDS_IN_HOUR,
MILISECONDS_IN_DAY, SECONDS_IN_MINUTE} = require '../constants/base'

buildOptions = (options) ->
  plotOptions = options.plotOptions
  plotOptions.column = {} unless plotOptions.column
  plotOptions.line = {} unless plotOptions.line
  plotOptions.series = {} unless plotOptions.series
  plotOptions.series.animation = false
  plotOptions.column.animation = false
  plotOptions.line.animation = false
  chartOptions = options.chart
  chartOptions.animation = false
  if options.tooltip
    options.tooltip.animation = false
    options.tooltip.shared = true

  yAxis = options.yAxis || {} 
  chart: chartOptions
  title: options.title
  xAxis: options.xAxis
  yAxis:
    min: yAxis.min || 0
    minRange: yAxis.minRange
    plotLines: yAxis.plotLines || []
    title:
      text: options.yTitle
  legend: options.legend || enabled: true
  tooltip: options.tooltip || {
    animation: false
    shared: true
    formatter: () ->
      s = ''
      total = 0
      $.each @points, () ->
        s += "#{@series?.name}: #{@y} #{pluralize(options.yTitle.toLowerCase(), @y)}<br/>"
        total += @y
      s += "<b>Total: #{total} #{pluralize(options.yTitle.toLowerCase(), total)}</b><br/>" if @points.length >= 2 && options.yTitle != 'Minutes'
      s
    }
  plotOptions: plotOptions
  series: options.series
  credits: false

getShiftStartTime = (now, offset) =>
  {shiftNum, startHours, endHours} = getCurrentShift(moment().utcOffset(offset).hours())
  now = Math.floor(now / MILISECONDS_IN_HOUR) * MILISECONDS_IN_HOUR
  nowHours = moment(now).utcOffset(offset).hours()
  if startHours > nowHours
    xAxisLength = 24 - startHours + nowHours
  else
    xAxisLength = nowHours - startHours
  xAxisStart = now - MILISECONDS_IN_HOUR * xAxisLength
  {xAxisStart, xAxisLength, now}

getCurrentShift = (now)->
  hoursOfShift = [23, 15, 7]
  for hours, i in hoursOfShift
    if hours <= now
      return shiftNum: i, startHours: hoursOfShift[i], endHours: hoursOfShift[i - 1] || hoursOfShift[hoursOfShift.length - 1]
  shiftNum: 0, startHours: hoursOfShift[0], endHours: hoursOfShift[hoursOfShift.length - 1]

module.exports = {
  buildOptions
  getShiftStartTime
  getCurrentShift
}
