PersonalizedStore = require '../../../stores/abstract/PersonalizedStore'
AgentProfileWrapperActionCreator = require './AgentProfileWrapperActionCreator'
ActionTypes = require '../../../constants/ActionTypes'


_successChanging = (payload) ->
  @loading.createOrUpdate = false
  @errors = null
  @agent = payload
  @emitChange()

_failChanging = (errors) ->
  @loading.createOrUpdate = false
  @errors = errors
  @emitChange()

actionsMap =
  "#{ActionTypes.AGENTS_GET_REQUESTING}": ->
    @loading.get = true
    @emitChange()
  "#{ActionTypes.AGENTS_GET_SUCCESS}": (payload) ->
    @loading.get = false
    @errors = null
    @agent = payload
    @emitChange()
  "#{ActionTypes.AGENTS_GET_FAILURE}": (payload) ->
    @error = payload
    @loading.get = false
    @emitChange()
  "#{ActionTypes.AGENTS_CREATE_REQUESTING}": ->
    @loading.createOrUpdate = true
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_REQUESTING}": (payload) ->
    @loading.createOrUpdate = true
    @emitChange()
  "#{ActionTypes.AGENTS_CREATE_SUCCESS}": _successChanging
  "#{ActionTypes.AGENTS_UPDATE_SUCCESS}": _successChanging
  "#{ActionTypes.AGENTS_CREATE_FAILURE}": _failChanging
  "#{ActionTypes.AGENTS_UPDATE_FAILURE}": _failChanging
  "#{ActionTypes.AGENT_REMOVE_ERRORS}": ->
    @errors = null
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_AVATAR_REQUESTING}": ->
    @loading.createOrUpdate = true
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_AVATAR_SUCCESS}": ({image}) ->
    @agent.image = image
    @loading.createOrUpdate = false
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_AVATAR_FAILURE}": _failChanging

class AgentProfileWrapperStore extends PersonalizedStore
  constructor: ->
    super()
    @agent = null
    @loading = {}
    @errors = null
    @registerActions()

  getActionCreator: -> new AgentProfileWrapperActionCreator()

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = AgentProfileWrapperStore
