PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
LastDaysByShiftActionCreator = require './LastDaysByShiftActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap = 
  "#{ActionTypes.LAST_DAYS_STAT_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.LAST_DAYS_STAT_SUCCESS}": (data) ->
    @loading = false
    @uniq_clients = data.uniq_clients
    @conversation = data.conversation
    @waiting_time = data.waiting_time
    @answer_times = data.answer_times
    @analyticDaysInterval = data.analytic_days_interval
    @good_rt = data.good_rt
    @good_wt = data.good_wt
    @emitChange()
  "#{ActionTypes.LAST_DAYS_STAT_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class LastDaysByShiftStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @uniq_clients = 
      0: []
      1: []
      2: []
    @conversation = 
      0: []
      1: []
      2: []
    @waiting_time = 
      0: []
      1: []
      2: []
    @answer_times = 
      0: []
      1: []
      2: []
    @analyticDaysInterval = 0
    @good_rt = null
    @good_wt = null
    @error = null
    @registerActions()

  getActionCreator: -> new LastDaysByShiftActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = LastDaysByShiftStore
