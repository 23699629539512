GroupItemComponent = require './GroupItemComponent'
styles = require './group-list'

propTypes = require 'prop-types'

groupListComponent = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement("ul", {"className": (styles.list)},
      (props.groups.map (group) ->
        React.createElement("li", {"key": (group.id), "className": (styles.item_container)},
          React.createElement(GroupItemComponent, { \
            "group": (group),  \
            "onGroupDelete": (props.onGroupDelete)
          })
        )
      )
    )
  )

groupListComponent.propTypes = 
  groups: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired
      title: propTypes.string.isRequired
    })
  ).isRequired
  onGroupDelete: propTypes.func.isRequired

module.exports = groupListComponent