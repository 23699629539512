browserHistory = require './../../../history'

AgentsAPI = require '../../../api/AgentsAPI'
GuidedActionCreator = require '../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../constants/ActionTypes'

class AgentProfileWrapperActionCreator extends GuidedActionCreator

  get: (id) =>
    dispatch = @dispatch
    setTimeout(() ->
        dispatch type: ActionTypes.AGENTS_GET_REQUESTING
        AgentsAPI.get(
          id
          (data) =>
            dispatch type: ActionTypes.AGENTS_GET_SUCCESS, payload: data
          (response) =>
            msg = ''
            if response.status == 404
              msg = "Agent with id - #{id} is not found"
            else
              msg = "An error occurred while fetching agent with id - #{id}"
            dispatch type: ActionTypes.AGENTS_GET_FAILURE, payload: msg
        )
      10
    )

  create: (attributes) =>
    dispatch = @dispatch
    dispatch type: ActionTypes.AGENTS_CREATE_REQUESTING
    AgentsAPI.create attributes,
      (response) ->
        if response.success
          dispatch type: ActionTypes.AGENTS_CREATE_SUCCESS, payload: response.data
          browserHistory.default.push "/agents/#{response.data.id}/shifts"
        else
          dispatch type: ActionTypes.AGENTS_CREATE_FAILURE, payload: 'Some error'
      (error) -> dispatch type: ActionTypes.AGENTS_CREATE_FAILURE, payload: error.responseJSON.errors

  update: (id, attributes, successCallback) =>
    dispatch = @dispatch
    dispatch type: ActionTypes.AGENTS_UPDATE_REQUESTING
    AgentsAPI.update { id, attributes },
      (response) ->
        if response.success
          dispatch
            type: ActionTypes.AGENTS_UPDATE_SUCCESS
            payload: response.data
          successCallback() if successCallback
        else
          dispatch
            type: ActionTypes.AGENTS_UPDATE_FAILURE
            payload: response.errors
      (error) ->
        console.error error.responseJSON
        dispatch
          type: ActionTypes.AGENTS_UPDATE_FAILURE
          payload: 'An error occurred while updating user'

  updateAvatar: (id, data) =>
    dispatch = @dispatch
    dispatch type: ActionTypes.AGENTS_UPDATE_AVATAR_REQUESTING
    AgentsAPI.updateAvatar {id, data},
      (response) ->
        if response.success
          dispatch
            type: ActionTypes.AGENTS_UPDATE_AVATAR_SUCCESS
            payload:
              image: response.image
              id: Number.parseInt response.user_id
        else
          dispatch type: ActionTypes.AGENTS_UPDATE_AVATAR_FAILURE, payload: response.error
      (error) ->
        dispatch type: ActionTypes.AGENTS_UPDATE_AVATAR_FAILURE, payload: error.responseJSON

  removeErrors: =>
    @dispatch type: ActionTypes.AGENT_REMOVE_ERRORS


module.exports = AgentProfileWrapperActionCreator
