PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
MessageErrorsActionCreator = require './MessageErrorsActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.MESSAGE_ERRORS_REQUESTING}": ->
    @loading = true
    @statistic = {
      total: 0
      messages: []
    }
    @error = null
    @emitChange()
  "#{ActionTypes.MESSAGE_GLOSSARY_ERRORS_REQUESTING}": ->
    @loading = true
    @statisticGlossary = {
      total: 0
      rows: []
    }
    @error = null
    @emitChange()
  "#{ActionTypes.MESSAGE_ERRORS_SUCCESS}": (data) ->
    @loading = false
    @statistic = data
    @emitChange()
  "#{ActionTypes.MESSAGE_GLOSSARY_ERRORS_SUCCESS}": (data) ->
    @loading = false
    @statisticGlossary = data
    @emitChange()
  "#{ActionTypes.MESSAGE_ERRORS_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

class MessageErrorsStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @statistic = {
      total: 0
      messages: []
    }
    @statisticGlossary = {
      total: 0
      rows: []
    }
    @error = null
    @registerActions()

  getActionCreator: -> new MessageErrorsActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = MessageErrorsStore
