String.prototype.truncateRange = (start, end, options = {}) ->
  padding = options.padding || 0
  truncation = options.truncation || '...'
  truncateStart = true
  truncateEnd = true
  start = start - padding
  end = end + padding


  if start <= 0
    start = 0
    truncateStart = false
  if end >= @length
    end = @length
    truncateEnd = false

  result = @substring(start, end)
  result = truncation + result if truncateStart
  result = result + truncation if truncateEnd
  result

String.prototype.matchesWithIndex = (regexp) ->
  matches = []
  while (match = regexp.exec(@)) != null
    matches.push content: match[0], position: match.index
  matches