classnames = require 'classnames'
Checkbox = require 'components/shared/Checkbox'
styles = require './tag.styl'

module.exports = (props) ->
  React.createElement("div", {"className": (classnames(styles.container, {"#{[styles.disabled]}": props.disabled })), "onClick": (-> props.onClick(props.tag) unless (props.loading || props.disabled))},
    (
      if props.loading
        React.createElement("img", { \
          "className": (styles.tag_target_loading),  \
          "src": (require '../../../../assets/images/loader.svg')
        })
      else
        React.createElement(Checkbox, {"checked": (props.selected), "disabled": (props.disabled)})
    ),
    React.createElement("span", {"className": ((styles.label))}, (props.tag.name))
  )
