classnames = require 'classnames'

styles = require './system-attention'

propTypes = require 'prop-types'

attentionComponent = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement("span", {"className": (classnames styles.text, props.textStyle)},
      (props.message)
    ),
    React.createElement("button", {"className": (styles.button), "onClick": (-> location.reload())},
      (props.button)
    )
  )

attentionComponent.propTypes = 
  message: propTypes.string.isRequired
  button: propTypes.string.isRequired
  textStyle: propTypes.string

module.exports = attentionComponent
