React = require 'react'
classnames = require 'classnames'
Dropzone = require 'dropzone'
{v4} = require 'uuid'
_ = require 'underscore'
styles = require './file-uploader'
config = require 'root/config'
{ getToken } = require 'lib/tokenStorage'
HTTP_STATUS = require 'constants/HttpStatuses'
{ oauthRedirect } = require 'lib/utils'

propTypes = require 'prop-types'

FILE_IS_TOO_BIG_REGEXP = /file is too big/i
MAX_FILE_SIZE_IN_MIB = 20


class FileUploaderComponent extends React.Component

  constructor: ->
    super()
    @state =
      filePreviews: {}
      uploadingBegined: false
      readyFiles: {}

  componentDidMount: ->
    @initDropzone()

  componentWillUnmount: ->
    @dropzone.destroy() if @dropzone

  initDropzone: =>
    cookie = decodeURIComponent(document.cookie)

    Dropzone.autoDiscover = false
    headers = {}
    token = getToken()
    headers.Authorization = token if token
    options =
      url: config.urls.attach_file
      clickable: "#clickable"
      parallelUploads: 5
      maxFilesize: MAX_FILE_SIZE_IN_MIB
      withCredentials: true
      addedfile: @addedfile
      headers: headers
      uploadprogress: @uploadprogress
      success: @success
      error: @error
      reset: @reset
      queuecomplete: @queuecomplete
    @dropzone = new Dropzone document.body, options

  addedfile: (file) =>
    @props.beginUploading()

    hashName = v4()
    file.hashName = hashName
    file.progress = 0
    filePreviews = @state.filePreviews
    filePreviews[hashName] = file

    {readyFiles} = @state
    readyFiles[hashName] = { conversationId: @props.conversationId }

    @refs.files.scrollTop = @refs.files.scrollHeight
    @setState {
      filePreviews
      uploadingBegined: true
      readyFiles
    }

  uploadprogress: (file, progress) =>
    file.progress = progress
    filePreviews = @state.filePreviews
    filePreviews[file.hashName].progress = progress
    @setState {filePreviews}

  success: (file, response) =>
    if response.success
      file.success = true
      file.url = response.url

      {filePreviews} = @state
      filePreviews[file.hashName].success = true
      filePreviews[file.hashName].url = response.url

      {readyFiles} = @state
      readyFiles[file.hashName].url = response.url

      @setState {
        filePreviews
        readyFiles
      }
    else
      @error file, null, response

  error: (file, errorMessage, response, msg = 'Upload error') =>
    if errorMessage.match(FILE_IS_TOO_BIG_REGEXP)
      msg = "Max is #{MAX_FILE_SIZE_IN_MIB}Mib"

    if response && (response.status == HTTP_STATUS.UNAUTHORIZED || response.status == HTTP_STATUS.FORBIDDEN)
      oauthRedirect()

    console.error msg, errorMessage, response
    file.error = msg
    filePreviews = @state.filePreviews
    filePreviews[file.hashName].error = msg
    @setState {filePreviews}

  reset: =>
    @props.resetUploading()
    @setState
      uploadingBegined: false
      readyFiles: {}
      filePreviews: {}

  queuecomplete: =>
    @props.readyForSend(@state.readyFiles)

  onRemoveFile: (e) =>
    hashName = e.target.dataset.hashName
    @removeFile(@state.filePreviews[hashName])

  initialDropzoneState: =>
    _.mapObject(@state.filePreviews, (file) =>
      @removeFile file
    )

    @props.resetUploading()
    @setState
      uploadingBegined: false
      readyFiles: {}
      filePreviews: {}

  removeFile: (file) =>
    @dropzone.removeFile file
    {readyFiles, filePreviews} = @state
    filePreviews[file.hashName].removed = true
    delete readyFiles[file.hashName]
    if _.isEmpty(readyFiles)
      @reset()

    @setState {filePreviews, readyFiles}

    setTimeout(
      () =>
        delete filePreviews[file.hashName]
        @setState {filePreviews}
      500
    )

  componentWillReceiveProps: (nextProps) ->
    if nextProps.doResetUploading
      @reset()

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("span", { \
        "id": 'clickable',  \
        "ref": "clickable",  \
        "className": (classnames(styles.upload_button,
          "#{styles.upload_button_top}": @state.uploadingBegined
        ))
      }),
      React.createElement("div", {"ref": "files", "className": (styles.files_container)},
        (Object.keys @state.filePreviews
          .map (hashName, i) =>
            file = @state.filePreviews[hashName]
            React.createElement("div", { \
              "key": (i),  \
              "ref": ("#{hashName}"),  \
              "className": (classnames(styles.file_container,
                "#{styles.file_container_removed}": file.removed
              ))
            },
              React.createElement("div", {"className": (styles.file_state)},
                React.createElement("i", { \
                  "className": (classnames(styles.progress,
                    "#{styles.progress_hide}": file.success || file.error
                  ))
                }),
                React.createElement("span", {"className": (styles.filename)}, (file.name))
              ),
              (unless file.removed
                React.createElement("span", { \
                  "className": (classnames(styles.cancel,
                    "#{styles.cancel_hidden}": file.success || file.error
                  )),  \
                  "data-hash-name": (hashName),  \
                  "onClick": (@onRemoveFile)
                })
              ),
              React.createElement("div", {"className": (styles.error)}, (file.error))
            )
        )
      )
    )

FileUploaderComponent.propTypes =
  beginUploading: propTypes.func.isRequired
  resetUploading: propTypes.func.isRequired
  readyForSend: propTypes.func.isRequired
  doResetUploading: propTypes.bool

module.exports = FileUploaderComponent
