moment = require 'moment'
Isvg = require 'react-inlinesvg'
classnames = require 'classnames'

styles = require './scheduled-messages.styl'

class ScheduledMessagesComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      selectedMessage: null
    }

  componentWillReceiveProps: (nextProps) ->
    return unless @state.selectedMessage

    { selectedMessage } = @state
    filteredMessage = @props.messages.filter((m) -> m.id == selectedMessage.id)[0]
    if filteredMessage && filteredMessage.text != selectedMessage.text
      @setState selectedMessage: filteredMessage
    else if !filteredMessage
      @setState selectedMessage: null

  onMessageClick: (e) =>
    messageId = parseInt e.currentTarget.dataset.id, 10
    selectedMessage = @props.messages.filter((m) -> m.id == messageId)[0]
    @setState { selectedMessage } if selectedMessage

  hideMessage: =>
    @setState selectedMessage: null

  render: ->
    return null if !@props.messages || !@props.messages.length
    { onMessageClick } = @
    { selectedMessage } = @state
    React.createElement("div", {"className": (styles.container), "tabIndex": "1", "onBlur": (@hideMessage)},
      React.createElement("div", {"className": (styles.header_container)},
        React.createElement("ul", {"className": (styles.header)},
          (@props.messages.map (message) ->
            React.createElement("li", { \
              "key": (message.id),  \
              "className": (classnames(styles.header_item,
                "#{styles.header_item_active}": (selectedMessage || {}).id == message.id
              )),  \
              "onClick": (onMessageClick),  \
              "data-id": (message.id)
            },
              React.createElement("span", {"className": (styles.icon)},
                React.createElement(Isvg.default, {"src": (require 'components/shared/images/scheduled-message.svg')})
              ),
              React.createElement("span", {"className": (styles.header_item_title)},
                (moment(message.time).format('DD/MM/YYYY, HH:mm'))
              )
            )
          )
        ),

        (if @state.selectedMessage
          React.createElement("span", {"className": (styles.hide), "onClick": (@hideMessage)},
            React.createElement(Isvg.default, {"src": (require 'components/shared/images/cross.svg')}),
            React.createElement("span", {"className": (styles.hide_text)}, "Hide")
          )
        )
      ),

      React.createElement("p", {"className": (classnames(styles.message,
          "#{styles.message_opened}": @state.selectedMessage
        ))
      },
        (@state.selectedMessage?.text)
      )
    )

module.exports = ScheduledMessagesComponent
