classnames = require 'classnames'
onClickOutside = require 'react-onclickoutside'

styles = require 'components/admin/assets/item-activities.styl'

class ScheduledItemActivities extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      open: false

  handleClickOutside: =>
    if @state && @state.open
      @setState open: false

  onOpen: (e) =>
    e.stopPropagation()
    @setState
      open: !@state.open

  onClose: =>
    @setState
      open: false

  onDelete: (e) =>
    e.stopPropagation()
    @props.onDelete()
    @onClose()

  onEdit: (e) =>
    e.stopPropagation()
    @props.onEdit()
    @onClose()

  onOpenConversation: (e) =>
    e.stopPropagation()
    @onClose()

  render: =>
    (
      React.createElement("div", {"className": (classnames(
        @props.className,
        styles.container
      ))},
        React.createElement("button", { \
          "className": (classnames(
            styles.open_button,
            "#{styles.open_button_is_open}": @state.open
          )),  \
          "onClick": (@onOpen)
        },
          React.createElement("span", null),
          React.createElement("span", null),
          React.createElement("span", null)
        ),
        (if @state.open
          React.createElement("div", {"className": (styles.activities)},
            React.createElement("ul", {"className": (styles.activities_list)},
              React.createElement("li", {"onClick": (@onDelete)}, "Delete"),
              React.createElement("li", {"onClick": (@onEdit)}, "Edit"),
              React.createElement("li", {"onClick": (@onOpenConversation)},
                React.createElement("a", {"href": (@props.conversationLink), "target": '_blank'}, "Open conversation")
              )
            )
          )
        )
      )
    )

module.exports = onClickOutside.default(ScheduledItemActivities)