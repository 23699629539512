config = require 'root/config'

RotationsAPI =
  getList: (data, success, error) ->
    $.ajax {
      url: config.urls.rotations.getList
      type: 'GET'
      data
      success
      error
      timeout: 5000
    }

  get: (id, success, error) ->
    $.ajax {
      url: config.urls.rotations.get(id)
      type: 'GET'
      success
      error
      timeout: 5000
    }

  destroy: (id, success, error) ->
    $.ajax {
      url: config.urls.rotations.get(id)
      type: 'DELETE'
      success
      error
      timeout: 5000
    }

  create: (data, success, error) ->
    $.ajax {
      url: config.urls.rotations.create
      contentType: 'application/json'
      data: JSON.stringify(data)
      type: 'POST'
      success
      error
      timeout: 5000
    }

  update: (data, success, error) ->
    $.ajax {
      url: config.urls.rotations.update(data.id)
      contentType: 'application/json'
      data: JSON.stringify(data)
      type: 'PUT'
      success
      error
      timeout: 5000
    }

module.exports = RotationsAPI
