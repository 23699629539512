GuidedActionCreator = require '../../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
DashboardAPI = require '../../../../api/DashboardAPI'

class ConversationsEachHourActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.CONVERSATIONS_EACH_HOUR_REQUESTING

    DashboardAPI.getConversationsEachHour(
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.CONVERSATIONS_EACH_HOUR_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.CONVERSATIONS_EACH_HOUR_ERROR
            payload: json.error
      (response) =>
        msg = 'An error occurred while getting conversations each hour statistic'
        @dispatch
          type: ActionTypes.CONVERSATIONS_EACH_HOUR_ERROR
          payload: msg
    )

module.exports = ConversationsEachHourActionCreator