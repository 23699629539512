moment = require 'moment'

BaseComponent = require 'components/BaseComponent'
RemindersActionCreator = require 'actions/admin/RemindersActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AccordionByDate = require 'components/admin/AccordionByDate'
OnlineStore = require 'stores/OnlineStore'
RemindersStore = require 'stores/admin/RemindersStore'
{ getQueryParams } = require 'lib/utils'
styles = require 'components/admin/assets/common-styles.styl'

class RemindersComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1
    @state =
      searchOptions: params
    @initComponent()

  dependsOnStores: [RemindersStore, OnlineStore]

  componentWillMount: =>
    options = Object.assign(
      {}
      @props.agent
      @props.dateRange
      {
        timezone: moment.tz.guess()
        search_query: @props.search
      }
    )
    @setState searchOptions: options
    setTimeout(
      -> RemindersActionCreator.getList(options)
      10
    )

  componentWillReceiveProps: (nextProps) =>
    options = Object.assign(
      {}
      @props.agent
      @props.dateRange
      {
        timezone: moment.tz.guess()
        search_query: @props.search
      }
    )
    nextOptions = Object.assign(
      {}
      nextProps.agent
      nextProps.dateRange
      {
        timezone: moment.tz.guess()
        search_query: nextProps.search
      }
    )
    if !_.isEqual(options, nextOptions)
      @setState searchOptions: nextOptions
      setTimeout(
        -> RemindersActionCreator.getList(nextOptions)
        10
      )

  render: ->
    remindersKeys = Array.from(RemindersStore.reminders.keys())
    deleteConfirm =
      message: 'Are you sure this reminder should be canceled?'
      confirmTitle: 'Cancel reminder'
    React.createElement("section", {"className": (styles.container)},
      (if RemindersStore.error
        React.createElement("span", {"className": (styles.error)}, (RemindersStore.error))
      ),
      React.createElement(LoadableWrapperComponent, {"loading": (RemindersStore.loading)},
        (remindersKeys.map (key) =>
          React.createElement(AccordionByDate, { \
            "key": (key),  \
            "date": (key),  \
            "data": (RemindersStore.reminders.get(key)),  \
            "itemsStore": (RemindersStore),  \
            "itemsActionCreator": (RemindersActionCreator),  \
            "itemType": 'reminders',  \
            "deleteConfirm": deleteConfirm,  \
            "editLink": 'edit_reminder',  \
            "getOptions": (@state.searchOptions),  \
            "agents": (OnlineStore.agents)
          })
        )
      )
    )

module.exports = RemindersComponent
