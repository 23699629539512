classnames = require 'classnames'
ReactPaginate = require 'react-paginate'

AnswerComponent = require './AnswerComponent'
AnswerManagerComponent = require '../AnswerManagerComponent'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
ReplyAnswersStore = require 'stores/ReplyAnswersStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
SearchInputComponent = require('components/shared/SearchInputComponent/SearchInputComponent.cjsx')
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
{ SORT_LIST, SORT_LIST_PERSONAL, SORT_LIST, SORT_ORDER } = require 'constants/ReplyTemplates'
dndUtils = require 'lib/dnd-utils'

styles = require './answers-section.styl'
sectionStyles = require '../section-style.styl'
paginationStyles = require 'components/shared/styles/pagination.styl'

class AnswersSectionComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      search: null
      selected: null
      isNew: false
      answerPerPage: 30
    }

  dependsOnStores: [ReplyAnswersActionCreator]

  onAnswerClick: (e) =>
    answerId = parseInt e.currentTarget.dataset.value, 10
    selected = @props.answers.filter((a) -> a.id == answerId)[0]
    @setState { selected, isNew: false }

  onCancel: =>
    @setState { selected: null, isNew: false }

  addNewClick: =>
    @setState { selected: null, isNew: true }

  onDeleteCallback: =>
    @setState { selected: null, isNew: false }

  onCreateCallback: (answer) =>
    @setState { selected: answer, isNew: false }

  onSearch: (query) =>
    ReplyAnswersActionCreator.getAnswersByGroup(@props.categoryId, {
      query_string: query
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      sort_by: @props.sortRule
    })
    @setState { search: query }

  onSearchClear: =>
    ReplyAnswersActionCreator.getAnswersByGroup(@props.categoryId, {
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      per_page: @state.answerPerPage
      page: 0
      sort_by: @props.sortRule
      template_type: @props.templateType
    })
    @setState { search: null }

  onSortChange: (selectedValue) =>
    @setState { search: null }
    @props.onSortChange(selectedValue)

  render: ->
    { selected, isNew } = @state
    { onAnswerClick } = @
    options = SORT_LIST

    if @props.categoryId == 'personal'
      options = SORT_LIST_PERSONAL

    React.createElement("div", {"className": (styles.container)},
      React.createElement("section", {"className": (classnames(styles.answers, sectionStyles.section))},
        React.createElement("header", {"className": (sectionStyles.header)},
          React.createElement("h2", {"className": (sectionStyles.title)}, "Answers")
        ),
        React.createElement("div", {"className": (sectionStyles.content)},
          React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
            React.createElement("div", {"className": (sectionStyles.add_new_container)},
              (if @props.categoryId != 'personal'
                React.createElement("button", {"onClick": (@addNewClick), "className": (sectionStyles.add_new)},
                  React.createElement("i", {"className": (sectionStyles.add_new_icon)}, React.createElement("span", null, "+")),
                  ('Add Answer')
                )
              ),
              React.createElement("span", {"className": (styles.order)},
                React.createElement(CustomSelectComponent, { \
                  "title": 'Sort by',  \
                  "defaultValue": (@props.sortRule),  \
                  "options": (options),  \
                  "onOptionSelect": (@onSortChange),  \
                  "customOptionsStyle": (styles.order_options)
                })
              ),
              (if @props.categoryId == 'personal'
                React.createElement("span", null,
                  React.createElement(SearchInputComponent, { \
                    "className": (styles.search),  \
                    "onChange": (@onSearch),  \
                    "onClear": (@onSearchClear),  \
                    "placeholder": "Find templates",  \
                    "autofocus": (true),  \
                    "defaultValue": (@state.search),  \
                    "autofocus": true
                  })
              )
              )
            ),
            React.createElement("ul", {"className": (sectionStyles.section_list)},
              (if isNew
                React.createElement("li", null,
                  React.createElement("div", { \
                    "className": (classnames(sectionStyles.item, sectionStyles.item_selected))
                  },
                    ('New answer (unsaved)')
                  )
                )
              ),
              (@props.answers.map (answer, i) =>
                React.createElement(AnswerComponent, { \
                  "key": (answer.id),  \
                  "index": (i),  \
                  "onAnswerClick": (onAnswerClick),  \
                  "selected": (selected),  \
                  "answer": (answer),  \
                  "swapItems": (@props.swapAnswers),  \
                  "updatePosition": (@props.updatePosition),  \
                  "sortRule": (@props.sortRule),  \
                  "isSearch": (@state.search)
                })
              ),
              (if @props.pageCount > 1 && !@state.search
                React.createElement("li", {"className": (styles.pagination)},
                  React.createElement(ReactPaginate.default, { \
                    "key": (@props.categoryId),  \
                    "previousLabel": '<',  \
                    "nextLabel": '>',  \
                    "pageCount": (@props.pageCount),  \
                    "marginPagesDisplayed": (1),  \
                    "pageRangeDisplayed": (3),  \
                    "onPageChange": (@props.onPageChange),  \
                    "initialPage": (@props.page),  \
                    "pageClassName": (paginationStyles.page),  \
                    "nextClassName": (paginationStyles.page),  \
                    "previousClassName": (paginationStyles.page),  \
                    "activeClassName": (paginationStyles.active),  \
                    "pageLinkClassName": (paginationStyles.link),  \
                    "nextLinkClassName": (paginationStyles.link),  \
                    "previousLinkClassName": (paginationStyles.link),  \
                    "containerClassName": (paginationStyles.container),  \
                    "breakClassName": (paginationStyles.page)
                  })
                )
              )
            )
          )
        )
      ),
      (if selected || isNew
        key = (selected || {}).id || 'new'

        answer = Object.assign({}, selected)

        if @state.search
          answer = dndUtils.trimTags(answer)

        React.createElement(AnswerManagerComponent, { \
          "key": (key),  \
          "answer": (answer || {}),  \
          "onCancel": (@onCancel),  \
          "categoryId": (@props.categoryId),  \
          "onDeleteCallback": (@onDeleteCallback),  \
          "onCreateCallback": (@onCreateCallback),  \
          "templateType": (@props.templateType),  \
          "isSearch": (@state.search),  \
          "pageCategoryCount": (@props.pageCategoryCount),  \
          "onPageCategoryChange": (@props.onPageCategoryChange),  \
          "page": (@props.page)
        })
      )
    )

module.exports = AnswersSectionComponent
