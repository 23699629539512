BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.IMAGES_PREVIEW_CHECK}": ({ data }) ->
    @checked = data
    @emitChange()

class ImagePreviewStore extends BaseStore
  constructor: ->
    super()
    @checked = {}
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new ImagePreviewStore()
