classnames = require 'classnames'

styles = require './assigner-preview'
BaseComponent = require '../../BaseComponent'
{ agentStatus } = require '../../../lib/utils'
{ ROLES } = require '../../../constants/Agents'

propTypes = require 'prop-types'

class AssignerPreviewComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    agent: propTypes.object.isRequired
    selected: propTypes.bool

  onClick: (e) =>
    e.preventDefault()
    @props.onClick(e) if @props.onClick

  name: ->
    if @props.agent.first_name || @props.agent.last_name
      R.filter(R.isString, [@props.agent.first_name, @props.agent.last_name]).join(' ')
    else
      @props.agent.email

  render: ->
    status = agentStatus(@props.agent, @props.onlineAgents)
    newbieSign = @props.agent.role == ROLES.newbie_agent.value
    salesSign = @props.agent.role == ROLES.sales.value
    rt = Math.round(parseFloat(@props.agent.last_shift?.average_response_time) * 10 / 60) / 10
    activeChats = @props.agent.new_conversations_count + @props.agent.open_conversations_count
    allChats = @props.agent.new_conversations_count + @props.agent.open_conversations_count + @props.agent.dormant_conversations_count

    React.createElement("div", { \
      "className": (classnames(styles.container, 
        "#{styles.highlighted}": @props.agent.highlighted
        "#{styles.selected}": @props.selected
      )),  \
      "onClick": (@onClick)
    },
      React.createElement("span", {"className": (styles.avatar_container)},
        React.createElement("img", { \
          "className": (styles.avatar),  \
          "src": (@props.agent.image || require '../../shared/images/unknown.png')
        }),
        React.createElement("div", {"className": (styles["indicator_#{status}"])})
      ),
      React.createElement("div", {"className": (styles.label)},
        React.createElement("span", {"className": (styles.value)},
          (@name())
        ),
        (
          if !@props.agent.highlighted
            if salesSign
              React.createElement("span", {"className": (styles.newbie_sign)}, "sales")
            else if newbieSign
              React.createElement("span", {"className": (styles.newbie_sign)}, "newbie")
          else if @props.agent.highlighted
            React.createElement("span", {"className": (styles.recomended_sign)}, "rec")
        ),
        (if @props.agent.reserved
          React.createElement("img", {"src": (require './images/return-client.svg')})
        ),
        React.createElement("div", {"className": (styles.stats)},
          React.createElement("span", {"className": (styles.stat)},
            React.createElement("img", {"src": (require './images/chat.svg'), "className": (styles.stat_icon)}),
            (activeChats || 0), "\x2F", (allChats || 0)
          ),
          React.createElement("span", {"className": (styles.stat)},
            React.createElement("img", {"src": (require './images/client.svg'), "className": (styles.stat_icon)}),
            (@props.agent.ucpops || 0)
          ),
          React.createElement("span", {"className": (styles.stat)},
            React.createElement("img", {"src": (require './images/time.svg'), "className": (styles.stat_icon)}),
            (rt || 0), """m
""")
        )
      ),
      (if @props.agent.highlighted
        React.createElement("span", {"className": (styles.highlight_line)})
      ),
      (if @props.selected
        React.createElement("span", {"className": (styles.selected_line)})
      )
    )


module.exports = AssignerPreviewComponent
