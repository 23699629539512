config = require '../config'

SettingsAPI =
  get: (data, successCallback, errorCallback) ->
    $.ajax {
      url: config.urls.settings.getList()
      type: 'GET'
      data
      success: successCallback
      error: errorCallback
      timeout: 5000
    }

  update: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.settings.update()
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  getByType: (data, success, error) ->
    $.ajax {
      url: config.urls.settings.getByType
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
      timeout: 5000
    }

module.exports = SettingsAPI
