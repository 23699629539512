{ urls } = require '../config'

module.exports =
  getGroups: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.groups
      type: 'GET'
      data
      success
      error
    }
  
  createGroup: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.groups
      type: 'POST'
      data
      success
      error
    }

  updateGroup: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.groupsWithId data.id
      type: 'PUT'
      data
      success
      error
    }
  
  deleteGroup: (groupId, success, error) ->
    $.ajax {
      url: urls.replyAnswers.groupsWithId(groupId)
      type: 'DELETE'
      success
      error
    }

  createAnswer: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.answers
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify(data)
      success
      error
    }

  getAnswers: (groupId, data={}, success, error) ->
    $.ajax {
      url: urls.replyAnswers.answersByGroup groupId
      type: 'GET'
      contentType: 'application/json'
      data
      success
      error
    }

  getFavoriteAnswers: (data={}, success, error) ->
    $.ajax {
      url: urls.replyAnswers.favoriteAnswers
      type: 'GET'
      contentType: 'application/json'
      data
      success
      error
    }

  searchAnswers: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.answers
      type: 'GET'
      contentType: 'application/json'
      data
      success
      error
    }

  deleteAnswer: (answerId, success, error) ->
    $.ajax {
      url: urls.replyAnswers.answersWithId answerId
      type: 'DELETE'
      contentType: 'application/json'
      success
      error
    }

  updateAnswer: (data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.answersWithId data.id
      type: 'PUT'
      contentType: 'application/json'
      data: JSON.stringify data
      success
      error
    }

  toggleFavorite: (answerId, success, error) ->
    $.ajax {
      url: urls.replyAnswers.favoriteAnswer answerId
      type: 'POST'
      contentType: 'application/json'
      success
      error
    }

  onToggleVisibleAnswer: (answerId, data, success, error) ->
    $.ajax {
      url: urls.replyAnswers.updateStatus(answerId)
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify data
      success
      error
    }