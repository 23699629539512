Dispatcher = require 'root/dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

MqttActionCreator =
  connect: () ->
    Dispatcher.dispatch type: ActionTypes.MQTT_CONNECT
  connectGlobal: () ->
    Dispatcher.dispatch type: ActionTypes.MQTT_CONNECT_GLOBAL

module.exports = MqttActionCreator
