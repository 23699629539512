classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
styles = require './ranking-status'
RankingConversationsActionCreator = require 'actions/RankingConversationsActionCreator'
RankingTemplateSelector = require 'components/replyTemplates/RankingTemplateSelector'
RankingActionCreator = require 'actions/RankingActionCreator'
{ STATUSES, QUALITY, BAD_TAGS, SALES_BAD_TAGS } = require 'constants/Ranking'
{ capitalizeFirstLetter, getQueryParams } = require 'lib/utils'
RankingStore = require 'stores/RankingStore'
AuthStore = require 'stores/AuthStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
{ menus } = require 'root/config'
{ ROLES } = require 'constants/Agents'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'ranking'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

class RankingStatusComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @currentRanking = null
    @description = null
    @currentConversationId = null
    @minCommentHeight = 90
    @state =
      changedDescription: false
      commentHeight: @minCommentHeight
    @initComponent()

  dependsOnStores: [RankingStore, AuthStore]

  isRankingChanged: (newState) =>
    if @refs.description
      return true unless @currentRanking.description == @refs.description.value

    ranking = (RankingStore.estimation.conversation || {}).approve_data || {}
    return true if RankingStore.estimation.messageId != ranking.messageId

    result = false
    Object.keys ranking
      .map (key) ->
        if !!newState[key]
          if ranking[key] != newState[key]
            result = true
    result

  isStatusChanges: (state) =>
    { status } = (RankingStore.estimation.conversation || {}).approve_data || {}
    status != state.status

  switchStatusTab: (e) =>
    status = e.target.getAttribute('data-value')
    if @state.status != status
      newState = Object.assign @state,
        status: status
      RankingActionCreator.changeEstimation(status == STATUSES.ranked.value)
      @setState status: status

  switchQualityTab: (e) =>
    quality = e.target.getAttribute('data-value')
    if @state.quality != quality
      newState = Object.assign @state,
        quality: quality
      if @isRankingChanged(newState)
        RankingActionCreator.changeEstimation(!!@state.quality)
      else
        RankingActionCreator.savedEstimation(!!@state.quality)
      @setState quality: quality

  switchBadTag: (e) =>
    badTag = e.target.getAttribute('data-value')

    if @state.badTag != badTag
      newState = Object.assign @state,
        badTag: badTag
      if @isRankingChanged(newState)
        RankingActionCreator.changeEstimation(!!@state.quality)
      else
        RankingActionCreator.savedEstimation(!!@state.quality)
      @setState badTag: badTag

  submitRanking: =>
    data = status: @state.status, quality: @state.quality
    data.badTag = @state.badTag unless @state.quality == QUALITY.good.value
    data.description = @refs.description.value
    data.messageId = RankingStore.estimation.messageId
    data.messageType = RankingStore.estimation.messageType
    params = getQueryParams(window.location.search.substring(1))
    RankingActionCreator.submitValue(
      {
        id: RankingStore.estimation.conversation.id
        approve_data: data
      }
      -> RankingConversationsActionCreator.getConversations params
    )

  submitStatus: =>
    params = getQueryParams(window.location.search.substring(1))
    RankingActionCreator.submitValue(
      {
        id: RankingStore.estimation.conversation.id
        approve_data: status: @state.status
      }
      -> RankingConversationsActionCreator.getConversations params
    )

  cancelRanking: =>
    @description = null
    @refs.description.value = @currentRanking.description if @refs.description
    RankingActionCreator.savedEstimation(@currentRanking.status == STATUSES.ranked.value)
    @setState
      status: @currentRanking.status
      quality: @currentRanking.quality
      badTag: @currentRanking.badTag
      changedDescription: false

  onMouseEnter:  =>
    classes = @refs.note.className.split ' '
    classes.push styles.note_visible
    @refs.note.className = classes.join ' '

  onMouseLeave: =>
    classes = @refs.note.className.split ' '
    classes = classes.filter (item) ->
      item != styles.note_visible
    @refs.note.className = classes.join ' '

  setDescription: (description) =>
    @description = description
    if @currentRanking.description != description
      @setState changedDescription: true
    else if @currentRanking.description == description
      @setState changedDescription: false

  onTextareaInput: (e) =>
    value = e.target.value
    @setDescription(value)

  onCommentResize: =>
    document.addEventListener('mousemove', @onCommentMouseMove)
    document.addEventListener('mouseup', @onCommentMouseUp)

  onCommentMouseUp: =>
    document.removeEventListener('mousemove', @onCommentMouseMove)
    document.removeEventListener('mouseup', @onCommentMouseUp)
    { commentHeight } = @state
    if commentHeight < @minCommentHeight
      commentHeight = @minCommentHeight
      @setState { commentHeight }
    else if commentHeight > window.innerHeight / 2
      commentHeight = window.innerHeight / 2
      @setState { commentHeight }

  onCommentMouseMove: ({ movementY }) =>
    @setState(commentHeight: @state.commentHeight + movementY)

  setTemplate: (replyTemplate) =>
    @refs.description.value = replyTemplate
    @setDescription(replyTemplate)
    @refs.description.focus()

  isActiveSubmit: =>
    isRankingChanged = @isRankingChanged(@state)
    changedEstimation = isRankingChanged &&
      @state.quality == QUALITY.good.value ||
      isRankingChanged &&
      @state.quality == QUALITY.bad.value &&
      (@refs.description || {}).value != ''
    allTabsSelected = true if @state.quality == QUALITY.good.value ||
      @state.quality == QUALITY.bad.value && @state.badTag
    selectedMessage = true
    selectedMessage = !!(RankingStore.estimation || {}).messageId if @state.quality == QUALITY.bad.value
    !!changedEstimation && !!allTabsSelected && selectedMessage

  componentDidMount: ->
    super()
    setTimeout RankingActionCreator.startRanking, 30

  componentWillUpdate: ->
    if RankingStore.estimation.conversation && (@currentConversationId != RankingStore.estimation.conversation.id)
      @currentRanking = RankingStore.estimation.conversation.approve_data || {}
      @currentConversationId = RankingStore.estimation.conversation.id
      @setState
        status: @currentRanking.status
        quality: @currentRanking.quality
        badTag: @currentRanking.badTag

  componentWillUnmount: ->
    super()
    RankingActionCreator.stopRanking()

  render: ->
    isClicable = true
    unless ROLES[AuthStore.user.role].value in accessConfig.toEdit
      isClicable = false

    if RankingStore.estimation.conversation
      @currentRanking = RankingStore.estimation.conversation.approve_data || {}

    states = @state
    switchStatusTab = @switchStatusTab
    statusBlock = React.createElement("div", {"className": (styles.block)},
      React.createElement("p", {"className": (styles.block__title)}, "Status"),
      React.createElement("div", null,
        (Object.keys STATUSES
          .map (key, i) ->
            React.createElement("span", { \
              "className": (classnames(styles.tab, styles.status__tab,
                "#{styles.tab_active}": states.status == STATUSES[key].value
              )),  \
              "data-value": (STATUSES[key].value),  \
              "onClick": (switchStatusTab if isClicable),  \
              "key": (i)
            },
              (STATUSES[key].label)
            )
        )
      )
    )

    notePopup = React.createElement("span", {"id": 'note', "className": (styles.note), "ref": "note"}, """
      Please, highlight the message, choose a tag and write a comment to save estimation
""")

    qualityBlock = null
    badTagsBlock = null
    commentBlock = null
    submitBlock = null
    BAD_TAGS_FOR_ROLE = if @props.userRole == 'sales' then SALES_BAD_TAGS else BAD_TAGS
    if states.status == STATUSES.ranked.value
      if states.quality == QUALITY.bad.value
        switchBadTag = @switchBadTag
        badTagsBlock = React.createElement("div", {"className": (styles.block)},
          React.createElement("p", {"className": (styles.block__title)}, "Bad Tags"),
          React.createElement("div", null,
            (Object.keys BAD_TAGS_FOR_ROLE
              .map (key, i) ->
                React.createElement("span", { \
                  "className": (classnames(styles['bad-tag'],
                    "#{styles['bad-tag_active']}": states.badTag == BAD_TAGS_FOR_ROLE[key].value
                  )),  \
                  "key": (i),  \
                  "data-value": (BAD_TAGS_FOR_ROLE[key].value),  \
                  "onClick": (switchBadTag if isClicable)
                },
                  React.createElement("span", { \
                    "className": (styles['bad-tag__check']),  \
                    "data-value": (BAD_TAGS_FOR_ROLE[key].value)
                  }),
                  React.createElement("span", {"data-value": (BAD_TAGS_FOR_ROLE[key].value)},
                    (BAD_TAGS_FOR_ROLE[key].label)
                  )
                )
            )
          )
        )

      switchQualityTab = @switchQualityTab
      isActiveSubmit = @isActiveSubmit()
      qualityBlock = React.createElement("div", {"className": (classnames(styles.block, styles['quality-block']))},
        React.createElement("p", {"className": (styles.block__title)}, "Quality"),
        React.createElement("div", null,
          React.createElement("span", { \
            "className": (classnames(styles.tab, styles['quality-block__tab'],
              "#{styles.tab_active}": states.quality == QUALITY.good.value
            )),  \
            "onClick": (switchQualityTab if isClicable),  \
            "data-value": (QUALITY.good.value)
          },
            React.createElement("span", { \
              "className": (classnames(styles['quality-block__tab_positive'], styles['quality-block__tab_icon'])),  \
              "data-value": (QUALITY.good.value)
            }),
            React.createElement("span", {"data-value": (QUALITY.good.value)}, (QUALITY.good.label))
          ),
          React.createElement("span", { \
            "className": (classnames(styles.tab, styles['quality-block__tab'],
              "#{styles.tab_active}": states.quality == QUALITY.bad.value
            )),  \
            "onClick": (switchQualityTab if isClicable),  \
            "data-value": (QUALITY.bad.value)
          },
            React.createElement("span", { \
              "className": (classnames(styles['quality-block__tab_negative'], styles['quality-block__tab_icon'])),  \
              "data-value": (QUALITY.bad.value)
            }),
            React.createElement("span", {"data-value": (QUALITY.bad.value)}, (QUALITY.bad.label))
          )
        )
      )

      commentBlock = React.createElement("div", {"className": (styles.block)},
        React.createElement("p", {"className": (styles.block__title)}, """
          Comment
""", (React.createElement("span", {"className": (styles.block__title_thin)}, " (Required)") if @state.quality == QUALITY.bad.value)
        ),
        React.createElement("textarea", { \
          "ref": "description",  \
          "style": (height: @state.commentHeight),  \
          "className": (styles.comment__textarea),  \
          "defaultValue": (@description || (@currentRanking || {}).description),  \
          "onInput": (@onTextareaInput),  \
          "disabled": (!isClicable)
        }),
        React.createElement("div", {"className": (styles.comment_resize_border), "onMouseDown": (@onCommentResize)}),
        (isClicable &&
          React.createElement(RankingTemplateSelector, { \
            "onPick": (@setTemplate),  \
            "searchQuery": (@description),  \
            "templateType": ('ranking')
          })
        )
      )

      if isClicable
        submitBlock = React.createElement(SubmitBlock, { \
          "cancelRanking": (@cancelRanking),  \
          "isActiveSubmit": (isActiveSubmit),  \
          "submitRanking": (@submitRanking),  \
          "onMouseEnter": (@onMouseEnter),  \
          "onMouseLeave": (@onMouseLeave),  \
          "quality": (@state.quality)
        })
    else if isClicable
      submitBlock = React.createElement(SubmitBlock, { \
        "cancelRanking": (@cancelRanking),  \
        "isActiveSubmit": (@isStatusChanges @state),  \
        "submitRanking": (@submitStatus)
      })

    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (!RankingStore.estimation.conversation)},
        (statusBlock),
        (qualityBlock),
        (badTagsBlock),
        (commentBlock),
        (submitBlock),
        React.createElement("span", {"className": (styles.error)}, (RankingStore.error)),
        React.createElement("span", {"className": (styles.success)}, (RankingStore.success)),
        (notePopup)
      )
    )

module.exports = RankingStatusComponent

SubmitBlock = (props) ->
  React.createElement("div", {"className": (classnames(styles.block, styles['buttons-block']))},
    React.createElement("button", { \
      "className": (styles['buttons-block__cancel']),  \
      "onClick": (props.cancelRanking)
    }, """
      Cancel
"""),
    React.createElement("button", { \
      "className": (classnames(styles['buttons-block__submit'],
        "#{styles['buttons-block__submit_disabled']}": !props.isActiveSubmit
      )),  \
      "onClick": (props.submitRanking if props.isActiveSubmit),  \
      "onMouseEnter": (props.onMouseEnter if !props.isActiveSubmit && props.quality == QUALITY.bad.value),  \
      "onMouseLeave": (props.onMouseLeave if !props.isActiveSubmit && props.quality == QUALITY.bad.value)
    }, """
      Save
""")
  )
