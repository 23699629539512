ActionTypes = require 'constants/ActionTypes'
LandingsAPI = require 'api/LandingsAPI'
Dispatcher = require 'root/dispatcher/Dispatcher'

LandingsActionCreator =
  new: ->
    Dispatcher.dispatch type: ActionTypes.LANDING_FORM_CLEAR

  getList: (data) ->
    Dispatcher.dispatch type: ActionTypes.LANDINGS_GET_LIST_REQUESTING
    LandingsAPI.getList(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDINGS_GET_LIST_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDINGS_GET_LIST_FAILURE
          payload: error
    )

  get: (data) ->
    Dispatcher.dispatch type: ActionTypes.LANDING_GET_REQUESTING
    LandingsAPI.get(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_GET_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_GET_FAILURE
          payload: error
    )

  create: (data) ->
    Dispatcher.dispatch type: ActionTypes.LANDING_CREATE_REQUESTING
    LandingsAPI.create(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_CREATE_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_CREATE_FAILURE
          payload: error
    )

  update: (data) ->
    Dispatcher.dispatch type: ActionTypes.LANDING_UPDATE_REQUESTING
    LandingsAPI.update(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_UPDATE_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.LANDING_UPDATE_FAILURE
          payload: error
    )

module.exports = LandingsActionCreator
