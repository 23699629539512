{ getQueryParams } = require 'lib/utils'
{ setToken } = require 'lib/tokenStorage'
queryString = require 'query-string'

propTypes = require 'prop-types'

class LoginComponent extends React.Component
  componentDidMount: ->
    { history, match } = @props
    { token } = match.params
    setToken("Bearer #{token}")
    $.ajaxSetup({
      headers: {
        Authorization: "Bearer #{token}"
      }
    })
    params = queryString.parse(window.location.search)
    redirectUrl = params.redirect_url
    path = '/'
    if redirectUrl
      url = new URL(Buffer.from(redirectUrl, 'base64').toString())
      path = url.pathname + url.search
    history.replace(path)

  render: -> null

module.exports = LoginComponent
