{ urls } = require '../config'

RankingMessageAPI =
#   postMessage: (data, success, error) ->
#     $.ajax
#       url: urls.rankingMessage.postMessage
#       type: 'POST'
#       contentType: 'application/json'
#       data: JSON.stringify(data)
#       success: success
#       error: error
#       timeout: 5000

  getMessages: (success, error) ->
    $.ajax {
      url: urls.rankingMessage.getMessages
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  getMessagesGroups: (success, error) ->
    $.ajax {
      url: urls.rankingMessage.getMessagesGroups
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  getConversation: (id, success, error) ->
    $.ajax {
      url: urls.rankingMessage.getConversation(id)
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  rank: (data, success, error) ->
    $.ajax {
      url: urls.rankingMessage.rank
      type: 'POST'
      contentType: 'application/json'
      data
      success
      error
    }

module.exports = RankingMessageAPI
