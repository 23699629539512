TIME_RANGES_PRESENTER =
  15: '15 min'
  30: '30 min'
  60: '1 hour'

TIME_RANGES_REPRESENTER =
  '15 min': 15
  '30 min': 30
  '1 hour': 60

module.exports = {
  TIME_RANGES_PRESENTER
  TIME_RANGES_REPRESENTER
}