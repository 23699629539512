React = require 'react'
moment = require 'moment'
classnames = require 'classnames'

styles = require './sla-tracking-table'


class SLATrackingTableComponent extends React.Component
  constructor: (props) ->
    super props
    @state =
      hoveredType: null
      hoveredField: null

  onMouseEnter: (e) =>
    { field, type } = e.currentTarget.dataset
    @setState({ hoveredType: type, hoveredField: field })

  onMouseLeave: (e) => @setState({ hoveredType: null, hoveredField: null })

  renderStatisticItem: (date, statistic, fields) =>
    morningStatistic = statistic.morning
    dayStatistic = statistic.day
    nightStatistic = statistic.night
    { onMouseEnter, onMouseLeave } = @
    { hoveredType, hoveredField } = @state

    React.createElement("div", {"key": (date), "className": (styles.statistic_item)},
      (fields.map (field) ->
        React.createElement("div", {"key": (field), "className": (styles.statistic_item_group)},
          React.createElement("div", {"className": (styles.statistic_item_field)},
            React.createElement("b", null, (moment(date).format('D MMM')))
          ),
          React.createElement("div", { \
            "className": (classnames(styles.statistic_item_field,
              "#{styles.hover_row}": hoveredType == 'morning' && hoveredField == field
            )),  \
            "onMouseEnter": (onMouseEnter),  \
            "onMouseLeave": (onMouseLeave),  \
            "data-field": (field),  \
            "data-type": "morning"
          },
            (if morningStatistic then morningStatistic[field] else '-')
          ),
          React.createElement("div", { \
            "className": (classnames(styles.statistic_item_field,
              "#{styles.hover_row}": hoveredType == 'day' && hoveredField == field
            )),  \
            "onMouseEnter": (onMouseEnter),  \
            "onMouseLeave": (onMouseLeave),  \
            "data-field": (field),  \
            "data-type": "day"  
          },
            (if dayStatistic then dayStatistic[field] else '-')
          ),
          React.createElement("div", { \
            "className": (classnames(styles.statistic_item_field,
              "#{styles.hover_row}": hoveredType == 'night' && hoveredField == field
            )),  \
            "onMouseEnter": (onMouseEnter),  \
            "onMouseLeave": (onMouseLeave),  \
            "data-field": (field),  \
            "data-type": "night"  
          },
            (if nightStatistic then nightStatistic[field] else '-')
          )
        )
      )
    )

  render: ->
    { statistic, fieldsMap } = @props
    fields = Object.keys fieldsMap
    dates = Object.keys statistic
    { renderStatisticItem, onMouseEnter, onMouseLeave } = @
    { hoveredType, hoveredField } = @state

    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.headers)},
        (fields.map (field) -> 
          React.createElement("div", {"key": (field), "className": (styles.header_item)},
            React.createElement("div", {"className": (styles.header_item_title)}, (fieldsMap[field])),
            React.createElement("div", { \
              "className": (classnames(styles.header_item_type,
                "#{styles.hover_row}": hoveredType == 'morning' && hoveredField == field
              )),  \
              "data-field": (field),  \
              "data-type": "morning",  \
              "onMouseEnter": (onMouseEnter),  \
              "onMouseLeave": (onMouseLeave)
            }, """
              Morning
"""),
            React.createElement("div", { \
              "className": (classnames(styles.header_item_type,
                "#{styles.hover_row}": hoveredType == 'day' && hoveredField == field
              )),  \
              "data-field": (field),  \
              "data-type": "day",  \
              "onMouseEnter": (onMouseEnter),  \
              "onMouseLeave": (onMouseLeave)
            }, """
              Day
"""),
            React.createElement("div", { \
              "className": (classnames(styles.header_item_type,
                "#{styles.hover_row}": hoveredType == 'night' && hoveredField == field
              )),  \
              "data-field": (field),  \
              "data-type": "night",  \
              "onMouseEnter": (onMouseEnter),  \
              "onMouseLeave": (onMouseLeave)
            }, """
              Night
""")
          )
        )
      ),
      React.createElement("div", {"className": (styles.statistic)},
        (dates.map (date) ->
          renderStatisticItem date, statistic[date], fields
        )
      )
    )

module.exports = SLATrackingTableComponent
