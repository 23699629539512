classnames = require 'classnames'
_ = require 'underscore'
BaseComponent = require 'components/BaseComponent'
ReplyCategoriesComponent = require './ReplyCategoriesComponent'
ReplyAnswersComponent = require './ReplyAnswersComponent'
ReplyAnswersStore = require 'stores/ReplyAnswersStore'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'

InfoModal = require 'components/shared/InfoModal'

{ SORT_LIST, SORT_ORDER } = require 'constants/ReplyTemplates'
crminfo = require '@verdaccio/crminfo'
{ ReplyTemplate, ReplyTemplateList } = crminfo.controls
AuthStore = require 'stores/AuthStore'

styles = require './reply-templates-manager.styl'

class ReplyTemplatesManager extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      categoryId: null
      categories: []
      answers: []
      search: null
      categoryPage: 0
      allCategoryPage: 0
      categoryPerPage: 30
      answerPage: 0
      answerPerPage: 30
    }
    @initComponent()
    @debounceOnSearch ||= _.debounce(@onSearch, 700)

  dependsOnStores: [ReplyAnswersStore]

  componentDidMount: ->
    super()
    ReplyAnswersActionCreator.getGroups({
      page: @state.categoryPage
      per_page: @state.categoryPerPage
      sort_by: SORT_LIST.position.value
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      template_type: @props.templateType
    })
    ReplyAnswersActionCreator.getGroupsMore({
      page: @state.allCategoryPage
      per_page: @state.categoryPerPage
      sort_by: SORT_LIST.position.value
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      template_type: @props.templateType
    })

  addNewGroup: (value) =>
    ReplyAnswersActionCreator.createGroup
      name: value
      template_type: 'message'
      custom: true

  addNewSubGroup: (value, categoryId) =>
    ReplyAnswersActionCreator.createGroup
      original_id: categoryId
      name: value
      template_type: 'message'
      custom: true

  onCategoryClick: (e, categoryId) =>
    if @props.isRanking
      categoryId = parseInt e.target.dataset.id, 10
    ReplyAnswersActionCreator.getAnswersByGroup(categoryId, {
      sort_order: SORT_ORDER[SORT_LIST.created_at.value]
      sort_by: SORT_LIST.created_at.value
      page: 0
      per_page: @state.answerPerPage
      template_type: @props.templateType
      query_string: @state.search
      reply_group_id: categoryId
    })
    @setState { categoryId, answerPage: 0 }

  onFavoriteCategoryClick: =>
    @setState { categoryId: 'favorite', answerPage: 0 }
    ReplyAnswersActionCreator.getFavoriteAnswers({
      sort_order: SORT_ORDER[SORT_LIST.created_at.value]
      sort_by: SORT_LIST.created_at.value
      page: 0
      per_page: @state.answerPerPage
      template_type: @props.templateType
      query_string: @state.search
    })

  onPersonalCategoryClick: =>
    categoryId = 'personal'
    @setState { categoryId, answerPage: 0 }
    ReplyAnswersActionCreator.getAnswersByGroup(categoryId, {
      user_id: AuthStore.user.id
      sort_order: SORT_ORDER[SORT_LIST.created_at.value]
      sort_by: SORT_LIST.created_at.value
      page: 0
      per_page: @state.answerPerPage
      template_type: @props.templateType
      query_string: @state.search
      reply_group_id: categoryId
    })

  onCreatePersonal: (answer) =>
    params = Object.assign(answer, { user_id: AuthStore.user.id })
    params.template_type = @props.templateType
    ReplyAnswersActionCreator.createAnswer(params)

  onEditPersonal: (answer) =>
    answer.template_type = @props.templateType
    ReplyAnswersActionCreator.updateAnswer answer

  onFavoriteClick: (answerId) ->
    ReplyAnswersActionCreator.toggleFavorite answerId

  onSearch: (query, category) =>
    @state.answerPerPage
    if category == 'favorites'
      setTimeout(
        =>
          ReplyAnswersActionCreator.getFavoriteAnswers({
            sort_order: SORT_ORDER[SORT_LIST.created_at.value]
            sort_by: SORT_LIST.created_at.value
            page: 0
            per_page: @state.answerPerPage
            template_type: @props.templateType
            query_string: query
          })
        0
      )
    else
      if !query
        setTimeout(
          =>
            ReplyAnswersActionCreator.getAnswersByGroup(category, {
              user_id: AuthStore.user.id
              sort_order: SORT_ORDER[SORT_LIST.created_at.value]
              sort_by: SORT_LIST.created_at.value
              page: 0
              per_page: @state.answerPerPage
              template_type: @props.templateType
              query_string: @state.search
              reply_group_id: category
            })
          0
        )
      else
        setTimeout(
          =>
            ReplyAnswersActionCreator.searchAnswers
              query_string: query
              per_page: @state.answerPerPage
              template_type: @props.templateType
              reply_group_id: category
              user_id: AuthStore.user.id
          0
        )
    @setState { search: query, categoryId: null }

  onClear: => @setState search: null

  onDeletePersonalModal: (answerId) =>
    ReplyAnswersActionCreator.deleteAnswer answerId
    InterfaceActionCreator.closeModal()

  onDeletePersonalConfirm: (answerId) =>
    InterfaceActionCreator.openModal InfoModal, {
      text: 'Are you sure?'
      buttonTitle: 'Cancel'
      cancelButtonTitle: 'Delete'
      onCancel: => @onDeletePersonalModal answerId
    }

  onDeletePersonal: (answerId) ->
    if confirm 'Are you sure that you want delete this answer?'
      ReplyAnswersActionCreator.deleteAnswer answerId

  onToggleVisibleAnswer: (answerId, data) ->
    ReplyAnswersActionCreator.onToggleVisibleAnswer(answerId, data)

  onAnswerPageChange: ({ selected }) =>
    return if selected == @state.answerPage
    @setState { answerPage: selected }
    categoryId = @state.categoryId
    if categoryId == 'favorite'
      return ReplyAnswersActionCreator.getFavoriteAnswers({
        sort_order: SORT_ORDER[SORT_LIST.position.value]
        sort_by: SORT_LIST.position.value
        page: selected
        per_page: @state.answerPerPage
        template_type: @props.templateType
      })
    ReplyAnswersActionCreator.getAnswersByGroup(categoryId, {
      user_id: AuthStore.user.id
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      sort_by: SORT_LIST.position.value
      page: selected
      per_page: @state.answerPerPage
      template_type: @props.templateType
      query_string: @state.search
      reply_group_id: categoryId
    })

  onCategoryPageChange: ({ selected }) =>
    return if selected == @state.categoryPage
    @setState { categoryPage: selected }
    ReplyAnswersActionCreator.getGroups {
      page: selected
      per_page: @state.categoryPerPage
      sort_by: SORT_LIST.position.value
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      template_type: @props.templateType
    }

  resetLastGroup: =>
    ReplyAnswersActionCreator.resetLastGroup()

  onAllCategoryPageChange: ({ selected }) =>
    return if selected == @state.allCategoryPage
    @setState { allCategoryPage: selected }
    ReplyAnswersActionCreator.getGroupsMore {
      page: selected
      per_page: @state.categoryPerPage
      sort_by: SORT_LIST.position.value
      sort_order: SORT_ORDER[SORT_LIST.position.value]
      template_type: @props.templateType
    }

  render: ->
    answerPageCount = Math.ceil(ReplyAnswersStore.answersTotal / @state.answerPerPage)
    categoryPageCount = Math.ceil(ReplyAnswersStore.groupsTotal / @state.categoryPerPage)
    answers = if @state.categoryId || @state.search != null then ReplyAnswersStore.answers else []

    # console.log('ReplyAnswersStore.lastNewGroup: ', ReplyAnswersStore.lastNewGroup);
    # console.log('ReplyAnswersStore.lastNewSubGroup: ', ReplyAnswersStore.lastNewSubGroup);

    React.createElement("div", {"className": (classnames(styles["container_#{@props.templateType}"], {[styles.templates]: @props.isOpenTemplateList }))},
      (!@props.isRanking && !@props.isOpenTemplateList &&
        React.createElement(ReplyTemplate, { \
          "onCategoryClick": (@onCategoryClick),  \
          "categories": (ReplyAnswersStore.groups),  \
          "allCategories": (ReplyAnswersStore.allGroups),  \
          "onFavoriteCategoryClick": (@onFavoriteCategoryClick),  \
          "onPersonalCategoryClick": (@onPersonalCategoryClick),  \
          "onFavoriteClick": (@onFavoriteClick),  \
          "groupsLoading": (ReplyAnswersStore.groupsLoading),  \
          "page": (@state.categoryPage),  \
          "allCategoryPage": (@state.allCategoryPage),  \
          "answersLoading": (ReplyAnswersStore.answersLoading),  \
          "pageCount": (categoryPageCount),  \
          "onPageChange": (@onCategoryPageChange),  \
          "onAllPageChange": (@onAllCategoryPageChange),  \
          "answers": (answers),  \
          "onSearch": (@debounceOnSearch),  \
          "initSearchQuery": (@props.searchQuery),  \
          "onClear": (@onClear),  \
          "totalGroup": (ReplyAnswersStore.groupsTotal),  \
          "onReplyTemplatePick": (@props.onReplyTemplatePick),  \
          "onToggleVisibleAnswer": (@onToggleVisibleAnswer),  \
          "selected": (@state.categoryId),  \
          "onCreatePersonal": (@onCreatePersonal),  \
          "onAnswerPageChange": (@onAnswerPageChange),  \
          "answerPageCount": (@state.answerPage),  \
          "onEditPersonal": (@onEditPersonal),  \
          "totalAnswer": (ReplyAnswersStore.answersTotal),  \
          "pageAnswer": (0),  \
          "onDeletePersonalConfirm": (@onDeletePersonalConfirm),  \
          "addNewGroup": (@addNewGroup),  \
          "addNewSubGroup": (@addNewSubGroup),  \
          "lastNewGroup": (ReplyAnswersStore.lastNewGroup),  \
          "lastNewSubGroup": (ReplyAnswersStore.lastNewSubGroup),  \
          "resetLastGroup": (@resetLastGroup)
        })
      ),

      (!@props.isRanking && @props.isOpenTemplateList && @props.searchQuery.length > 0 &&
        React.createElement(ReplyTemplateList, { \
          "answers": (answers),  \
          "initSearchQuery": (@props.searchQuery),  \
          "onSearch": (@debounceOnSearch),  \
          "onReplyTemplatePick": (@props.onReplyTemplatePick),  \
          "answersLoading": (ReplyAnswersStore.answersLoading),  \
          "onToggleVisibleAnswer": (@onToggleVisibleAnswer)
        })
      ),

      (@props.isRanking &&
        React.createElement("div", null,
          React.createElement("div", {"className": (styles.content)},
            React.createElement(ReplyCategoriesComponent, { \
              "selected": (@state.categoryId),  \
              "categories": (ReplyAnswersStore.groups),  \
              "onCategoryClick": (@onCategoryClick),  \
              "onFavoriteCategoryClick": (@onFavoriteCategoryClick),  \
              "onPersonalCategoryClick": (@onPersonalCategoryClick),  \
              "loading": (ReplyAnswersStore.groupsLoading),  \
              "pageCount": (categoryPageCount),  \
              "onPageChange": (@onCategoryPageChange),  \
              "page": (0),  \
              "isPersonal": (@props.templateType == 'message')
            }),
            React.createElement(ReplyAnswersComponent, { \
              "answers": (answers),  \
              "loading": (ReplyAnswersStore.answersLoading),  \
              "onReplyTemplatePick": (@props.onReplyTemplatePick),  \
              "onFavoriteClick": (@onFavoriteClick),  \
              "categoryId": (@state.categoryId),  \
              "onSearch": (@onSearch),  \
              "initSearchQuery": (@props.searchQuery),  \
              "onClear": (@onClear),  \
              "onCreatePersonal": (@onCreatePersonal),  \
              "onEditPersonal": (@onEditPersonal),  \
              "onDeletePersonal": (@onDeletePersonal),  \
              "editable": (@state.categoryId == 'personal'),  \
              "pageCount": (answerPageCount),  \
              "onPageChange": (@onAnswerPageChange),  \
              "page": (0)
            })
          ),
          React.createElement("i", {"className": (styles["arrow_#{@props.templateType}"])})
        )
      )
    )

module.exports = ReplyTemplatesManager
