classnames = require 'classnames'
deepcopy = require 'deepcopy'

baseStyles = require '../styles/custom-select'
styles = require './custom-multi-select'

propTypes = require 'prop-types'

class CustomMultiSelectComponent extends React.Component

  constructor: (props) ->
    super(props)
    @state =
      selectedValues: deepcopy(props.selectedValues) || {}
      showOptions: false
      eventListenerExist: false

  @propTypes:
    label: propTypes.string
    options: propTypes.object
    selectedValues: propTypes.object
    onOptionSelect: propTypes.func
    customSelectStyle: propTypes.string
    customOptionsStyle: propTypes.string

  toggleOptions: =>
    @setState showOptions: true

  getOptionsList: =>
    if @state.showOptions
      selectedValues = @state.selectedValues
      setValue = @setValue
      options = @props.options

      optionsClasses = classnames baseStyles.container__options, @props.customOptionsStyle
      React.createElement("ul", {"ref": "options", "id": 'options', "className": (optionsClasses)},
        (Object.keys options
          .map (key, i) ->
            React.createElement("li", { \
              "key": (i), "className": (classnames baseStyles['container__options-option'],
                "#{baseStyles['container__options-option_current']}": selectedValues[key]
              ),  \
              "data-value": (options[key].value), "onClick": (setValue)
            },
              React.createElement("i", { \
                "className": (classnames styles.icon,
                  "#{styles['icon_active']}": selectedValues[key]
                ),  \
                "data-value": (options[key].value)
              }),
              React.createElement("span", {"data-value": (options[key].value)},
                (options[key].label)
              )
            )
        )
      )
    else
      null

  setValue: (e) =>
    selectedValue = e.target.getAttribute 'data-value'
    newSelectedValues = @state.selectedValues
    if newSelectedValues[selectedValue]
      delete newSelectedValues[selectedValue]
    else
      newSelectedValues[selectedValue] = true
    @setState selectedValues: newSelectedValues
    newSelectedValues = if Object.keys(newSelectedValues).length
      newSelectedValues
    else
      undefined
    @props.onOptionSelect newSelectedValues

  optionsBlur: (e) =>
    unless $(e.target).parents('#options').length
      document.removeEventListener 'click', @optionsBlur
      @setState showOptions: false, eventListenerExist: false

  componentDidUpdate: ->
    if @refs.options && !@state.eventListenerExist
      document.addEventListener 'click', @optionsBlur
      @setState eventListenerExist: true

  render: ->
    selectClasses = classnames baseStyles.container, @props.customSelectStyle
    React.createElement("div", {"className": (selectClasses)},
      React.createElement("div", {"className": (baseStyles.container__select), "onClick": (@toggleOptions)},
        (@props.label),
        React.createElement("span", {"className": (classnames baseStyles.container__select_icon, 'icon')})
      ),
      (@getOptionsList())
    )

module.exports = CustomMultiSelectComponent
