constants = require '../../../constants/base'

propTypes = require 'prop-types'

# todo use UrlExtractor
class TextWithLinks extends React.Component
  @propTypes:
    text: propTypes.string.isRequired

  render: ->
    matches = @props.text.matchesWithIndex(constants.URL_REGEXP)

    textStart = 0
    elements = []
    for match, index in matches
      elements.push(@renderText(@props.text.substring(textStart, match.position), "text-#{index}"))
      elements.push(@renderUrl(match.content, index))
      textStart += match.position + match.content.length
    elements.push(@renderText(@props.text.substring(textStart), "last"))

    React.createElement("div", null,
      ( elements )
    )

  renderUrl: (url, index)->
    throw "Abstract method. Not implemented."

  renderText: (text, index) ->
    elements.push(React.createElement("span", {"key": index}, " ", ( text ), " "))

module.exports = TextWithLinks