{ createSelectorCreator, defaultMemoize } = require 'reselect'
{ isEqual } = require 'underscore'

createDeepEqualSelector = createSelectorCreator defaultMemoize, isEqual

class BaseComponent extends React.Component
  initComponent: ->
    if @getPersonalStoreClass
      PersonalStoreClass = @getPersonalStoreClass()
      @personalStore = new PersonalStoreClass()
      #todo deprecate @personalStore.getActionCreator?(); User inheritance of PersonalStore
      @actionCreator = @personalStore.actionCreator || @personalStore.getActionCreator?()

    @state = R.merge(@state, @getState())
    if @requestData?
      @requestDataOnKeyChange = if @getDataKey?
        createDeepEqualSelector @getDataKey, @requestData
      else
        R.once @requestData

  componentDidMount: ->
    @requestDataOnKeyChange?()
    _(@dependsOnStores).each (store, index) =>
      store.addChangeListener @_onChange
    @personalStore?.addChangeListener @_onChange

  componentDidUpdate: ->
    @requestDataOnKeyChange?()

  componentWillUnmount: ->
    _(@dependsOnStores).each (store, index) =>
      store.removeChangeListener @_onChange
    @personalStore?.removeChangeListener @_onChange

  _onChange: =>
    @setState @getState()

  # todo deprecate
  dependsOnStores: []

  getState: -> {}

module.exports = BaseComponent
