PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
CredentialsActionCreator = require '../../../../actions/CredentialsActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
LOAD_STATE = require '../../../../constants/LoadStates'
_ = require 'underscore'

class CustomerCardCredentialsFormByCustomerStore extends PersonalizedStore
  constructor: ->
    super()
    @scope = {}
    @credentialsIndex = {}
    @loading =
      credentials: LOAD_STATE.EMPTY
    @registerActions()

  getActionCreator: ->
    new CredentialsActionCreator()

  setDecryptProp: (credential) ->
    _.extend {}, credential, { isDecrypted: true }

  _registerToActions: ({ type, payload, guid }) =>
    if @isPersonalAction(guid)
      switch type
        when ActionTypes.CREDENTIALS_GET_LIST_REQUESTING
          @scope = payload.scope
          @loading.credentials = LOAD_STATE.LOADING
          @emitChange()
        when ActionTypes.CREDENTIALS_GET_LIST_SUCCESS, ActionTypes.CREDENTIALS_SET_LIST
          @loading.credentials = LOAD_STATE.LOADED
          @credentialsIndex = _.indexBy(payload.credentials, 'id')
          @emitChange()

        when ActionTypes.CREDENTIAL_CREATE_SUCCESS
          @credentialsIndex[payload.credential.id] = @setDecryptProp payload.credential
          @emitChange()

        when ActionTypes.CREDENTIAL_GET_DECRYPTED_REQUESTING
          @loading.credentials = LOAD_STATE.LOADING
          @emitChange()

        when ActionTypes.CREDENTIAL_GET_DECRYPTED_SUCCESS
          @loading.credentials = LOAD_STATE.LOADED
          @credentialsIndex[payload.credential.id] = @setDecryptProp payload.credential
          @emitChange()

        when ActionTypes.CREDENTIAL_UPDATE_SUCCESS
          if payload.credential.is_chosen
            _.values(@credentialsIndex).filter((credential) ->
              credential.credential_type == payload.credential.credential_type
            ).forEach (credential) =>
              @credentialsIndex[credential.id].is_chosen = false
          @credentialsIndex[payload.credential.id] = @setDecryptProp payload.credential
          @emitChange()

        when ActionTypes.CREDENTIAL_DESTROY_SUCCESS
          for id in payload.ids
            delete @credentialsIndex[id] if @credentialsIndex[id]
          @emitChange()

module.exports = CustomerCardCredentialsFormByCustomerStore
