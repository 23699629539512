BaseComponent = require 'components/BaseComponent'
Isvg = require 'react-inlinesvg'
Button = require 'components/shared/Button'
DownloadAllStore = require 'stores/DownloadAllStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
ConversationsActionCreator = new ConversationsActionCreator()
styles = require './download-all-button'
DOWNLOAD_SVG = require './csv-export.svg'

class DownloadAllButtonComponent extends BaseComponent
  dependsOnStores: [DownloadAllStore]

  constructor: (props) ->
    super(props)
    @initComponent()

  onDownloadAll: (e) =>
    e.preventDefault()
    e.stopPropagation()
    return if DownloadAllStore.loading
    return unless @props.conversationId
    ConversationsActionCreator.downloadAll(@props.conversationId)

  render: ->
    { loading } = DownloadAllStore
    React.createElement("span", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        React.createElement("div", {"className": (styles.button), "onClick": (@onDownloadAll)},
          React.createElement(Isvg.default, {"src": (DOWNLOAD_SVG)})
        )
      )
    )

module.exports = DownloadAllButtonComponent
