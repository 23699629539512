Isvg = require 'react-inlinesvg'
classnames = require 'classnames'

Button = require 'components/shared/Button'
WarningPopupComponent = require '../WarningPopupComponent'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
ChangeCategoryModal = require '../ChangeCategoryModal'

styles = require './answer-manager.styl'

class AnswerManagerComponent extends React.Component
  constructor: (props) ->
    super(props)
    { answer } = props
    @state = {
      name: answer.name || ''
      text: answer.text || ''
      deleteId: null
    }

  onFieldChange: (e) =>
    field = e.currentTarget.dataset.field
    @setState "#{field}": e.currentTarget.value

  checkDisabledSave: ->
    !@state.name || !@state.text ||
    (@props.answer.name || '') == @state.name &&
    (@props.answer.text || '') == @state.text

  onSaveClick: =>
    if @props.answer.id
      ReplyAnswersActionCreator.updateAnswer {
        id: @props.answer.id
        name: @state.name
        text: @state.text
        template_type: @props.templateType
        reply_group_id: if @props.categoryId == 'personal' then undefined else @props.categoryId
      }
    else
      ReplyAnswersActionCreator.createAnswer {
        name: @state.name
        text: @state.text
        reply_group_id: @props.categoryId
        template_type: @props.templateType
      }, @props.onCreateCallback

  onCancelClick: (e) => @props.onCancel()

  componentWillReceiveProps: (nextProps) ->
    if nextProps.answer.id != @props.answer.id
      @setState({ name: nextProps.answer.name, text: nextProps.answer.text })

  onDeleteClick: (e) =>
    answerId = parseInt e.currentTarget.dataset.id, 10
    @setState deleteId: answerId

  onCancelDeleting: (e) =>
    e.stopPropagation()
    @setState deleteId: null

  onApproveDeleting: (e) =>
    e.stopPropagation()
    @setState deleteId: null
    ReplyAnswersActionCreator.deleteAnswer @state.deleteId, @props.onDeleteCallback

  onMoveClick: =>
    InterfaceActionCreator.openModal(
      ChangeCategoryModal
      {
        onCategoryClick: @onChangeCategory
        currentCategoryId: @props.categoryId
        pageCategoryCount: @props.pageCategoryCount
        onPageCategoryChange: @props.onPageCategoryChange
        page: @props.page
      }
    )

  onChangeCategory: (categoryId) =>
    return if categoryId == @props.categoryId

    ReplyAnswersActionCreator.updateAnswer {
      id: @props.answer.id
      name: @state.name
      text: @state.text
      reply_group_id: categoryId
      template_type: @props.templateType
    }
    InterfaceActionCreator.closeModal()
    @props.onCancel()

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("header", { \
        "className": (classnames styles.header,
          "#{styles.header_disabled}": !@props.answer.id
        )
      },
        React.createElement("span", {"className": (styles.header_button_wrapper)},
          React.createElement("button", { \
            "className": (classnames(styles.header_button, styles.header_button_delete)),  \
            "data-id": (@props.answer.id),  \
            "onClick": (@onDeleteClick)
          },
            React.createElement("span", null,
              React.createElement(Isvg.default, {"src": (require 'components/shared/images/delete.svg')})
            ),
            React.createElement("span", null, """
              Delete answer
""")
          ),
          (if @state.deleteId
            React.createElement("div", {"className": (styles.warning)},
              React.createElement(WarningPopupComponent, { \
                "text": "Are you sure you want to delete that answer?",  \
                "onCancel": (@onCancelDeleting),  \
                "onDelete": (@onApproveDeleting)
              })
            )
          )
        ),
        React.createElement("button", { \
          "className": (classnames(styles.header_button, styles.header_button_move)),  \
          "onClick": (@onMoveClick)
        },
          React.createElement("span", null,
            React.createElement(Isvg.default, {"src": (require 'components/shared/images/refresh.svg')})
          ),
          React.createElement("span", null, """
            Move to...
""")
        )
      ),

      React.createElement("div", {"className": (styles.content)},
        React.createElement("label", {"className": (styles.label)}, "Title"),
        React.createElement("input", { \
          "className": (styles.input),  \
          "value": (@state.name),  \
          "onChange": (@onFieldChange),  \
          "data-field": "name",  \
          "placeholder": "Enter title here",  \
          "maxLength": "100"
        }),

        React.createElement("label", {"className": (styles.label)}, "Answer text"),
        React.createElement("textarea", { \
          "className": (styles.textarea),  \
          "onChange": (@onFieldChange),  \
          "data-field": "text",  \
          "value": (@state.text),  \
          "placeholder": "Add text here"
        }),
        React.createElement("div", {"className": (styles.submit)},
          React.createElement(Button, {"onClick": (@onCancelClick), "title": "Cancel", "white": true}),
          React.createElement(Button, { \
            "onClick": (@onSaveClick),  \
            "title": "Save Changes",  \
            "className": (styles.save_button),  \
            "disabled": (@checkDisabledSave())
          })
        )
      )
    )

module.exports = AnswerManagerComponent
