createConfirmation = (Component, unmountDelay = 1000, mountingNode = document.body) ->
  (props) ->
    wrapper = mountingNode.appendChild document.createElement 'div'

    dispose = () ->
      setTimeout(
        ->
          ReactDOM.unmountComponentAtNode(wrapper);
          setTimeout(
            -> document.body.removeChild wrapper
            10
          )
        unmountDelay
      )

    promise = new Promise (resolve, reject) ->
      try
        ReactDOM.render(
          React.createElement(Component, { \
            "reject": (reject),  \
            "resolve": (resolve),  \
            "dispose": (dispose),  \
            "confirmation": (props.confirmation),  \
            "options": (props.options)
          })
          wrapper
        )
      catch e
        console.error e
        throw e

    promise.then(
      (result) ->
        dispose()
        result
      (result) ->
        dispose()
        Promise.reject result
    )

module.exports = createConfirmation