React = require 'react'
classnames = require 'classnames'
moment = require 'moment'
browserHistory = require './../../../../history'

BaseComponent = require '../../../BaseComponent'
{ Link } = require 'react-router-dom'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
ReactPaginate = require 'react-paginate'
{ domain, protocol } = require 'root/config.coffee'
MessageErrorsStore = require './MessageErrorsStore'
Calendar = require '../../../shared/CalendarComponent'
Button = require 'components/shared/Button'
{ RANGE_DAILY } = require '../../../../constants/calendar'
{ getQueryParams, buildQueryParamsString } = require 'lib/utils'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
paginationStyles = require 'components/shared/styles/pagination.styl'
AuthStore = require 'stores/AuthStore'
{ ALLOW_ACCESS_ERRORS_ROLES, HOVER_INFO_FAILED } = require 'constants/Agents'
{ getWeekByDate,
generateNewRangeByType } = require 'lib/calendar-calculator'

ErrorsGlossary = require('./ErrorsGlossary').default
ErrorTabs = require('./ErrorTabs').default

styles = require '../dashboard.styl'

fieldsMap =
  average_wating_time: 'shift waiting time'
  average_response_time: 'shift response time'
  unique_clients_count: '# unique clients'
  sales_unique_clients_count: '# sales unique clients'
  conversations_count: '# unique conversations'
  overload: "% average overload"

DEFAULT_PER_PAGE = 25
COLUMNS =
  'order': (_skip, __skip, order) -> React.createElement("span", null, (order))
  'created_at': (message, date) ->
    utc = moment(date).utc().format 'YYYY-MM-DD HH:mm'
    local = moment(date).format 'YYYY-MM-DD HH:mm'
    React.createElement("span", {"title": (local)}, (utc))
  'conversation_id': (message, id) ->
    link = "/conversations/my_conversations/#{id}"
    React.createElement(Link, {"to": (link)},
      (id)
    )
  'channel_name': false
  'type': (_skip, number) -> if number.length < 6 then 'short code' else '10DLC'
  'from': false
  'to': false
  'error_code': (message, code) ->
    if message.info.failed_info
      React.createElement("span", {"title": (HOVER_INFO_FAILED[message.operator](message.info.failed_info))}, (code))
    else
      code
  'operator': (_skip, text) -> React.createElement("span", {"className": (styles.capitalize_text)}, (text))
  'provider': false
  
SORT_COLUMNS = ['created_at']
COLUMNS_WIDTH =
  'created_at': styles.mesages_table_td_12
  'type': styles.mesages_table_td_6

KEY_VALUE_MAP =
  'type': 'from'

CustomDateOpen = (props) => React.createElement("div", {"onClick": (props.onDateClick)}, (props.date || props.placeholder))

FILTERS =
  'created_at': (state, handler, context) =>
    React.createElement("div", {"className": (styles.created_at_filter)},
      React.createElement("div", {"data-calendar": "true", "className": (classnames styles.created_at_filter_item, styles.created_at_filter_item_first)},
        React.createElement(Calendar, { \
            "beginDate": (state.beginDate),  \
            "endDate": (state.endDate),  \
            "onSelectHandler": ((range) -> context.newDateRangeHandler(range, 'daily')),  \
            "onChangeTypeHandler": (context.onChangeRangeType),  \
            "types": (['daily']),  \
            "type": ('daily'),  \
            "dailyType": "beginDate",  \
            "hideArrow": true,  \
            "disableRangeChanging": true
          }, React.createElement(CustomDateOpen, {"placeholder": "from", "date": (state.beginDate)}))
      ),
      React.createElement("div", {"data-calendar": "true", "className": (styles.created_at_filter_item)},
        React.createElement(Calendar, { \
            "beginDate": (state.beginDate),  \
            "endDate": (state.endDate),  \
            "onSelectHandler": ((range) -> context.newDateRangeHandler(range, 'daily')),  \
            "onChangeTypeHandler": (context.onChangeRangeType),  \
            "types": (['daily']),  \
            "type": ('daily'),  \
            "dailyType": "endDate",  \
            "hideArrow": true,  \
            "disableRangeChanging": true
          }, React.createElement(CustomDateOpen, {"placeholder": "to", "date": (state.endDate)})))
    )
  'conversation_id': (state, handler) ->
    onSearch = (query) ->
      handler({'conversation_id': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'Conversation id',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.conversation_id)
    })
  'channel_name': (state, handler) ->
    onSearch = (query) ->
      handler({'channel_name': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'Channel name',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.channel_name)
    })
  'from': (state, handler) ->
    onSearch = (query) ->
      handler({'from': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'From',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.from)
    })
  'to': (state, handler) ->
    onSearch = (query) ->
      handler({'to': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'To',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.to)
    })
  'error_code': (state, handler) ->
    onSearch = (query) ->
      handler({'errorCode': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'ErrorCode',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.errorCode)
    })
  'operator': (state, handler) ->
    onSearch = (query) ->
      _query = if query then query.toLocaleLowerCase() else null
      handler({'operator': _query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'From Provider',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.operator.replace /\b\w/g, (l) -> l.toUpperCase())
    })
  'provider': (state, handler) ->
    onSearch = (query) ->
      handler({'provider': query || ''})
    React.createElement(SearchInputComponent, { \
      "placeholder": 'To provider',  \
      "inputClassName": (styles.textbox_input),  \
      "crossClassName": (styles.cross_input),  \
      "clearCross": true,  \
      "onClear": (onSearch),  \
      "onChange": (onSearch),  \
      "ref": "textbox",  \
      "defaultValue": (state.provider)
    })

COLUMN_NAMES =
  'error_code': 'Error code'
  'from': 'From'
  'to': 'To'
  'created_at': 'Created at (UTC)'
  'operator': 'From Provider'
  'provider': 'To Provider'
  'conversation_id': 'Conversation ID'
  'channel_name': 'Channel name'
  'order': '#'
  'type': 'Type'

RANGE_TYPES = ['monthly', 'weekly', 'daily']

class MessageErrorsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    params = getQueryParams location.search.substring(1)
    beginDate = new moment(params.begin_date)
    endDate = new moment(params.end_date)
    dateRange = generateNewRangeByType rangeType, beginDate, endDate, true
    rangeType = RANGE_TYPES[1]
    unless RANGE_TYPES.indexOf(params.range_type) == -1
      rangeType = params.range_type
    sortOrder = params.sort_order || 'desc'
    sortBy = params.sort_by || 'created_at'
    @state =
      rangeType: rangeType
      beginDate: dateRange.beginDate
      endDate: dateRange.endDate
      page: params.page || 0
      perPage: params.per_page || DEFAULT_PER_PAGE
      sortOrder: sortOrder
      orderBy: sortBy
      to: params.to || ''
      from: params.from || ''
      errorCode: params.error_code || ''
      operator: params.operator || ''
      provider: params.provider || ''
      conversation_id: params.conversation_id || ''
      channel_name: params.channel_name || ''
      type: params.type || ''
    @initComponent()

  getPersonalStoreClass: -> MessageErrorsStore

  componentDidMount: ->
    super()
    if ALLOW_ACCESS_ERRORS_ROLES[AuthStore.user.role]
      @setSearchFilters()
    else
      browserHistory.default.push '/'

  setSearchFilters: =>
    {
      beginDate,
      endDate,
      page,
      perPage,
      sortOrder,
      orderBy,
      rangeType,
      to,
      from,
      operator,
      provider,
      errorCode,
      channel_name,
      type,
      conversation_id
    } = @state
    params =
      begin_date: beginDate
      end_date: endDate
      page: page
      per_page: perPage
      sort_order: sortOrder
      order_by: orderBy
      range_type: rangeType
      error_code: errorCode
      to: to
      from: from
      operator: operator
      provider: provider
      conversation_idconversation_id: conversation_id
      channel_name: channel_name
      type: type
    queryParams = "?#{buildQueryParamsString params}"
    window.history.replaceState null, null, queryParams
    setTimeout(
      =>
        @personalStore.actionCreator.getMessageErrors(@state)
      10
    )

  newDateRangeHandler: (newRange, type) =>
    newDates =
      beginDate: newRange.startDate.format 'YYYY-MM-DD'
      endDate: newRange.endDate.format 'YYYY-MM-DD'
    @setState(
      { beginDate: newDates.beginDate, endDate: newDates.endDate, page: 0, rangeType: type || @state.rangeType }
      @setSearchFilters
    )

  onChangeRangeType: (type) =>
    @setState( rangeType: type )

  onPageChange: ({selected}) =>
    if @state.page == selected
      return
    @setState(
      { page: selected }
      @setSearchFilters
    )

  onChangeFilter: (data) =>
    data.page = 0
    @setState(
      data
      @setSearchFilters
    )

  clearFilters: () =>
    @setState(
      {
        beginDate: undefined
        endDate: undefined
        rangeType: 'weekly'
        to: ''
        from: ''
        errorCode: ''
        operator: ''
        provider: ''
        page: 0
      }
      @setSearchFilters
    )

  changeSortValue: (column) =>
    =>
      if !SORT_COLUMNS.includes column
        return
      { sortOrder } = @state
      _sortOrder = if sortOrder == 'asc' then 'desc' else 'asc'
      @setState(
        { orderBy: column, sortOrder: _sortOrder, page: 0 }
        @setSearchFilters
      )

  render: ->
    { perPage, page, beginDate, endDate, rangeType, sortOrder } = @state
    total = @personalStore.statistic?.total || 0
    pageCount = pageCount = Math.ceil(total / perPage)
    React.createElement("div", {"className": (classnames(styles.grid, styles.grid__full_height))},
      React.createElement("div", {"className": (styles.full_row_of_grid)},
        React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
          React.createElement(Calendar, { \
            "beginDate": (beginDate),  \
            "endDate": (endDate),  \
            "onSelectHandler": (@newDateRangeHandler),  \
            "onChangeTypeHandler": (@onChangeRangeType),  \
            "type": (rangeType)
          }),
          React.createElement(Button, {"title": "Clear filters", "onClick": (@clearFilters), "className": (styles.message_errors_clear)}),
          React.createElement(ErrorTabs, null),
          (if @personalStore.statistic
            React.createElement("table", {"className": (styles.mesages_table)},
              React.createElement("thead", {"className": (styles.mesages_table_head)},
                React.createElement("tr", {"key": ('filters')},
                  (Object.keys(COLUMNS).map (key) =>
                    if FILTERS[key]
                      React.createElement("td", {"className": (classnames(styles.mesages_table_td)), "key": ('filters' + key)},
                        (FILTERS[key](@state, @onChangeFilter, @))
                      )
                    else
                      React.createElement("td", {"key": ('filters' + key)})
                  )
                ),
                React.createElement("tr", {"key": ('head')},
                  (Object.keys(COLUMNS).map (key) =>
                    React.createElement("td", {"className": (classnames styles.mesages_table_td, COLUMNS_WIDTH[key],
                      "#{styles.mesages_table_td_sort}": SORT_COLUMNS.includes key
                      "#{styles.mesages_table_td_sort_asc}": sortOrder == 'asc' && SORT_COLUMNS.includes key
                      ), "key": (key), "onClick": (@changeSortValue(key))}, (COLUMN_NAMES[key] || key))
                  )
                )
              ),
              React.createElement("tbody", null,
                (@personalStore.statistic.messages.map (message, index) ->
                  React.createElement("tr", {"key": (message.id)},
                    (Object.keys(COLUMNS).map (key) ->
                      React.createElement("td", { \
                        "className": (styles.mesages_table_td),  \
                        "key": (message.id + key)
                      },
                        (
                          if COLUMNS[key]
                            COLUMNS[key](message, message[KEY_VALUE_MAP[key] || key], ((index + 1) + (page * DEFAULT_PER_PAGE)))
                          else
                            message[key]
                        )
                      )
                    )
                  )
                )
              )
            )
          ),
          (if pageCount > 1
            React.createElement("div", {"className": (styles.failed_messages_pages)},
              React.createElement(ReactPaginate.default, { \
                "previousLabel": 'previous',  \
                "nextLabel": 'next',  \
                "pageCount": (pageCount),  \
                "marginPagesDisplayed": (2),  \
                "pageRangeDisplayed": (4),  \
                "onPageChange": (@onPageChange),  \
                "initialPage": (page),  \
                "pageClassName": (paginationStyles.page),  \
                "nextClassName": (paginationStyles.page),  \
                "previousClassName": (paginationStyles.page),  \
                "activeClassName": (paginationStyles.active),  \
                "pageLinkClassName": (paginationStyles.link),  \
                "nextLinkClassName": (paginationStyles.link),  \
                "previousLinkClassName": (paginationStyles.link),  \
                "containerClassName": (paginationStyles.container),  \
                "breakClassName": (paginationStyles.page)
              })
            )
          )
        )
      )
    )

module.exports = MessageErrorsComponent
