{Link} = require 'react-router-dom'
deepcopy = require 'deepcopy'

BaseComponent = require '../../BaseComponent'
styles = require './agent-search'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
CustomSelectComponent = require '../../shared/CustomSelectComponent'
{ROLES, ACTIVITIES, STATUSES} = require '../../../constants/Agents'
AuthStore = require '../../../stores/AuthStore'

rolesWithoutDuplications =
  role:
    label: 'Role'
    value: 'role'

Object.keys ROLES
  .forEach (key) ->
    unless (rolesWithoutDuplications[ROLES[key].value] || {}).active
      rolesWithoutDuplications[ROLES[key].value] = ROLES[key]

activities =
  activity:
    label: 'Activity'
    value: 'activity'

activities = Object.assign activities, ACTIVITIES

statuses =
  status:
    label: 'Status'
    value: 'status'

statuses = Object.assign statuses, STATUSES


class AgentSearchComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  onSelectChange: (options, selectName, value) ->
    unless value == @props.filters[selectName]
      if options[value]
        @props.filters[selectName] = options[value].value
      else
        delete @props.filters[selectName]

      @props.onChangeHandler(
        @refs.textbox.refs.searchField.value
        @props.filters
      )

  searchOperators: (query) =>
    @props.onChangeHandler query.toLowerCase(), @props.filters

  onClearSearch: =>
    @props.onChangeHandler '', @props.filters

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.container)},
        React.createElement("div", {"className": (styles.textbox)},
          React.createElement(SearchInputComponent, { \
            "placeholder": 'Find Operator',  \
            "inputClassName": (styles.textbox_input),  \
            "clearCross": true,  \
            "onClear": (@onClearSearch),  \
            "onChange": (@searchOperators),  \
            "ref": "textbox",  \
            "defaultValue": (@props.query),  \
            "autofocus": true
          })
        ),
        React.createElement("div", {"className": (styles.select)},
          React.createElement(CustomSelectComponent, { \
            "defaultValue": (if (rolesWithoutDuplications[@props.filters.role] || {}).value
                rolesWithoutDuplications[@props.filters.role].value
              else
                rolesWithoutDuplications.role.value
            ),  \
            "options": (rolesWithoutDuplications),  \
            "onOptionSelect": (@onSelectChange.bind(@, ROLES, 'role')),  \
            "customOptionsStyle": (styles.select__options)
          })
        ),
        React.createElement("div", {"className": (styles.select)},
          React.createElement(CustomSelectComponent, { \
            "defaultValue": (if (statuses[@props.filters.status] || {}).value
                statuses[@props.filters.status].value
              else
                statuses.status.value
            ),  \
            "options": (statuses),  \
            "onOptionSelect": (@onSelectChange.bind(@, STATUSES, 'status')),  \
            "customOptionsStyle": (styles.select__options)
          })
        ),
        React.createElement("div", {"className": (styles.select)},
          React.createElement(CustomSelectComponent, { \
            "defaultValue": (if (activities[@props.filters.activity] || {}).value
                activities[@props.filters.activity].value
              else
                activities.activity.value
            ),  \
            "options": (activities),  \
            "onOptionSelect": (@onSelectChange.bind(@, ACTIVITIES, 'activity')),  \
            "customOptionsStyle": (styles.select__options)
          })
        )
      )
    )

module.exports = AgentSearchComponent
