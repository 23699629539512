Dispatcher = require 'root/dispatcher/Dispatcher'
CustomerioEventsAPI = require 'root/api/admin/CustomerioEventsAPI'
ActionTypes = require 'constants/ActionTypes'

CustomerioEventsActionCreator =
  getList: ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_REQUESTING
    CustomerioEventsAPI.getList(
      {}
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_SUCCESS
          payload: data
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_FAILURE
          payload: 'An error occurred while get customerio events'
    )

  get: (data) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_REQUESTING
      payload: data
    CustomerioEventsAPI.getList(
      data
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_SUCCESS
          payload:
            data: response
            text: data.text
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_FAILURE
          payload:
            error: 'An error occurred while get customerio events'
            text: text
    )

  pause: (data, successCallback, errorCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_REQUESTING
      payload: data
    CustomerioEventsAPI.pause(
      data
      () ->
        successCallback?()
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_SUCCESS
          payload: data
      (error) ->
        errorCallback?(error)
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_FAILURE
          payload:
            error: 'An error occurred while pausing customerio event(s)'
            data: data
    )

  destroy: (data, successCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_REQUESTING
      payload: data
    CustomerioEventsAPI.destroy(
      data
      () ->
        successCallback?()
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_SUCCESS
          payload: data
      () ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_FAILURE
          payload:
            error: 'An error occurred while deleting customerio event(s)'
            data: data
    )

module.exports = CustomerioEventsActionCreator
