classnames = require 'classnames'
onClickOutside = require 'react-onclickoutside'

styles = require './channels'
BaseComponent = require 'components/BaseComponent'
ChannelsStore = require 'stores/ChannelsStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'

propTypes = require 'prop-types'

isActive = (channel) -> !channel.disabled

class ChannelsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      opened: false
    }
    @initComponent()

  @propTypes:
    value: propTypes.object
    onChange: propTypes.func

  dependsOnStores: [ChannelsStore]

  toggleOpen: =>
    if @props.customerId && !@state.opened
      ChannelsActionCreator.getForCustomer @props.customerId
    @setState opened: !@state.opened

  handleClickOutside: => @setState opened: false if @state.opened

  render: ->
    selectedItemClassName = classnames(
      styles.current,
      styles[@props.className],
      "#{styles.opened}": @state.opened
    )

    optionsListClassName = classnames(
      styles.dropdown_container,
      "#{styles.dropdown_opened}": @state.opened
    )

    activeChannels = @props.channels

    React.createElement("div", {"className": (styles.container), "onClick": (@toggleOpen)},
      React.createElement("div", { \
        "className": (selectedItemClassName),  \
        "style": (backgroundColor: @props.value?.highlight_color)
      },
        (@props.value?.name)
      ),
      React.createElement("div", {"className": (optionsListClassName)},
        React.createElement(LoadableWrapperComponent, {"loading": (ChannelsStore.loading)},
          React.createElement("div", {"className": (styles.dropdown)},
            (ChannelsStore.channels.map (channel) =>
              highlightStyle =
                backgroundColor: channel.highlight_color

              onClick = _.partial(@props.onChange, channel)
              className = classnames(
                styles.dropdown_item,
                "#{styles.dropdown_item_selected}": @props.value.id == channel.id
              )

              React.createElement("div", { \
                "className": (className),  \
                "key": (channel.id),  \
                "onClick": (onClick),  \
                "style": (highlightStyle)
              },
                (channel.name)
              )
            )
          )
        )
      )
    )

module.exports = onClickOutside.default(ChannelsComponent)
