BaseComponent               = require '../../BaseComponent'
onClickOutside              = require 'react-onclickoutside'
CREDENTIAL_TYPE             = require '../../../constants/CredentialTypes'

getCredentialTypeName = (type) ->
  switch type
    when CREDENTIAL_TYPE.PHONE then 'Cell phone number'
    when CREDENTIAL_TYPE.EMAIL then 'Email'
    when CREDENTIAL_TYPE.BILLING_EMAIL then 'Billing email'
    when CREDENTIAL_TYPE.FACEBOOK then "fb" # messenger_id
    when CREDENTIAL_TYPE.FACEBOOK_UNACCESSIBLE then "Facebook account" # facebook_id
    when CREDENTIAL_TYPE.TWITTER then 'Twitter account'
    when CREDENTIAL_TYPE.CUSTOM then 'Custom credential'

class CredentialTypeListComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  handleClickOutside: (e) =>
    @props.handleClickOutside()

  render: =>
    React.createElement("div", {"className": @props.classList},
      (
        for type in CREDENTIAL_TYPE._ALL
          React.createElement("div", { \
            "className": @props.classListItem,  \
            "key": type,  \
            "onClick": ( _.partial(@props.onItemClick, type) )
          },
            ( getCredentialTypeName(type) )
          )
      )
    )

module.exports = onClickOutside.default(CredentialTypeListComponent)
