classnames = require 'classnames'
browserHistory = require './../../history'

BaseComponent = require '../BaseComponent'
CSVExportStore = require './CSVExportStore'
LoadableWrapperComponent = require '../shared/LoadableWrapperComponent/LoadableWrapperComponent'
{urls, menus} = require '../../config'
styles = require './csv-export'
AuthStore = require '../../stores/AuthStore'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'groups'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

class CSVExportComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> CSVExportStore

  componentWillMount: ->
    if accessConfig.toPage
      if accessConfig.toPage.indexOf(AuthStore.user.role) == -1
        browserHistory.default.push '/'

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  render: ->
    data = null

    if @personalStore.error
      data = React.createElement("div", { \
        "className": (classnames styles.message, styles.error)
      },
        (@personalStore.error)
      )
    else
      data = Object.keys @personalStore.links
        .map (type, i) =>
          React.createElement("a", { \
            "key": (i),  \
            "href": (urls.csvExport.download @personalStore.links[type]),  \
            "className": (styles.link),  \
            "target": '_blank'
          },
            React.createElement("button", {"className": (styles.button)},
              ("Download #{type} report")
            )
          )

      unless data.length
        data = React.createElement("div", {"className": (styles.message)}, """
          No data yet
""")

    React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
      React.createElement("div", {"className": (styles.container)},
        (data)
      )
    )

module.exports = CSVExportComponent
