{ urls } = require '../config'

ProfileAPI =
  get: (success, error) ->
    $.ajax {
      url: urls.self
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

module.exports = ProfileAPI
