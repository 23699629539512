classnames = require 'classnames'
ReactDnD = require 'react-dnd'

dndUtils = require 'lib/dnd-utils'
SearchUtils = require 'lib/SearchUtils'
{ SORT_LIST } = require 'constants/ReplyTemplates'
ItemTypes = require './../ItemTypes'
sectionStyles = require '../section-style.styl'


propTypes = require 'prop-types'

answerSource =
  canDrag: (props) ->
    # in other cases DnD positioning work not quite correct
    props.sortRule ==  SORT_LIST.position.value

  beginDrag: (props) ->
    id: props.answer.id
    index: props.index

  endDrag: (props, monitor) ->
    dragId = monitor.getItem().id
    props.updatePosition(dragId) if monitor.didDrop()

answerTarget =
  hover: dndUtils.standartHoverHandler

  canDrop: (props, monitor) ->
    return !!props.answer

class AnswerComponent extends React.Component
  render: ->
    { props } = @

    textOrComponent = props.answer.name
    
    if props.isSearch
      textOrComponent = SearchUtils.decodeElasticsearchHighlighting(props.answer.name)

    props.connectDragSource(
      props.connectDropTarget(
        React.createElement("li", {"className": (classnames(
          "#{sectionStyles.dropable}": props.canDrop,
          "#{sectionStyles.hide}": props.isDragging
        ))},
          React.createElement("div", { \
            "className": (classnames(sectionStyles.item,
              "#{sectionStyles.item_selected}": props.selected && props.selected.id == props.answer.id
            )),  \
            "onClick": (props.onAnswerClick),  \
            "data-value": (props.answer.id)
          },
            React.createElement("span", null, (textOrComponent)),
            (if props.answer.user
              React.createElement("span", {"className": (sectionStyles.user_name)}, "(", (props.answer.user.name), ")")
            )
          )
        )
      )
    )


DragSourceDecorator = ReactDnD.DragSource(
  ItemTypes.ANSWER,
  answerSource,
  (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()
)

DropTargetDecorator = ReactDnD.DropTarget(
  ItemTypes.ANSWER,
  answerTarget,
  (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    canDrop: monitor.canDrop()
)
AnswerComponent.propTypes =
  answer: propTypes.shape(
    id: propTypes.number
    name: propTypes.string.isRequired
    user: propTypes.shape(
      id: propTypes.number
      name: propTypes.string
    )
  ).isRequired
  selected: propTypes.any
  onClick: propTypes.func.isRequired
  connectDragSource: propTypes.func.isRequired
  connectDropTarget: propTypes.func.isRequired
  isDragging: propTypes.bool.isRequired
  swapItems: propTypes.func.isRequired
  updatePosition: propTypes.func
  sortRule: propTypes.string.isRequired

module.exports = DropTargetDecorator(DragSourceDecorator(AnswerComponent))