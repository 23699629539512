Isvg = require 'react-inlinesvg'
moment = require 'moment'
browserHistory = require './../../../history'
DateTimePicker = require 'react-widgets/lib/DateTimePicker'

BaseComponent = require 'components/BaseComponent'
Button = require 'components/shared/Button'
ScheduledMessagesActionCreator = require 'actions/admin/ScheduledMessagesActionCreator'
ScheduledMessagesStore = require 'stores/admin/ScheduledMessagesStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AuthStore = require 'stores/AuthStore'

styles = require './scheduled-message-edit-component.styl'

class ScheduledMessageEditComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @backUrl = "/admin/#{props.match.params.type}"

    item = ScheduledMessagesStore.scheduledMessages.get(@props.match.params.date)
    @scheduledMessage = item?.scheduled_messages.find (scheduledMessage) => scheduledMessage.id == +@props.match.params.scheduledMessageId

    @state = {
      text: @scheduledMessage?.text || ''
      time: new Date(@scheduledMessage?.time)
    }

    @initComponent()

  dependsOnStores: [ScheduledMessagesStore]

  componentWillMount: =>
    item = ScheduledMessagesStore.scheduledMessages.get(@props.match.params.date)
    if !item?.scheduled_messages
      setTimeout(
        => ScheduledMessagesActionCreator.get(@props.match.params.date)
        10
      )

  getState: =>
    item = ScheduledMessagesStore.scheduledMessages.get(@props.match.params.date)
    @scheduledMessage = item?.scheduled_messages.find (scheduledMessage) => scheduledMessage.id == +@props.match.params.scheduledMessageId

    if @scheduledMessage
      text: @scheduledMessage?.text || ''
      time: new Date(@scheduledMessage?.time)

  onMessageChange: (e) => @setState text: e.target.value

  setNewDateTime: (date, time) =>
    datePart = moment(date).format('YYYY-MM-DD')
    timePart = moment(time).format('HH:mm Z')
    @setState time: moment("#{datePart} #{timePart}").toDate()

  onBackUrl: =>
    browserHistory.default.push @backUrl

  onSetDate: (date) =>
    @setNewDateTime(date, @state.time)

  onSetTime: (time) =>
    @setNewDateTime(@state.time, time)


  onSaveMessage: =>
    ScheduledMessagesActionCreator.update(
      @props.match.params.scheduledMessageId
      Object.assign({}, @scheduledMessage, @state)
      @scheduledMessage
      @onSuccess
    )

  onSuccess: =>
    @onBackUrl()

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("header", {"className": (styles.header)},
        React.createElement("h2", null, """
          Edit scheduled message
"""),
        React.createElement("span", {"className": (styles.close), "onClick": (@onBackUrl)},
          React.createElement(Isvg.default, {"src": (require 'components/shared/images/cross.svg')})
        )
      ),

      React.createElement("div", {"className": (styles.content)},
        React.createElement("div", {"className": (styles.schedule)},
          React.createElement("textarea", { \
            "placeholder": "Add text of your message here",  \
            "className": (styles.message),  \
            "onChange": (@onMessageChange),  \
            "value": (@state.text)
          }),
          React.createElement("div", {"className": (styles.controls)},
            React.createElement("div", {"className": (styles.datepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set date",  \
                "time": (false),  \
                "onChange": (@onSetDate),  \
                "value": (@state.time)
              })
            ),
            React.createElement("div", {"className": (styles.timepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set time",  \
                "date": (false),  \
                "onChange": (@onSetTime),  \
                "value": (@state.time)
              })
            ),
            React.createElement(Button, { \
              "title": "Save scheduled message",  \
              "disabled": (!@state.text),  \
              "className": (styles.submit),  \
              "onClick": (@onSaveMessage)
            })
          )
        ),

        (if ScheduledMessagesStore.error
          React.createElement("p", {"className": (styles.error)}, (ScheduledMessagesStore.error))
        )
      )
    )

module.exports = ScheduledMessageEditComponent
