GuidedActionCreator = require '../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../constants/ActionTypes'
DashboardAPI = require '../../../api/DashboardAPI'

class DashboardTabsActionCreator extends GuidedActionCreator

  requests: [
    'getAnalytic'
    'getAllConversations'
    'getWaitingConversations'
  ]

  getStatistic: () =>
    @dispatch type: ActionTypes.DASHBOARD_REQUESTING
    promises = @requests.map (req) ->
      new Promise (resolve, reject) ->
        DashboardAPI[req](
          (data) ->
            resolve data
          (error) ->
            reject error
        )

    dispatch = @dispatch
    Promise.all promises
      .then (data) ->
        dispatch(
          type: ActionTypes.DASHBOARD_SUCCESS
          payload:
            analytics: data[0]
            all: data[1]
            waiting: data[2]
        )
      .catch (err) ->
        dispatch type: ActionTypes.DASHBOARD_ERROR, payload: err

module.exports = DashboardTabsActionCreator