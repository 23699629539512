BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
AuthStore = require './AuthStore'


class FlagsStore extends BaseStore
  constructor: ->
    super()
    @flags = AuthStore.user?.flags
    AuthStore.addChangeListener @onUserChange
    @newFlags = {}
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actions = @getActions()
    actions[type].call(@, payload) if actions[type]

  getActions: =>
    "#{ActionTypes.FLAGS_CHANGE}": @onChange
    "#{ActionTypes.FLAGS_SYNC}": @onSync

  onUserChange: =>
    if !@flags
      @flags = AuthStore.user?.flags
      @emitChange()

  onChange: (payload) =>
    @flags = {} if !@flags
    @newFlags[payload.id] = { color: payload.color }
    @flags[payload.id] = { color: payload.color }
    @emitChange()

  onSync: =>
    @newFlags = {}

module.exports = new FlagsStore()
