classnames = require 'classnames'

styles = require '../styles/custom-select'

propTypes = require 'prop-types'

class SearchTypeSelect extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      showOptions: false
      value: props.defaultValue
      eventListenerExist: false

  @propTypes:
    title: propTypes.string
    defaultValue: propTypes.string
    options: propTypes.object
    onOptionSelect: propTypes.func
    customSelectStyle: propTypes.string
    customOptionsStyle: propTypes.string

  toggleOptions: =>
    @setState showOptions: !@state.showOptions

  getOptionsList: =>
    if @state.showOptions
      currentValue = @state.value
      setValue = @setValue
      options = @props.options

      optionsClasses = classnames styles.container__options, @props.customOptionsStyle
      React.createElement("ul", {"ref": "options", "className": (optionsClasses)},
        (Object.keys options
          .map (key, i) ->
            React.createElement("li", { \
              "key": (i), "className": (classnames styles['container__options-option'],
                "#{styles['container__options-option_current']}": currentValue == options[key].value
              ),  \
              "data-value": (options[key].value), "onClick": (setValue)
            },
              (options[key].title)
            )
        )
      )
    else
      null

  setValue: (e) =>
    selectedValue = e.target.getAttribute 'data-value'
    @setState value: selectedValue
    @props.onOptionSelect selectedValue

  rolesBlur: (e) =>
    unless e.target == @refs.options
      document.removeEventListener 'click', @rolesBlur
      @setState showOptions: false, eventListenerExist: false

  componentDidUpdate: ->
    if @refs.options && !@state.eventListenerExist
      document.addEventListener 'click', @rolesBlur
      @setState eventListenerExist: true

  render: ->
    selectClasses = classnames styles.container, @props.customSelectStyle
    React.createElement("div", {"className": (selectClasses)},
      React.createElement("div", {"className": (styles.container__select), "onClick": (@toggleOptions)},
        (@props.options[@state.value].label),
        React.createElement("i", {"className": (classnames styles.container__select_icon, 'icon')})
      ),
      (@getOptionsList())
    )

module.exports = SearchTypeSelect
