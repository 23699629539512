countdown = require 'countdown'
classnames = require 'classnames'
moment = require 'moment'

styles = require './active-reminders'

propTypes = require 'prop-types'

class ActiveRemindersComponent extends React.Component
  @propTypes:
    reminders: propTypes.arrayOf(propTypes.object).isRequired
    destroyReminder: propTypes.func.isRequired

  countdown: (time) ->
    countdown.setLabels(
      ' millisecond| second| minute| hour| day| week| month| year| decade| century| millennium',
      ' milliseconds| seconds| minutes| hours| days| weeks| months| years| decades| centuries| millennia',
      ' and ',
      ', ',
      ''
    )
    
    units = countdown.DAYS|countdown.HOURS|countdown.MINUTES
    (countdown(new Date(), time, units).toString() || '< 1 minute') + " left"

  render: ->
    React.createElement("div", {"className": styles.root},
      React.createElement("div", {"className": styles.header},
        React.createElement("div", {"className": styles.title},
          ( "Active Reminders (#{@props.reminders.length}) " )
        )
      ),
      React.createElement("div", null,
        (
          for reminder in @props.reminders
            reminderStyles = classnames(styles.reminder,
              "#{styles.reminder_active}": @props.activeId == reminder.id
            )
            React.createElement("div", {"className": (reminderStyles), "key": reminder.id},
              React.createElement("div", {"className": styles.reminder_body},
                React.createElement("div", {"className": styles.time}, " ", ( moment(reminder.time).format("D MMMM YYYY") ), " "),
                React.createElement("div", {"className": styles.countdown}, "(", ( @countdown(moment(reminder.time).toDate()) ), ")"),
                React.createElement("div", {"className": styles.delete, "onClick": ( _.partial(@props.destroyReminder, reminder.id))}, """
                  Delete
"""),
                React.createElement("div", {"className": styles.edit_btn, "onClick": ( _.partial(@props.onEditReminder, reminder))}, """
                  Edit
"""),
                React.createElement("div", {"className": styles.text}, " ", ( reminder.text ), " ")
              ),
              React.createElement("div", {"className": 'clr'})
            )
        )
      )
    )

module.exports = ActiveRemindersComponent
