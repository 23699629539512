_ = require 'underscore'

QADashboardAPI = require '../api/QADashboardAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
{buildQueryParamsString, qaDashboardSortingRule} = require '../lib/utils'

module.exports =

  getList: (params) ->
    Dispatcher.dispatch type: ActionTypes.QA_GET_STATISTIC_REQUESTING
    error = 'An error occurred while fetch operator ranking statistic'

    queryParams = "?#{buildQueryParamsString params}"
    window.history.replaceState null, null, queryParams

    QADashboardAPI.getList params,
      (json) ->
        if json.success
          sortingRule = qaDashboardSortingRule.bind @, params.sort_by, params.sort_order
          sortedStatistic = json.users.data.sort sortingRule
          qa_teamleads = json.users.qa_teamleads || []
          Dispatcher.dispatch
            type: ActionTypes.QA_GET_STATISTIC_SUCCESS
            payload:
              qa_teamleads: qa_teamleads
              data: sortedStatistic
              total: json.users.total_row
        else
          Dispatcher.dispatch
            type: ActionTypes.QA_GET_STATISTIC_FAIL
            payload: error
      (response) ->
        console.error error, response
        Dispatcher.dispatch
          type: ActionTypes.QA_GET_STATISTIC_FAIL
          payload: error

  changeOrder: (statistic, params) ->
    queryParams = "?#{buildQueryParamsString params}"
    window.history.replaceState null, null, queryParams

    sortedStatistic = _.sortBy statistic, params.order_by
    if params.order == 'desc'
      sortedStatistic = sortedStatistic.reverse()
    Dispatcher.dispatch type: ActionTypes.QA_CHANGE_ORDER, payload: sortedStatistic

  changeTeamlead: (agent, id, endDate, teamleadId) ->
    Dispatcher.dispatch type: ActionTypes.QA_UPDATE_TEAMLEAD_REQUESTING

    params = {user_id: id, end_date: endDate, teamlead_id: teamleadId}
    QADashboardAPI.updateUser params,
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.QA_UPDATE_TEAMLEAD_SUCCESS
          payload:
            agent: agent,
            name: json.name,
            id: json.id
      (response) ->
        console.error response.error, response
        Dispatcher.dispatch
          type: ActionTypes.QA_UPDATE_TEAMLEAD_FAIL
          payload: response.error
