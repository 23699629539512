classnames = require 'classnames'

BaseComponent = require './../../BaseComponent'
SearchTypeSelect = require './SearchTypeSelect'
styles = require './search-input'

propTypes = require 'prop-types'

searchTypes =
  'and':
    value: 'and'
    label: 'FS'
    title: 'Full search'
  and_partially:
    value: 'and_partially'
    label: 'PS'
    title: 'Partial search'

class SearchInputComponent extends React.Component
  @propTypes:
    onChange: propTypes.func
    onClear: propTypes.func
    defaultValue: propTypes.string
    placeholder: propTypes.string
    className: propTypes.string
    clearCross: propTypes.bool
    inputClassName: propTypes.string
    autofocus: propTypes.bool

  @defaultProps:
    placeholder: "Search..."
    clearCross: false

  constructor: (props) ->
    super(props)
    @state =
      value: props.defaultValue || ""
      type: 'and'
    @debounceEmitChange ||= _.debounce(@emitChange, 700)

  onChange: (e) =>
    @setState value: e.target.value
    if e.target.value == ""
      @props.onClear?()
    else
      @debounceEmitChange()

  emitChange: =>
    @props.onChange? @state.value, @state.type

  setSearchType: (type) =>
    @setState type: type
    @debounceEmitChange()

  onClearCrossClick: (e) =>
    @setState value: ''
    @props.onClear()

  componentWillReceiveProps: (newProps) =>
    if @props.defaultValue && !newProps.defaultValue
      @setState value: ''
    if @props.folder && @props.folder != newProps.folder
      @setState value: ''

  componentDidMount: =>
    @refs.searchField.focus() if @props.autofocus

  render: ->
    rootClassName = classnames(
      @props.className, styles.root,
      "#{styles.root_show_clear_cross}": @state.value != ""
      "#{styles.enable_type_choosing}": @props.chooseType
    )
    inputClassName = classnames @props.inputClassName, styles.input
    crossClassName = classnames @props.crossClassName, styles.clear_cross
    React.createElement("div", {"className": rootClassName},
      React.createElement("input", { \
        "value": @state.value,  \
        "className": inputClassName,  \
        "onChange": @onChange,  \
        "placeholder": @props.placeholder,  \
        "form": 'no',  \
        "ref": "searchField"
      }),
      (@props.children),
      (
        if @props.clearCross
          React.createElement("div", {"className": crossClassName, "onClick": @onClearCrossClick})
      ),
      (
        if @props.chooseType
          React.createElement(SearchTypeSelect, { \
            "title": "Search type",  \
            "defaultValue": (@state.type),  \
            "options": (searchTypes),  \
            "onOptionSelect": (@setSearchType),  \
            "customSelectStyle": (styles.type_select),  \
            "customOptionsStyle": (styles.options_container)
          })
      )
    )

module.exports = SearchInputComponent
