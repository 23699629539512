Dispatcher = require 'root/dispatcher/Dispatcher'
ScheduledMessagesAPI = require 'root/api/admin/ScheduledMessagesAPI'
ActionTypes = require 'constants/ActionTypes'

ScheduledMessagesActionCreator =
  getList: (scope) ->
    Dispatcher.dispatch type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_REQUESTING
    ScheduledMessagesAPI.getList(
      scope
      (days) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_SUCCESS
          payload: days
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_GET_FAILURE
          payload: 'An error occurred while get scheduled messages'
    )

  get: (day) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_REQUESTING
      payload: day
    ScheduledMessagesAPI.getList(
      day
      (reminders) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_SUCCESS
          payload:
            data: reminders
            day: day
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_FAILURE
          payload:
            error: 'An error occurred while get scheduled messages'
            day: day
    )

  update: (id, attributes, previousMessage, successCallback, errorCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_REQUESTING
      payload: previousMessage
    ScheduledMessagesAPI.update(
      id
      attributes
      (data) ->
        if data.error
          return Dispatcher.dispatch
            type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_FAILURE
            payload:
              error: data.error
              time: previousMessage.time
        successCallback?(data)
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_SUCCESS
          payload:
            data: data
            time: previousMessage.time
      (error) ->
        errorCallback?(error)
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_UPDATE_FAILURE
          payload:
            error: 'An error occurred while updating scheduled message'
            time: previousMessage.time
    )

  destroy: (id, day, successCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_REQUESTING
      payload: day
    ScheduledMessagesAPI.destroy(
      id
      () ->
        successCallback?()
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_SUCCESS
          payload:
            id: id
            day: day
      () ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_SCHEDULED_MESSAGES_DESTROY_FAILURE
          payload:
            error: 'An error occurred while deletting scheduled message'
            day: day
    )

module.exports = ScheduledMessagesActionCreator
