classnames = require 'classnames'
deepcopy = require 'deepcopy'
ReactPaginate = require 'react-paginate'

LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
EditAnswerForm = require './EditAnswerForm'
{ tidyHtmlPart } = require 'lib/SearchUtils'

styles = require './reply-answers.styl'
paginationStyles = require 'components/shared/styles/pagination.styl'

class ReplyAnswersComponent extends React.Component
  constructor: (props) ->
    super props
    if props.initSearchQuery
      props.onSearch(props.initSearchQuery)
    @state = {
      showMore: {}
      edit: {}
    }

  componentWillReceiveProps: (nextProps) ->
    if nextProps.categoryId != @props.categoryId
      @setState({ showMore: {}, edit: {} })
      @refs.search.setState(value: "") if nextProps.categoryId

  componentDidUpdate: =>
    { showMore } = @state
    isChanged = false
    @props.answers.forEach (a) =>
      return if showMore[a.id] != undefined
      p = @refs["textP#{a.id}"]
      span = @refs["textSpan#{a.id}"]
      if span && p && p.clientWidth < span.clientWidth
        isChanged = true
        showMore[a.id] = false
    @setState {showMore} if isChanged

  onAnswerClick: (answer) =>
    @props.onReplyTemplatePick(answer.replace(/<\/?em>/g, ''))

  onShowMoreClick: (e) =>
    e.stopPropagation()
    answerId = e.target.dataset.id
    { showMore } = @state
    showMore[answerId] = !showMore[answerId]
    @setState { showMore }

  onEditAnswers: (answer) =>
    return unless @props.editable || @state.edit.id == answer.id
    @setState { edit: deepcopy(answer) }

  onNameChange: (e) =>
    { edit } = @state
    edit.name = e.target.value
    @setState { edit }

  onTextChange: (e) =>
    { edit } = @state
    edit.text = e.target.value
    @setState { edit }

  onEditCancel: => @setState { edit: {} }

  onCreatePersonal: =>
    { name, text } = @state.edit
    @props.onCreatePersonal({ name, text })
    @setState { edit: {} }

  onEditSubmit: =>
    @props.onEditPersonal(@state.edit)
    @setState { edit: {} }

  render: ->
    {
      props, onShowMoreClick, onAnswerClick, onEditAnswers,
      onTextChange, onNameChange, onEditCancel, onEditSubmit,
      onCreatePersonal
    } = @
    { showMore, edit } = @state

    React.createElement("div", {"className": (styles.container)},
      React.createElement("header", {"className": (styles.header)},
        React.createElement(SearchInputComponent, { \
          "ref": "search",  \
          "placeholder": 'Quick search',  \
          "inputClassName": (styles.search),  \
          "onChange": (props.onSearch),  \
          "defaultValue": (props.initSearchQuery),  \
          "onClear": (props.onClear),  \
          "clearCross": true,  \
          "autofocus": true
        })
      ),
      (if props.editable
        React.createElement("div", {"className": (styles.add_new)},
          React.createElement("span", { \
            "className": (styles.add_new_text),  \
            "onClick": (-> onEditAnswers({ id: 'new' }))
          }, """
            Add personal answer
""")
        )
      ),

      React.createElement(LoadableWrapperComponent, {"loading": (props.loading)},
        (if props.editable && edit.id == 'new'
          React.createElement("div", {"className": (styles.add_new_form)},
            React.createElement(EditAnswerForm, { \
              "name": (edit.name),  \
              "text": (edit.text),  \
              "onNameChange": (onNameChange),  \
              "onTextChange": (onTextChange),  \
              "onEditCancel": (onEditCancel),  \
              "onEditSubmit": (onCreatePersonal),  \
              "creation": true
            })
          )
        ),
        React.createElement("ul", { \
          "className": (classnames(styles.list,
            "#{styles.list_editable}": props.editable
            "#{styles.list_with_new}": props.editable && edit.id == 'new'
          ))
        },
          (props.answers.map (a) ->
            if props.editable && edit.id == a.id
              React.createElement("li", {"key": (a.id), "className": (styles.list_item_edit)},
                React.createElement(EditAnswerForm, { \
                  "name": (edit.name),  \
                  "text": (edit.text),  \
                  "onNameChange": (onNameChange),  \
                  "onTextChange": (onTextChange),  \
                  "onEditCancel": (onEditCancel),  \
                  "onEditSubmit": (onEditSubmit)
                })
              )
            else
              withShowMore = showMore[a.id] != undefined
              React.createElement("li", {"key": (a.id), "className": (styles.list_item)},
                React.createElement("article", { \
                  "className": (styles.item),  \
                  "onClick": (-> onAnswerClick(a.text))
                },
                  React.createElement("header", null,
                    React.createElement("span", { \
                      "className": (classnames(styles.favorite, {
                        "#{styles.favorite_active}": a.is_favorite
                      })),  \
                      "onClick": ((e) ->
                        e.stopPropagation()
                        props.onFavoriteClick a.id
                      )
                    }),
                    React.createElement("h2", { \
                      "className": (styles.item_name),  \
                      "dangerouslySetInnerHTML": ( __html: tidyHtmlPart(a.name) )
                    }),
                    (if props.editable
                      React.createElement("div", {"className": (styles.controls)},
                        React.createElement("span", { \
                          "onClick": ((e) ->
                            e.stopPropagation()
                            onEditAnswers(a)
                          )
                        }, """
                          Edit
"""),
                        React.createElement("strong", null, "•"),
                        React.createElement("span", { \
                          "onClick": ((e) ->
                            e.stopPropagation()
                            props.onDeletePersonal(a.id)
                          )
                        }, """
                          Delete
""")
                      )
                    )
                  ),

                  React.createElement("div", {"className": (styles.item_text_container)},
                    React.createElement("p", { \
                      "ref": ("textP#{a.id}"),  \
                      "className": (classnames(styles.item_text, {
                        "#{styles.item_text_large}": withShowMore
                        "#{styles.show_more_text}": showMore[a.id]
                      }))
                    },
                      React.createElement("span", { \
                        "ref": ("textSpan#{a.id}"),  \
                        "dangerouslySetInnerHTML": ( __html: tidyHtmlPart(a.text) )
                      })
                    ),

                    (if withShowMore
                      React.createElement("span", { \
                        "onClick": (onShowMoreClick),  \
                        "className": (styles.show_more),  \
                        "data-id": (a.id)
                      },
                        (
                          if showMore[a.id]
                            'Hide'
                          else
                            'Show more'
                        )
                      )
                    )
                  )
                )
              )
          ),
          (if @props.pageCount > 1
            React.createElement("li", {"className": (styles.pagination)},
              React.createElement(ReactPaginate.default, { \
                "key": (@props.categoryId),  \
                "previousLabel": '<',  \
                "nextLabel": '>',  \
                "pageCount": (@props.pageCount),  \
                "marginPagesDisplayed": (1),  \
                "pageRangeDisplayed": (3),  \
                "onPageChange": (@props.onPageChange),  \
                "initialPage": (@props.page),  \
                "pageClassName": (paginationStyles.page),  \
                "nextClassName": (paginationStyles.page),  \
                "previousClassName": (paginationStyles.page),  \
                "activeClassName": (paginationStyles.active),  \
                "pageLinkClassName": (paginationStyles.link),  \
                "nextLinkClassName": (paginationStyles.link),  \
                "previousLinkClassName": (paginationStyles.link),  \
                "containerClassName": (paginationStyles.container),  \
                "breakClassName": (paginationStyles.page)
              })
            )
          )
        )
      )
    )

module.exports = ReplyAnswersComponent
