PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
PercentOfGoodRTAndWTActionCreator = require './PercentOfGoodRTAndWTActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.GOOD_RT_WT_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.GOOD_RT_WT_SUCCESS}": (data) ->
    @loading = false
    @rt = data.rt
    @wt = data.wt
    @benchmark = data.benchmark
    @analyticDaysInterval = data.interval
    @emitChange()
  "#{ActionTypes.GOOD_RT_WT_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()

class PercentOfGoodRTAndWTStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @rt = null
    @wt = null
    @benchmark = null
    @analyticDaysInterval = null
    @error = null
    @registerActions()

  getActionCreator: -> new PercentOfGoodRTAndWTActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = PercentOfGoodRTAndWTStore
