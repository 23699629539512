module.exports =
  # http://stackoverflow.com/questions/6707476/how-to-find-if-a-text-contains-url-string
  URL_REGEXP: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&\'\*@#\(\)\/%?=~_|!:,.;]*[-A-Z0-9+&@#\(\)\/%=~_|])/ig
  TWILIO_MEDIA_URL_REGEXP: /(\bhttps:\/\/api\.twilio\.com\/2010-04-01\/Accounts[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
  ANY_PICTURE_URL_REGEXP: /https?:\/\/(?!.*?\/l\.php).*\.(png|jpg|jpeg|gif)\??.*/i # note: there's a negation of facebook l.php page (for redirect links)
  ANY_FILE_URL_REGEXP: /https?:\/\/(?!.*?\/l\.php).*\.(png|jpg|jpeg|gif|bmp|doc|docx|pdf|xls|xlsx|ppt|pptx)\??.*/i # note: there's a negation of facebook l.php page (for redirect links)
  STICKER_REGEXP: /^(https?:\/\/scontent\.xx\.fbcdn\.net\/(?!v\/)|https?:\/\/gonerdify\.com\/static\/stikers\/)/i

  CLASS_CONVERSATION_ASSIGNER_BUTTON: 'conversation_assigner_button'
  STATISTIC_DAYS: 5
  MILISECONDS_IN_HOUR: 3600000
  MILISECONDS_IN_DAY: 86400000
  SECONDS_IN_MINUTE: 60
  MAX_UPLOADED_PICTURE_SIZE: 5
  BANDWIDTH_URL: 'api.catapult.inetwork.com'
  DEFAULT_FLAG_COLOR: 'transparent'
  NEXT_FLAG_COLOR: {
    'transparent': 'red',
    'red': 'green',
    'green': 'yellow',
    'yellow': 'gray',
    'gray': 'transparent'
  }
