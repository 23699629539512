LOAD_STATE = require '../../../../constants/LoadStates'
BaseComponent = require '../../../BaseComponent'
CustomerCardCredentialsFormComponent = require './CustomerCardCredentialsFormComponent'
CustomerCardCredentialsFormByCustomerStore = require './CustomerCardCredentialsFormByCustomerStore'

propTypes = require 'prop-types'

class CustomerCardCredentialsFormByCustomerComponent extends BaseComponent
  @propTypes:
    customer: propTypes.shape(
      credentials: propTypes.arrayOf(propTypes.object).isRequired
    ).isRequired

  constructor: (props) ->
    super(props)
    @state =
      credentials: props.customer?.credentials || {}
    @initComponent()
    _.defer @actionCreator.setList, props.customer?.credentials || {}

  getPersonalStoreClass: -> CustomerCardCredentialsFormByCustomerStore

  getState: ->
    credentials: _.values(@personalStore.credentialsIndex)
    dataReady: @personalStore.loading.credentials == LOAD_STATE.LOADED &&
      @personalStore.scope.customerId == @props.customer.id

  onDecryptCredential: (credential) =>
    @actionCreator.get(credential.id)

  onCreate: (credential) =>
    credential = _.extend({customer_id: @props.customer.id}, credential)
    @actionCreator.create(credential)

  onUpdate: (credential) =>
    setTimeout(
      =>
        @actionCreator.update(credential.id, credential)
      10
    )

  onDestroy: (credential) =>
    if confirm('Are you sure want to delete credential?')
      @actionCreator.batchDestroy([credential.id])

  render: ->
    credentials = R.sortBy(R.prop('credential_type'), @state.credentials)
    React.createElement(CustomerCardCredentialsFormComponent, { \
      "credentials": (@state.credentials),  \
      "onCreate": @onCreate,  \
      "onUpdate": @onUpdate,  \
      "onDestroy": @onDestroy,  \
      "onDecryptCredential": @onDecryptCredential

    })

module.exports = CustomerCardCredentialsFormByCustomerComponent
