classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
confirm = require 'components/shared/ConfirmDialog'
CustomerioItem = require 'components/admin/CustomerioItem'
styles = require 'components/admin/assets/accordion.styl'
customerioStyles = require './customer-accordion-by-text.styl'

propTypes = require 'prop-types'

class CustomerioAccordionByText extends BaseComponent
  constructor: (props) ->
    super(props)
    @text = props.text
    @data = props.data
    @state =
      expand: @data.events?.length > 0
      events: @data.events || []
      total: @data.total
    @initComponent()

  onSwitchPanel: =>
    if !@state.expand && @state.events.length == 0
      options = text: @text
      @props.itemsActionCreator.get(options)

    @setState
      expand: !@state.expand

  componentWillReceiveProps: (nextProps) =>
    items = nextProps.data.events
    if items
      @setState
        events: nextProps.data.events
        total: nextProps.data.total
    else
      @setState
        total: nextProps.data.total

  onPause: (id) =>
    confirm(
      'This action will stop this activity.'
      {
        confirmTitle: 'Cancel activity'
      }
    ).then(
      => @props.itemsActionCreator.pause event_id: id, text: @text
      ->
    )
  
  onPauseCampaign: =>
    confirm(
      'This action will stop this campaign activities.'
      {
        confirmTitle: 'Cancel campaign'
      }
    ).then(
      => @props.itemsActionCreator.pause text: @text
      ->
    )

  onDelete: (id) =>
    confirm(
      'This action will delete scheduled activity.'
      {
        confirmTitle: 'Delete activity'
      }
    ).then(
      => @props.itemsActionCreator.destroy event_id: id, text: @text
      ->
    )

  onDeleteCampaign: =>
    confirm(
      'This action will delete this campaign activities.'
      {
        confirmTitle: 'Delete campaign'
      }
    ).then(
      => @props.itemsActionCreator.destroy text: @text
      ->
    )

  render: =>
    (
      React.createElement("div", null,
        React.createElement("div", { \
          "className": (styles.title),  \
          "onClick": (@onSwitchPanel)
        },
          React.createElement("b", { \
            "className": (classnames(
              styles.title_text,
              customerioStyles.title_text
            )),  \
            "title": (@text)
          },
            (@text)
          ),
          React.createElement("span", {"className": (styles.total)}, (" (#{@state.total})")),
          React.createElement("i", {"className": (classnames(
            styles.switch_icon,
            "#{styles.switch_icon_open}": @state.expand
          ))})
        ),
        React.createElement("div", {"className": (customerioStyles.common_actions)},
          React.createElement("button", { \
            "className": (classnames(
              customerioStyles.action_button,
              customerioStyles.action_button_pause
            )),  \
            "title": 'Pause campaign',  \
            "onClick": (@onPauseCampaign)
          }, """
            Pause campaign
"""),
          React.createElement("button", { \
            "className": (classnames(
              customerioStyles.action_button,
              customerioStyles.action_button_delete
            )),  \
            "title": 'Delete campaign',  \
            "onClick": (@onDeleteCampaign)
          }, """
            Delete campaign
""")
        ),
        React.createElement("div", {"className": (classnames(
          styles.scheduled,
          "#{styles.scheduled_open}": @state.expand
        ))},
          React.createElement(LoadableWrapperComponent, {"loading": (@props.itemsStore.loadingByText[@text])},
            (@state.events.map (item) =>
              React.createElement(CustomerioItem, { \
                "key": (item.id),  \
                "item": (item),  \
                "onPause": (@onPause),  \
                "onDelete": (@onDelete)
              })
            )
            )
        )
      )
    )

CustomerioAccordionByText.propTypes =
  itemsStore: propTypes.object.isRequired
  itemsActionCreator: propTypes.object.isRequired


module.exports = CustomerioAccordionByText
