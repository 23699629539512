RankingStatusComponent = require './RankingStatusComponent'
InfoBlockComponent = require 'components/shared/InfoBlockComponent'

propTypes = require 'prop-types'

class RankingModule extends React.Component
  componentDidMount: -> @props.setTabsState 'qa-info': !!@props.anyOpen

  render: ->
    React.createElement("div", null,
      React.createElement(InfoBlockComponent, {  \
        "dataKey": 'qa-info',  \
        "title": 'qa-info',  \
        "openByDefault": (!!@props.anyOpen),  \
        "onToggleBlock": (@props.onToggleBlock)
      },
        React.createElement(RankingStatusComponent, { \
          "userRole": (@props.conversation?.approve_data?.user_role)
        })
      )
    )

RankingModule.propTypes = 
  setTabsState: propTypes.func.isRequired
  onToggleBlock: propTypes.func.isRequired
  anyOpen: propTypes.bool

module.exports = RankingModule
