{urls} = require '../config'

ProfileStatisticAPI =
  get: (data, success, error) ->
    $.ajax
      url: urls.profile[data.type] data.id
      type: 'GET'
      data: data.params
      success: success
      error: error

  update: (shift, success, error) ->
    $.ajax
      url: urls.profile.updateShift shift.userId, shift.id
      type: 'PUT'
      data: shift
      success: success
      error: error
      timeout: 5000

module.exports = ProfileStatisticAPI
