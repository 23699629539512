classnames = require 'classnames'
ReactPaginate = require 'react-paginate'

CategoriesComponent = require './CategoriesComponent'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
WarningPopupComponent = require '../WarningPopupComponent'

styles = require './categories-section'
sectionStyles = require '../section-style.styl'
paginationStyles = require 'components/shared/styles/pagination.styl'

class CategoriesSectionComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      addNew: false
      deleteCategory: null
    }
    @inputHandlerAssign = false

  addNewClick: =>
    @inputHandlerAssign = true
    @setState addNew: true

  inputBlur: =>
    if @state.addNew
      value = @refs.input.value.trim()
      if value
        @props.addNewGroup(value)
      @setState addNew: false

  componentDidUpdate: (prevProps, prevState) ->
    if @refs.input && @inputHandlerAssign
      @refs.input.focus()
      @refs.input.addEventListener('blur', @inputBlur)
      @inputHandlerAssign = false

  onCategoryClick: (e) =>
    categoryId = parseInt e.currentTarget.dataset.value, 10
    @props.onCategoryClick(categoryId)

  onCategoryDelete: (e) =>
    e.stopPropagation()
    categoryId = parseInt e.currentTarget.dataset.id, 10
    @setState deleteCategoryId: categoryId

  onCancelDeleting: (e) =>
    e.stopPropagation()
    @setState deleteCategoryId: null

  onApproveDeleting: (e) =>
    e.stopPropagation()
    { deleteCategoryId } = @state
    @props.onCategoryDelete(deleteCategoryId)
    @setState deleteCategoryId: null

  onPersonalClick: => @props.onCategoryClick 'personal'
  onDeletedClick: => @props.onCategoryClick 'deleted'

  render: ->
    {
      onCategoryClick, onCategoryDelete, onCancelDeleting, onApproveDeleting
    } = @
    { selected, addTitle, title } = @props
    { deleteCategoryId } = @state
    React.createElement("section", {"className": (classnames(styles.categories, sectionStyles.section))},
      React.createElement("header", {"className": (sectionStyles.header)},
        React.createElement("h2", {"className": (sectionStyles.title)}, (title || 'Categories'))
      ),

      React.createElement("div", {"className": (sectionStyles.content)},
        React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
          React.createElement("button", {"onClick": (@addNewClick), "className": (sectionStyles.add_new)},
            React.createElement("i", {"className": (sectionStyles.add_new_icon)}, React.createElement("span", null, "+")),
            (addTitle || 'Add new category')
          ),
          (if @state.addNew
            React.createElement("input", {"ref": "input", "type": 'text', "className": (styles.input_field), "maxLength": "100"})
          ),
          React.createElement("ul", {"className": (sectionStyles.section_list)},
            (if @props.isPeronal
              React.createElement("li", {"key": ('personal')},
                React.createElement("div", { \
                  "className": (classnames(sectionStyles.item, styles.category,
                    "#{sectionStyles.item_selected}": selected == 'personal'
                  )),  \
                  "onClick": (@onPersonalClick)
                },
                  React.createElement("span", {"className": (classnames(styles.personal,
                      "#{styles.personal_selected}": selected == 'personal'
                    ))
                  }, """
                    Personal
""")
                )
              )
            ),
            (@props.categories.map (category, i) =>
              React.createElement(CategoriesComponent, { \
                "key": (category.id),  \
                "index": (i),  \
                "category": (category),  \
                "categories": (@props.categories),  \
                "templateType": (@props.templateType),  \
                "onCategoryClick": (onCategoryClick),  \
                "selected": (selected),  \
                "onCategoryDelete": (onCategoryDelete),  \
                "deleteCategoryId": (deleteCategoryId),  \
                "onCancelDeleting": (onCancelDeleting),  \
                "onApproveDeleting": (onApproveDeleting),  \
                "swapItems": (@props.swapCategories),  \
                "updatePosition": (@props.updatePosition),  \
                "isSub": (@props.isSub)
              })
            ),

            (if @props.pageCount > 1
              React.createElement("li", {"className": (styles.pagination)},
                React.createElement(ReactPaginate.default, { \
                  "previousLabel": '<',  \
                  "nextLabel": '>',  \
                  "pageCount": (@props.pageCount),  \
                  "marginPagesDisplayed": (1),  \
                  "pageRangeDisplayed": (1),  \
                  "onPageChange": (@props.onPageChange),  \
                  "initialPage": (@props.page),  \
                  "pageClassName": (paginationStyles.page),  \
                  "nextClassName": (paginationStyles.page),  \
                  "previousClassName": (paginationStyles.page),  \
                  "activeClassName": (paginationStyles.active),  \
                  "pageLinkClassName": (paginationStyles.link),  \
                  "nextLinkClassName": (paginationStyles.link),  \
                  "previousLinkClassName": (paginationStyles.link),  \
                  "containerClassName": (paginationStyles.container),  \
                  "breakClassName": (paginationStyles.page),  \
                  "forcePage": (@props.forcePage)
                })
              )
            )
          )
        )
      )
    )

module.exports = CategoriesSectionComponent
