Auth = require 'j-toker'

BaseComponent = require 'components/BaseComponent'
Calendar = require 'components/shared/CalendarComponent'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
styles = require './operator-filter'
{ urls, protocol, domain } = require 'root/config'
{ ROLES, ACTIVITIES } = require 'constants/Agents'
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
QADashboardAPI = require 'api/QADashboardAPI'

rolesWithoutDuplications =
  role:
    label: 'Role'
    value: 'role'

Object.keys ROLES
  .forEach (key) ->
    unless (rolesWithoutDuplications[ROLES[key].value] || {}).active
      rolesWithoutDuplications[ROLES[key].value] = ROLES[key]

activities =
  activity:
    label: 'Activity'
    value: 'activity'

activities = Object.assign activities, ACTIVITIES

class OperatorFilterComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  onSelectChange: (options, selectName, value) ->
    unless value == @props.filters[selectName]
      if options[value]
        @props.filters[selectName] = options[value].value
      else
        delete @props.filters[selectName]

      @props.onChangeHandler(
        @refs.textbox.refs.searchField.value
        @props.filters, @props.beginDate, @props.endDate
        @props.rangeType
      )

  searchOperators: (query) =>
    @props.onChangeHandler(
      query.toLowerCase(), @props.filters, @props.beginDate
      @props.endDate, @props.rangeType
    )

  onClearSearch: =>
    @props.onChangeHandler(
      '', @props.filters, @props.beginDate, @props.endDate, @props.rangeType
    )

  newDateRangeHandler: (newRange) =>
    newDates =
      beginDate: newRange.startDate.format 'YYYY-MM-DD'
      endDate: newRange.endDate.format 'YYYY-MM-DD'
    @props.onChangeHandler(
      @refs.textbox.refs.searchField.value
      @props.filters, newDates.beginDate, newDates.endDate
      @props.rangeType
    )

  onChangeRangeType: (type) =>
    @props.onRangeTypeChangeHandler type

  onDownloadClick: =>
    QADashboardAPI.getCSVList(@onSuccess)

  onSuccess: (res) ->
    searchQuery = window.location.search
    link = $('<a>')
    r = JSON.parse(res)
    url = "#{protocol}://#{domain}#{r.url}#{searchQuery}"
    link.prop('href', url)
    link.prop('target', '_blank')
    link[0].click()
    link.remove()

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.textbox)},
        React.createElement(SearchInputComponent, { \
          "placeholder": 'Find Operator',  \
          "inputClassName": (styles.textbox_input),  \
          "clearCross": true,  \
          "onClear": (@onClearSearch),  \
          "onChange": (@searchOperators),  \
          "ref": "textbox",  \
          "defaultValue": (@props.query),  \
          "autofocus": true
        })
      ),
      React.createElement("div", {"className": (styles.calendar)},
        React.createElement(Calendar, { \
          "beginDate": (@props.beginDate),  \
          "endDate": (@props.endDate),  \
          "onSelectHandler": (@newDateRangeHandler),  \
          "onChangeTypeHandler": (@onChangeRangeType),  \
          "weeksTwo": (@props.weeksTwo),  \
          "type": (@props.rangeType),  \
          "isShiftsMode": true
        })
      ),
      React.createElement("div", {"className": (styles.select)},
        React.createElement(CustomSelectComponent, { \
          "defaultValue": (if @props.filters.role
              rolesWithoutDuplications[@props.filters.role].value
            else
              rolesWithoutDuplications.role.value
          ),  \
          "options": (rolesWithoutDuplications),  \
          "onOptionSelect": (@onSelectChange.bind @, ROLES, 'role'),  \
          "customOptionsStyle": (styles.select__options)
        })
      ),
      React.createElement("div", {"className": (styles.select)},
        React.createElement(CustomSelectComponent, { \
          "defaultValue": (if @props.filters.activity
              activities[@props.filters.activity].value
            else
              activities.activity.value
          ),  \
          "options": (activities),  \
          "onOptionSelect": (@onSelectChange.bind @, ACTIVITIES, 'activity'),  \
          "customOptionsStyle": (styles.select__options)
        })
      ),
      React.createElement("a", { \
        "className": (styles.export),  \
        "onClick": (@onDownloadClick)
      }, """
        Export
""")
    )

module.exports = OperatorFilterComponent
