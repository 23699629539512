classnames = require 'classnames'

styles = require './conversations-component'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
ConversationsComponent = require '../abstract/ConversationsComponent'
ConversationsListComponent = require '../ConversationsListComponent/ConversationsListComponent'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
AgentsSelectorComponent = require '../../shared/AgentsSelectorComponent/AgentsSelectorComponent'
ConversationsAllStore = require 'stores/ConversationsAllStore'
FlagsStore = require 'stores/FlagsStore'
AuthStore = require 'stores/AuthStore'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AgentAssignActionCreator = require 'actions/AgentAssignActionCreator'
{ ASSIGN_BUTTON_ACCESS } = require 'constants/Agents'

propTypes = require 'prop-types'
ConversationsActionCreator = new ConversationsActionCreator()

class ConversationsAllComponent extends ConversationsComponent
  constructor: (props) ->
    super(props)
    @folder = 'all_conversations'
    @initComponent()

  dependsOnStores: [ConversationsAllStore, FlagsStore]

  onAgentChange: (agentId) =>
    ConversationsActionCreator.filterByAgent(agentId)

  onSearch: (query, searchType) =>
    if query
      @search.query = query
      @search.searchType = searchType
      @search.pageSize = @props.pageSize
      ConversationsActionCreator.getAllList(
        queryString: @search.query,
        searchType: @search.searchType,
        size: @search.pageSize
      )
    else
      ConversationsActionCreator.searchAllReset()

  showMore: =>
    @search.pageSize += @props.pageSize
    ConversationsActionCreator.getAllList(
      queryString: @search.query,
      searchType: @search.searchType,
      size: @search.pageSize
    )

  componentWillUpdate: (props, state) ->
    searchEnabled = ConversationsAllStore.state.searchEnabled
    if !searchEnabled
      conversations = ConversationsAllStore.conversations
      @handleUpdatedConversations(conversations)

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeAllConversations()
        if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
          AgentAssignActionCreator.subscribe()
      0
    )

  componentWillUnmount: ->
    super()
    ConversationsActionCreator.setDefaultAllState()
    ConversationsActionCreator.unSubscribeAllConversations()
    if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
      AgentAssignActionCreator.unSubscribe()
    ConversationsActionCreator.clearFilterByAgent()

  render: ->
    conversations = ConversationsAllStore.conversations
    loading = ConversationsAllStore.state.loading
    searchEnabled = ConversationsAllStore.state.searchEnabled
    agentId = ConversationsAllStore.state.agentId

    listContainerClassName = classnames(
      styles.list_container,
      "#{styles.with_top_row}": !searchEnabled
    )
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.toolbarContainer)},
        React.createElement(SearchInputComponent, { \
          "key": (@folder),  \
          "onChange": @onSearch,  \
          "onClear": ( R.partial(@onSearch, [null]) ),  \
          "placeholder": "Search by keywords, #tags, @agents, *credentials",  \
          "clearCross": (true),  \
          "folder": (@folder),  \
          "autofocus": true
        }),
        (
          if searchEnabled && !loading
            React.createElement("div", {"className": styles.search_result_amount}, """
              Results: """, (conversations.length)
            )
        )
      ),
      (
        if !searchEnabled
          React.createElement("div", {"className": (styles.agents_selector)},
            React.createElement(AgentsSelectorComponent, { \
              "agentId": (agentId),  \
              "onAgentChange": (@onAgentChange)
            })
          )
      ),
      React.createElement(LoadableWrapperComponent, { \
        "containerClassName": (listContainerClassName),  \
        "loading": (loading)
      },
        React.createElement(ConversationsListComponent, { \
          "flags": (FlagsStore.flags || {}),  \
          "folder": (@folder),  \
          "conversations": (conversations),  \
          "order": (if searchEnabled then 'updated_at' else 'default'),  \
          "limit": (@searchPageSize()),  \
          "searchEnabled": (searchEnabled),  \
          "showMoreLoading": (loading && searchEnabled),  \
          "loadMore": (@showMore),  \
          "loading": (loading)
        })
      )
    )

module.exports = ConversationsAllComponent
