Button = require '../Button'
styles = require './modal-preview-component.styl'

module.exports = (props) =>
  React.createElement("div", {"className": (styles.container)},
    React.createElement("img", {"src": (props.data.url)}),
    React.createElement("i", {  \
      "onClick": (props.onClick),  \
      "className": (styles.button)
    })
  )