FastClick = require 'fastclick'
classnames = require 'classnames'

styles = require './button'
BaseComponent = require '../../BaseComponent'

class IconButtonWithTooltipComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @isHovering = false
    @initComponent()

  handleMouseOver: => @setState isHovering: true

  handleMouseOut: => @setState isHovering: false

  componentDidMount: ->
    super()
    FastClick.attach ReactDOM.findDOMNode @refs.icon

  onClick: => @props.callback?()

  render: ->
    React.createElement("div", { \
      "ref": "icon",  \
      "onClick": (@onClick),  \
      "className": (@props.className),  \
      "onMouseOut": (@handleMouseOut),  \
      "onMouseOver": (@handleMouseOver)
    },
      (React.createElement("div", {"className": (styles.tooltip)}, (@props.tooltip)) if @state.isHovering),
      (this.props.children)
    )

module.exports = IconButtonWithTooltipComponent
