classnames = require 'classnames'
{ NavLink } = require 'react-router-dom'
browserHistory = require './../../../history'
Auth = require 'j-toker'

BaseComponent = require 'components/BaseComponent'
styles = require './agent-statistic'
ShiftsStatisticComponent = require '../AgentStatisticTypes/ShiftsStatisticComponent'
WeeklyStatisticComponent = require '../AgentStatisticTypes/WeeklyStatisticComponent'
MonthlyStatisticComponent = require '../AgentStatisticTypes/MonthlyStatisticComponent'
TLShiftsStatisticComponent = require '../AgentStatisticTypes/TLShiftsStatisticComponent'
TLWeeklyStatisticComponent = require '../AgentStatisticTypes/TLWeeklyStatisticComponent'
TLMonthlyStatisticComponent = require '../AgentStatisticTypes/TLMonthlyStatisticComponent'
{ urls, protocol, domain } = require 'root/config'
{ SHIFT_ROLES } = require 'constants/Agents'
ProfileStatisticAPI = require 'api/ProfileStatisticAPI'

class AgentStatisticComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    if props.agent.role == SHIFT_ROLES.teamlead.value
      @statisticComponents =
        shifts:
          component: TLShiftsStatisticComponent
          label: 'Shifts'
        weekly:
          component: TLWeeklyStatisticComponent
          label: 'Weekly'
        monthly:
          component: TLMonthlyStatisticComponent
          label: 'Monthly'
    else
      @statisticComponents =
        shifts:
          component: ShiftsStatisticComponent
          label: 'Shifts'
        weekly:
          component: WeeklyStatisticComponent
          label: 'Weekly'
        monthly:
          component: MonthlyStatisticComponent
          label: 'Monthly'
    @initComponent()

  componentWillMount: =>
    browserHistory.default.push("/agents/#{@props.agent.id}/shifts") unless @statisticComponents[@props.tab]

  onDownloadClick: =>
    data =
      type: @props.tab + 'CSV'
      id: @props.agent.id
    ProfileStatisticAPI.get(data, @onSuccess)

  onSuccess: (res) ->
    link = $('<a>')
    r = JSON.parse(res)
    url = "#{protocol}://#{domain}#{r.url}"
    link.prop('href', url)
    link.prop('target', '_blank')
    link[0].click()
    link.remove()

  render: ->
    statisticComponents = @statisticComponents
    StatisticComponent = statisticComponents[@props.tab || 'shifts'].component
    agentId = @props.agent.id
    tabs = Object.keys statisticComponents
      .map (key, i) ->
        React.createElement(NavLink, {"key": (i), "to": ("/agents/#{agentId}/#{key}"), "className": (styles.tab), "activeClassName": (styles.tab_active)},
          (statisticComponents[key].label)
        )

    React.createElement("div", {"className": (styles.tabs)},
      React.createElement("div", {"className": (styles.tabs__container)},
        (tabs),
        React.createElement("a", { \
          "onClick": (@onDownloadClick),  \
          "className": (styles.export)
        },
          ("Export #{@props.tab} tab")
        )
      ),
      React.createElement("div", {"className": (styles.tabs__devider)}),
      React.createElement("div", {"className": (styles.tab__content)},
        React.createElement(StatisticComponent, {"type": (@props.tab || 'shifts'), "agentId": (agentId), "agent": (@props.agent)})
      )
    )

module.exports = AgentStatisticComponent
