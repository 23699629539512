classnames = require 'classnames'
styles = require './switch'

propTypes = require 'prop-types'

class SwitchComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = switch: !!props.value

  componentWillReceiveProps: (nextProps) ->
    if @props.value != nextProps.value
      value = nextProps.value
      value = !value if nextProps.reverse
      @setState switch: value

  toggleSwitch: () =>
    value = !@state.switch
    value = !value if @props.reverse
    @props.onToggle value
    # @setState switch: !@state.switch

  render: ->
    React.createElement("div", {"className": (styles.activity)},
      React.createElement("div", { \
        "className": (classnames(@props.className, styles.activity__overlay,
          "#{styles.activity__overlay_active}": @state.switch
          "#{styles.cursor_auto}": @props.disabled
          "#{styles.activity__auto}": @props.auto
        )),  \
        "onClick": (@toggleSwitch if !@props.disabled)
      },
        React.createElement("div", {"className": (styles.activity__switcher), "title": (if @props.auto then 'The client opted-out using Twilio/Bandwith functionality. You can\'t override it.' else '')},
          (if @props.auto then '!' else '')
        )
      )
    )

SwitchComponent.propTypes = 
  value: propTypes.bool.isRequired
  onToggle: propTypes.func.isRequired
  reverse: propTypes.bool
  disabled: propTypes.bool

module.exports = SwitchComponent
