classnames = require 'classnames'
styles = require './checkbox'

propTypes = require 'prop-types'

Checkbox = (props) ->
  React.createElement("i", { \
    "onClick": (props.onClick if props.onClick),  \
    "className": (classnames(styles.checkbox,
      "#{styles.checkbox_checked}": props.checked,
      "#{styles.checkbox_green_checked}": props.green_checked,
      "#{styles.checkbox_disabled}": props.disabled,
    ))
  })

Checkbox.propTypes =
  checked: propTypes.bool
  onClick: propTypes.func

module.exports = Checkbox
