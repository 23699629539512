ConversationItemsAPI = require 'api/ConversationItemsAPI'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'

module.exports =
  getList: (conversationId, params) ->
    Dispatcher.dispatch
      type: ActionTypes.CONVERSATION_ITEMS_GET_LIST_REQUESTING
      payload: conversationId

    ConversationItemsAPI.show conversationId, params,
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.CONVERSATION_ITEMS_GET_LIST_SUCCESS
            payload: {
              conversationId
              items: json.data.items
            }
        else
          Dispatcher.dispatch
            type: ActionTypes.CONVERSATION_ITEMS_GET_LIST_FAILURE
            payload: {
              conversationId
              error: json.error
            }
      (error) =>
        Dispatcher.dispatch
          type: ActionTypes.CONVERSATION_ITEMS_GET_LIST_FAILURE
          payload: {
            conversationId
            error: 'An error occurred while getting data'
          }

  clearStore: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATION_ITEMS_CLEARE_STORE
