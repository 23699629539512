ConversationItemsListSyncComponent = require './ConversationItemsListTypes/ConversationItemsListSyncComponent'
ConversationItemsListNonSyncComponent = require './ConversationItemsListTypes/ConversationItemsListNonSyncComponent'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'

component = (props) ->
  if props.conversation.status == CONVERSATION_STATUS.CLOSED
    React.createElement(ConversationItemsListNonSyncComponent, Object.assign({},  props ))
  else
    React.createElement(ConversationItemsListSyncComponent, Object.assign({},  props ))

propTypes = require 'prop-types'

component.propTypes =
  onNewItem: propTypes.func
  itemsHighLightingIndex: propTypes.object # {<id>: <item>}
  selectedItem: propTypes.shape(
    id: propTypes.number.isRequired
  )
  onItemSelect: propTypes.func
  onReadyChange: propTypes.func
  conversation: propTypes.object.isRequired

module.exports = component
