browserHistory = require './../../../history'

TagsStore = require '../../../stores/TagsStore'
BaseComponent = require '../../BaseComponent'
HashTags = require '../../HashTags'
Button = require '../../shared/Button'
TagsActionCreator = require '../../../actions/TagsActionCreator'
styles = require './styles.styl'
tagsConstants = require '../../../constants/Tags'
{capitalizeFirstLetter} = require '../../../lib/utils'
{menus} = require '../../../config'
AuthStore = require '../../../stores/AuthStore'
Checkbox = require '../../shared/Checkbox'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'tags'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

GROUP_CREATION = 'Group'
TAG_CREATION = 'Tag'

class TagsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      perPage: 30
      conversation:
        creation: null
        group: {}
        value: null
        page: 0
        error: null
      customer:
        creation: null
        group: {}
        value: null
        page: 0
        error: null
    @initComponent()

  dependsOnStores: [TagsStore]

  componentWillMount: ->
    if accessConfig.toPage.indexOf(AuthStore.user.role) == -1
      browserHistory.default.push('/')

  componentDidMount: ->
    super()
    {state} = @
    setTimeout(
      ->
        TagsActionCreator.getGeneralList
          type: tagsConstants.CONVERSATION_TAGS_TYPE
          page: state.conversation.page
          per_page: state.perPage
          all: true
        TagsActionCreator.getGeneralList
          type: tagsConstants.CUSTOMER_TAGS_TYPE
          page: state.customer.page
          per_page: state.perPage
          all: true
      10
    )

  downloadListOnSuccess: (type) =>
    if @state[type].group.id
      TagsActionCreator.getTagsByGroup(
        @state[type].group
        null
        @state[type].page
        @state.perPage
        true
      )
    else
      TagsActionCreator.getGeneralList
        type: type
        page: @state[type].page || 0
        per_page: @state.perPage
        all: true

  onTagSelected: (tag) =>
    console.log tag

  onGroupSelected: (type, group) =>
    if group.id
      TagsActionCreator.getTagsByGroup(
        group
        null
        0
        @state.perPage
        true
      )
    newState = @state
    newState[type].page = 0
    newState[type].group = group
    newState[type].creation = null
    @setState newState

  onGroupSave: (group, type) =>
    TagsActionCreator.updateGroup(Object.assign group, {type})

  onTagSave: (tag) =>
    TagsActionCreator.updateTag(tag.id, tag, @downloadListOnSuccess)

  onGroupDelete: (group, type) =>
    confirmed = confirm("Are you sure you want delete '#{group.name}' group?")
    TagsActionCreator.deleteGroup(
      {id: group.id, type}
      =>
        newState = @state
        newState[type].group = {}
        @setState newState
        @downloadListOnSuccess(type)
    ) if confirmed

  onTagDelete: (tag) =>
    confirmed = confirm("Are you sure you want delete '#{tag.name}' tag?")
    TagsActionCreator.destroy(tag, @downloadListOnSuccess) if confirmed

  onInputChange: (e) =>
    value = e.target.value
    type = e.target.getAttribute('id')
    newState = @state
    newState[type].value = value
    @setState newState

  onGroupCreate: (type) =>
    @setState "#{type}": {creation: GROUP_CREATION, group: {}, page: @state[type].page }

  onTagCreate: (type, group) =>
    newState = @state
    newState[type] = Object.assign(
      @state[type]
      {
        creation: TAG_CREATION
        group
      }
    )
    @setState newState

  onCancelCreation: (type) =>
    newState = @state
    newState[type] =
      creation: null
      group: {}
      errors: null
      value: null
    @setState newState

  onSaveNew: (type) =>
    tags_source = tagsConstants.SYSTEM_TAGS_SOURCE
    if type == tagsConstants.CONVERSATION_TAGS_TYPE && @state[type].isOrdersGroup
      tags_source = tagsConstants.ORDERS_TAGS_SOURCE

    TagsActionCreator["create#{capitalizeFirstLetter(@state[type].creation)}"](
      {
        type
        name: @state[type].value
        group_id: @state[type].group.id
        tags_source
      }
      @downloadListOnSuccess
    )

    newState = @state
    newState[type] =
      creation: null
      group: @state[type].group
      value: null
      page: @state[type].page
    @setState newState

  onConversationPageChange: (selected, group) =>
    if selected != @state.conversation.page
      if group.id
        TagsActionCreator.getTagsByGroup(
          group
          null
          selected
          @state.perPage
          true
        )
      else
        TagsActionCreator.getGeneralList({
          type: tagsConstants.CONVERSATION_TAGS_TYPE
          page: selected
          per_page: @state.perPage
          all: true
        })
      newState = @state
      newState.conversation.page = selected

  onCustomerPageChange: (selected, group) =>
    if selected != @state.customer.page
      if group.id
        TagsActionCreator.getTagsByGroup(
          group
          null
          selected
          @state.perPage
          true
        )
      else
        TagsActionCreator.getGeneralList({
          type: tagsConstants.CUSTOMER_TAGS_TYPE
          page: selected
          per_page: @state.perPage
          all: true
        })
      newState = @state
      newState.customer.page = selected
      @setState newState

  onChangeTagsSource: (type) =>
    newState = @state
    newState[type].isOrdersGroup = !newState[type].isOrdersGroup
    @setState newState

  generateInputForCreation: (type, isOrdersGroups) ->
    {onCancelCreation, onSaveNew, onChangeTagsSource} = @
    React.createElement("div", {"className": (styles.edit_container)},
      React.createElement("label", {"className": (styles.label)},
        ("#{@state[type].creation} name")
      ),
      React.createElement("input", { \
        "id": (type),  \
        "onChange": (@onInputChange),  \
        "type": 'text',  \
        "className": (styles.input)
      }),
      React.createElement("div", {"className": (styles.error)}, (@state[type].error)),
      (if isOrdersGroups && @state[type].creation == GROUP_CREATION
        React.createElement("div", {"className": (styles.tags_source)},
          React.createElement("div", { \
            "className": (styles.tags_source_container),  \
            "onClick": (-> onChangeTagsSource(type))
          },
            React.createElement(Checkbox, { \
              "checked": (@state[type].isOrdersGroup)
            }),
            React.createElement("span", {"className": (styles.tags_source_text)}, """
              is orders group
""")
          )
        )
      ),
      React.createElement("div", {"className": (styles.submit_container)},
        React.createElement(Button, { \
          "title": 'Create',  \
          "disabled": (!@state[type].value),  \
          "onClick": (-> onSaveNew(type))
        }),
        React.createElement(Button, { \
          "title": 'Cancel',  \
          "onClick": (-> onCancelCreation(type)),  \
          "white": true
        })
      )
    )

  onBackToGroups: (type) =>
    newState = @state
    newState[type] = Object.assign(
      @state[type]
      {
        group: {}
        creation: null
        value: null
        page: 0
      }
    )

    @setState newState
    TagsActionCreator.getGeneralList
      type: type
      page: newState[type].page
      per_page: @state.perPage
      all: true

  render: ->
    {
      onGroupCreate, onTagCreate, onGroupSelected
      onGroupSave, onGroupDelete, onBackToGroups
    } = @
    React.createElement("div", {"ref": "container", "className": (styles.container)},
      React.createElement("section", {"className": (styles.section)},
        React.createElement("h1", {"className": (styles.section_title)}, "Conversation tags"),
        React.createElement(HashTags, { \
          "groups": (TagsStore.conversationGroups),  \
          "isReadyToDrag": (true),  \
          "tagsWithoutGroup": (TagsStore.conversationTagsWithoutGroup),  \
          "tagsWithGroup": (TagsStore.conversationTagsWithGroup),  \
          "groupsLoading": (TagsStore.conversationGroupsLoading),  \
          "groupsError": (TagsStore.conversationGroupsError),  \
          "tagsLoading": (TagsStore.conversationTagsLoading),  \
          "tagsError": (TagsStore.conversationTagsError),  \
          "onGroupSelected": ((group) -> onGroupSelected('conversation', group)),  \
          "onTagSelected": (@onTagSelected),  \
          "editable": true,  \
          "isConversationTags": true,  \
          "onGroupSave": ((group) -> onGroupSave(group, 'conversation')),  \
          "onTagSave": (@onTagSave),  \
          "onGroupDelete": ((group) -> onGroupDelete(group, 'conversation')),  \
          "onTagDelete": (@onTagDelete),  \
          "onGroupCreate": (-> onGroupCreate('conversation')),  \
          "onTagCreate": ((group) -> onTagCreate('conversation', group)),  \
          "page": (@state.conversation.page),  \
          "pageCount": (TagsStore.conversationPageCount),  \
          "onPageChange": (@onConversationPageChange),  \
          "onBackToGroups": (-> onBackToGroups('conversation'))
        }),

        (@generateInputForCreation('conversation', true) if @state.conversation.creation)
      ),
      React.createElement("section", {"className": (styles.section)},
        React.createElement("h1", {"className": (styles.section_title)}, "Client\'s tags"),
        React.createElement(HashTags, { \
          "groups": (TagsStore.customerGroups),  \
          "isReadyToDrag": (true),  \
          "tagsWithoutGroup": (TagsStore.customerTagsWithoutGroup),  \
          "tagsWithGroup": (TagsStore.customerTagsWithGroup),  \
          "groupsLoading": (TagsStore.customerGroupsLoading),  \
          "groupsError": (TagsStore.customerGroupsError),  \
          "tagsLoading": (TagsStore.customerTagsLoading),  \
          "tagsError": (TagsStore.customerTagsError),  \
          "onGroupSelected": ((group) -> onGroupSelected('customer', group)),  \
          "onTagSelected": (@onTagSelected),  \
          "editable": true,  \
          "onGroupSave": ((group) -> onGroupSave(group, 'customer')),  \
          "onTagSave": (@onTagSave),  \
          "onGroupDelete": ((group) -> onGroupDelete(group, 'customer')),  \
          "onTagDelete": (@onTagDelete),  \
          "onGroupCreate": (-> onGroupCreate('customer')),  \
          "onTagCreate": ((group) -> onTagCreate('customer', group)),  \
          "page": (@state.customer.page),  \
          "pageCount": (TagsStore.customerPageCount),  \
          "onPageChange": (@onCustomerPageChange),  \
          "onBackToGroups": (-> onBackToGroups('customer'))
        }),

        (@generateInputForCreation('customer') if @state.customer.creation)
      )
    )

module.exports = TagsComponent
