ActionTypes = require 'constants/ActionTypes'
RotationsAPI = require 'api/RotationsAPI'
Dispatcher = require 'root/dispatcher/Dispatcher'

RotationsActionCreator =
  new: ->
    Dispatcher.dispatch type: ActionTypes.ROTATION_FORM_CLEAR

  getList: (data) ->
    Dispatcher.dispatch type: ActionTypes.ROTATIONS_GET_LIST_REQUESTING
    RotationsAPI.getList(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATIONS_GET_LIST_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATIONS_GET_LIST_FAILURE
          payload: error
    )

  get: (data) ->
    Dispatcher.dispatch type: ActionTypes.ROTATION_GET_REQUESTING
    RotationsAPI.get(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_GET_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_GET_FAILURE
          payload: error
    )

  create: (data) ->
    Dispatcher.dispatch type: ActionTypes.ROTATION_CREATE_REQUESTING
    RotationsAPI.create(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_CREATE_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_CREATE_FAILURE
          payload: error
    )

  update: (data) ->
    Dispatcher.dispatch type: ActionTypes.ROTATION_UPDATE_REQUESTING
    RotationsAPI.update(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_UPDATE_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_UPDATE_FAILURE
          payload: error
    )

  delete: (data) ->
    Dispatcher.dispatch type: ActionTypes.ROTATION_DELETE_REQUESTING
    RotationsAPI.destroy(
      data
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_DELETE_SUCCESS
          payload: data
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.ROTATION_DELETE_FAILURE
          payload: error
    )

module.exports = RotationsActionCreator
