classnames = require 'classnames'

BaseComponent = require '../../BaseComponent'
GroupsStore = require '../../../stores/GroupsStore'
GroupsActionCreator = require '../../../actions/GroupsActionCreator'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
AgentListComponent = require '../AgentListComponent'
ChannelListComponent = require '../ChannelListComponent'
InlineTextInput = require '../../shared/InlineTextInput/InlineTextInput'
Button = require '../../shared/Button'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
SwitchComponent = require '../../shared/SwitchComponent'
CustomSelectComponent = require '../../shared/CustomSelectComponent'
{ GROUP_TYPES } = require 'constants/conversations'
styles = require './group-settings'

USERS_PER_PAGE = 30
CHANNELS_PER_PAGE = 30

class GroupSettingComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      addedAgents: []
      addedChannels: []
      deletedAgents: []
      deletedChannels: []
      groupType: null
      onlySelected: false
      userPage: 0
      channelPage: 0
      userPerPage: USERS_PER_PAGE
      channelPerPage: CHANNELS_PER_PAGE
    }
    @initComponent()

  dependsOnStores: [GroupsStore]

  getState: =>
    groupType: GroupsStore.group.group_type

  componentDidMount: ->
    super()
    {groupId} = @props.match.params
    {
      userPage, userPerPage, agentFilter
      channelFilter, channelPage, channelPerPage
    } = @state
    setTimeout(
      ->
        GroupsActionCreator.getGroupComposition groupId
        GroupsActionCreator.getUsersForGroup {
          group_id: groupId
          query: agentFilter
          page: userPage
          per_page: userPerPage
        }

        GroupsActionCreator.getChannelsForGroup {
          group_id: groupId
          query: channelFilter
          page: channelPage
          per_page: channelPerPage
        }
      10
    )

  onTitleChange: (title) =>
    @setState {title}

  componentWillReceiveProps: (nextProps) ->
    if @props.match.params.groupId != nextProps.match.params.groupId
      @setState {
        agentFilter: null
        channelFilter: null
        addedAgents: []
        addedChannels: []
        deletedAgents: []
        deletedChannels: []
        groupType: null
        onlySelected: false
        userPage: 0
        channelPage: 0
        userPerPage: USERS_PER_PAGE
        channelPerPage: CHANNELS_PER_PAGE
      }

      GroupsActionCreator.getGroupComposition nextProps.match.params.groupId

      GroupsActionCreator.getUsersForGroup {
        group_id: nextProps.match.params.groupId
        query: null
        page: 0
        per_page: USERS_PER_PAGE
      }

      GroupsActionCreator.getChannelsForGroup {
        group_id: nextProps.match.params.groupId
        query: null
        page: 0
        per_page: CHANNELS_PER_PAGE
      }

  getAllCheckedIds: (defaultIds, selectedIds, withoutIds) =>
    union = _.union(defaultIds, selectedIds)
    _.without(union, withoutIds...)

  onAgentClick: (id, isChecked) =>
    {addedOptions, deletedOptions} = @changeOptionsLists(
      'agents', @state.addedAgents, @state.deletedAgents, id, isChecked
    )
    @setState {
      addedAgents: addedOptions
      deletedAgents: deletedOptions
    }

  onChannelClick: (id, isChecked) =>
    {addedOptions, deletedOptions} = @changeOptionsLists(
      'channels', @state.addedChannels, @state.deletedChannels, id, isChecked
    )
    @setState {
      addedChannels: addedOptions
      deletedChannels: deletedOptions
    }

  changeOptionsLists: (type, addedOptions, deletedOptions, id, isChecked) ->
    if isChecked
      if _.contains(GroupsStore.group[type], id)
        deletedOptions.push id
        deletedOptions = _.uniq(deletedOptions)
      else
        addedOptions = _.without(addedOptions, id)
    else
      if _.contains(GroupsStore.group[type], id)
        deletedOptions = _.without(deletedOptions, id)
      else
        addedOptions.push id
        addedOptions = _.uniq(addedOptions)

    {addedOptions, deletedOptions}

  onGroupTypeChange: (value) =>
    @setState groupType: value

  onSaveClick: =>
    GroupsActionCreator.saveSettings(
      GroupsStore.group.id,
      {
        title: @state.title
        added_user_ids: @state.addedAgents
        deleted_user_ids: @state.deletedAgents
        added_channel_ids: @state.addedChannels
        deleted_channel_ids: @state.deletedChannels
        group_type: if @state.groupType == 'empty' then null else @state.groupType
      }
      @onSuccessSave
    )

  onSuccessSave: =>
    @setState {
      addedAgents: []
      addedChannels: []
      deletedAgents: []
      deletedChannels: []
    }

  toggleSelectedFilter: =>
    @setState onlySelected: !@state.onlySelected

  onPageChangeUsers: ({selected}) =>
    return if selected == @state.userPage

    {groupId} = @props.match.params
    {userPerPage, agentFilter} = @state
    GroupsActionCreator.getUsersForGroup {
      group_id: groupId
      query: agentFilter
      page: selected
      per_page: userPerPage
    }
    @setState userPage: selected

  onPageChangeChannels: ({selected}) =>
    return if selected == @state.channelPage

    {groupId} = @props.match.params
    {channelPerPage, channelFilter} = @state
    GroupsActionCreator.getChannelsForGroup {
      group_id: groupId
      query: channelFilter
      page: selected
      per_page: channelPerPage
    }
    @setState channelPage: selected

  onAgentFilter: (agentFilter) =>
    {groupId} = @props.match.params
    {userPage, userPerPage} = @state
    GroupsActionCreator.getUsersForGroup {
      group_id: groupId
      query: agentFilter
      page: userPage
      per_page: userPerPage
    }
    @setState {agentFilter}

  onAgentFilterClear: =>
    agentFilter = null
    {groupId} = @props.match.params
    {userPage, userPerPage} = @state
    GroupsActionCreator.getUsersForGroup {
      group_id: groupId
      query: agentFilter
      page: userPage
      per_page: userPerPage
    }
    @setState {agentFilter}

  onChannelFilter: (channelFilter) =>
    {groupId} = @props.match.params
    {channelPage, channelPerPage} = @state
    GroupsActionCreator.getChannelsForGroup {
      group_id: groupId
      query: channelFilter
      page: channelPage
      per_page: channelPerPage
    }
    @setState {channelFilter}

  onChannelFilterClear: =>
    channelFilter = null
    {groupId} = @props.match.params
    {channelPage, channelPerPage} = @state
    GroupsActionCreator.getChannelsForGroup {
      group_id: groupId
      query: channelFilter
      page: channelPage
      per_page: channelPerPage
    }
    @setState {channelFilter}

  render: ->
    React.createElement("div", {"key": (@props.match.params.groupId), "className": (styles.container)},
      React.createElement(LoadableWrapperComponent, { \
        "loading": (GroupsStore.optionsLoading || GroupsStore.compositionLoading)
      },
        React.createElement("div", {"className": (styles.header)},
          React.createElement(InlineTextInput, { \
            "className": (styles.title),  \
            "value": (GroupsStore.group.title),  \
            "onSubmit": (@onTitleChange)
          }),
          React.createElement(Button, { \
            "title": 'save',  \
            "className": (styles.float_right),  \
            "onClick": (@onSaveClick)
          })
        ),
        React.createElement("div", {"className": (styles.data_section)},
          React.createElement("span", {"className": (styles.group_type_select_label)}, "Group type:"),
          (@state.groupType &&
            React.createElement(CustomSelectComponent, { \
              "defaultValue": (@state.groupType),  \
              "options": (GROUP_TYPES),  \
              "onOptionSelect": (@onGroupTypeChange),  \
              "customSelectStyle": (styles.group_type_select)
            })
          )
        ),
        React.createElement("div", {"className": (classnames styles.data_section, styles.no_margin)},
          React.createElement("p", null, "Channels"),
          React.createElement("div", {"className": (styles.search)},
            React.createElement(SearchInputComponent, { \
              "onChange": (@onChannelFilter),  \
              "onClear": (@onChannelFilterClear),  \
              "placeholder": 'Channel title',  \
              "clearCross": true
            })
          ),
          React.createElement(ChannelListComponent, { \
            "channels": (GroupsStore.options.channels),  \
            "checkedIds": (@getAllCheckedIds(
              GroupsStore.group.channels
              @state.addedChannels
              @state.deletedChannels
            )),  \
            "onChannelClick": (@onChannelClick),  \
            "onPageChange": (@onPageChangeChannels),  \
            "defaultPage": (@state.channelPage),  \
            "pageCount": (GroupsStore.channelsPageCount),  \
            "loading": (GroupsStore.channelsLoading)
          })
        )
      )
    )

module.exports = GroupSettingComponent
