config = require '../config'
BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionMap =
  "#{ActionTypes.TAGS_GET_LIST_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.TAGS_GET_LIST_SUCCESS}": (tags) ->
    @loading = false
    @tags = tags
    @emitChange()
  "#{ActionTypes.TAGS_GET_LIST_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.TAGS_BY_GROUP_REQUESTING}": (type) ->
    @isOnOrderList = false
    @[type + 'TagsLoading'] = true
    @[type + 'TagsWithGroup'] = []
    @emitChange()
  "#{ActionTypes.TAGS_BY_GROUP_SUCCESS}": ({type, data}) ->
    @[type + 'TagsLoading'] = false
    @[type + 'TagsWithGroup'] = data.tags
    @[type + 'PageCount'] = data.page_count
    @emitChange()
  "#{ActionTypes.TAGS_BY_GROUP_FAILURE}": ({type, error}) ->
    @[type + 'TagsLoading'] = false
    @emitChange()

  "#{ActionTypes.TAGS_CREATE_REQUESTING}": ({tags_group_id, tag_type}) ->
    entity = if tags_group_id then 'Tags' else 'Groups'
    @["#{tag_type}#{entity}Loading"] = true
    @["#{tag_type}#{entity}Error"] = null
    @emitChange()
  "#{ActionTypes.TAGS_CREATE_SUCCESS}": (tag) ->
    entity = if tag.tags_group_id then 'Tags' else 'Groups'
    @["#{tag.tag_type}#{entity}Loading"] = false
    @emitChange()
  "#{ActionTypes.TAGS_CREATE_FAILURE}": (data) ->
    {tags_group_id, tag_type} = data.attributes
    entity = if tags_group_id then 'Tags' else 'Groups'
    @["#{tag_type}#{entity}Loading"] = false
    @["#{tag_type}#{entity}Error"] = data.error
    @emitChange()

  "#{ActionTypes.TAGS_DESTROY_REQUESTING}": ({tags_group_id, tag_type}) ->
    entity = if tags_group_id then 'Tags' else 'Groups'
    @["#{tag_type}#{entity}Loading"] = true
    @["#{tag_type}#{entity}Error"] = null
    @emitChange()
  "#{ActionTypes.TAGS_DESTROY_SUCCESS}": (tag) ->
    type = tag.tag_type
    if tag.tags_group_id
      @[type + 'TagsLoading'] = false
      @[type + 'TagsWithGroup'] = @["#{type}TagsWithGroup"].filter (t) -> t.id != tag.id
    else
      @[type + 'GroupsLoading'] = false
      @[type + 'TagsWithoutGroup'] = @["#{type}TagsWithoutGroup"].filter (t) -> t.id != tag.id
    @emitChange()
  "#{ActionTypes.TAGS_DESTROY_FAILURE}": ({error, tag}) ->
    entity = if tag.tags_group_id then 'Tags' else 'Groups'
    @["#{tag.tag_type}#{entity}Loading"] = false
    @["#{tag.tag_type}#{entity}Error"] = error
    @emitChange()

  "#{ActionTypes.TAG_GROUPS_GET_REQUESTING}": (type) ->
    @[type + 'GroupsLoading'] = true
    @[type + 'GroupsError'] = null
    @[type + 'Groups'] = []
    @[type + 'TagsWithoutGroup'] = []
    @emitChange()
  "#{ActionTypes.TAG_GROUPS_GET_SUCCESS}": (data) ->
    {type} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'Groups'] = data.groups
    @[type + 'TagsWithoutGroup'] = data.tags_without_group
    @[type + 'PageCount'] = data.page_count
    @emitChange()
  "#{ActionTypes.TAG_GROUPS_GET_FAILURE}": (data) ->
    {type, error} = data
    @[type + 'GroupsError'] = error
    @[type + 'GroupsLoading'] = false
    @emitChange()

  "#{ActionTypes.TAG_GROUP_CREATION_REQUESTING}": (type) ->
    @[type + 'GroupsLoading'] = true
    @[type + 'GroupsError'] = null
    @emitChange()
  "#{ActionTypes.TAG_GROUP_CREATION_SUCCESS}": (data) ->
    {type} = data
    @[type + 'GroupsLoading'] = false
    @emitChange()
  "#{ActionTypes.TAG_GROUP_CREATION_FAILURE}": (data) ->
    {type, error} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'GroupsError'] = error
    @emitChange()

  "#{ActionTypes.TAG_GROUP_UPDATE_REQUESTING}": (type) ->
    @[type + 'GroupsLoading'] = true
    @[type + 'GroupsError'] = null
    @emitChange()
  "#{ActionTypes.TAG_GROUP_UPDATE_SUCCESS}": (data) ->
    {type, group} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'Groups'].forEach (g) ->
      if g.id == group.id
        g.name = group.name
        g.category = group.category
        g.sla = group.sla
        g.hidden = group.hidden
    @emitChange()
  "#{ActionTypes.TAG_GROUP_UPDATE_FAILURE}": (data) ->
    {type, error} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'GroupsError'] = error
    @emitChange()

  "#{ActionTypes.TAG_GROUP_DELETE_REQUESTING}": (type) ->
    @[type + 'GroupsLoading'] = true
    @[type + 'GroupsError'] = null
    @emitChange()
  "#{ActionTypes.TAG_GROUP_DELETE_SUCCESS}": (data) ->
    {type, id} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'Groups'] = @["#{type}Groups"].filter (g) -> g.id != id
    @emitChange()
  "#{ActionTypes.TAG_GROUP_UPDATE_FAILURE}": (data) ->
    {type, error} = data
    @[type + 'GroupsLoading'] = false
    @[type + 'GroupsError'] = error
    @emitChange()

  "#{ActionTypes.TAG_TARGET_CREATE_REQUESTING}": (data) ->
    {targetType, tagName} = data
    if data.isInGroup
      @[data.targetType + 'Groups'].forEach (group) ->
        if group.tags
          group.tags.forEach (tag) ->
            if tag.id == data.tagId
              tag.isLoading = true
    @[targetType + 'TagTargetLoading'][tagName] = true
    @emitChange()
  "#{ActionTypes.TAG_TARGET_CREATE_SUCCESS}": (data) ->
    entity = if data.isInGroup then 'TagsWithGroup' else 'TagsWithoutGroup'
    if data.isInGroup
      @[data.targetType + 'Groups'].forEach (group) ->
        if group.tags
          group.tags.forEach (tag) ->
            if tag.id == data.tagId
              tag.isLoading = false
              tag.checked = true
    @[data.targetType + entity].forEach (tag) ->
      if tag.id == data.tagId || tag.name == data.tagName
        tag.checked = true
        tag.id = data.tagId
        tag.tags_group_id = data.groupId unless tag.tags_group_id

    delete @[data.targetType + 'TagTargetLoading'][data.tagName]
    @emitChange()
  "#{ActionTypes.TAG_TARGET_CREATE_FAILURE}": (data) ->
    delete @[data.targetType + 'TagTargetLoading'][data.tagName]
    @emitChange()

  "#{ActionTypes.TAG_TARGET_DESTROY_REQUESTING}": (data) ->
    {targetType, tagName} = data
    @[targetType + 'TagTargetLoading'][tagName] = true
    if data.isInGroup
      @[data.targetType + 'Groups'].forEach (group) ->
        if group.tags
          group.tags.forEach (tag) ->
            if tag.id == data.tagId
              tag.isLoading = true
    @emitChange()
  "#{ActionTypes.TAG_TARGET_DESTROY_SUCCESS}": (data) ->
    entity = if data.isInGroup then 'TagsWithGroup' else 'TagsWithoutGroup'
    if data.isInGroup
      @[data.targetType + 'Groups'].forEach (group) ->
        if group.tags
          group.tags.forEach (tag) ->
            if tag.id == data.tagId
              tag.isLoading = false
              tag.checked = false
    @[data.targetType + entity].forEach (tag) ->
      if tag.id == data.tagId
        tag.checked = false

    delete @[data.targetType + 'TagTargetLoading'][data.tagName]
    @emitChange()
  "#{ActionTypes.TAG_TARGET_DESTROY_FAILURE}": (data) ->
    delete @[data.targetType + 'TagTargetLoading'][data.tagName]
    @emitChange()

  "#{ActionTypes.CUSTOMER_CRM_WITH_TAGS_REQUESTING}": ->
    @orderList = []
    @ordersLoading = true
    @conversationTagsLoading = true
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_WITH_TAGS_SUCCESS}": (data) ->
    @orderList = data?.results.map (order) ->
      name: order.number
      additional:
        type: 'order'
        completeTime: order.deadline
        status: order.main_status
        id: order.id

    tagsHash = {}
    @conversationTagsWithGroup.forEach (tag) ->
      tagsHash[tag.name] = tag

    @conversationTagsWithGroup = @orderList.map (order) ->
      if tagsHash[order.name]
        Object.assign(
          tagsHash[order.name]
          order
        )
      else
        order

    @ordersLoading = false
    @conversationTagsLoading = false unless @tagsForOrdersLoading
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE}": ->
    @ordersLoading = false
    @conversationTagsLoading = false unless @tagsForOrdersLoading
    @emitChange()

  "#{ActionTypes.TAGS_FOR_ORDER_REQUESTING}": ->
    @isOnOrderList = true
    @conversationTagsLoading = true
    @conversationTagsError = null
    @tagsForOrdersLoading = true
    @conversationTagsWithGroup = []
    @emitChange()
  "#{ActionTypes.TAGS_FOR_ORDER_SUCCESS}": (tags) ->
    tagsHash = {}
    tags.forEach (tag) ->
      tagsHash[tag.name] = tag
    if @orderList.length
      @conversationTagsWithGroup = @orderList.map (order) ->
        return order unless tagsHash[order.number]
        Object.assign(
          tagsHash[order.number]
          order
        )
    else
      @conversationTagsWithGroup = tags

    @tagsForOrdersLoading = false
    @conversationTagsLoading = false unless @ordersLoading
    @emitChange()
  "#{ActionTypes.TAGS_FOR_ORDER_FAILURE}": (error) ->
    @tagsForOrdersLoading = false
    @conversationTagsLoading = false unless @ordersLoading
    @conversationTagsError = error
    @emitChange()

class TagsStore extends BaseStore

  constructor: ->
    super()
    @conversationGroupsLoading = false
    @conversationGroupsError = null
    @conversationTagsLoading = false
    @conversationTagsError = null
    @conversationGroups = []
    @conversationTagsWithoutGroup = []
    @conversationTagsWithGroup = []
    @conversationPageCount = 0
    @conversationTagTargetLoading = {}

    @orderList = []
    @isOnOrderList = false
    @ordersLoading = false
    @tagsForOrdersLoading = false

    @customerGroupsLoading = false
    @customerGroupsError = null
    @customerTagsLoading = false
    @customerTagsError = null
    @customerGroups = []
    @customerTagsWithoutGroup = []
    @customerTagsWithGroup = []
    @customerPageCount = 0
    @customerTagTargetLoading = {}
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new TagsStore()
