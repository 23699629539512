_ = require 'underscore'
ReactPaginate = require 'react-paginate'

paginationStyles = require '../../shared/styles/pagination.styl'
ChannelItemComponent = require './ChannelItemComponent'
styles = require './channel-list'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'

propTypes = require 'prop-types'

channelListComponent = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement("div", {"className": (styles.position_relative)},
      React.createElement(LoadableWrapperComponent, {"loading": (!!props.loading)},
        React.createElement("ul", {"className": (styles.list)},
          (props.channels.map (channel) ->
            React.createElement("li", {"key": (channel.id), "className": (styles.item_container)},
              React.createElement(ChannelItemComponent, { \
                "channel": (channel),  \
                "onChannelClick": (props.onChannelClick),  \
                "checked": (_.contains props.checkedIds, channel.id)
              })
            )
          )
        )
      )
    ),
    (if props.pageCount > 1
      React.createElement("div", {"className": (styles.text_center)},
        React.createElement(ReactPaginate.default, { \
          "previousLabel": 'previous',  \
          "nextLabel": 'next',  \
          "pageCount": (props.pageCount),  \
          "marginPagesDisplayed": (1),  \
          "pageRangeDisplayed": (2),  \
          "onPageChange": (props.onPageChange),  \
          "initialPage": (props.defaultPage),  \
          "pageClassName": (paginationStyles.page),  \
          "nextClassName": (paginationStyles.page),  \
          "previousClassName": (paginationStyles.page),  \
          "activeClassName": (paginationStyles.active),  \
          "pageLinkClassName": (paginationStyles.link),  \
          "nextLinkClassName": (paginationStyles.link),  \
          "previousLinkClassName": (paginationStyles.link),  \
          "containerClassName": (paginationStyles.container),  \
          "breakClassName": (paginationStyles.page)
        })
      )
    )
  )

channelListComponent.propTypes =
  channels: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired
      name: propTypes.string.isRequired
      service_name: propTypes.string.isRequired
    })
  ).isRequired
  checkedIds: propTypes.arrayOf(propTypes.number).isRequired
  pageCount: propTypes.number.isRequired
  onPageChange: propTypes.func.isRequired
  onChannelClick: propTypes.func
  defaultPage: propTypes.number
  loading: propTypes.bool

module.exports = channelListComponent
