moment = require 'moment'
classnames = require 'classnames'
browserHistory = require './../../../history'

BaseComponent = require 'components/BaseComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ScheduledItem = require 'components/admin/ScheduledItem'
confirm = require 'components/shared/ConfirmDialog'
styles = require 'components/admin/assets/accordion.styl'

propTypes = require 'prop-types'

class AccordionByDate extends BaseComponent
  constructor: (props) ->
    super(props)
    @date = props.date
    @data = props.data
    @state =
      expand: @data[@props.itemType]?.length > 0
      "#{@props.itemType}": @data[@props.itemType] || []
      total: @data.total
    @initComponent()

  onSwitchPanel: =>
    if !@state.expand && @state[@props.itemType].length == 0
      options = Object.assign({}, @props.getOptions, { date: @date })
      @props.itemsActionCreator.get(options)

    @setState
      expand: !@state.expand

  componentWillReceiveProps: (nextProps) =>
    items = nextProps.data[@props.itemType]
    if items
      @setState
        "#{@props.itemType}": nextProps.data[@props.itemType]
        total: nextProps.data.total
    else
      @setState
        total: nextProps.data.total

  onEdit: (id) =>
    browserHistory.default.push "/admin/#{@props.itemType}/#{@props.editLink}/#{@date}/#{id}"

  onDelete: (id) =>
    confirm(
      @props.deleteConfirm.message
      {
        confirmTitle: @props.deleteConfirm.confirmTitle
      }
    ).then(
      => @props.itemsActionCreator.destroy(id, @date)
      ->
    )

  render: =>
    formatedDate = moment(@date).format('DD MMMM YYYY')
    (
      React.createElement("div", null,
        React.createElement("div", { \
          "className": (styles.title),  \
          "onClick": (@onSwitchPanel)
        },
          React.createElement("b", {"className": (styles.title_text)}, (formatedDate)),
          React.createElement("span", {"className": (styles.total)}, (" (#{@state.total})")),
          React.createElement("i", {"className": (classnames(
            styles.switch_icon,
            "#{styles.switch_icon_open}": @state.expand
          ))})
        ),
        React.createElement("div", {"className": (classnames(
          styles.scheduled,
          "#{styles.scheduled_open}": @state.expand
        ))},
          React.createElement(LoadableWrapperComponent, {"loading": (@props.itemsStore.loadingByDay[@date])},
            (@state[[@props.itemType]]?.map (item) =>
              React.createElement(ScheduledItem, { \
                "key": (item.uuid),  \
                "item": (item),  \
                "onEdit": (@onEdit),  \
                "onDelete": (@onDelete),  \
                "agents": (@props.agents)
              })
            )
          )
        )
      )
    )

AccordionByDate.propTypes =
  itemsStore: propTypes.object.isRequired
  itemsActionCreator: propTypes.object.isRequired
  itemType: propTypes.string.isRequired
  deleteConfirm: propTypes.object.isRequired
  editLink: propTypes.string.isRequired
  date: propTypes.string.isRequired
  data: propTypes.object.isRequired
  getOptions: propTypes.object.isRequired

AccordionByDate.defaultProps =
  getOptions: {}

module.exports = AccordionByDate
