React = require 'react'
{ Link } = require 'react-router-dom'

Button = require '../../shared/Button'
ConversationsActionCreator = require '../../../actions/ConversationsActionCreator'
InterfaceActionCreator = require '../../../actions/InterfaceActionCreator'

styles = require './dormant-modal'

ConversationsActionCreator = new ConversationsActionCreator()

class DormantModalComponent extends React.Component
  onCloseConversation: =>
    ConversationsActionCreator.update @props.data.conversation.id, status: 'closed'
    setTimeout(
      ->
        InterfaceActionCreator.closePopup()
      200
    )

  onDeferClosing: =>
    ConversationsActionCreator.deferDormantStatus @props.data.conversation.id
    setTimeout(
      ->
        InterfaceActionCreator.closePopup()
      200
    )

  render: ->
    { conversation } = @props.data
    conversationHasTags = conversation.tags.length != 0

    React.createElement("div", {"className": (styles.container), "key": (conversation.id)},
      React.createElement("p", null,
        React.createElement("span", null, "Chat from  "),
        React.createElement(Link, { \
          "className": (styles.link),  \
          "to": ("/conversations/my_conversations/#{conversation.id}")
        },
          (conversation.customer.name)
        ),
        React.createElement("span", null, " has status \"dormant\" and can be closed"),
        (unless conversationHasTags
          React.createElement("strong", {"className": (styles.warning)}, "(conversation has no tags)")
        )
      ),
      React.createElement("div", null,
        React.createElement(Button, { \
          "title": 'Close conversation',  \
          "onClick": (@onCloseConversation if conversationHasTags),  \
          "disabled": (!conversationHasTags)
        }),
        React.createElement(Button, { \
          "title": 'Remind later',  \
          "onClick": (@onDeferClosing),  \
          "white": true
        })
      )
    )

module.exports = DormantModalComponent
