FlagsStore = require './stores/FlagsStore'
FlagsActionCreator = require './actions/FlagsActionCreator'

sync = ->
  flags = FlagsStore.newFlags || {}
  if Object.keys(flags).length > 0
    FlagsActionCreator.sync(flags)

infinitySync = _.once ->
  setInterval sync, 3 * 1000

module.exports = infinitySync
