classnames = require 'classnames'
deepcopy = require 'deepcopy'
ReactDnD = require 'react-dnd'

dndUtils = require 'lib/dnd-utils'
ItemTypes = require './../ItemTypes'
Checkbox = require '../../shared/Checkbox'
AbstractListItem = require './AbstractListItem'
styles = require './hash-tag-list-item'

propTypes = require 'prop-types'

groupSource =
  canDrag: (props) ->
    props.isReadyToDrag
  beginDrag: (props) ->
    group: props.group
    index: props.index
  endDrag: (props, monitor) ->
    dragId = monitor.getItem().group.id
    props.updatePosition(dragId) if monitor.didDrop()

groupTarget =
  hover: dndUtils.standartHoverHandler

  canDrop: (props, monitor) ->
    itemType = monitor.getItemType()
    itemGroupType = monitor.getItem()[itemType]['tags_group_type']
    return !!props[itemType] && props[itemType]['tags_group_type'] == itemGroupType

class HashTagGroup extends AbstractListItem
  constructor: (props) ->
    super(props)
    @type = 'group'
    @state =
      edit: props.onlyEdit
      sla: props[@type].sla || false
      hidden: props[@type].hidden || false

  onSave: =>
    newItem = deepcopy @props[@type]
    newItem.name = @refs.input.value
    newItem.sla = @state.sla
    newItem.hidden = @state.hidden
    newItem.category = @refs.category?.value
    @props.onSave newItem
    @setState edit: false

  onSlaCheckbox: (e) =>
    @setState sla: !@state.sla

  onHiddenCheckbox: (e) =>
    @setState hidden: !@state.hidden

  generateInput: ->
    React.createElement("div", {"className": (styles.input_container)},
      React.createElement("input", { \
        "ref": "input",  \
        "onKeyPress": (@onKeyPress),  \
        "defaultValue": (@props[@type].name),  \
        "className": (styles.input)
      }),
      React.createElement("span", { \
        "onClick": (@onCancel),  \
        "className": (styles.cancel)},
        React.createElement("i", {"className": (styles.cancel_icon)})
      ),
      React.createElement("span", { \
        "onClick": (@onSave),  \
        "className": (styles.apply)
      },
        React.createElement("i", {"className": (styles.apply_icon)})
      ),
      (
        if @props.isConversationTags
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.sla),  \
                "onClick": (@onSlaCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "SAL")
            ),
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.hidden),  \
                "onClick": (@onHiddenCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "hidden")
            ),
            React.createElement("input", { \
              "ref": "category",  \
              "placeholder": "Category ...",  \
              "defaultValue": (@props[@type].category),  \
              "className": (classnames styles.input, styles.input_category)
            })
          )
      )
    )

  replace: (name) ->
    return name unless @props.searchQuery
    { searchQuery } = @props
    searchQuery = searchQuery.replace(/[^\w\s]/gi, '')
    parts = name.split(new RegExp("(#{searchQuery})", 'gi'))
    for i in [1...parts.length] by 2
      parts[i] = React.createElement("span", {"className": (styles.search), "key": (i)}, (parts[i]))
    parts

  generateData: ->
    isEditNotSystemGroup = @props.editable && @props.group.tags_source != 'system'
    @props.connectDragSource(
      @props.connectDropTarget(
        React.createElement("li", {"className": (classnames(
          @props.className,
          "#{styles.dropable}": @props.canDrop,
          "#{styles.hide}": @props.isDragging
        ))},
          React.createElement("div", { \
            "className": (classnames(styles.data,
              "#{styles.data_not_system}": isEditNotSystemGroup
            )),  \
            "onClick": (@onItemClick unless isEditNotSystemGroup)
          },
            (React.createElement("span", {"className": (styles.group_contains)}, "#") if @props.group.contains_checked && !@props.editable),
            React.createElement("span", { \
              "className": (classnames(styles.group_name,
                "#{styles.group_name_editable}": @props.editable
              ))
            },
              (@replace(@props.group.name))
            ),

            (React.createElement("span", {"title": (@props.group.category), "className": (classnames styles.flag_c, styles.group_flag_c)}, "C") if @props.editable && @props.group.category),
            (React.createElement("span", {"className": (classnames styles.flag_s, styles.group_flag_s)}, "S") if @props.editable && @props.group.sla),
            (React.createElement("span", {"className": (classnames styles.flag_h, styles.group_flag_h)}, "H") if @props.editable && @props.group.hidden),
            (React.createElement("i", {"className": (styles.edit), "onClick": (@onEditClick)}) if @props.editable),
            (React.createElement("i", {"className": (styles.delete), "onClick": (@onDeleteClick)}) if @props.editable),
            React.createElement("i", {"className": (styles.arrow)})
          )
        )
      )
    )

DragSourceDecorator = ReactDnD.DragSource(
  ItemTypes.TAGS_GROUP,
  groupSource,
  (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()
)

DropTargetDecorator = ReactDnD.DropTarget(
  ItemTypes.TAGS_GROUP,
  groupTarget,
  (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    canDrop: monitor.canDrop()
)

HashTagGroup.propTypes =
  group: propTypes.shape(
    id: propTypes.number.isRequired
    name: propTypes.string.isRequired
    contains_checked: propTypes.bool
  ).isRequired
  onClick: propTypes.func.isRequired
  editable: propTypes.bool
  onSave: propTypes.func
  onEditCancel: propTypes.func
  onDelete: propTypes.func
  onlyEdit: propTypes.bool
  connectDragSource: propTypes.func.isRequired
  connectDropTarget: propTypes.func.isRequired
  isDragging: propTypes.bool.isRequired
  swapItems: propTypes.func.isRequired
  updatePosition: propTypes.func

module.exports = DropTargetDecorator(DragSourceDecorator(HashTagGroup))
