moment = require 'moment'
classnames = require 'classnames'

ScheduledItemActivities = require 'components/admin/ScheduledItem/ScheduledItemActivities'
styles = require './scheduled-item-header.styl'

class ScheduledItemHeader extends React.Component
  constructor: ->
    super()
  
  getDurationLeft: (timeForAction) ->
    duration = moment.duration(moment(timeForAction) - moment())
    days = Math.floor(duration.asDays())
    hours = Math.floor(duration.subtract(days, 'days').asHours())
    minutes = Math.floor(duration.subtract(hours, 'hours').asMinutes())
    return {
      days
      hours
      minutes
    }
  
  getOperatorInfo: ->
    { operator, item } = @props
    userImageSrc = if operator?.image then operator.image else require '../../../shared/images/unknown.png'
    image = React.createElement("img", {"className": (styles.operator_image), "src": (userImageSrc), "alt": 'User image'})
    nickname = "@#{item.user.last_name}"
    return (
      React.createElement("div", {"className": (styles.operator_info)},
        (image),
        React.createElement("span", null, React.createElement("b", null, (nickname)))
      )
    )

  render: ->
    { item } = @props

    timezone = item.customer.timezone || 'Etc/UCT'
    timeZoneShift = "GMT#{moment.tz(timezone).format('Z')}"
    timeForAction = moment(item.time).format('DD MMMM, YYYY / HH:mm')
    dLeft = @getDurationLeft(item.time)
    timeLeft = "#{dLeft.days}d #{dLeft.hours}h #{dLeft.minutes}m left"
    createTime = moment(item.created_at).format('DD MMMM, YYYY / HH:mm')
    (
      React.createElement("div", { \
        "className": (classnames(
          @props.className,
          styles.header
        )),  \
        "onClick": (@props.onEdit)
      },
        React.createElement("div", null,
          React.createElement("span", null, React.createElement("b", null, (item.customer.name))),
          React.createElement("span", {"className": (styles.string_divider)}),
          React.createElement("span", null, (timeZoneShift)),
          React.createElement("span", {"className": (styles.string_divider)}),
          React.createElement("span", null, (timeForAction)),
          React.createElement("span", {"className": (styles.time_gray)}, ("(#{timeLeft})"))
        ),
        React.createElement("div", null,
          (@getOperatorInfo()),
          React.createElement("span", {"className": (styles.time_gray)}, ("(#{createTime})")),
          React.createElement(ScheduledItemActivities, { \
            "className": (styles.activities),  \
            "onEdit": (@props.onEdit),  \
            "onDelete": (@props.onDelete),  \
            "conversationLink": (@props.conversationLink)
          })
        )
      )
    )

module.exports = ScheduledItemHeader
