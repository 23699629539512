moment = require 'moment'

BaseAgentStatisticComponent = require 'components/shared/BaseAgentStatisticComponent'
AgentStatisticStore = require 'stores/AgentStatisticStore'
AgentStatisticActionCreator = require 'actions/AgentStatisticActionCreator'
{ DEFAULT_STATISTIC_ROWS_PER_PAGE, ROLES,
TL_STATISTIC_ROWS_PER_PAGE, STATISTIC_ROWS_PER_PAGE } = require 'constants/Agents'
{ getQueryParams } = require 'lib/utils'
{ urls, menus } = require 'root/config'
AuthStore = require 'stores/AuthStore'

ORDER_TYPES = ['desc', 'asc']

class AbstractStatisticComponent extends BaseAgentStatisticComponent
  initComponent: ->
    ORDER_FIELDS = Object.keys(@tableFields)

    @storeData = AgentStatisticStore
    params = getQueryParams window.location.search.substring 1

    page = Number.parseInt params.page
    if !page || page < 0
      page = 0

    perPage = Number.parseInt params.per_page
    if !perPage || perPage < 0
      perPage = STATISTIC_ROWS_PER_PAGE[@props.type] ||
      DEFAULT_STATISTIC_ROWS_PER_PAGE

    orderBy = params.sort_by
    if ORDER_FIELDS.indexOf(orderBy) == -1
      orderBy = ORDER_FIELDS[0]

    order = params.sort_order
    if ORDER_TYPES.indexOf(order) == -1
      order = ORDER_TYPES[0]

    @state = {
      page
      perPage
      pageCount: AgentStatisticStore.pageCount
      orderBy
      order
    }
    super()


  constructor: (props) ->
    super(props)

    @accessConfig = {}
    menus.forEach (item) =>
      if item.key == 'agents'
        @accessConfig =
          accessToEditShiftData: item.accessToEditShiftData || null
          accessToEditTLShiftData: item.accessToEditTLShiftData || null

  dependsOnStores: [AgentStatisticStore]

  onOrderChangeHandler: (orderBy, order) =>
    @downloadStatistic @state.page, @state.perPage, orderBy, order
    @setState {
      orderBy
      order
    }

  componentWillMount: ->
    @downloadStatistic @state.page, @state.perPage, @state.orderBy, @state.order

  componentWillUpdate: ->
    @pageCount = AgentStatisticStore.pageCount
    
    if AgentStatisticStore.pageCount != @state.pageCount
      @setState pageCount: AgentStatisticStore.pageCount

  downloadStatistic: (page, perPage, orderBy, order) =>
    params =
      page: page
      per_page: perPage
      sort_by: orderBy
      sort_order: order

    AgentStatisticActionCreator.getStatistic @props.agentId, params, @type

  onPageChange: ({selected}) =>
    unless selected == @state.page
      @defaultPage = selected
      @downloadStatistic selected, @state.perPage, @state.orderBy, @state.order
      @setState page: selected

  componentWillUnmount: ->
    super()
    AgentStatisticActionCreator.clearStore()

module.exports = AbstractStatisticComponent
