classnames = require 'classnames'

styles        = require './inline-textarea-input-unclickable'
BaseComponent = require '../../BaseComponent'

propTypes = require 'prop-types'

class InlineTextareaInputUnclickable extends BaseComponent
  @propTypes:
    className: propTypes.string
    value: propTypes.string,
    onSubmit: propTypes.func.isRequired

  constructor: (props) ->
    super(props)
    @state =
      value: props.value
    @initComponent()

  componentDidUpdate: =>
    _.defer =>
      if @props.editing
        input = ReactDOM.findDOMNode(@refs.input)
        input.focus() if input

  onBlur: =>
    @props.onSubmit(@state.value)
    @props.onBlur()

  onKeyDown: (e) =>
    if e.ctrlKey && e.key == 'Enter'
      e.preventDefault()
      @props.onBlur()
      @props.onSubmit(@state.value)

  onChange: (e) =>
    value = e.target.value
    @setState value: value

  render: =>
    className = classnames(
      styles.container, @props.className,
      "#{styles.editing}": @props.editing
    )

    React.createElement("div", {"className": (className)},
      (@props.editing &&
        React.createElement("textarea", { \
          "ref": "input",  \
          "value": (@state.value),  \
          "onBlur": (@onBlur),  \
          "onChange": (@onChange),  \
          "onKeyDown": (@onKeyDown),  \
          "className": (styles.input)
        })
      ),
      (!@props.editing && @props.value &&
        React.createElement("div", {"className": (styles.value)},
          (@state.value)
        )
      ),
      (!@props.editing && (@props.value == null || @props.value == '')  &&
        React.createElement("span", {"className": (styles.value)}, ('Click icon to add commentaries'))
      )
    )

module.exports = InlineTextareaInputUnclickable
