styles = require './agents-selector'
OnlineStore = require 'stores/OnlineStore'
ConversationsDashboardStore = require 'stores/ConversationsDashboardStore'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
BaseComponent   = require '../../BaseComponent'
{ agentStatus } = require '../../../lib/utils'
Select = require '../Select'
ConversationsActionCreator = new ConversationsActionCreator()

statusOrder =
  online: 0
  dormant: 1
  offline: 2

sortingRule = (a, b) ->
  lowerA = a.label.toLowerCase()
  lowerB = b.label.toLowerCase()
  statusA = statusOrder[a.status]
  statusB = statusOrder[b.status]

  if statusA > statusB
    1
  else if statusA == statusB
    if lowerA > lowerB
      1
    else
      -1
  else
    -1

getOptions = (agents, onlineAgents) ->
  mapedAgents = Object.values(agents).map (a) ->
    label: a.first_name + ' ' + a.last_name
    value: a.id
    status: agentStatus(a, onlineAgents)

  mapedAgents.sort(sortingRule)

getSelectedOption = (options, value) ->
  options.find (option) ->
    option.value == value

class AgentsSelectorComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [OnlineStore, ConversationsDashboardStore]

  @propTypes:
    onAgentChange: require('prop-types').func.isRequired

  componentDidMount: ->
    super()
    setTimeout(
      ConversationsActionCreator.subscribeDashboardConversations
      0
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ConversationsActionCreator.unSubscribeDashboardConversations
      0
    )

  getState: =>
    agents: ConversationsDashboardStore.agents

  onAgentChange: (o) =>
    @props.onAgentChange? o?.value

  optionRenderer: (props) =>
    indicatorClass = styles["#{props.data.status}Indicator"]
    React.createElement("span", Object.assign({},  props.innerProps, {"style": (props.getStyles('option', props))}),
      React.createElement("span", {"className": ( indicatorClass )}),
      React.createElement("span", null, ( props.data.label ))
    )

  valueRenderer: (props) =>
    indicatorClass = styles["#{props.data.status}Indicator"]
    React.createElement("span", Object.assign({},  props.innerProps, {"style": (props.getStyles('singleValue', props))}),
      React.createElement("span", {"className": ( indicatorClass )}),
      React.createElement("span", null, ( props.data.label ))
    )

  render: =>
    options = getOptions(@state.agents, OnlineStore.agents)
    selectedValue = getSelectedOption(options, @props.agentId)
    (React.createElement("div", {"className": ( styles.container )},
      React.createElement(Select.default, { \
          "value": ( selectedValue ),  \
          "options": ( getOptions(@state.agents, OnlineStore.agents) ),  \
          "onChange": ( @onAgentChange ),  \
          "className": ( styles.input ),  \
          "isClearable": true,  \
          "placeholder": "Agent",  \
          "components": ({
            Option: @optionRenderer
            SingleValue: @valueRenderer
          })
        })
    ))

module.exports = AgentsSelectorComponent
