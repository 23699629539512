TagsAPI = require '../api/TagsAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

TagsActionCreator =
  getList: (tagType) ->
    Dispatcher.dispatch type: ActionTypes.TAGS_GET_LIST_REQUESTING
    TagsAPI.getList(
      (json) ->
        if json.success 
          Dispatcher.dispatch 
            type: ActionTypes.TAGS_GET_LIST_SUCCESS 
            payload: json.data
        else
          Dispatcher.dispatch 
            type: ActionTypes.TAGS_GET_LIST_FAILURE
            payload: json.error
      (error) -> 
        Dispatcher.dispatch 
          type: ActionTypes.TAGS_GET_LIST_FAILURE 
          payload: error
      tagType
    )

  getGroupList: (data) ->
    Dispatcher.dispatch type: ActionTypes.TAGS_GET_GROUP_LIST_REQUESTING
    TagsAPI.getGroupList data,
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_GET_GROUP_LIST_SUCCESS
          payload: json.data.groups
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_GET_GROUP_LIST_FAILURE
          payload: error

  createTag: (data, successCallback, errorCallback) ->
    attributes =
      name: data.name
      tag_type: data.type
      tags_group_id: data.group_id
    Dispatcher.dispatch type: ActionTypes.TAGS_CREATE_REQUESTING, payload: attributes
    TagsAPI.create attributes,
      (json) ->
        Dispatcher.dispatch type: ActionTypes.TAGS_CREATE_SUCCESS, payload: json
        successCallback?(data.type)
      (data) ->
        console.error data.responseJSON.errors
        Dispatcher.dispatch
          type: ActionTypes.TAGS_CREATE_FAILURE
          payload:
            attributes: attributes
            error: 'An error occurred while creating new tag'
        errorCallback?(data.responseJSON.errors)

  updateTag: (id, attributes, successCallback, errorCallback) ->
    Dispatcher.dispatch 
      type: ActionTypes.TAGS_UPDATE_REQUESTING 
      payload: attributes
    TagsAPI.update id, attributes,
      (data) ->
        Dispatcher.dispatch 
          type: ActionTypes.TAGS_UPDATE_SUCCESS 
          payload: data
        successCallback?(attributes.tag_type)
      (data) ->
        Dispatcher.dispatch 
          type: ActionTypes.TAGS_UPDATE_FAILURE 
          payload: attributes
        errorCallback?(data.responseJSON.errors)

  destroy: (data, successCallback) ->
    Dispatcher.dispatch type: ActionTypes.TAGS_DESTROY_REQUESTING, payload: data
    TagsAPI.destroy data.id,
      (json) ->
        Dispatcher.dispatch type: ActionTypes.TAGS_DESTROY_SUCCESS, payload: data
        successCallback?(data.tag_type)
      (error) ->
        console.error error
        Dispatcher.dispatch
          type: ActionTypes.TAGS_DESTROY_FAILURE
          payload:
            tag: data
            error: 'An error occurred while deleting tag'

  getGeneralList: (data) ->
    Dispatcher.dispatch
      type: ActionTypes.TAG_GROUPS_GET_REQUESTING
      payload: data.type

    errorMsg = 'An error occurred while getting groups and tags without group'

    TagsAPI.getGeneralList(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUPS_GET_SUCCESS
            payload: Object.assign(
              json.data
              type: data.type
            )
        else
          console.error(json.error || json.errors)
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUPS_GET_FAILURE
            payload:
              error: errorMsg
              type: data.type
      (response) ->
        console.error response
        Dispatcher.dispatch
          type: ActionTypes.TAG_GROUPS_GET_FAILURE
          payload:
            error: errorMsg
            type: data.type
    )

  createGroup: (data, successCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.TAG_GROUP_CREATION_REQUESTING
      payload: data.type

    errorMsg = 'An error occurred while creating new tags group'
    TagsAPI.createGroup(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_CREATION_SUCCESS
            payload:
              group: json.data
              type: data.type
          successCallback?(data.type)
        else
          console.error(json.error || json.errors)
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_CREATION_FAILURE
            payload:
              error: errorMsg
              type: data.type
      (response) ->
        console.error response
        Dispatcher.dispatch
          type: ActionTypes.TAG_GROUP_CREATION_FAILURE
          payload:
            error: errorMsg
            type: data.type
    )

  updateGroup: (data) ->
    Dispatcher.dispatch
      type: ActionTypes.TAG_GROUP_UPDATE_REQUESTING
      payload: data.type

    errorMsg = 'An error occurred while updating of tags group'
    TagsAPI.updateGroup(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_UPDATE_SUCCESS
            payload:
              group: json.data
              type: json.data.tags_group_type
        else
          console.error(json.error || json.errors)
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_UPDATE_FAILURE
            payload:
              error: errorMsg
              type: data.type
      (response) ->
        console.error response
        Dispatcher.dispatch
          type: ActionTypes.TAG_GROUP_UPDATE_FAILURE
          payload:
            error: errorMsg
            type: data.type
    )

  deleteGroup: (data, successCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.TAG_GROUP_DELETE_REQUESTING
      payload: data.type

    errorMsg = 'An error occurred while deleting of tags group'
    TagsAPI.deleteGroup(
      data.id
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_DELETE_SUCCESS
            payload: data
          successCallback?(data.type)
        else
          console.error(json.error || json.errors)
          Dispatcher.dispatch
            type: ActionTypes.TAG_GROUP_DELETE_FAILURE
            payload:
              error: errorMsg
              type: data.type
      (response) ->
        console.error response
        Dispatcher.dispatch
          type: ActionTypes.TAG_GROUP_DELETE_FAILURE
          payload:
            error: errorMsg
            type: data.type
    )

  getTagsByGroup: (group, target_id, page, per_page, all) ->
    Dispatcher.dispatch
      type: ActionTypes.TAGS_BY_GROUP_REQUESTING
      payload: group.tags_group_type
    data = {
      tags_group_id: group.id
      target_id
      page
      per_page
    }
    if all
      data.all = all
    TagsAPI.getTagsByGroup(
      group.id
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAGS_BY_GROUP_SUCCESS
            payload:
              data: json.data
              type: group.tags_group_type
        else
          console.error json.error
          Dispatcher.dispatch
            type: ActionTypes.TAGS_BY_GROUP_FAILURE
            payload:
              error: json.error
              type: group.tags_group_type
      (error) ->
        console.error error
        Dispatcher.dispatch
          type: ActionTypes.TAGS_BY_GROUP_FAILURE
          payload:
            error: json.error
            type: group.tags_group_type
    )

  getTagsForOrderGroup: (group_id, target_id) ->
    Dispatcher.dispatch type: ActionTypes.TAGS_FOR_ORDER_REQUESTING

    TagsAPI.getTagsForOrderGroup(
      {group_id, target_id}
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAGS_FOR_ORDER_SUCCESS
            payload: json.data
        else
          console.error json.error
          Dispatcher.dispatch
            type: ActionTypes.TAGS_FOR_ORDER_FAILURE
            payload: json.error
      (error) ->
        console.error error
        Dispatcher.dispatch
          type: ActionTypes.TAGS_FOR_ORDER_FAILURE
          payload: 'An error occurred while getting order tags'
    )

module.exports = TagsActionCreator
