Highcharts = require 'highcharts'
addStackedChart = require 'highcharts/modules/exporting'
BaseComponent = require '../../BaseComponent'

class ChartComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  componentDidMount: ->
    if (@props.modules)
      @props.modules.forEach (module)->
        module(Highcharts)
    @chart = new Highcharts[@props.type || "Chart"](
      @props.container,
      @props.options
    )

  componentWillReceiveProps: (newProps) ->
    if newProps.options.chart?.height
      @chart.setSize(null, newProps.options.chart.height)
    if newProps.options.xAxis
      @chart.xAxis[0].setCategories newProps.options.xAxis.categories
    if @chart.series
      for i in [@chart.series.length - 1..0] by -1
        if (!newProps.options.series[i]) || 
            newProps.options.series[i].name != @chart.series[i].name
          @chart.series[i].remove()
    for series, i in newProps.options.series
      if (!@chart.series[i]) || @chart.series[i].name != series.name
        @chart.addSeries(series)
      else
        @chart.series[i].setData(series.data)

  componentWillUnmount: ->
    @chart.destroy()

  render: ->
    React.createElement("div", {"id": (@props.container)})

module.exports = ChartComponent
