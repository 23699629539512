classnames = require 'classnames'
moment = require 'moment'

styles = require '../PreviousConversationComponent/previous-conversation'

module.exports = (props) ->
  React.createElement("div", { \
    "onClick": (props.onClick),  \
    "className": (classnames styles.title, styles.root)
  },
    React.createElement("span", {"className": (styles.primary_text)},
      (moment(props.title + '-01').format('MMMM YYYY'))
    ),
    React.createElement("span", {"className": (styles.secondary_text)}, "total: ", (props.count))
  )
