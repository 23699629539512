ActionTypes = require '../constants/ActionTypes'
ProfileStatisticAPI = require '../api/ProfileStatisticAPI'
Dispatcher          = require '../dispatcher/Dispatcher'
{buildQueryParamsString} = require '../lib/utils'

module.exports =
  getStatistic: (id, params, type) =>
    setTimeout(
      ->
        Dispatcher.dispatch type: ActionTypes.AGENT_STATISTIC_REQUESTING

        queryParams = "?#{buildQueryParamsString params}"
        window.history.replaceState null, null, queryParams
        
        msg = "An error occurred while fetch #{type} statistic"

        ProfileStatisticAPI.get(
          {id, params, type}
          (json) ->
            if json.success
              rawPages = json.statistic.total / params.per_page
              pages = Number.parseInt rawPages
              pages += 1 if pages < rawPages
              Dispatcher.dispatch
                type: ActionTypes.AGENT_STATISTIC_SUCCESS,
                payload:
                  statistic: json.statistic.data
                  pageCount: pages
            else
              console.error msg
              Dispatcher.dispatch type: ActionTypes.AGENT_STATISTIC_FAIL, payload: msg
          (response) ->
            if response
              { errors } = response.responseJSON || {}
              console.error msg, errors
              Dispatcher.dispatch type: ActionTypes.AGENT_STATISTIC_FAIL, payload: msg
        )
      10
    )

  clearStore: ->
    Dispatcher.dispatch type: ActionTypes.AGENT_STATISTIC_CLEAR

  changeShiftStatistic: (userId, shiftId, buildedField, newValue, changedField) ->
    msg = "An error occurred while update shift statistic"

    Dispatcher.dispatch type: ActionTypes.AGENT_STATISTIC_UPDATE_REQUESTING
    data = {userId, id: shiftId, "#{buildedField}": newValue}
    ProfileStatisticAPI.update(
      data
      ({success}) ->
        if success
          Dispatcher.dispatch
            type: ActionTypes.AGENT_STATISTIC_UPDATE_SUCCESS
            payload:
              changedField: changedField
              value: newValue
              id: shiftId
        else
          Dispatcher.dispatch
            type: ActionTypes.AGENT_STATISTIC_UPDATE_FAIL
            payload: msg
      (response) ->
        if response
          { errors } = response.responseJSON || {}
          console.error msg, errors
          Dispatcher.dispatch
            type: ActionTypes.AGENT_STATISTIC_UPDATE_FAIL
            payload: msg
    )
