ConversationsStore = require './abstract/ConversationsStore'
ActionTypes = require '../constants/ActionTypes'

class ConversationsAllStore extends ConversationsStore
  constructor: ->
    super()
    @registerActions()

  getActions: =>
    "#{ActionTypes.CONVERSATIONS_FILTER_AGENT}": @onAgentChange
    "#{ActionTypes.CONVERSATIONS_ALL_GET_LIST_REQUESTING}": @onSearchInit
    "#{ActionTypes.CONVERSATIONS_ALL_GET_LIST_SUCCESS}": @onSearchSuccess
    "#{ActionTypes.CONVERSATIONS_ALL_GET_LIST_FAILURE}": @onSearchError
    "#{ActionTypes.CONVERSATIONS_ALL_SEARCH_RESET}": @onSearchReset
    "#{ActionTypes.CONVERSATIONS_SET_DEFAULT_ALL_STATE}": @onSetDefaultState
    "#{ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_REQUEST}": @onConversationsRequest
    "#{ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING_INITIAL}": @onConversationsRequest
    "#{ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING}": @onConversationsUpdating

  onAgentChange: (agentId) =>
    @state.agentId = agentId

module.exports = new ConversationsAllStore()
