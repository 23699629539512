propTypes      = require 'prop-types'
BaseComponent  = require '../BaseComponent.cjsx'
styles         = require './selector'

R              = require 'ramda'
classnames     = require 'classnames'
onClickOutside = require 'react-onclickoutside'

Isvg = require 'react-inlinesvg'
icons = {
  search: require '!file-loader!./search.svg'
}

# Main component
class SelectorComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    values: propTypes.arrayOf(propTypes.object)
    options: propTypes.arrayOf(propTypes.object)
    placeholder: propTypes.string

  @defaultProps:
    placeholder: 'Input text...'

  getState: =>
    isSelected: false
    inputText: ''

  disableSelect: =>
    @setState isSelected: false

  actualOptions: =>
    sortOpts = (v) =>
      v.label.includes(@state.inputText)

    options = R.map(((v) => R.pick(['label', 'value'], v)), @props.options)
    values = R.map(((v) => R.pick(['label', 'value'], v)), @props.values)

    R.compose(
      R.filter(sortOpts, R.__)
      R.without(values, R.__)
    )(options)

  onClick: =>
    @setState isSelected: true

  onSearch: (e) =>
    @setState inputText: e.target.value
    e.preventDefault()

  addValue: (value) =>
    @setState inputText: ''
    @props.onChange(R.append(value, @props.values))

  addCustomOption: (value) =>
    @setState inputText: ''
    @props.onCustomTagAdd(value.replace(/ /g, '_'))

  removeValue: (value) =>
    @props.onChange(R.without([value], @props.values))

  render: =>
    actualOptions = @actualOptions()

    React.createElement("div", {"className": (classnames(styles.input_wrapper, styles.selected if @state.isSelected))},
      React.createElement(InputComponent, { \
        "actualOptions": actualOptions,  \
        "isSelected": @state.isSelected,  \
        "onClick": @onClick,  \
        "addValue": @addValue,  \
        "addCustomOption": @addCustomOption,  \
        "disableSelect": @disableSelect,  \
        "onSearch": @onSearch,  \
        "placeholder": @props.placeholder,  \
        "value": @state.inputText,  \
        "underinput": (if @props.underinput then true else false)
      }),
      React.createElement("div", {"className": (styles.values)},
        ( for value, i in @props.values
          React.createElement("div", {"className": (styles.value), "key": (i), "onClick": (R.partial(@removeValue, [value])), "title": (value.label)},
            React.createElement("span", null, "#", (value.label)),
            React.createElement("span", {"className": (styles.close)})
          )
        )
      )
    )

# Stupid components
class InputComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  handleClickOutside: =>
    @props.disableSelect()

  onKeyDown: (e) =>
    if e.keyCode == 27 #esc
      @props.disableSelect()
      document.activeElement.blur() # to remove the cursor
    if e.keyCode == 13 #enter
      @props.addCustomOption(@props.value)

  render: =>
    React.createElement("div", null,
      ( if @props.value.length != 0
        React.createElement("div", {"className": (styles.add), "onClick": (R.partial(@props.addCustomOption, [@props.value]))})
      ),
      ( if @props.isSelected && @props.actualOptions && @props.actualOptions.length > 0
        React.createElement("div", {"className": styles.options_wrapper},
          React.createElement("div", {"className": (if @props.underinput then styles.options_underinput else styles.options)},
            ( for value, i in @props.actualOptions
              React.createElement("div", {"className": styles.option, "key": i, "onClick": (R.partial(@props.addValue, [value])), "title": value.label}, (value.label))
            )
          )
        )
      ),
      React.createElement("div", {"className": (styles.selector)},
        React.createElement("span", {"className": (styles.icon)},
          React.createElement(Isvg.default, {"src": (icons.search)})
        ),
        React.createElement("input", { \
          "value": @props.value,  \
          "className": (classnames(styles.input, styles.selected if @props.isSelected)),  \
          "placeholder": @props.placeholder,  \
          "onChange": @props.onSearch,  \
          "onKeyDown": @onKeyDown,  \
          "onClick": @props.onClick
        })
      )
    )

module.exports = onClickOutside.default(SelectorComponent)
