config = require '../config'
{ underscoreParams } = require '../lib/utils'

module.exports =
  create: (attributes, success, error) ->
    $.ajax {
      url: config.urls.tagTargets.create
      data: JSON.stringify(underscoreParams(attributes))
      contentType: 'application/json'
      type: 'POST'
      success
      error
    }

  createByName: (targetType, targetId, tagName, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.tagTargets.create
      data: JSON.stringify(underscoreParams({targetType, targetId, tagName}))
      contentType: 'application/json'
      type: 'POST'
      successCallback
      errorCallback

  destroy: (scope, success, error) ->
    $.ajax {
      url: config.urls.tagTargets.destroy
      data: underscoreParams(scope)
      type: 'DELETE'
      success
      error
    }

  createOrder: (attributes, success, error) ->
    $.ajax {
      url: config.urls.tagTargets.createOrder
      data: JSON.stringify(underscoreParams(attributes))
      contentType: 'application/json'
      type: 'POST'
      success
      error
    }

  destroyOrder: (scope, success, error) ->
    $.ajax {
      url: config.urls.tagTargets.destroyOrder
      data: underscoreParams(scope)
      type: 'DELETE'
      success
      error
    }
