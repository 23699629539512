BaseStore = require '../BaseStore'
ActionTypes = require '../../constants/ActionTypes'

class ConversationsStore extends BaseStore
  constructor: ->
    super()
    @conversations = []
    @conversationsHash = {}
    @activeConversations = []
    @state =
      errors: null
      loading: false
      searchEnabled: false

  _registerToActions: ({ type, payload }) =>
    actions = @getActions()
    actions[type].call(@, payload) if actions[type]

  onSearchInit: =>
    @state.loading = true
    @state.errors = null
    @state.searchEnabled = true
    @emitChange()

  onSearchSuccess: (response) =>
    @state.loading = false
    @state.errors = null
    @conversations = response.conversations
    @emitChange()

  onSearchError: (response) =>
    @state.loading = false
    @errors = response.error || 'Unknown error'
    @emitChange()

  onSearchReset: =>
    @state.loading = false
    @state.searchEnabled = false
    @conversations = @activeConversations
    @emitChange()

  onSetDefaultState: =>
    @state.loading = false
    @state.searchEnabled = false
    @state.errors = null
    @conversations = @activeConversations
    @emitChange()

  onConversationsRequest: () ->
    if !@state.searchEnabled
      @state.loading = true
      @activeConversations = []
      @conversationsHash = {}
      @conversations = []
      @emitChange()

  onConversationsUpdating: (payload) ->
    Object.keys(payload.message).forEach (id) =>
      if payload.message[id]
        @conversationsHash[id] ||= {}
        Object.keys(payload.message[id]).forEach (key) =>
          @conversationsHash[id][key] = payload.message[id][key]
        delete @conversationsHash[id] unless @conversationsHash[id].id
      else
        delete @conversationsHash[id]
    @activeConversations = Object.keys(@conversationsHash).map (key) =>
      @conversationsHash[key]
    if !@state.searchEnabled
      @conversations = @activeConversations
      @state.loading = false if payload.initial
      @emitChange()

module.exports = ConversationsStore
