styles = require './blacklisted-warning-modal.styl'
{ config } = require('@verdaccio/crminfo')
crmConfig = config

clientLink = (clientId) ->
  if crmConfig.a5Host then "#{crmConfig.a5Host}/clients/#{clientId}" else '#'

module.exports = (props) ->
  React.createElement("div", {"className": (styles.customer_row)},
    (
      if props.customersIds[props.customer._id]
        React.createElement("a", { \
          "target": "_blank",  \
          "href": ("/customers/#{props.customersIds[props.customer._id]}")
        },
          (props.customer.name || props.customer.email)
        )
      else
        React.createElement("a", { \
          "target": ("_blank" if crmConfig.a5Host),  \
          "href": (clientLink(props.customer._id))
        },
          (props.customer.name || props.customer.email)
        )
    )
  )
