# TagsAPI      = require '../api/TagsAPI'
Dispatcher   = require '../dispatcher/Dispatcher'
ActionTypes  = require '../constants/ActionTypes'

ConversationTemplatesActionCreator =
  setTemplate: (state) ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATION_TEMPLATES_SET_TEMPLATE, payload: state
  clearTemplate: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATION_TEMPLATES_CLEAR_TEMPLATE

module.exports = ConversationTemplatesActionCreator
