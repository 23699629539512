classnames = require 'classnames'
ReactPaginate = require 'react-paginate'
BaseComponent = require '../../BaseComponent'
styles = require './base-agent-statistic'
LoadableWrapperComponent = require '../LoadableWrapperComponent/LoadableWrapperComponent'
paginationStyles = require '../styles/pagination.styl'
AgentStatisticStore = require 'stores/AgentStatisticStore'
AuthStore = require 'stores/AuthStore'
{ QA_DASHBOARD_STATISTIC } = require 'constants/Agents'

class BaseAgentStatisticComponent extends BaseComponent
  dependsOnStores: [AgentStatisticStore, AuthStore]

  constructor: (props) ->
    super(props)
    @toggleOrder =
      asc: 'desc'
      desc: 'asc'
    @state =
      orderBy: props.orderBy
      order: props.order

  componentWillReceiveProps: (props) ->
    @setState
      orderBy: props.orderBy
      order: props.order

  onOrderChange: (orderBy) ->
    if orderBy == @state.orderBy
      @onOrderChangeHandler orderBy, @toggleOrder[@state.order]
    else
      @onOrderChangeHandler orderBy, 'asc'

  render: ->
    tableFields = @tableFields
    rowStyle = @rowStyle
    data = null
    that = @
    {orderBy, order} = @state
    if @storeData.error
      data = React.createElement("p", {"className": (classnames styles.message, styles.error)},
        (@storeData.error)
      )
    else
      if @storeData.statistic.length
        data = React.createElement("div", null,
          React.createElement("table", {"className": (styles.head)},
            React.createElement("thead", null,
              (if @storeData.total
                React.createElement("tr", {"key": (1)},
                  (Object.keys tableFields
                    .map (key, i) =>
                      React.createElement("td", { \
                        "key": (i),  \
                        "className": (classnames(
                          styles.head__element
                          tableFields[key].addHeadCss
                        ))
                      },
                        (@storeData.total[i])
                      )
                  )
                )
              ),
              (if @storeData.agentTotal && QA_DASHBOARD_STATISTIC[AuthStore.user.role]
                React.createElement("tr", {"key": (2)},
                  (Object.keys tableFields
                    .map (key, i) =>
                      React.createElement("td", { \
                        "key": (i),  \
                        "className": (classnames(
                          styles.head__element
                          tableFields[key].addHeadCss
                        ))
                      },
                        (@storeData.agentTotal[i])
                      )
                  )
                )
              ),
              (if @storeData.newbieAgentTotal && QA_DASHBOARD_STATISTIC[AuthStore.user.role]
                React.createElement("tr", {"key": (3)},
                  (Object.keys tableFields
                    .map (key, i) =>
                      React.createElement("td", { \
                        "key": (i),  \
                        "className": (classnames(
                          styles.head__element
                          tableFields[key].addHeadCss
                        ))
                      },
                        (@storeData.newbieAgentTotal[i])
                      )
                  )
                )
              ),
              React.createElement("tr", {"key": (4)},
                (Object.keys tableFields
                  .map (key, i) ->
                    React.createElement("td", { \
                      "key": (i),  \
                      "className": (classnames(
                        styles.head__element
                        tableFields[key].addHeadCss
                      )),  \
                      "onClick": (that.onOrderChange.bind(that, key) if tableFields[key].isSortable)
                    },
                      (tableFields[key].label),
                      (if tableFields[key].isSortable
                        [
                          React.createElement("span", { \
                            "className": (classnames(
                              styles.arrow
                              "#{styles.arrow__up}": orderBy != key || orderBy  == key && order == 'asc'
                            )),  \
                            "key": (1)
                          }),
                          React.createElement("span", {"className": (classnames(
                              styles.arrow
                              "#{styles.arrow__down}": orderBy != key || orderBy  == key && order == 'desc'
                            )),  \
                          "key": (2)
                          })
                        ]
                      )
                    )
                )
              )
            ),
            React.createElement("tbody", null,
              (@storeData.statistic.map (row, i) ->
                additionStyle = if rowStyle then rowStyle row else null

                React.createElement("tr", { \
                  "key": (i),  \
                  "className": (classnames styles.body__row, 'row', additionStyle),  \
                  "data-value": (JSON.stringify row.conversations_info)
                },
                  (Object.keys tableFields
                    .map (key, i) ->
                      classNames = styles['body__row-element']
                      if tableFields[key].addBodyCss
                        classNames = classnames(
                          styles['body__row-element']
                          tableFields[key].addBodyCss(row[key])
                        )

                      React.createElement("td", { \
                        "key": (i),  \
                        "onMouseEnter": (tableFields[key].handlers.onMouseEnter),  \
                        "onMouseLeave": (tableFields[key].handlers.onMouseLeave),  \
                        "onClick": (tableFields[key].handlers.onClick),  \
                        "className": (classNames)
                      },
                        (tableFields[key].presenter row[key], row)
                      )
                  )
                )
              )
            )
          ),
          React.createElement("div", {"ref": "strikedPopup", "className": (styles['content__strikes-popup'])},
            React.createElement("p", {"className": (styles['content__strikes-popup-title'])}, """
              Strikes
""", React.createElement("span", { \
                "ref": "strikedHeader",  \
                "className": (styles['content__strikes-popup-title_data'])
              })
            ),
            React.createElement("div", { \
              "ref": "strikedBody",  \
              "className": (styles['content__strikes-popup-body'])
            })
          )
        )
      else
        data = React.createElement("p", {"className": (styles.message)}, "No data yet")
    React.createElement("div", {"className": (styles.content)},
      React.createElement(LoadableWrapperComponent, {"loading": (@storeData.loading)},
        (data)
      ),
      (if @pageCount > 1
        React.createElement("div", {"className": (styles.body__pagination)},
          React.createElement(ReactPaginate.default, { \
            "previousLabel": 'previous',  \
            "nextLabel": 'next',  \
            "pageCount": (@pageCount),  \
            "marginPagesDisplayed": (2),  \
            "pageRangeDisplayed": (4),  \
            "onPageChange": (@onPageChange),  \
            "initialPage": (@defaultPage),  \
            "pageClassName": (paginationStyles.page),  \
            "nextClassName": (paginationStyles.page),  \
            "previousClassName": (paginationStyles.page),  \
            "activeClassName": (paginationStyles.active),  \
            "pageLinkClassName": (paginationStyles.link),  \
            "nextLinkClassName": (paginationStyles.link),  \
            "previousLinkClassName": (paginationStyles.link),  \
            "containerClassName": (paginationStyles.container),  \
            "breakClassName": (paginationStyles.page)
          })
        )
      )
    )

module.exports = BaseAgentStatisticComponent
