PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
SLATrackingActionCreator = require './SLATrackingActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.SLA_TRACKING_REQUESTING}": ->
    @loading = true
    @statistic = null
    @error = null
    @emitChange()
  "#{ActionTypes.SLA_TRACKING_SUCCESS}": (data) ->
    @loading = false
    @statistic = data
    @emitChange()
  "#{ActionTypes.SLA_TRACKING_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

class SLATrackingStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @statistic = null
    @error = null
    @registerActions()

  getActionCreator: -> new SLATrackingActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = SLATrackingStore
