BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.AGENTS_GET_LIST_REQUESTING}": ->
    @loading = true
    @emitChange()
  "#{ActionTypes.AGENTS_GET_LIST_SUCCESS}": (payload) ->
    @loading = false
    @agents = payload.list
    @pageCount = payload.pageCount
    @emitChange()
  "#{ActionTypes.AGENTS_GET_LIST_FAIL}": ->
    @loading = false
    @emitChange()

class AgentListStore extends BaseStore
  constructor: ->
    super()
    @agents = []
    @pageCount = 0
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new AgentListStore()
