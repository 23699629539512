classnames = require 'classnames'

paginationStyles = require '../../shared/styles/pagination.styl'
Checkbox = require '../../shared/Checkbox'
styles = require './agent-list'

propTypes = require 'prop-types'

agentItemComponent = (props) ->
  {agent, onAgentClick} = props
  React.createElement("div", { \
    "className": (styles.item),  \
    "onClick": (->
      onAgentClick(agent.id, props.checked) if onAgentClick
    )
  },
    React.createElement("img", { \
      "className": (styles.avatar),  \
      "src": (agent.image || require '../../shared/images/unknown.png')
    }),
    React.createElement("span", {"className": (styles.agent_name)},
      ("#{agent.first_name} #{agent.last_name}")
    ),
    React.createElement(Checkbox, {"checked": (props.checked)})
  )

agentItemComponent.propTypes =
  agent: propTypes.shape({
    id: propTypes.number.isRequired
    first_name: propTypes.string.isRequired
    last_name: propTypes.string.isRequired
  }).isRequired
  checked: propTypes.bool
  onAgentClick: propTypes.func

module.exports = agentItemComponent
