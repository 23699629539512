moment        = require 'moment-timezone'
classnames = require 'classnames'

BaseComponent = require '../../BaseComponent'
styles = require './inline-timezone-input.styl'
propTypes = require 'prop-types'
Isvg = require 'react-inlinesvg'
Select = require '../Select'

propTypes = require 'prop-types'

clock = require("./clock.svg")

class InlineTimezoneInput extends BaseComponent
  @propTypes:
    className: propTypes.string
    value: propTypes.string,
    options: propTypes.array,
    onSubmit: propTypes.func.isRequired

  constructor: (props) ->
    super(props)
    @state =
      format: props.format || 'HH:mm (z)'
      options: props.options || moment.tz.names().map (tz) -> value: tz, label: tz
      timezone: props.value
    @initComponent()

  componentWillMount: =>
    @intervals = []

  componentWillUnmount: =>
    super()
    @intervals.forEach(clearInterval)

  componentDidMount: =>
    @setInterval(@tick, 60000)
    @tick()

  componentWillReceiveProps: (props) =>
    @state = _.extend @state,
      timezone: props.value
      value: moment(new Date()).tz(props.value)?.format(@state?.format || 'HH:mm (z)') || null
    @intervals.forEach(clearInterval)
    @intervals = []
    @setInterval(@tick, 60000)
    @tick()

  setInterval: =>
    @intervals.push(setInterval.apply(null, arguments))

  onClick: =>
    @setState editing: true

  onBlur: =>
    @setState editing: false
    @props.onSubmit(@state.timezone)

  onChange: (newOption) =>
    newTimezone = newOption?.value
    if newTimezone == undefined
      @props.onSubmit(@state.timezone)
      @intervals.forEach(clearInterval)
      newTimezone = null
    @setState
      editing: false
      timezone: newTimezone
      value: if newTimezone == null then undefined else @state.value

    _.defer => @setState editing: false
    _.defer => @tick()
    @props.onSubmit(newTimezone)

  tick: =>
    if @state.timezone
      @setState
        value: moment(new Date()).tz(@state.timezone).format(@state.format)

  render: =>
    className = classnames(
      @props.className, styles.container
      "#{styles.editing}": @state.editing
    )
    selectedValue = @state.options.find (option) =>
      option.value == @state.timezone

    React.createElement("div", {"className": ( className ), "onClick": ( @onClick )},
      (
        @state.editing &&
          React.createElement(Select.default, { \
            "value": ( selectedValue ),  \
            "onBlur": ( @onBlur ),  \
            "options": ( @state.options ),  \
            "onChange": ( @onChange ),  \
            "className": ( styles.input ),  \
            "placeholder": "",  \
            "autoFocus": true,  \
            "menuIsOpen": true
            })
      ),
      (
        !@state.editing && @state.value &&
          React.createElement("span", {"className": (classnames( styles.value, styles.svg))}, React.createElement(Isvg.default, {"src": (clock)}), ( @state.value ), " ", (if @props.auto_timezone then "(Auto)" else ""))
      ),
      (
        !@state.editing && !@state.value &&
          React.createElement("span", {"className": (classnames( styles.value, styles.svg))}, React.createElement(Isvg.default, {"src": (clock)}), "Unknown time zone")
      )
    )

module.exports = InlineTimezoneInput
