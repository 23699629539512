browserHistory = require './../../../history'

styles = require './modal'

class ModalComponent extends React.Component
  close: ->
    browserHistory.default.goBack()

  stopPropagation: (e) ->
    e.stopPropagation()

  render: ->
    React.createElement("div", {"className": styles.overlay, "onClick": @close},
      React.createElement("div", {"className": styles.modal, "onClick": @stopPropagation},
        ( @props.children )
      )
    )

module.exports = ModalComponent
