BaseComponent = require 'components/BaseComponent'
CustomerFormComponent = require './CustomerFormComponent'
CustomersStore = require 'stores/CustomersStore'
CustomersActionCreator = require 'actions/CustomersActionCreator'

styles = require './customer-form'

class CustomerFormWrapper extends BaseComponent
  constructor: (props) ->
    super(props)
    if props.new
      @customerId = 'new'
    else
      @customerId = parseInt props.match.params.id, 10

    @state = {
      loading: false
      customer: {}
    }
    @initComponent()

  dependsOnStores: [CustomersStore]

  getState: ->
    customer = {}
    customer = CustomersStore.customer || {} unless @customerId == 'new'

    {
      loading: CustomersStore.customerLoading || CustomersStore.updateLoading
      customer
    }

  componentDidMount: ->
    super()
    if @customerId && @customerId != 'new'
      customerId = @customerId
      setTimeout(
        -> CustomersActionCreator.get(customerId, { extended: true })
        0
      )

  componentWillReceiveProps: (newProps) =>
    if newProps.new
      customerId = 'new'
      @customerId = customerId
    else
      if newProps.match
        customerId = parseInt newProps.match.params.id, 10
        if @customerId != customerId
          @customerId = customerId
          setTimeout(
            -> CustomersActionCreator.get(customerId, { extended: true })
            10
          )

  render: ->
    React.createElement("div", {"className": (styles.wrapper)},
      React.createElement(CustomerFormComponent, { \
        "customer": (if @props.new then {} else @state.customer),  \
        "loading": (@state.loading),  \
        "key": (@customerId),  \
        "id": (@customerId),  \
        "new": (@props.new || false),  \
        "lastConversationId": (@state.customer.last_conversation_id),  \
        "tags": (@state.customer.tags || [])
      })
    )

module.exports = CustomerFormWrapper
