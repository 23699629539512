confirmable = require './confirmable'
ConfirmDialog = require './ConfirmDialog'
createConfirmation = require './createConfirmation'

Dialog = ({ show, confirm, dismiss, cancel, confirmation, options }) =>
  React.createElement(ConfirmDialog, { \
    "show": (show),  \
    "onDissmis": (dismiss),  \
    "confirmationMessage": (confirmation),  \
    "onConfirm": (confirm),  \
    "onCancel": (cancel),  \
    "options": (options)
  })

confirm = createConfirmation(confirmable(Dialog))

confirmDialog = (confirmation, options = {}) ->
  confirm({ confirmation, options })

module.exports = confirmDialog
