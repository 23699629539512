PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
AgentChatsActionCreator = require './AgentChatsActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.AGENT_CHATS_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.AGENT_CHATS_SUCCESS}": (data) ->
    @loading = false
    @overload = data.overload.map (item) ->
      item.color = '#7cb5ec'
      item
    @chats_each_hour = data.chats_each_hour.map (item) ->
      item.color = '#7cb5ec'
      item
    @interval = data.interval
    @emitChange()
  "#{ActionTypes.AGENT_CHATS_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class AgentChatsStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @overload = null
    @chats_each_hour = null
    @interval = null
    @error = null
    @registerActions()

  getActionCreator: -> new AgentChatsActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = AgentChatsStore
