classnames = require 'classnames'
propTypes = require 'prop-types'

styles = require './scroll-style.styl'


class Scroll extends React.Component
  @propTypes:
    maxHeight: propTypes.string
    style: propTypes.object

    # todo
    initialPosition: propTypes.oneOf ['top', 'bottom']

  constructor: (props) ->
    super(props)

  render: ->
    style = @props.style || {}
    className = styles.innerContainer
    if !@props.maxHeight? || @props.maxHeight == 'auto'
      className = classnames(className, styles.heightAuto)
    else
      style.maxHeight = @props.maxHeight

    React.createElement("div", {"ref": "scroll", "className": className, "style": style},
      (this.props.children)
    )

module.exports = Scroll
