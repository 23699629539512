constants = require 'constants/base'

class UrlExtractor
  PART_TYPE_TEXT: 'text'
  PART_TYPE_URL: 'url'

  toParts: (text) ->
    matches = text.matchesWithIndex(constants.URL_REGEXP)
    textStart = 0
    parts = []

    for match, index in matches
      subtext = text.substring(textStart, match.position)
      parts.push(@textPart(subtext)) if @isTextPresent(subtext)
      parts.push(@urlPart(match.content, index))
      textStart = match.position + match.content.length + 1

    lastText = text.substring(textStart)
    parts.push(@textPart(lastText)) if @isTextPresent(lastText)
    parts

  isTextPresent: (text) ->
    text != '<br/>' && text != '</br>' && text.match(/^\s*$/) == null

  textPart: (value) ->
    { type: @PART_TYPE_TEXT, content: value }

  urlPart: (value) ->
    { type: @PART_TYPE_URL, content: value }


module.exports = new UrlExtractor()
