ActionTypes = require '../constants/ActionTypes'
ShiftsAPI = require '../api/ShiftsAPI'
Dispatcher = require '../dispatcher/Dispatcher'

ShiftsActionCreator =
  createTeamleadShift: ->
    Dispatcher.dispatch type: ActionTypes.CREATE_TEAMLEAD_SHIFT_REQUESTING
    ShiftsAPI.createTeamleadShift {},
      ({success}) ->
        if success
          Dispatcher.dispatch type: ActionTypes.CREATE_TEAMLEAD_SHIFT_SUCCESS
        else
          Dispatcher.dispatch type: ActionTypes.CREATE_TEAMLEAD_SHIFT_FAILURE
      (error) ->
        Dispatcher.dispatch type: ActionTypes.CREATE_TEAMLEAD_SHIFT_FAILURE

module.exports = ShiftsActionCreator
