emojione = require 'emojione'
{buildParagraphWithEmoji} = require '../../../lib/ConversationItemUtils'

propTypes = require 'prop-types'
createReactClass = require 'create-react-class'


module.exports = createReactClass(
  propTypes:
    text: propTypes.string.isRequired
    className: propTypes.string
    emojiSupport: propTypes.bool

  render: ->
    paragraphs = @props.text.split("\n")

    React.createElement("div", {"className": (@props.className)},
      (
        for paragraph, index in paragraphs
          if @props.emojiSupport
            paragraphWithEmoji = emojione.toImage paragraph
            if paragraphWithEmoji == paragraph
              React.createElement("div", {"key": (index), "className": (@props.className)}, (paragraph))
            else
              buildParagraphWithEmoji(
                paragraphWithEmoji, index, @props.className
              )
          else
            React.createElement("div", {"key": (index), "className": (@props.className)}, (paragraph))
      )
    )
)
