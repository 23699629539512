BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'

actionsMap =
  "#{ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_SUCCESS}": ({ messages, total }) ->
    @loading = false
    @uncompletedMessages = messages
    @totalUncompleted = total
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.SCHEDULED_MESSAGE_CREATE_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_CREATE_SUCCESS}": (message) ->
    @uncompletedMessages.unshift(message)
    @totalUncompleted += 1
    @loading = false
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_CREATE_FALIURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.SCHEDULED_MESSAGE_DESTROY_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_DESTROY_SUCCESS}": (messageId) ->
    @uncompletedMessages = @uncompletedMessages.filter (m) -> m.id != messageId 
    @totalUncompleted -= 1
    @loading = false
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_DESTROY_FALIURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.SCHEDULED_MESSAGE_UPDATE_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_UPDATE_SUCCESS}": (message) ->
    @uncompletedMessages = @uncompletedMessages.map (m) ->
      return Object.assign(m, message) if m.uuid == message.uuid
      m
    @loading = false
    @emitChange()
  "#{ActionTypes.SCHEDULED_MESSAGE_UPDATE_FALIURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

class ScheduledMessagesStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @uncompletedMessages = []
    @totalUncompleted = 0
    @error = null
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new ScheduledMessagesStore
