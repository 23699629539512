ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'

class LandingsStore extends BaseStore
  constructor: ->
    super()
    @landings = []
    @loading = false
    @total = 0
    @error = null
    @registerActions()
  
  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.LANDINGS_GET_LIST_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.LANDINGS_GET_LIST_SUCCESS
        @loading = false
        @landings = payload.landings
        @total = payload.total
        @emitChange()
      when ActionTypes.LANDINGS_GET_LIST_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.LANDING_CREATE_SUCCESS
        @landings.push(payload)
        @emitChange()

module.exports = new LandingsStore()
