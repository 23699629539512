GuidedActionCreator = require '../../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
DashboardAPI = require '../../../../api/DashboardAPI'

class PercentOfGoodRTAndWTActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.GOOD_RT_WT_REQUESTING

    DashboardAPI.getPercentOfGoodRTWT(
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.GOOD_RT_WT_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.GOOD_RT_WT_ERROR
            payload: json.error
      (response) =>
        msg = 'An error occurred while getting percent of good rt and wt statistic'
        @dispatch
          type: ActionTypes.LAST_DAYS_STAT_ERROR
          payload: msg
    )

module.exports = PercentOfGoodRTAndWTActionCreator