CSVAPI = require '../../api/CSVAPI'
GuidedActionCreator = require '../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../constants/ActionTypes'

class CSVExportActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.CSV_EXPORT_REQUESTING

    CSVAPI.get(
      (json) =>
        @dispatch type: ActionTypes.CSV_EXPORT_SUCCESS, payload: json
      (error) =>
        @dispatch 
          type: ActionTypes.CSV_EXPORT_FAILURE 
          payload: 'An error occurred while generate csv links'
    )

module.exports = CSVExportActionCreator