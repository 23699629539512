import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../dashboard.styl';

const ErrorTabs = () => {
  return (
    <ul className={styles.tabs}>
      <li>
        <NavLink
          className={styles.tab}
          activeClassName={styles.tab_active}
          to="/dashboard/message_errors"
        >
          Data
        </NavLink>
      </li>
      <li>
        <NavLink
            className={styles.tab}
            activeClassName={styles.tab_active}
            to="/dashboard/message_errors_glossary"
          >
          Errors glossary
        </NavLink>
      </li>
    </ul>
  )
};

export default ErrorTabs;
