import React from 'react';
import ReactSelect from 'react-select';
import classnames from 'classnames';

const classNamePrefix = 'ReactSelect';

const styles = {
  control: (style, state) => {
    const result = {
      ...style,
      backgroundColor: '#fff',
      color: '#333',
      cursor: 'default',
      minHeight: 36,
      height: 36,
      outline: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#e5e9ed',
      borderRadius: '3px',
      boxShadow: 'none',
      '&:hover': {
        ...style['&:hover'],
        borderColor: '#e5e9ed',
      }
    };
    return result;
  },
  menu: (style, state) => {
    const result = {
      ...style,
      marginTop: 0,
      marginBottom: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    };
    return result;
  },
  option: (style, state) => {
    const result = {
      ...style,
      backgroundColor: state.isFocused ? '#DEEBFF' : 'transparent',
      color: 'inherit',
    };
    return result;
  },
};
//border: 1px solid #e5e9ed;
const Select = (props) => {
  const newProps = {
    ...props,
    className: classnames(props.className, classNamePrefix),
    classNamePrefix,
    styles,
    components: { IndicatorSeparator: null, ...props.components }
  };
  if (props.isTop) {
    newProps.menuPlacement = "top"
  }
  return <ReactSelect {...newProps} />;
};

export default Select;
