ReplyAnswersAPI = require 'api/ReplyAnswersAPI'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'

module.exports =
  getGroups: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_GET_REQUESTING
    ReplyAnswersAPI.getGroups(
      data
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_GET_SUCCESS
          payload: response
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_GET_FAILURE
          payload: 'An error occurred while initialization reconnection process. Please, try again or ask your administrator for help.'
    )
  
  getGroupsMore: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_MORE_GET_REQUESTING
    ReplyAnswersAPI.getGroups(
      data
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_MORE_GET_SUCCESS
          payload: response
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_MORE_GET_FAILURE
          payload: 'An error occurred while initialization reconnection process. Please, try again or ask your administrator for help.'
    )
  
  resetLastGroup: () ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_RESET

  createGroup: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_CREATE_REQUESTING
    ReplyAnswersAPI.createGroup(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_CREATE_SUCCESS
            payload: json.reply_group
        else
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_CREATE_FAILURE
            payload: json.error
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_CREATE_FAILURE
          payload: 'An error occurred while creating new group'
    )

  updateGroup: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_UPDATE_REQUESTING
    ReplyAnswersAPI.updateGroup(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_UPDATE_SUCCESS
            payload: json.reply_group
        else
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_UPDATE_FAILURE
            payload: json.error
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_UPDATE_FAILURE
          payload: 'An error occurred while creating new group'
    )
  
  deleteGroup: (groupId, originalId) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_GROUPS_DELETE_REQUESTING
    ReplyAnswersAPI.deleteGroup(
      groupId
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_DELETE_SUCCESS
            payload:
              groupId: groupId
              originalId: originalId
        else
          Dispatcher.dispatch
            type: ActionTypes.REPLY_GROUPS_DELETE_FAILURE
            payload: json.error
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_GROUPS_DELETE_FAILURE
          payload: 'An error occurred while delete group'
    )

  createAnswer: (data, onSuccessCallback) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_CREATE_REQUESTING
    ReplyAnswersAPI.createAnswer(
      data
      (json) ->
        onSuccessCallback?(json)
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_CREATE_SUCCESS
          payload: json
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_CREATE_FAILURE
          payload: 'An error occurred while creating answer'
    )

  getAnswersByGroup: (groupId, data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_GET_REQUESTING
    ReplyAnswersAPI.getAnswers(
      groupId
      data
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_SUCCESS
          payload: json
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_FAILURE
          payload: 'An error occurred while getting answers'
    )

  getFavoriteAnswers: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_GET_REQUESTING
    ReplyAnswersAPI.getFavoriteAnswers(
      data
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_SUCCESS
          payload: json
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_FAILURE
          payload: 'An error occurred while getting answers'
    )

  searchAnswers: (query) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_GET_REQUESTING
    ReplyAnswersAPI.searchAnswers(
      query
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_SUCCESS
          payload: json
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_GET_FAILURE
          payload: 'An error occurred while getting answers'
    )

  onToggleVisibleAnswer: (answerId, data, onSuccessCallback) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_VISIBILITY_REQUESTING
    ReplyAnswersAPI.onToggleVisibleAnswer(
      answerId
      data
      (json) ->
        onSuccessCallback?()
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_VISIBILITY_SUCCESS
          payload: {
            answerId
            status: json.status
          }
          
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_VISIBILITY_FAILURE
          payload: 'An error occurred while deletting answer'
    )

  deleteAnswer: (answerId, onSuccessCallback) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_DELETE_REQUESTING
    ReplyAnswersAPI.deleteAnswer(
      answerId
      (json) ->
        onSuccessCallback?()
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_DELETE_SUCCESS
          payload: answerId
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_DELETE_FAILURE
          payload: 'An error occurred while deletting answer'
    )

  updateAnswer: (data) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_UPDATE_REQUESTING
    ReplyAnswersAPI.updateAnswer(
      data
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_UPDATE_SUCCESS
          payload: json
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_UPDATE_FAILURE
          payload: 'An error occurred while updating answer'
    )

  toggleFavorite: (answerId) ->
    Dispatcher.dispatch type: ActionTypes.REPLY_ANSWERS_FAVORITE_REQUESTING
    ReplyAnswersAPI.toggleFavorite(
      answerId
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_FAVORITE_SUCCESS
          payload: {
            answerId
            is_favorite: json.favorite
          }
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.REPLY_ANSWERS_FAVORITE_FAILURE
          payload: 'An error occurred while updating answer'
    )
