SettingsAPI = require 'api/SettingsAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

SettingsActionCreator =
  get: (page, per_page) ->
    Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_REQUESTING
    SettingsAPI.get(
      {page, per_page}
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.SETTINGS_GET_SUCCESS
            payload: json.data
        else
          Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_FAILURE, payload: json.error
      (error) -> Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_FAILURE, payload: error
    )

  update: (attributes, successCallback, errorCallback) ->
    Dispatcher.dispatch type: ActionTypes.SETTINGS_UPDATE_REQUESTING, payload: {attributes}
    SettingsAPI.update attributes,
      (json) ->
        if json.success
          Dispatcher.dispatch type: ActionTypes.SETTINGS_UPDATE_SUCCESS, payload: json.data
          successCallback?()
        else
          Dispatcher.dispatch type: ActionTypes.SETTINGS_UPDATE_FAILURE, payload: json.errors
      (data) ->
        Dispatcher.dispatch type: ActionTypes.SETTINGS_UPDATE_FAILURE, payload: data.responseJSON
        errorCallback?(data.responseJSON.errors)

  getByType: (data, successCallback, errorCallback) ->
    Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_BY_TYPE_REQUESTING
    SettingsAPI.getByType(
      data
      (json) ->
        if json.success
          Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_BY_TYPE_SUCCESS, payload: json.data
          successCallback?(json.data)
        else
          Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_BY_TYPE_FAILURE, payload: json.error
      (data) ->
        Dispatcher.dispatch type: ActionTypes.SETTINGS_GET_BY_TYPE_FAILURE, payload: data.responseJSON
        errorCallback?(data.responseJSON.errors)
    )

module.exports = SettingsActionCreator
