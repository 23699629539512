browserHistory = require './../../../history'
moment = require 'moment'

BaseComponent = require 'components/BaseComponent'
AuthStore = require 'stores/AuthStore'
AgentsSelectorComponent = require 'components/shared/AgentsSelectorComponent/AgentsSelectorComponent'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
Calendar = require 'components/shared/CalendarComponent'
RemindersComponent = require 'components/admin/RemindersComponent'
ScheduledMessagesComponent = require 'components/admin/ScheduledMessagesComponent'
CustomerioEventsComponent = require 'components/admin/CustomerioEventsComponent'
AgentListStore = require 'stores/AgentListStore'
{ ROLES } = require 'constants/Agents'
{ menus } = require 'root/config'
{ getQueryParams, buildQueryParamsString } = require 'lib/utils'
styles = require './admin-wrapper-component.styl'
Select = require '../../shared/Select'

DEFAULT_URL = '/admin/reminders'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'admin'
    accessConfig =
      roles: item.accessToPage

componentsMap =
  reminders: RemindersComponent
  scheduled_messages: ScheduledMessagesComponent
  customerio_events: CustomerioEventsComponent

links = [
  {url: '/admin/reminders', name: 'Reminders', key: 'reminders'}
  {url: '/admin/scheduled_messages', name: 'Scheduled Messages', key: 'scheduled_messages'}
  {url: '/admin/customerio_events', name: 'Customerio Events', key: 'customerio_events'}
]

class AdminWrapperComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1
    dateRange = if params.begin_date then {begin_date: params.begin_date, end_date: params.end_date} else {}
    agent = if params.user_id then {user_id: params.user_id} else {}
    @state =
      agent: agent
      calendarType: 'daily'
      dateRange: dateRange
      search_query: params.search_query || ''
    @initComponent()

  dependsOnStores: [AuthStore]

  componentWillMount: =>
    if accessConfig.roles.indexOf(ROLES[AuthStore.user.role].value) == -1
      return setTimeout(
        -> browserHistory.default.push '/'
        10
      )
    if !componentsMap[@props.match.params.type]
      return setTimeout(
        -> browserHistory.default.push DEFAULT_URL
        10
      )

  componentDidMount: =>
    @setQueryParams() if @props.match.params.type != 'customerio_events'

  setQueryParams: () =>
    queryObject = Object.assign(
      {}
      @state.agent
      @state.dateRange
      {
        timezone: moment.tz.guess()
        search_query: window.encodeURI @state.search_query
      }
    )
    queryString = "?#{buildQueryParamsString queryObject}"
    window.history.replaceState null, null, queryString

  newDateRangeHandler: (newRange) =>
    if newRange.startDate
    then newDates =
      begin_date: newRange.startDate.format 'YYYY-MM-DD'
      end_date: newRange.endDate.format 'YYYY-MM-DD'
    else newDates = {}
    @setState(
      {dateRange: newDates}
      @setQueryParams
    )

  onChangeTypeHandler: (type) =>
    @setState
      calendarType: type

  onAgentChange: (agentId) =>
    agent = if agentId then { user_id: agentId } else {}
    @setState(
      { agent: agent }
      @setQueryParams
    )

  onSearchChange: (value) =>
    @setState(
      { search_query: value.toLowerCase() }
      @setQueryParams
    )

  onSearchClear: =>
    @setState(
      { search_query: '' }
      @setQueryParams
    )

  getTypeOptions: (links) ->
    return links.map (link) ->
      value: link.key
      label: link.name
      link: link.url

  onComponentTypeChange: (option) =>
    browserHistory.default.push option.link
    @setQueryParams() if option.value != 'customerio_events'

  getSelectValue: (type) =>
    options = @getTypeOptions(links)
    options.find (option) ->
      option.value == type

  render: =>
    { type } = @props.match.params
    Component = componentsMap[type]
    selectOption = @getSelectValue(type)

    (
      React.createElement("div", null,
        React.createElement("header", {"className": (styles.header)},
          React.createElement("div", null,
            React.createElement(Select.default, { \
              "className": (styles.component_selector),  \
              "backspaceRemovesValue": (false),  \
              "name": 'admin-components-type',  \
              "value": (selectOption),  \
              "options": (@getTypeOptions(links)),  \
              "onChange": (@onComponentTypeChange)
            })
          ),
          (if @props.match.params.type != 'customerio_events'
            React.createElement("div", null,
              React.createElement("div", {"className": (styles.agent_selector)}, """
                TODO
"""),
              React.createElement(SearchInputComponent, { \
                "placeholder": 'Content search',  \
                "defaultValue": (@state.search_query),  \
                "onChange": (@onSearchChange),  \
                "onClear": (@onSearchClear),  \
                "className": (styles.search_field),  \
                "inputClassName": (styles.search_field_input),  \
                "clearCross": true
              })
            )
          ),
          (if @props.match.params.type != 'customerio_events'
            React.createElement("div", null,
              React.createElement(Calendar, { \
                "beginDate": (@state.dateRange.begin_date),  \
                "endDate": (@state.dateRange.end_date),  \
                "onSelectHandler": (@newDateRangeHandler),  \
                "onChangeTypeHandler": (@onChangeTypeHandler),  \
                "type": (@state.calendarType),  \
                "isShiftsMode": true,  \
                "isUnlimit": true,  \
                "isResetable": true
              })
            )
          )
        ),
        React.createElement(Component, { \
          "agent": (@state.agent),  \
          "dateRange": (@state.dateRange),  \
          "search": (@state.search_query)
        })
      )
    )

module.exports = AdminWrapperComponent
