# handler for react-dnd in hashTags and answerTemplates components
standartHoverHandler = (props, monitor, component) ->
  dragIndex = monitor.getItem().index
  hoverIndex = props.index
  return if dragIndex == hoverIndex
  hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect()
  hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  clientOffset = monitor.getClientOffset()
  hoverClientY = clientOffset.y - hoverBoundingRect.top

  return if dragIndex < hoverIndex && hoverClientY < hoverMiddleY
  return if dragIndex > hoverIndex && hoverClientY > hoverMiddleY

  props.swapItems(dragIndex, hoverIndex)
  monitor.getItem().index = hoverIndex


trimTags = (item) =>
  rex = /(<([^>]+)>)/ig;
  item.text = item.text && item.text.replace(rex , "")
  item.name = item.name && item.name.replace(rex , "")
  return item


updateItemPositionAndItemsArray = (items, itemId) =>
    itemIndex = null
    # search element after it dropped
    item = items.find(
      (item, i) ->
        itemIndex = i if item.id == itemId
        item.id == itemId
    )

    trimTags(item)

    # calculate position for element in new place, by default order by position, so just find a average
    if itemIndex == 0
      newPosition = if !!items[1] then items[1].position - 1 else 1
    if itemIndex == items.length - 1
      newPosition = +items[items.length - 2]?.position + 1
    if itemIndex > 0 && itemIndex < items.length - 1
      newPosition = (+items[itemIndex - 1]?.position + +items[itemIndex + 1]?.position) / 2
    item.position = newPosition || items[itemIndex].position

    items.splice(itemIndex, 1, item)
    return { item, items }

module.exports = {
  standartHoverHandler,
  trimTags,
  updateItemPositionAndItemsArray
}
