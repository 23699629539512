Button = require 'components/shared/Button'

styles = require './edit-answer-form.styl'

module.exports = (props) ->
  React.createElement("form", {"className": (styles.form), "onSubmit": ((e) -> e.preventDefault())},
    React.createElement("input", { \
      "placeholder": 'Enter title here',  \
      "value": (props.name),  \
      "onChange": (props.onNameChange),  \
      "className": (styles.name),  \
      "type": 'text'
    }),
    React.createElement("textarea", { \
      "placeholder": 'Enter your answer here',  \
      "value": (props.text),  \
      "onChange": (props.onTextChange),  \
      "className": (styles.text)
    }),

    React.createElement("div", {"className": (styles.controls)},
      React.createElement(Button, { \
        "title": 'Cancel',  \
        "className": (styles.button),  \
        "onClick": (props.onEditCancel),  \
        "white": true
      }),
      React.createElement(Button, { \
        "title": (if props.creation then 'Add' else 'Save'),  \
        "className": (styles.button),  \
        "disabled": (!(props.name && props.text)),  \
        "onClick": (props.onEditSubmit)
      })
    )
  )