AgentsAPI = require '../api/AgentsAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

module.exports =
  getList: (params) ->
    AgentsAPI.getList(
      params
      (data) ->
        pageCount = Math.ceil(data.total / params.per_page)
        Dispatcher.dispatch
          type: ActionTypes.AGENTS_GET_LIST_SUCCESS
          payload:
            list: data.users
            pageCount: pageCount
    )

  setPage: (page) ->
    setTimeout ->
        Dispatcher.dispatch type: ActionTypes.AGENTS_LIST_SET_PAGE, payload: page
      10

  setSearchFilters: (params) ->
    setTimeout ->
      Dispatcher.dispatch
        type: ActionTypes.AGENTS_LIST_SET_SEARCH_FILTERS
        payload: params
      10
