classnames = require 'classnames'
styles = require './tooltip.styl'

module.exports = (props) ->
  {
    className,
    children,
    content,
    positionLeft,
    positionRight,
    positionAsideRight,
    widthMedium,
    positionDown,
    triggerZIndex,
  } = props
  conentClassName = classnames(styles.content, className, {
    "#{styles.position_left}": positionLeft,
    "#{styles.position_right}": positionRight,
    "#{styles.position_aside_right}": positionAsideRight,
    "#{styles.position_down}": positionDown,
    "#{styles.width_medium}": widthMedium,
  });
  React.createElement("div", {"className": (styles.container)},
    React.createElement("span", {"className": (classnames(styles.trigger, { "#{styles.trigger_z_index}": triggerZIndex })), "onMouseOver": (props.onMouseOver)},
      (children)
    ),
    (!!content &&
      React.createElement("span", {"className": (conentClassName)},
        (content)
      )
    )
  )
