ARROW_ELEMENT_HEIGHT = 50

calculateTopAndBottomPosition = (elementTopOffset, elementBottomOffset, scrollElement, maxScrollValue) ->
  newPosition = {}
  scrollClientHeight = scrollElement.clientHeight
  maxScrollValue -= ARROW_ELEMENT_HEIGHT
  newPosition.scrollBottom = elementBottomOffset - scrollElement.scrollTop - scrollClientHeight
  if newPosition.scrollBottom < 0
    newPosition.scrollBottom = 0
  if newPosition.scrollBottom > maxScrollValue
    newPosition.scrollBottom = maxScrollValue

  newPosition.scrollTop = scrollElement.scrollTop - elementTopOffset
  if newPosition.scrollTop < 0
    newPosition.scrollTop = 0
  if newPosition.scrollTop > maxScrollValue
    newPosition.scrollTop = maxScrollValue
  newPosition

module.exports = {
  calculateTopAndBottomPosition
}
