reactMixin = require 'react-mixin'
onClickOutside = require 'react-onclickoutside'
classnames = require 'classnames'

styles = require './assigner'
Scroll = require '../Scroll/Scroll'
AgentsAssignStore = require 'stores/AgentsAssignStore'
AgentListStore = require 'stores/AgentListStore'
OnlineStore = require 'stores/OnlineStore'
stylesLoader = require '../styles/loader.styl'
BaseComponent = require '../../BaseComponent'
AssignerPreviewComponent = require '../AssignerPreviewComponent/AssignerPreviewComponent'
{ toggleItemInList } = require '../../../lib/utils'
SearchInputComponent = require '../SearchInputComponent/SearchInputComponent'
AssignModuleMixin = require '../../mixin/AssignModuleMixin'
AgentListActionCreator = require 'actions/AgentListActionCreator'

propTypes = require 'prop-types'

class AssignerComponent extends BaseComponent
  @propTypes:
    initialSelectedAgentIds: propTypes.array
    handleClickOutside: propTypes.func
    multicheck: propTypes.bool
    highlightedAgentId: propTypes.array
    fastCallback: propTypes.func
    readonly: propTypes.bool
    reservedAgent: propTypes.number
    onAgentSelected: propTypes.func
    agentsClassName: propTypes.string

  constructor: (props) ->
    super(props)
    @state = R.merge @state,
      selectedAgentIds: props.initialSelectedAgentIds || []
      searchEnabled: false
    @fastCallbackWasUsed = false
    @initComponent()

  dependsOnStores: [AgentsAssignStore, OnlineStore, AgentListStore]

  useFastCallback: (agents, fastCallback) =>
    if agents.length && !@fastCallbackWasUsed && fastCallback
      @fastCallbackWasUsed = true
      fastCallback(agents[0].id)

  componentWillReceiveProps: (nextProps) ->
    agents = @handleAgents(nextProps)
    @setState({ agents })
    if !@props.fastCallback && nextProps.fastCallback
      @useFastCallback(agents, nextProps.fastCallback)

  toggleAgentSelection: (agentId) =>
    isNotInSelected = @state.selectedAgentIds.indexOf(agentId) == -1
    return if !@props.multicheck && @state.selectedAgentIds.length == 1 && isNotInSelected
    selectedAgentIds = toggleItemInList(agentId, @state.selectedAgentIds)
    @props.onAgentSelected(selectedAgentIds) if @props.onAgentSelected
    @setState { selectedAgentIds }

  handleClickOutside: (e) =>
    @props.handleClickOutside(e) if @props.handleClickOutside

  onChange: (value) =>
    if value
      AgentListActionCreator.getList
        search_query: value.toLowerCase()
        page: 0
        per_page: 30

      @setState { searchEnabled: true }
    else
      @onClear()

  onClear: =>
    @setState searchEnabled: false

  render: =>
    loading = AgentsAssignStore.loading || AgentListStore.loading
    loadingClass = if loading then 'loading' else ''
    agents = []
    if @state.searchEnabled
      agents = AgentListStore.agents
      for i in [0...agents.length]
        assign_agent = AgentsAssignStore.agents[agents[i].id]
        agents[i] = assign_agent if assign_agent
    else
      agents = @handleAgents(@props)
      @useFastCallback(agents, @props.fastCallback)

    React.createElement("div", {"className": (styles.container)},
      (
        if loading
          React.createElement("div", {"className": (stylesLoader.container)},
            React.createElement("img", { \
              "className": (stylesLoader.loader),  \
              "src": (require '../../../assets/images/loader.svg')
            })
          )
        else
          if @state.error
            React.createElement("div", {"className": 'error'}, """
              Can not connect to the server.
""", React.createElement("div", {"className": 'button', "onClick": (@tryAgain)}, """
                Try again
""")
            )
          React.createElement("div", {"className": (styles.data_container)},
            React.createElement(SearchInputComponent, { \
              "clearCross": (true),  \
              "className": (styles.search),  \
              "onChange": (@onChange),  \
              "onClear": (@onClear),  \
              "autofocus": true
            }),
            (
              if agents.length > 0
                React.createElement("div", { \
                  "className": (classnames(styles.agents_list, @props.agentsClassName))
                },
                  (
                    agents.map (agent, index) =>
                      return null if agent.removed
                      React.createElement(AssignerPreviewComponent, { \
                        "key": (agent.id),  \
                        "agent": (agent),  \
                        "onlineAgents": (OnlineStore.agents),  \
                        "onClick": (R.partial(@toggleAgentSelection, [agent.id]) unless @props.readonly),  \
                        "selected": (R.contains(agent.id, @state.selectedAgentIds))
                      })
                  )
                )
            )
          )
      )
    )

reactMixin.onClass(AssignerComponent, AssignModuleMixin)
module.exports = onClickOutside.default(AssignerComponent)
