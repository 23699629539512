ReactPaginate = require 'react-paginate'
{ NavLink } = require 'react-router-dom'

styles = require 'components/shared/styles/list'
stylesList = require 'components/shared/styles/list-item'
paginationStyles = require 'components/shared/styles/pagination.styl'
stylesLoader = require 'components/shared/styles/loader.styl'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

class RotationsListComponent extends React.Component
  render: ->
    { loading, error, rotations, pagesCount, paginateClickHandler } = @props
    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        (if error
          React.createElement("div", {"className": (styles.error)}, """
            Can\'t connect to the server
""")
        else
          if rotations.length > 0
            rotations.map (rotation) ->
              React.createElement(NavLink, { \
                "to": ("/rotations/#{rotation.id}"),  \
                "key": (rotation.id),  \
                "className": ( stylesList.container ),  \
                "activeClassName": ( stylesList.selected )
              },
                (rotation.name)
              )
          else
            React.createElement("div", {"className": (styles.no_results)}, """
              You don\'t have any rotations
""")
        )
      ),
      (
        if pagesCount > 1
          breakLabel = React.createElement("a", {"className": (paginationStyles.pageLink), "href": ''}, "...")
          React.createElement("div", {"className": (paginationStyles.wrapper)},
            React.createElement(ReactPaginate.default, { \
              "pageCount": (pagesCount),  \
              "marginPagesDisplayed": (2),  \
              "pageRangeDisplayed": (4),  \
              "breakLabel": (breakLabel),  \
              "breakClassName": (paginationStyles.page),  \
              "onPageChange": (paginateClickHandler),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container)
            })
          )
      )
    )

module.exports = RotationsListComponent
