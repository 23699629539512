config = require '../config'
BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionMap =
  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_REQUESTING}": (data) ->
    @tagsLoadings[data.tagId] = true
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_SUCCESS}": (data) ->
    @tags.forEach (tag) ->
      if tag.id == data.tagId || tag.name == data.tagName
        tag.checked = true
    delete @tagsLoadings[data.tagId]
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_FAILURE}": (data) ->
    delete @tagsLoadings[data.tagId]
    @emitChange()

  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_REQUESTING}": (data) ->
    @tagsLoadings[data.tagId] = true
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_SUCCESS}": (data) ->
    @tags.forEach (tag) ->
      if tag.id == data.tagId || tag.name == data.tagName
        tag.checked = false
    delete @tagsLoadings[data.tagId]
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_FAILURE}": (data) ->
    delete @tagsLoadings[data.tagId]
    @emitChange()
  
  "#{ActionTypes.TAGS_PREINSTALLED_FOR_CONV_REQUESTING}": ->
    @tags = []
    @tagsLoading = true
    @tagsError = null
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_FOR_CONV_SUCCESS}": (data) ->
    @tags = data
    @tagsLoading = false
    @tagsError = null
    @emitChange()
  "#{ActionTypes.TAGS_PREINSTALLED_FOR_CONV_FAILURE}": (error) ->
    @tagsLoading = false
    @tagsError = error
    @emitChange()

class TagsConversationStore extends BaseStore

  constructor: ->
    super()
    @tags = []
    @tagsLoadings = {}
    @tagsLoading = false
    @tagsError = null
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new TagsConversationStore()
