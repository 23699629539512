moment = require 'moment'

PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
ConversationsEachHourActionCreator = require './ConversationsEachHourActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.CONVERSATIONS_EACH_HOUR_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.CONVERSATIONS_EACH_HOUR_SUCCESS}": ({data, interval}) ->
    @loading = false
    @data = data
    @interval = moment(interval).valueOf()
    @emitChange()
  "#{ActionTypes.CONVERSATIONS_EACH_HOUR_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class ConversationsEachHourStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @data = null
    @interval = null
    @error = null
    @registerActions()

  getActionCreator: -> new ConversationsEachHourActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = ConversationsEachHourStore
