BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

class ProfileStore extends BaseStore

  constructor: ->
    super()
    @profile = {}
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>

    switch type
      when ActionTypes.PROFILE_GET_REQUESTING
        @loading = true
        @error = null
        @errors = {}
        @emitChange()

      when ActionTypes.PROFILE_GET_SUCCESS
        @profile = payload
        @loading = false
        @error = null
        @emitChange()

      when ActionTypes.PROFILE_GET_FAILURE
        @errors = payload.errors
        @loading = false
        @emitChange()

module.exports = new ProfileStore()
