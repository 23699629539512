browserHistory = require('root/history').default
ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'

class RotationStore extends BaseStore
  constructor: ->
    super()
    @rotation = {}
    @loading = false
    @error = null
    @registerActions()
  
  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.ROTATION_FORM_CLEAR
        @loading = false
        @error = null
        @rotation = {}
        @emitChange()
      when ActionTypes.ROTATION_GET_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.ROTATION_GET_SUCCESS
        @loading = false
        @rotation = payload
        @emitChange()
      when ActionTypes.ROTATION_GET_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.ROTATION_CREATE_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.ROTATION_CREATE_SUCCESS
        @loading = false
        @rotation = payload
        browserHistory.push({ pathname: "/rotations/#{payload.id}" })
      when ActionTypes.ROTATION_CREATE_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.ROTATION_DELETE_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.ROTATION_DELETE_SUCCESS
        @loading = false
        @rotation = {}
        browserHistory.push({ pathname: "/rotations" })
      when ActionTypes.ROTATION_DELETE_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.ROTATION_UPDATE_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.ROTATION_UPDATE_SUCCESS
        @loading = false
        @rotation = payload
        @emitChange()
      when ActionTypes.ROTATION_UPDATE_FAILURE
        @loading = false
        @error = payload
        @emitChange()

module.exports = new RotationStore()
