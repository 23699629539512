moment = require 'moment'
BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
{ pauseCustomerioEvents, deleteCustomerioEvents } = require 'lib/admin-utils'

actionMap =
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_REQUESTING}": ->
    @loading = true
    @loadingByText = {}
    @events.clear()
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_SUCCESS}": (events) ->
    @loading = false
    @events = new Map(events.map((event) -> Object.entries(event)[0]))
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_GET_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_REQUESTING}": ({text}) ->
    @loadingByText[text] = true
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_SUCCESS}": ({text, data}) ->
    @loadingByText[text] = false
    @events.set(Object.keys(data[0])[0], Object.values(data[0])[0])
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_FAILURE}": ({text, error}) ->
    @loadingByText[text] = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_REQUESTING}": (payload) ->
    @loadingByText[payload?.text] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_SUCCESS}": (payload) ->
    @loadingByText[payload?.text] = false
    @events = pauseCustomerioEvents @events, payload
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_PAUSE_FAILURE}": ({data, error}) ->
    @loadingByText[data?.text] = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_REQUESTING}": (payload) ->
    @loadingByText[payload?.text] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_SUCCESS}": (payload) ->
    @loadingByText[payload?.text] = false
    @events = deleteCustomerioEvents @events, payload
    @emitChange()
  "#{ActionTypes.ADMIN_CUSTOMERIO_EVENTS_DESTROY_FAILURE}": ({data, error}) ->
    @loadingByText[data?.text] = false
    @error = error
    @emitChange()

class CustomerioEventsStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @loadingByText = {}
    @events = new Map()
    @error = null
    @registerActions()

  _registerToActions: ({type, payload}) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new CustomerioEventsStore()
