classnames = require 'classnames'

styles = require './conversations-component'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
ConversationsComponent = require '../abstract/ConversationsComponent'
ConversationsListComponent = require '../ConversationsListComponent/ConversationsListComponent'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
ConversationsUnassignedStore = require '../../../stores/ConversationsUnassignedStore'
ConversationsMyStore = require '../../../stores/ConversationsMyStore'
FlagsStore = require 'stores/FlagsStore'
AuthStore = require 'stores/AuthStore'
ConversationsActionCreator = require '../../../actions/ConversationsActionCreator'
AgentAssignActionCreator = require 'actions/AgentAssignActionCreator'
{ ASSIGN_BUTTON_ACCESS } = require 'constants/Agents'

propTypes = require 'prop-types'
ConversationsActionCreator = new ConversationsActionCreator()

class ConversationsUnassignedComponent extends ConversationsComponent
  @propTypes:
    pageSize: propTypes.number.isRequired

  constructor: (props) ->
    super(props)
    @folder = 'unassigned'
    @initComponent()

  dependsOnStores: [ConversationsUnassignedStore, ConversationsMyStore, FlagsStore]

  onSearch: (query, searchType) =>
    if query
      @search.query = query
      @search.searchType = searchType
      @search.pageSize = @props.pageSize
      ConversationsActionCreator.getList(
        queryString: @search.query,
        searchType: @search.searchType,
        size: @search.pageSize
      )
    else
      ConversationsActionCreator.searchReset()

  showMore: =>
    @search.pageSize += @props.pageSize
    ConversationsActionCreator.getList(
      queryString: @search.query,
      searchType: @search.searchType,
      size: @search.pageSize
    )

  componentWillUpdate: (props, state) =>
    searchEnabled = ConversationsUnassignedStore.state.searchEnabled
    if !searchEnabled
      conversations = ConversationsUnassignedStore.conversations
      myConversations = ConversationsMyStore.conversations
      @handleUpdatedConversations(conversations.concat(myConversations))

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeUnassignedConversations()
        if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
          AgentAssignActionCreator.subscribe()
      0
    )

  componentWillUnmount: ->
    super()
    ConversationsActionCreator.setDefaultUnassignedState()
    ConversationsActionCreator.unSubscribeUnassignedConversations()
    if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
      AgentAssignActionCreator.unSubscribe()

  render: ->
    conversations = ConversationsUnassignedStore.conversations
    loading = ConversationsUnassignedStore.state.loading
    searchEnabled = ConversationsUnassignedStore.state.searchEnabled

    React.createElement("div", null,
      React.createElement("div", {"className": (styles.toolbarContainer)},
        React.createElement(SearchInputComponent, { \
          "key": (@folder),  \
          "onChange": @onSearch,  \
          "onClear": ( R.partial(@onSearch, [null]) ),  \
          "placeholder": "Search by keywords, #tags, @agents, *credentials",  \
          "clearCross": (true),  \
          "folder": (@folder),  \
          "autofocus": true
        }),
        (
          if searchEnabled && !loading
            React.createElement("div", {"className": styles.search_result_amount}, """
              Results: """, (conversations.length)
            )
        )
      ),
      React.createElement(LoadableWrapperComponent, { \
        "containerClassName": (styles.list_container),  \
        "loading": (loading)
      },
        React.createElement(ConversationsListComponent, { \
          "flags": (FlagsStore.flags || {}),  \
          "folder": (@folder),  \
          "conversations": (conversations),  \
          "order": (if searchEnabled then 'updated_at' else 'default'),  \
          "limit": (@searchPageSize()),  \
          "searchEnabled": (searchEnabled),  \
          "showMoreLoading": (loading && searchEnabled),  \
          "loadMore": (@showMore),  \
          "loading": (loading)
        })
      )
    )

module.exports = ConversationsUnassignedComponent
