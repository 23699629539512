config = require 'root/config'
styles = require './conversations-list'
IScroll = require 'iscroll/build/iscroll-probe'

config = require 'root/config'
stylesLoader = require '../../shared/styles/loader.styl'
BaseComponent = require '../../BaseComponent'
ConversationListItemComponent = require '../ConversationListItemComponent/ConversationListItemComponent'
MultiselectMixin = require '../../mixin/MultiselectMixin'
{ sortConversations, sortConversationsByUpdatedAt } = require 'lib/utils'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
{ ROLES } = require 'constants/Agents'
AuthStore = require 'stores/AuthStore'
ConversationMockActionCreator = require 'actions/ConversationMockActionCreator'
{ TAKE_NEW_BUTTON_ACCESS } = require 'constants/Agents'
TakeNewConversationComponent = require '../TakeNewConversationComponent'

ConversationsActionCreator = new ConversationsActionCreator()

propTypes = require 'prop-types'

ORDER_TYPE_DEFAULT = 'default'
ORDER_TYPE_UPDATED_AT = 'updated_at'

class ConversationsListComponent extends BaseComponent
  @propTypes:
    multiselectedConversationIds: propTypes.array
    onMultiselectItemChange: MultiselectMixin.propTypes.onMultiselectItemChange
    folder: propTypes.string.isRequired
    order: propTypes.oneOf([ORDER_TYPE_DEFAULT, ORDER_TYPE_UPDATED_AT])
    reservedHighlighting: propTypes.bool

  @defaultProps:
    order: ORDER_TYPE_DEFAULT

  constructor: (props) ->
    super(props)
    @state =
      initialSelectedAgentIds: []
    @initComponent()

  pickSortFuction: (order) ->
    return sortConversations if order == ORDER_TYPE_DEFAULT
    return sortConversationsByUpdatedAt if order == ORDER_TYPE_UPDATED_AT
    throw "Unacceptable order. Order=#{order}"

  render: ->
    reservedHighlighting = fastAllocate = AuthStore.user.role == ROLES.teamlead.value
    React.createElement("div", {"className": styles.scroll},
      (if TAKE_NEW_BUTTON_ACCESS[AuthStore.user.role]
        React.createElement("div", {"className": ( styles.take_new )},
          React.createElement(TakeNewConversationComponent, null)
        )
      ),
      (
        if @props.error
          React.createElement("div", {"className": (styles.error)}, """
            Can not connect to the server.
""", React.createElement("br", null), React.createElement("br", null),
            React.createElement("div", {"className": (styles.try_again), "onClick": (@props.refresh)}, """
              Try again
""")
          )
        else
          if @props.conversations.length > 0
            conversations = @pickSortFuction(@props.order)(@props.conversations)
            conversations.map (conversation) =>
              React.createElement(ConversationListItemComponent, { \
                "flags": (@props.flags),  \
                "key": (conversation.id),  \
                "folder": (@props.folder),  \
                "conversation": (conversation),  \
                "reservedHighlighting": (reservedHighlighting),  \
                "fastAllocate": (fastAllocate)
              })
          else if !@props.loading
            React.createElement("div", {"className": (styles.no_results)},
              ("You don't have any messages in this folder")
            )
      ),
      (
        if @props.conversations.length >= @props.limit && @props.conversations.length > 0 && @props.searchEnabled
          React.createElement("div", { \
            "className": (stylesLoader.load_more),  \
            "onClick": (@props.loadMore)
          }, """
            + SHOW MORE
""")
      ),
      (
        if @props.showMoreLoading
          React.createElement("div", {"className": (stylesLoader.load_more)}, "loading...")
      )
    )

module.exports = ConversationsListComponent
