GuidedActionCreator = require 'actions/abstract/GuidedActionCreator'
ActionTypes = require 'constants/ActionTypes'
DashboardAPI = require 'api/DashboardAPI'

class UniqueClientsByAgentsActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_REQUESTING

    DashboardAPI.getUniqueClientsByAgents(
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_ERROR
            payload: json.error
      (response) =>
        msg = 'An error occurred while getting unique clients per operator per shift'
        @dispatch
          type: ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_ERROR
          payload: msg
    )

module.exports = UniqueClientsByAgentsActionCreator
