deepcopy = require 'deepcopy'
styles = require './hash-tag-list-item'

class AbstractListItem extends React.Component
  constructor: (props) ->
    super(props)
    @state = edit: props.onlyEdit

  onEditClick: (e) =>
    e.nativeEvent.stopImmediatePropagation()
    e.stopPropagation()
    @setState edit: true

  onCancel: =>
    @props.onEditCancel() if @props.onEditCancel
    @setState edit: false

  onSave: =>
    newItem = deepcopy @props[@type]
    newItem.name = @refs.input.value
    @props.onSave newItem
    @setState edit: false

  onKeyPress: (e) =>
    if e.charCode == 13
      @onSave()

  onItemClick: (e) =>
    e.nativeEvent.stopImmediatePropagation()
    e.stopPropagation()
    @props.onClick(@props[@type])

  onDeleteClick: (e) =>
    e.nativeEvent.stopImmediatePropagation()
    e.stopPropagation()
    @props.onDelete @props[@type]

  generateInput: ->
    React.createElement("div", {"className": (styles.input_container)},
      React.createElement("input", { \
        "ref": "input",  \
        "onKeyPress": (@onKeyPress),  \
        "defaultValue": (@props[@type].name),  \
        "className": (styles.input)
      }),
      React.createElement("span", { \
        "onClick": (@onCancel),  \
        "className": (styles.cancel)},
        React.createElement("i", {"className": (styles.cancel_icon)})
      ),
      React.createElement("span", { \
        "onClick": (@onSave),  \
        "className": (styles.apply)
      },
        React.createElement("i", {"className": (styles.apply_icon)})
      )
    )

  generateData: ->
    React.createElement("div", null)

  render: ->
    React.createElement("div", null,
      (
        if @state.edit
          @generateInput()
        else
          @generateData()
      )
    )

module.exports = AbstractListItem
