moment = require 'moment'

BaseComponent = require 'components/BaseComponent'
ScheduledMessagesActionCreator = require 'actions/admin/ScheduledMessagesActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AccordionByDate = require 'components/admin/AccordionByDate'
OnlineStore = require 'stores/OnlineStore'
ScheduledMessagesStore = require 'stores/admin/ScheduledMessagesStore'
{ getQueryParams } = require 'lib/utils'
styles = require 'components/admin/assets/common-styles.styl'

class ScheduledMessagesComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1
    @state =
      searchOptions: params
    @initComponent()

  dependsOnStores: [ScheduledMessagesStore, OnlineStore]

  componentWillMount: =>
    options = Object.assign(
      {}
      @props.agent
      @props.dateRange
      {
        timezone: moment.tz.guess()
        search_query: @props.search
      }
    )
    @setState searchOptions: options
    setTimeout(
      -> ScheduledMessagesActionCreator.getList(options)
      10
    )

  componentWillReceiveProps: (nextProps) =>
    options = Object.assign(
      {}
      @props.agent
      @props.dateRange
      {
        timezone: moment.tz.guess()
        search_query: @props.search
      }
    )
    nextOptions = Object.assign(
      {}
      nextProps.agent
      nextProps.dateRange
      {
        timezone: moment.tz.guess()
        search_query: nextProps.search
      }
    )
    if !_.isEqual(options, nextOptions)
      @setState searchOptions: nextOptions
      setTimeout(
        -> ScheduledMessagesActionCreator.getList(nextOptions)
        10
      )

  render: ->
    scheduledMessagesKeys = Array.from(ScheduledMessagesStore.scheduledMessages.keys())
    deleteConfirm =
      message: 'Are you sure this message should not be sent?'
      confirmTitle: 'Cancel scheduled message'
    React.createElement("section", {"className": (styles.container)},
      (if ScheduledMessagesStore.error
        React.createElement("span", {"className": (styles.error)}, (ScheduledMessagesStore.error))
      ),
      React.createElement(LoadableWrapperComponent, {"loading": (ScheduledMessagesStore.loading)},
        (scheduledMessagesKeys.map (key) =>
          React.createElement(AccordionByDate, { \
            "key": (key),  \
            "date": (key),  \
            "data": (ScheduledMessagesStore.scheduledMessages.get(key)),  \
            "itemsStore": (ScheduledMessagesStore),  \
            "itemsActionCreator": (ScheduledMessagesActionCreator),  \
            "itemType": 'scheduled_messages',  \
            "deleteConfirm": deleteConfirm,  \
            "editLink": 'edit_message',  \
            "getOptions": (@state.searchOptions),  \
            "agents": (OnlineStore.agents)
          })
        )
      )
    )

module.exports = ScheduledMessagesComponent
