ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'


OnlineCustomerOrdersActionCreator =
  subscribe: (client_id) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE_GLOBAL
      payload:
        topic: "crm/events/new_order/+/+/#{client_id}"
        actionType: ActionTypes.CUSTOMER_ORDERS_SOCKET_SUCCESS
  unSubscribe: (client_id) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE_GLOBAL
      payload:
        topic: "crm/events/new_order/+/+/#{client_id}"
        actionType: ActionTypes.MQTT_COUNTERS_UPDATING

module.exports = OnlineCustomerOrdersActionCreator
