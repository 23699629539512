{ urls } = require '../config'

module.exports =
  getTickets: (data, success, error) ->
    $.ajax {
      url: urls.getCustomerTickets()
      type: 'GET'
      xhrFields: withCredentials: true
      data
      success
      error
      timeout: 5000
    }
