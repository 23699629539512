{ Link } = require 'react-router-dom'
browserHistory = require './../../../history'
classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
AgentInfoComponent = require '../AgentInfoComponent'
ButtonComponent = require 'components/shared/ButtonComponent/ButtonComponent'
styles = require './agent-profile-wrapper'
PersonalStore = require './AgentProfileWrapperStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AgentStatisticComponent = require '../AgentStatisticComponent'
{ ROLES } = require 'constants/Agents'
{ menus, reconnectUrl } = require 'root/config'
AuthStore = require 'stores/AuthStore'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'agents'
    accessConfig =
      toEdit: item.accessToEdit
      another: item.anotherAccess
      reconnect: item.reconnectAccess

class AgentProfileWrapperComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> PersonalStore

  componentWillMount: ->
    userRole = ROLES[AuthStore.user.role].value
    if reconnectUrl && !(userRole in accessConfig.reconnect)
      browserHistory.default.push '/'

  componentDidMount: ->
    unless @props.new
      @actionCreator.get @props.match.params.id
    super()

  componentWillUpdate: (props) ->
    if !@props.new && !@props.match.params.tab
      browserHistory.default.push("/agents/#{props.match.params.id}/shifts")

  closeErrorTip: =>
    @actionCreator.removeErrors()

  render: ->
    content = null
    if @props.new
      content =
        React.createElement("div", null,
          React.createElement(AgentInfoComponent, {"saveData": (@actionCreator.create)})
        )
    else if @personalStore.agent
      content =
        React.createElement("div", null,
          React.createElement(AgentInfoComponent, { \
            "agent": (@personalStore.agent),  \
            "saveData": (@actionCreator.update.bind @, @personalStore.agent.id),  \
            "onAvatarChange": (@actionCreator.updateAvatar.bind @, @personalStore.agent.id)
          }),
          (
            if accessConfig.toEdit.indexOf(AuthStore.user.role) != -1 ||
               accessConfig.another.indexOf(AuthStore.user.role) != -1 ||
               AuthStore.user.id == Number.parseInt(@props.match.params.id)

              React.createElement(AgentStatisticComponent, { \
                "tab": (@props.match.params.tab || 'shifts'),  \
                "agentId": (@personalStore.agent.id),  \
                "agent": (@personalStore.agent)
              })
          )
        )

    React.createElement("div", {"className": (styles.overlay)},
      React.createElement("div", {"className": (classnames styles.content, 'profile')},
        React.createElement(LoadableWrapperComponent, {"loading": (!!@personalStore.loading.get || !!@personalStore.loading.createOrUpdate)},
          (content),
          (if @personalStore.errors
            errors = @personalStore.errors
            React.createElement("div", {"className": (styles.error)},
              React.createElement("span", {"className": (styles.error__title)}, "Error!"),
              (Object.keys @personalStore.errors
                .map (key, i) ->
                  React.createElement("div", {"className": (styles.error_row), "key": (i)},
                    React.createElement("span", {"className": (styles.error__fieldname)},
                      (key), """:
"""),
                    React.createElement("span", {"className": (styles.error__messages)},
                      (errors[key].map (error, i) ->
                        React.createElement("p", {"className": (styles.error__message), "key": (i)}, (error))
                      )
                    )
                  )
              ),
              React.createElement("div", {"onClick": (@closeErrorTip), "className": (styles.error__close)}, """
                X
""")
            )
          )
        )
      )
    )

module.exports = AgentProfileWrapperComponent
