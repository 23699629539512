moment = require 'moment'
classnames = require 'classnames'

BaseComponent = require '../../BaseComponent'
{formatFromSecondsToTime} = require '../../../lib/utils'
styles = require './ranking-conversation-item'
{STATUSES, QUALITY, BAD_TAGS} = require '../../../constants/Ranking'

propTypes = require 'prop-types'

class RankingConversationsItemComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    conversation: propTypes.object.isRequired

  buildConversationApproveData: =>
    {approve_data} = @props.conversation
    return [] unless approve_data.status
    statusStyles = classnames(
      styles['conversation__conversation-info-tag']
      styles["conversation__conversation-info-tag_#{approve_data.status}"]
    )
    data = [
      React.createElement("span", {"key": (0), "className": (statusStyles)},
        (STATUSES[approve_data.status].label)
      )
    ]

    if approve_data.status == STATUSES.ranked.value && QUALITY[approve_data.quality]
      qualityStyles = classnames(
        styles['conversation__conversation-info-tag']
        styles["conversation__conversation-info-tag_#{approve_data.quality}"]
        styles["conversation__conversation-info-tag_comment"] if approve_data.quality == QUALITY.good.value && approve_data.description
      )
      title = undefined
      title = approve_data.description if approve_data.quality == QUALITY.good.value
      data.push React.createElement("span", {"key": (data.length), "className": (qualityStyles), "title": (title)},
        (QUALITY[approve_data.quality].label)
      )
      if approve_data.quality == QUALITY.bad.value && BAD_TAGS[approve_data.badTag]
        badTagStyles = classnames(
          styles['conversation__conversation-info-tag']
          styles["conversation__conversation-info-tag_bad-tag"]
        )
        data.push React.createElement("span", {"key": (data.length), "className": (badTagStyles)},
          (BAD_TAGS[approve_data.badTag].label)
        )
 
    data

  render: ->
    { conversation } = @props
    last_message = conversation.last_message || {}
    React.createElement("div", {"className": (styles.conversation)},
      React.createElement("div", {"className": (styles['conversation__customer-info'])},
        React.createElement("span", {"className": (styles['conversation__customer-info-name'])},
          (conversation.customer.name)
        ),
        React.createElement("span", {"className": (styles['conversation__customer-info-service'])},
          ("(#{(last_message.channel || {}).name})" if (last_message.channel || {}).name)
        ),
        React.createElement("span", {"className": (styles['conversation__customer-info-date'])},
          (moment(conversation.created_at).format 'D MMM')
        )
      ),
      React.createElement("ul", {"className": (styles.conversation__tags)},
        (
          conversation.tags.map (tag, i) ->
            React.createElement("li", {"className": (styles.conversation__tag), "key": (i)}, "#", (tag.name))
        )
      ),
      React.createElement("div", null,
        (@buildConversationApproveData()),
        React.createElement("span", {"className": (styles['conversation__conversation-info-message'])},
          (conversation.messages_count)
        ),
        React.createElement("span", {"className": (styles['conversation__conversation-info-time'])},
          (formatFromSecondsToTime conversation.average_response_time)
        )
      )
    )

module.exports = RankingConversationsItemComponent
