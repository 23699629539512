classnames = require 'classnames'
ReactPaginate = require 'react-paginate'
BaseComponent = require 'components/BaseComponent'
Isvg = require 'react-inlinesvg'

ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
ReplyAnswersStore = require 'stores/ReplyAnswersStore'
{ SORT_LIST, SORT_ORDER } = require 'constants/ReplyTemplates'
{ reactDndContext } = require 'lib/utils'

styles = require './change-category-modal.styl'
paginationStyles = require 'components/shared/styles/pagination.styl'

class ChangeCategoryModal extends BaseComponent

  constructor: (props) ->
    super(props)

    @state = {
      templateType: 'message'
      categoryPage: 0
      categoryPerPage: 30
    }
    @initComponent()

  dependsOnStores: [ReplyAnswersStore]


  render: =>
    { onCategoryClick, currentCategoryId } = @props.data

    React.createElement("section", {"className": (styles.container)},
      React.createElement("header", {"className": (styles.header)},
        React.createElement("h2", {"className": (styles.title)}, "Move to category"),
        React.createElement("span", {"onClick": (@props.onClick)},
          React.createElement("span", {"className": (styles.close)},
            React.createElement(Isvg.default, {"src": (require 'components/shared/images/close.svg')})
          )
        )
      ),

      React.createElement("ul", {"className": (styles.list)},
        (ReplyAnswersStore.groups.map (category) ->
          React.createElement("li", {"key": (category.id)},
            React.createElement("div", { \
              "className": (classnames(styles.item,
                "#{styles.item_active}": category.id == currentCategoryId
              )),  \
              "onClick": (-> onCategoryClick(category.id)),  \
              "data-id": (category.id)
            },
              (category.name),
              (if category.id == currentCategoryId
                React.createElement("span", {"className": (styles.check)},
                  React.createElement(Isvg.default, {"src": (require 'components/shared/images/check.svg')})
                )
              )
            ),
            (category.categories.map (subCategory) ->
              React.createElement("li", {"key": (subCategory.id)},
                React.createElement("div", { \
                  "className": (classnames(styles.item,
                    "#{styles.item_sub}",
                    "#{styles.item_active}": subCategory.id == currentCategoryId
                  )),  \
                  "onClick": (-> onCategoryClick(subCategory.id)),  \
                  "data-id": (subCategory.id)
                },
                  (subCategory.name),
                  (if subCategory.id == currentCategoryId
                    React.createElement("span", {"className": (styles.check)},
                      React.createElement(Isvg.default, {"src": (require 'components/shared/images/check.svg')})
                    )
                  )
                )
              )
            )
          )
        ),
        React.createElement("li", {"className": (styles.pagination)},
          React.createElement(ReactPaginate.default, { \
            "previousLabel": '<',  \
            "nextLabel": '>',  \
            "pageCount": (@props.data.pageCategoryCount),  \
            "marginPagesDisplayed": (1),  \
            "pageRangeDisplayed": (3),  \
            "onPageChange": (@props.data.onPageCategoryChange),  \
            "initialPage": (@props.data.page),  \
            "pageClassName": (paginationStyles.page),  \
            "nextClassName": (paginationStyles.page),  \
            "previousClassName": (paginationStyles.page),  \
            "activeClassName": (paginationStyles.active),  \
            "pageLinkClassName": (paginationStyles.link),  \
            "nextLinkClassName": (paginationStyles.link),  \
            "previousLinkClassName": (paginationStyles.link),  \
            "containerClassName": (paginationStyles.container),  \
            "breakClassName": (paginationStyles.page)
          })
        )
      )
    )

module.exports = reactDndContext(ChangeCategoryModal)