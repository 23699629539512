ReactPaginate = require 'react-paginate'

styles = require 'components/shared/styles/list'
paginationStyles = require 'components/shared/styles/pagination.styl'
stylesLoader = require 'components/shared/styles/loader.styl'
ChannelListItemComponent = require '../ChannelListItemComponent/ChannelListItemComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

propTypes = require 'prop-types'

class ChannelListComponent extends React.Component
  @propTypes:
    pagesCount: propTypes.number
    channels: propTypes.arrayOf(propTypes.object)
    loading: propTypes.bool

  @defaultProps:
    loading: false

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
        (if !_.isEmpty(@props.error)
          React.createElement("div", {"className": (styles.error)}, """
            Can not connect to the server.
""")
        ),
        (unless !_.isEmpty(@props.error)
          if @props.channels.length > 0
            _(@props.channels).map (channel) =>
              React.createElement(ChannelListItemComponent, { \
                "key": (channel.id),  \
                "channel": (channel)
              })
          else if !@props.loading
            React.createElement("div", {"className": (styles.no_results)}, ("You don't have any channels"))
        )
      ),
      (
        if @props.pagesCount > 1
          breakLabel = React.createElement("a", {"className": (paginationStyles.pageLink), "href": ''}, "...")
          React.createElement("div", {"className": (paginationStyles.wrapper)},
            React.createElement(ReactPaginate.default, { \
              "pageCount": (@props.pagesCount),  \
              "marginPagesDisplayed": (2),  \
              "pageRangeDisplayed": (4),  \
              "breakLabel": (breakLabel),  \
              "breakClassName": (paginationStyles.page),  \
              "onPageChange": (@props.paginateClickHandler),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container)
            })
          )
      )
    )

module.exports = ChannelListComponent
