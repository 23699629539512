AgentsAssignStore = require '../../stores/AgentsAssignStore'
{ assignedOperatorsSortingRule } = require '../../lib/utils'

AssignModuleMixin =
  handleAgents: (props) ->
    highlightedAgents = []
    agents = []
    AgentsAssignStore.list.forEach (agent) =>
      agent.highlighted = false
      agent.reserved = false
      agent.ucpops = agent.last_shift?.unique_clients_count || 0
      agent.reserved = true if props.reservedAgent == agent.id
      if props.highlightedAgentId == agent.id
        agent.highlighted = true
        highlightedAgents.push agent
      else
        agents.push agent

    highlightedAgents = highlightedAgents.sort assignedOperatorsSortingRule
    agents = agents.sort assignedOperatorsSortingRule

    agents = highlightedAgents.concat agents
    agents[0].highlighted = true if agents[0]
    agents

module.exports = AssignModuleMixin
