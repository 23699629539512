classnames = require 'classnames'
ReactPaginate = require 'react-paginate'

LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

styles = require './reply-categories.styl'
paginationStyles = require 'components/shared/styles/pagination.styl'

module.exports = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement(LoadableWrapperComponent, {"loading": (props.loading)},
      React.createElement("ul", {"className": (styles.list)},
        React.createElement("li", null,
          React.createElement("div", { \
            "className": (classnames(styles.item, styles.favorite, {
              "#{styles.item_selected}": props.selected == 'favorite'
            })),  \
            "onClick": (props.onFavoriteCategoryClick)
          },
            React.createElement("span", {"className": (styles.favorite_icon)}),
            React.createElement("span", {"className": (styles.favorite_label)}, "Favorite")
          )
        ),
        (if props.isPersonal
          React.createElement("li", null,
            React.createElement("div", { \
              "className": (classnames(styles.item, styles.personal, {
                "#{styles.item_selected}": props.selected == 'personal'
              })),  \
              "onClick": (props.onPersonalCategoryClick)
            },
              React.createElement("span", {"className": (styles.personal_icon)}),
              React.createElement("span", {"className": (styles.personal_label)}, "Personal")
            )
          )
        ),

        (props.categories.map (c) ->
          React.createElement("li", {"key": (c.id)},
            React.createElement("div", { \
              "className": (classnames(styles.item,
                "#{styles.item_selected}": props.selected == c.id
              )),  \
              "data-id": (c.id),  \
              "onClick": (props.onCategoryClick)
            },
              (c.name)
            )
          )
        ),

        (if props.pageCount > 1
          React.createElement("li", {"className": (styles.pagination)},
            React.createElement(ReactPaginate.default, { \
              "previousLabel": '<',  \
              "nextLabel": '>',  \
              "pageCount": (props.pageCount),  \
              "marginPagesDisplayed": (1),  \
              "pageRangeDisplayed": (1),  \
              "onPageChange": (props.onPageChange),  \
              "initialPage": (props.page),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container),  \
              "breakClassName": (paginationStyles.page)
            })
          )
        )
      )
    )
  )
