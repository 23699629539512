_ = require 'underscore'
ReactPaginate = require 'react-paginate'

paginationStyles = require '../../shared/styles/pagination.styl'
AgentItemComponent = require './AgentItemComponent'
styles = require './agent-list'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'

propTypes = require 'prop-types'

agentListComponent = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement("div", {"className": (styles.position_relative)},
      React.createElement(LoadableWrapperComponent, {"loading": (!!props.loading)},
        React.createElement("ul", {"className": (styles.list)},
          (props.agents.map (agent) ->
            React.createElement("li", {"key": (agent.id), "className": (styles.item_container)},
              React.createElement(AgentItemComponent, { \
                "agent": (agent),  \
                "onAgentClick": (props.onAgentClick),  \
                "checked": (_.contains props.checkedIds, agent.id)
              })
            )
          )
       )
      )
    ),
    (if props.pageCount > 1
      React.createElement("div", {"className": (styles.text_center)},
        React.createElement(ReactPaginate.default, { \
          "previousLabel": 'previous',  \
          "nextLabel": 'next',  \
          "pageCount": (props.pageCount),  \
          "marginPagesDisplayed": (1),  \
          "pageRangeDisplayed": (2),  \
          "onPageChange": (props.onPageChange),  \
          "initialPage": (props.defaultPage),  \
          "pageClassName": (paginationStyles.page),  \
          "nextClassName": (paginationStyles.page),  \
          "previousClassName": (paginationStyles.page),  \
          "activeClassName": (paginationStyles.active),  \
          "pageLinkClassName": (paginationStyles.link),  \
          "nextLinkClassName": (paginationStyles.link),  \
          "previousLinkClassName": (paginationStyles.link),  \
          "containerClassName": (paginationStyles.container),  \
          "breakClassName": (paginationStyles.page)
        })
      )
    )
  )

agentListComponent.propTypes =
  agents: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired
      first_name: propTypes.string.isRequired
      last_name: propTypes.string.isRequired
    })
  ).isRequired
  checkedIds: propTypes.arrayOf(propTypes.number).isRequired
  pageCount: propTypes.number.isRequired
  onPageChange: propTypes.func.isRequired
  onAgentClick: propTypes.func
  defaultPage: propTypes.number
  loading: propTypes.bool

module.exports = agentListComponent
