moment = require 'moment'

CustomerioItemActivities = require './CustomerioItemActivities'
styles = require './custometio-item.styl'

class CustomerioItem extends React.Component

  render: =>
    { item } = @props
    timezone = item.customer.timezone || 'Etc/UCT'
    timeZoneShift = "GMT#{moment.tz(timezone).format('Z')}"
    (
      React.createElement("section", {"className": (styles.container)},
        React.createElement("div", {"className": (styles.header)},
          React.createElement("div", null,
            React.createElement("span", null, React.createElement("b", null, (item.customer.name))),
            React.createElement("span", {"className": (styles.string_divider)}),
            React.createElement("span", null, (timeZoneShift))
          ),
          React.createElement("div", null,
            React.createElement("span", {"className": (styles.status)}, ("Status: #{item.status}")),
            React.createElement(CustomerioItemActivities, { \
              "className": (styles.activities),  \
              "onPause": (() => @props.onPause(@props.item.id)),  \
              "onDelete": (() => @props.onDelete(@props.item.id))
            })
          )
        )
      )
    )

module.exports = CustomerioItem
