config = require '../config'
{ underscoreParams } = require 'lib/utils'

module.exports =
  uncompletedByCustomerId: (id, data, success, error) ->
    $.ajax {
      url: config.urls.customers.getScheduledMessages(id)
      type: 'GET'
      data
      success 
      error
      timeout: 5000
    }

  create: (data, success, error) ->
    $.ajax {
      url: config.urls.scheduledMessages.create
      type: 'POST'
      data: underscoreParams data
      success 
      error
      timeout: 5000
    }

  destroy: (id, success, error) ->
    $.ajax {
      url: config.urls.scheduledMessages.destroy id
      type: 'DELETE'
      success 
      error
      timeout: 5000
    }

  update: (id, data, success, error) ->
    $.ajax {
      url: config.urls.scheduledMessages.update id
      type: 'PUT'
      data: underscoreParams data
      success 
      error
      timeout: 5000
    }
