styles = require './ranking-statistic'

propTypes = require 'prop-types'

RankingStatisticComponent = (props) ->
  { total, toRank, ranked } = props

  React.createElement("ul", {"className": (styles.statistic)},
    React.createElement("li", {"className": (styles.statistic__item)},
      React.createElement("div", {"className": (styles['statistic__item-data'])}, (total)),
      React.createElement("div", {"className": (styles['statistic__item-label'])}, "Conversations")
    ),
    React.createElement("span", {"className": (styles.statistic__slash)}),
    React.createElement("li", {"className": (styles.statistic__item)},
      React.createElement("div", {"className": (styles['statistic__item-data'])}, (ranked)),
      React.createElement("div", {"className": (styles['statistic__item-label'])}, "Ranked")
    ),
    React.createElement("span", {"className": (styles.statistic__slash)}),
    React.createElement("li", {"className": (styles.statistic__item)},
      React.createElement("div", {"className": (styles['statistic__item-data'])}, (toRank)),
      React.createElement("div", {"className": (styles['statistic__item-label'])}, "To be ranked")
    )
  )

RankingStatisticComponent.propTypes =
  total: propTypes.number.isRequired
  toRank: propTypes.number.isRequired
  ranked: propTypes.number.isRequired

module.exports = RankingStatisticComponent
