Auth = require 'j-toker'
config = require './config'
# Cookies = require 'js-cookie'

{ Router, Route } = require 'react-router-dom';

LoginComponent = require './components/LoginComponent'

AppRouteComponent = require './AppRouteComponent'

HTTP_STATUS = require './constants/HttpStatuses'
history = require './history'

{ getToken } = require 'lib/tokenStorage'
{ oauthRedirect } = require 'lib/utils'

Auth.configure apiUrl: config.urls.auth

token = getToken()
if token
  $.ajaxSetup({
    headers:
      Authorization: token
  })

$(document).ajaxError (event, jqxhr, settings, thrownError) ->
  currentHost = config.domain
  if (settings.url)
    currentHost = new URL(settings.url).host

  if (jqxhr.status == HTTP_STATUS.UNAUTHORIZED || jqxhr.status == HTTP_STATUS.FORBIDDEN) && config.domain == currentHost
    oauthRedirect()

routes =
  React.createElement(Router, {"history": (history.default)},
    React.createElement(Route, {"path": "/login/:token", "component": (LoginComponent)}),
    React.createElement(Route, {"component": (AppRouteComponent.default)})
  )

module.exports = routes
