BaseComponent = require '../../BaseComponent'
TagsConversationStore = require 'stores/TagsConversationStore'
TagsConversationActionCreator = require 'actions/TagsConversationActionCreator'
Button = require '../../shared/Button'
Tag = require './Tag'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
styles = require './tags-warning-component'

class TagsWarningComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      tags: []
      error: null
      loading: false
    @initComponent()

  dependsOnStores: [TagsConversationStore]

  getState: ->
    tags: TagsConversationStore.tags
    error: TagsConversationStore.tagsError
    loading: TagsConversationStore.tagsLoading

  requestData: =>
    _.defer(TagsConversationActionCreator.getPreselectedTagsForConversation)

  onChange: (tag) =>
    if tag.checked
      TagsConversationActionCreator.destroy tag, @props.data.conversationId
    else
      TagsConversationActionCreator.create tag, @props.data.conversationId

  onClose: =>
    if @state.tags.some((tag) -> tag.checked)
      @props.data.updateConversationStatus(CONVERSATION_STATUS.CLOSED)
      @props.onClick()
    else
      @props.onClick()

  renderTags: ->
    return false if !@state.tags.length
    (React.createElement("div", null,
      (@state.tags.map (tag) =>
        React.createElement(Tag, { \
          "tag": (tag),  \
          "key": ("#{tag.id}#{tag.name}"),  \
          "onClick": (@onChange),  \
          "loading": (TagsConversationStore.tagsLoadings[tag.id]),  \
          "selected": (tag.checked)
        })
      )
    ))

  render: =>
    React.createElement("div", {"className": (styles.container)},
      React.createElement("img", { \
        "src": (require './images/hash-tags-notification.svg'),  \
        "className": (styles.icon)
      }),
      React.createElement("p", {"className": (styles.text)},
        ('Please don\'t forget to tag the conversation before closing the chat')
      ),
      (@renderTags()),
      React.createElement(Button, { \
        "title": (if @state.tags.some((tag) -> tag.checked) then 'Close chat' else 'Got it'),  \
        "onClick": (@onClose),  \
        "disabled": (!!Object.keys(TagsConversationStore.tagsLoadings).length || @state.loading),  \
        "className": (styles.button)
      })
    )

module.exports = TagsWarningComponent
