Dispatcher = require '../../../dispatcher/Dispatcher'
RemindersAPI = require '../../../api/RemindersAPI'

class RemindersActionCreator
  constructor: (@types) ->

  index: (scope) =>
    Dispatcher.dispatch type: @types.REMINDERS_LOAD_REQUESTING
    RemindersAPI.index scope,
      (reminders) =>
        Dispatcher.dispatch
          type: @types.REMINDERS_LOAD_SUCCESS
          payload: {reminders}
      (error) ->
        console.error(error)

  update: (id, attributes) ->
    Dispatcher.dispatch
      type: @types.REMINDER_UPDATE_REQUESTING
      payload: id
    RemindersAPI.update(
      id
      attributes
      (data) =>
        if data.errors
          return Dispatcher.dispatch
            type: @types.REMINDER_UPDATE_FAILURE
            payload:
              errors: data.errors
        Dispatcher.dispatch
          type: @types.REMINDER_UPDATE_SUCCESS
          payload:
            reminder: data
      (errors) =>
        Dispatcher.dispatch
          type: @types.REMINDER_UPDATE_FAILURE
          payload:
            errors: { errors }
    )
  create: (attributes) =>
    Dispatcher.dispatch type: @types.REMINDER_CREATE_REQUESTING
    RemindersAPI.create attributes,
      (reminder) =>
        Dispatcher.dispatch
          type: @types.REMINDER_CREATE_SUCCESS
          payload: { reminder }
      (errors) =>
        Dispatcher.dispatch
          type: @types.REMINDER_CREATE_ERROR
          payload: { errors }

  destroy: (id) =>
    Dispatcher.dispatch type: @types.REMINDER_DESTROY_REQUESTING, payload: { id }
    RemindersAPI.destroy id,
      () =>
        Dispatcher.dispatch type: @types.REMINDER_DESTROY_SUCCESS
      (error) ->
        console.error(error)

module.exports = RemindersActionCreator
