styles = require './prompts'
moment = require 'moment'
classnames = require 'classnames'
Dispatcher = require '../../../../../dispatcher/Dispatcher'
BaseComponent = require '../../../../BaseComponent'
MultilineText = require 'components/shared/MultilineText/MultilineText'
propTypes = require 'prop-types'
PromptsActionCreator = require '../../../../../actions/PromptsActionCreator'
PROMPT_STATUSES = require 'constants/PromptStatuses'
{ conversationIsClosed } = require '../../../../../lib/utils.coffee'

class PromptsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    prompts: propTypes.arrayOf(propTypes.object).isRequired
    conversation: propTypes.object.isRequired

  render: =>
    conversation = @props.conversation
    React.createElement("div", null,
      (
        @props.prompts.map (p, i) ->
          React.createElement(PromptComponent, {"key": (i), "prompt": (p), "conversation": (conversation)})
      )
    )

class PromptComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    prompt: propTypes.object.isRequired
    conversation: propTypes.object.isRequired

  getPromptId: (prompt)=>
    /\d+/.exec(prompt.id)[0]

  sendPrompt: (prompt)=>
    attributes = { id: @getPromptId(prompt), status: PROMPT_STATUSES.SENT }
    PromptsActionCreator.update attributes

  rejectPrompt: (prompt)=>
    attributes = { id: @getPromptId(prompt), status: PROMPT_STATUSES.REJECTED }
    PromptsActionCreator.update attributes

  editPrompt: (prompt)=>
    attributes =
      id: @getPromptId(prompt)
      status: PROMPT_STATUSES.REJECTED
      text: prompt.text
      is_edited: true
    PromptsActionCreator.edit attributes

  getState: =>
    showMask: false

  toggleRejectedPrompt: (id)=>
    @setState showMask: !@state.showMask

  render: ->
    { prompt } = @props
    return null if prompt?.status == PROMPT_STATUSES.SENT

    datetime = if prompt.created_at?
      React.createElement("div", {"className": (styles.small)},
        React.createElement("div", {"className": ( styles.smallText )},
          React.createElement("span", {"className": ( styles.time )},
            ( moment(prompt.created_at).format('HH:mm, ') )
          ),
          React.createElement("span", null, ( moment(prompt.created_at).format('DD MMM') ))
        )
      )

    shortMessage = if @state.showMask
      React.createElement("div", {"className": (styles.message_wrap_rejected_opened)},
        React.createElement("div", {"className": (styles.message_wrap_inner_rejected_opened)},
          (prompt.text),
          React.createElement("div", {"className": (styles.hide_btn), "onClick": (_.partial(@toggleRejectedPrompt, @getPromptId(prompt)))},
            React.createElement("div", {"className": (styles.close_btn)},
              React.createElement("div", {"className": (styles.in1)}),
              React.createElement("div", {"className": (styles.in2)})
            ), """
            Hide message
""")
        ),
        ( datetime )
      )
    else
      React.createElement("div", {"className": (styles.message_wrap_rejected)},
        React.createElement("div", {"className": (styles.message_wrap_inner_rejected), "onClick": (_.partial(@toggleRejectedPrompt, @getPromptId(prompt)))}, " Show message "),
        ( datetime )
      )

    content =  if prompt.status in [PROMPT_STATUSES.REJECTED, PROMPT_STATUSES.EDITED] || conversationIsClosed(@props.conversation)
      shortMessage
    else
      React.createElement("div", {"className": (styles.message_wrap)},
        React.createElement("div", {"className": (styles.message_wrap_inner)},
          React.createElement(MultilineText, {"text": prompt.text})
        ),
        React.createElement("div", {"className": (styles.buttons_wrap)},
          React.createElement("div", {"className": (styles.reject), "onClick": (_.partial(@rejectPrompt, prompt))}, "Reject "),
          React.createElement("div", {"className": (styles.send), "onClick": (_.partial(@sendPrompt, prompt))}, "Send "),
          React.createElement("div", {"className": (styles.edit), "onClick": (_.partial(@editPrompt, prompt))}, "Edit ")
        ),
        ( datetime )
      )

    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.person)}),
      ( content )
    )

module.exports = PromptsComponent;
