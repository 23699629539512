moment = require 'moment'
classnames = require 'classnames'

BaseComponent = require '../../../BaseComponent'
styles = require '../dashboard.styl'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
ChartComponent = require '../../ChartComponent/ChartComponent'
{buildOptions} = require '../../../../lib/dashboard-utils'
AgentChatsStore = require './AgentChatsStore'

class AgentChatsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> AgentChatsStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  getChatsEachHour: =>
    xAxis: type: 'category'
    series:[
      name: 'Agent:'
      colorByPoint: true
      data: @personalStore.chats_each_hour
    ]
    tooltip:
      headerFormat: '<span style="font-size:11px"><b>{point.key}</b></span><br>',
      pointFormat: '<span>{point.y}</span> conversations<br/>'
    chart: type: 'column'
    legend: enabled: false
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: "Average amount of conversations per agent"
    yTitle: 'Count'

  getOverload: =>
    xAxis: type: 'category'
    series:[
      name: 'Agent:'
      colorByPoint: true
      data: @personalStore.overload
    ]
    tooltip:
      headerFormat: '<span style="font-size:11px"><b>{point.key}</b></span><br>',
      pointFormat: '<span>{point.y}</span>%<br/>'
    chart: type: 'column'
    legend: enabled: false
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: "% of clients with bad experience"
    yTitle: 'Percents'

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'AgentChatEachHour',  \
                  "options": (buildOptions @getChatsEachHour())
                })
              )
            ),
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'Overload',  \
                  "options": (buildOptions @getOverload())
                })
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )

module.exports = AgentChatsComponent 
