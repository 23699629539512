ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'

class RotationsStore extends BaseStore
  constructor: ->
    super()
    @rotations = []
    @loading = false
    @total = 0
    @error = null
    @registerActions()
  
  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.ROTATIONS_GET_LIST_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.ROTATIONS_GET_LIST_SUCCESS
        @loading = false
        @rotations = payload.rotations
        @total = payload.total
        @emitChange()
      when ActionTypes.ROTATIONS_GET_LIST_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.ROTATION_CREATE_SUCCESS
        @rotations.push(payload)
        @emitChange()
      when ActionTypes.ROTATION_DELETE_SUCCESS
        @rotations = @rotations.filter((r) -> r.id != payload.id)
        @emitChange()

module.exports = new RotationsStore()
