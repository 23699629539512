ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'


AgentAssignActionCreator =
  subscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/assign_users"
        actionType: ActionTypes.MQTT_ASSIGN_USERS_UPDATING
        initial: true

  unSubscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/assign_users"
        actionType: ActionTypes.MQTT_ASSIGN_USERS_UPDATING

module.exports = AgentAssignActionCreator
