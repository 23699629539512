BaseStore = require '../BaseStore'

class PersonalizedStore extends BaseStore
  constructor: ->
    super()
    @actionCreator = @getActionCreator()
    @_guid = @actionCreator.guid

  isPersonalAction: (guid) ->
    @_guid == guid

  getActionCreator: ->
    throw 'Abstract method. Not implemented Error'

module.exports = PersonalizedStore
