PersonalizedStore = require '../../stores/abstract/PersonalizedStore'
CSVExportActionCreator  = require './CSVExportActionCreator'
ActionTypes = require '../../constants/ActionTypes'

actionsMap = 
  "#{ActionTypes.CSV_EXPORT_REQUESTING}": ->
    @links = {}
    @loading = true
    @errors = null
    @emitChange()
  "#{ActionTypes.CSV_EXPORT_SUCCESS}": (links) ->
    @links = links
    @loading = false
    @emitChange()
  "#{ActionTypes.CSV_EXPORT_FAILURE}": (error) ->
    @error = error
    @loading = false
    @emitChange()

class CSVExportStore extends PersonalizedStore
  constructor: ->
    super()
    @links = {}
    @loading = false
    @errors = null
    @registerActions()

  getActionCreator: -> new CSVExportActionCreator()

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = CSVExportStore
