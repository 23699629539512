{ urls } = require '../config'

ShiftsAPI =
  createTeamleadShift: (data, success, error) ->
    $.ajax
      url: urls.ranking.shifts.createTeamleadShift
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify(data)
      success: success
      error: error
      timeout: 5000

module.exports = ShiftsAPI
