Dispatcher    = require '../dispatcher/Dispatcher'
ActionTypes   = require '../constants/ActionTypes'
CustomersAPI  = require '../api/CustomersAPI'
TagTargetsAPI = require '../api/TagTargetsAPI'

# todo inherit from src/actions/CustomersActionCreator
# todo and get 'get' and 'update' methods from the parent
CustomerInfoActionCreator =
  get: (id) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_REQUESTING, payload: id
    CustomersAPI.get id,
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_FAILURE, payload: error

  update: (id, attributes) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UPDATE_REQUESTING, payload: { id, attributes }
    CustomersAPI.update { id, attributes },
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UPDATE_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UPDATE_FAILURE, payload: error.responseJSON

  # todo use TagTargetActionCreator
  createTag: (customerId, tag) ->
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_CREATE_REQUESTING, payload:  { customerId, tag }
    TagTargetsAPI.create { tagId: tag, targetType: 'Customer', targetId: customerId },
      (response) -> Dispatcher.dispatch type: ActionTypes.TAG_TARGET_CREATE_SUCCESS, payload:  { customerId, tag }
      (error) -> Dispatcher.dispatch type: ActionTypes.TAG_TARGET_CREATE_FAILURE, payload: { error: error.responseJSON }

  # todo use TagTargetActionCreator
  destroyTag: (customerId, tag) ->
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_DESTROY_REQUESTING, payload: { customerId, tag }
    TagTargetsAPI.destroy { tagId: tag, targetType: 'Customer', targetId: customerId },
      (response) -> Dispatcher.dispatch type: ActionTypes.TAG_TARGET_DESTROY_SUCCESS, payload: { customerId, tag }
      (error) -> Dispatcher.dispatch type: ActionTypes.TAG_TARGET_DESTROY_FAILURE, payload: { error: error.responseJSON }

  # todo, fix it clone of CustomerFormActionCreator
  startConversationByCustomerId: (id, onSuccess) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_REQUESTING, payload: { id }
    CustomersAPI.startConversation { id },
      (response) =>
        { conversation } = response
        Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_SUCCESS, payload: { conversation }
        onSuccess?(conversation)
      (error) => Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_FAILURE, payload: error.responseJSON

module.exports = CustomerInfoActionCreator
