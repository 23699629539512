ActionTypes = require '../constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'
RankingAPI = require '../api/RankingAPI'

module.exports =
  getConversations: (params) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_CONVERSATIONS_REQUESTING
    
    RankingAPI.getClosedConversations(
      params
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.RANKING_CONVERSATIONS_SUCCESS
            payload: json.data
        else
          Dispatcher.dispatch
            type: ActionTypes.RANKING_CONVERSATIONS_FAILURE
            payload: json.error
      ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_CONVERSATIONS_FAILURE
          payload: 'An error occured while getting conversations list'
    )
