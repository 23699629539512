Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
AnomalyStore = require 'stores/AnomalyStore'
AnomalyAPI = require 'api/AnomalyAPI'
_ = require 'underscore'

AnomalyActionCreator =
  takeNewClick: () ->
    clicks = AnomalyActionCreator.anomalySearch()
    if clicks
      AnomalyAPI.sendAnomaly(data: {clicks: clicks}, type: 'take_new')
    Dispatcher.dispatch
      type: ActionTypes.ANOMALY_TAKE_NEW_CLICK
      payload: new Date()

  anomalySearch: () ->
    clicks = AnomalyStore.takeNewClicks
    if clicks.length == 50
      begin_time = clicks[0]
      intervals = clicks.slice(1).map (end_time) ->
        interval = end_time - begin_time
        begin_time = end_time
        interval
      begin_interval = intervals[0]
      diffs = intervals.slice(1).map (end_interval) ->
        diff = Math.abs(end_interval - begin_interval)
        begin_interval = end_interval
        diff
      result = true
      _.each(
        diffs,
        (diff) ->
          result = false if diff > 1000
      )
      if result
        return clicks
    return null

module.exports = AnomalyActionCreator
