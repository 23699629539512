classnames = require 'classnames'
HashTagGroup = require './HashTagGroup'
HashTagItem = require './HashTagItem'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
styles = require './hash-tag-list'

propTypes = require 'prop-types'
createReactClass = require 'create-react-class'

HashTagList = createReactClass(
  displayName: 'HashTagList'

  getOptions: ->
    {props} = @
    result = []
    props.groups.forEach (group, i) ->
      result = result.concat([
        React.createElement(HashTagGroup, { \
          "group": (group),  \
          "key": (group.id),  \
          "index": (i),  \
          "isReadyToDrag": (props.isReadyToDrag),  \
          "className": (styles.list_item),  \
          "onClick": (props.onGroupSelected),  \
          "editable": (props.editable),  \
          "onSave": (props.onGroupSave),  \
          "onDelete": (props.onGroupDelete),  \
          "isConversationTags": (props.isConversationTags),  \
          "swapItems": (props.swapGroups),  \
          "updatePosition": ((dragId) => props.updatePosition('groups', dragId)),  \
          "searchQuery": (props.searchQuery)
        })
      ])
      if group.tags
        tags = group.tags.map (tag, i) ->
          React.createElement(HashTagItem, { \
            "tag": (tag),  \
            "key": (group.id + tag.id.toString()),  \
            "index": (i),  \
            "isReadyToDrag": (props.isReadyToDrag),  \
            "className": (classnames(styles.list_item, styles.list_item_group)),  \
            "onClick": (props.onTagSelected),  \
            "editable": (props.editable),  \
            "isConversationTags": (props.isConversationTags),  \
            "onSave": (props.onTagSave),  \
            "onDelete": (props.onTagDelete),  \
            "tagTargetLoading": (tag.isLoading),  \
            "swapItems": (props.swapItems),  \
            "updatePosition": ((dragId) => props.updatePosition('tags', dragId)),  \
            "searchQuery": (props.searchQuery)
          })
        result = result.concat(tags)
    tags = props.tags.map (tag, i) ->
      tagTargetLoading = !!(props.tagTargetLoading || {})[tag.name]
      React.createElement(HashTagItem, { \
        "tag": (tag),  \
        "key": "#{tag.id}_#{tag.name}_#{i}",  \
        "index": (i),  \
        "isReadyToDrag": (props.isReadyToDrag),  \
        "className": (styles.list_item),  \
        "onClick": (props.onTagSelected),  \
        "editable": (props.editable),  \
        "isConversationTags": (props.isConversationTags),  \
        "onSave": (props.onTagSave),  \
        "onDelete": (props.onTagDelete),  \
        "tagTargetLoading": (tagTargetLoading),  \
        "swapItems": (props.swapItems),  \
        "updatePosition": ((dragId) => props.updatePosition('tags', dragId)),  \
        "searchQuery": (props.searchQuery)
      })

    result.concat tags

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
        (unless @props.loading
          React.createElement("ul", {"className": (styles.list)},
            (@getOptions())
          )
        )
      )
    )
)

HashTagList.propTypes =
  tags: propTypes.arrayOf(
    propTypes.shape(
      id: propTypes.number
      name: propTypes.string.isRequired
      checked: propTypes.bool
      tags_group_id: propTypes.number
    ).isRequired
  ).isRequired
  groups: propTypes.arrayOf(
    propTypes.shape(
      id: propTypes.number.isRequired
      name: propTypes.string.isRequired
      contains_checked: propTypes.bool
    ).isRequired
  ).isRequired
  onGroupSelected: propTypes.func.isRequired
  onTagSelected: propTypes.func.isRequired
  editable: propTypes.bool
  onGroupSave: propTypes.func
  onTagSave: propTypes.func
  onGroupDelete: propTypes.func
  onTagDelete: propTypes.func
  tagTargetLoading: propTypes.object
  updatePosition: propTypes.func

module.exports = HashTagList
