{NavLink} = require 'react-router-dom'
styles = require './group-list'

propTypes = require 'prop-types'
createReactClass = require 'create-react-class'

groupItemComponent = createReactClass({
  render: ->
    {group, onGroupDelete} = @props
    React.createElement(NavLink, { \
      "to": ("/groups/#{group.id}"),  \
      "className": (styles.item),  \
      "activeClassName": (styles.item_active)
    },
      React.createElement("span", null, (group.title)),
      React.createElement("i", { \
        "className": (styles.remove),  \
        "onClick": ((e) ->
          e.stopPropagation()
          e.preventDefault()
          onGroupDelete(group.id)
        )})
    )
})

groupItemComponent.propTypes =
  group: propTypes.shape({
    id: propTypes.number.isRequired
    title: propTypes.string.isRequired
  }).isRequired
  onGroupDelete: propTypes.func.isRequired

module.exports = groupItemComponent
