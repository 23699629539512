{ urls } = require 'root/config'

ScheduledMessagesAPI =
  getList: (scope, successCallback, errorCallback) ->
    $.ajax
      url: urls.admin.scheduledMessages.getList
      type: 'GET'
      data: scope
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: (id, attributes, successCallback, errorCallback) ->
    $.ajax
      url: urls.scheduledMessages.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  destroy: (id, successCallback, errorCallback) ->
    $.ajax
      url: urls.scheduledMessages.destroy(id)
      contentType: 'application/json'
      data: JSON.stringify({ id: id })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

module.exports = ScheduledMessagesAPI
