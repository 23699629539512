TagTargetsAPI = require '../api/TagTargetsAPI'
TagsAPI = require '../api/TagsAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

TagsConversationActionCreator =
  getPreselectedTagsForConversation: ->
      Dispatcher.dispatch type: ActionTypes.TAGS_PREINSTALLED_FOR_CONV_REQUESTING
      TagsAPI.getPreselectedTagsForConversation(
        (json) ->
          if json.success
            Dispatcher.dispatch
              type: ActionTypes.TAGS_PREINSTALLED_FOR_CONV_SUCCESS
              payload: json.data
          else
            console.error json.error
            Dispatcher.dispatch
              type: ActionTypes.TAGS_PREINSTALLED_FOR_CONV_FAILURE
              payload: json.error
        (error) ->
          console.error error
          Dispatcher.dispatch
            type: ActionTypes.TAGS_PREINSTALLED_FOR_CONV_FAILURE
            payload: 'An error occurred while getting conversation tags'
      )
  create: (tag, targetId) ->
    payload = {
      targetId
      tagName: tag.name
      targetType: tag.tag_type
      tagId: tag.id
    }
    Dispatcher.dispatch
      type: ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_REQUESTING
      payload: payload
    TagTargetsAPI.create payload,
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_SUCCESS
          payload: payload
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_PREINSTALLED_TARGET_CREATE_FAILURE
          payload: Object.assign(
            payload
            { error: error.responseJSON }
          )

  destroy: (tag, targetId) ->
    payload = {
      targetId
      tagName: tag.name
      targetType: tag.tag_type
      tagId: tag.id
    }
    Dispatcher.dispatch
      type: ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_REQUESTING
      payload: payload
    TagTargetsAPI.destroy payload,
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_SUCCESS
          payload: payload
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.TAGS_PREINSTALLED_TARGET_DESTROY_FAILURE
          payload: Object.assign(payload, {error: error.responseJSON})

module.exports = TagsConversationActionCreator
