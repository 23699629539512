BaseStore = require './BaseStore'
ActionTypes = require 'constants/ActionTypes'

actionsMap =
  "#{ActionTypes.CONVERSATION_ITEMS_GET_LIST_REQUESTING}": (conversationId) ->
    delete @errors[conversationId]
    delete @conversationItems[conversationId]
    @loadings[conversationId] = true
    @emitChange()
  "#{ActionTypes.CONVERSATION_ITEMS_GET_LIST_SUCCESS}": ({ conversationId, items }) ->
    delete @loadings[conversationId]
    @conversationItems[conversationId] = items
    @emitChange()
  "#{ActionTypes.CONVERSATION_ITEMS_GET_LIST_FAILURE}": ({ conversationId, error }) ->
    delete @loadings[conversationId]
    @errors[conversationId] = error
    @emitChange()

  "#{ActionTypes.CONVERSATION_ITEMS_GET_SYNC_LIST}": () ->
    @conversationItemsSyncLoading = true
    @emitChange()

  "#{ActionTypes.MQTT_CONVERSATION_ITEMS_UPDATING}": (payload) ->
    @conversationItemsSyncLoading = false
    Object.keys(payload.message).forEach (id) =>
      if payload.message[id]
        if payload.message[id].conversation_id && payload.message[id].conversation_id != @conversationId
          @conversationItemsSyncHash = {}
          @conversationItemsSync = []
          @conversationId = payload.message[id].conversation_id
        @conversationItemsSyncHash[id] ||= {}
        Object.keys(payload.message[id]).forEach (key) =>
          @conversationItemsSyncHash[id][key] = payload.message[id][key]
      else
        delete @conversationItemsSyncHash[id]
    @conversationItemsSync = Object.keys(@conversationItemsSyncHash).map (key) =>
      @conversationItemsSyncHash[key]
    @emitChange()

  "#{ActionTypes.CONVERSATION_ITEMS_CLEARE_STORE}": ->
    @conversationItems = {}
    @conversationItemsSync = []
    @conversationItemsSyncHash = {}
    @loadings = {}
    @errors = {}
    @emitChange()

  "#{ActionTypes.CONVERSATION_ITEMS_CLEARE_CURRENT_STORE}": ->
    @conversationItemsSync = []
    @conversationItemsSyncHash = {}
    @emitChange()

class ConversationItemsStore extends BaseStore

  constructor: ->
    super()
    @conversationItems = {}
    @conversationItemsSync = []
    @conversationItemsSyncHash = {}
    @conversationId = null
    @conversationItemsSyncLoading = false
    @loadings = {}
    @errors = {}
    @state = null
    @registerActions()

  _registerToActions: ({ type, payload, guid }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new ConversationItemsStore
