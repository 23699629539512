PersonalizedStore = require '../../../../stores/abstract/PersonalizedStore'
ActionTypes = require '../../../../constants/ActionTypes'

class PromptsStore extends PersonalizedStore

  constructor: ->
    super()
    @text = null
    @promptId = null
    @registerActions()
    
  getActionCreator: => require('../../../../actions/PromptsActionCreator')

  _registerToActions: ({ type, payload, guid }) =>
    if @isPersonalAction(guid)
      switch type
        when ActionTypes.PROMPTS_EDIT
          @text = payload.attributes.text
          @focus = true
          @promptId = payload.attributes.id
          @emitChange()

    
module.exports = new PromptsStore
