moment = require 'moment'
classnames = require 'classnames'

BaseComponent = require '../../../BaseComponent'
ChartComponent = require '../../ChartComponent/ChartComponent'
PercentOfGoodRTAndWTStore = require './PercentOfGoodRTAndWTStore'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
{buildOptions} = require '../../../../lib/dashboard-utils'
styles = require '../dashboard.styl'  

class PercentOfGoodRTAndWTComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> PercentOfGoodRTAndWTStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  getChartOptions: =>
    datesList = _.map [-@personalStore.analyticDaysInterval..0], (daysAgo) =>
      date = moment(@props.now)
        .utcOffset(@props.offset)
        .subtract(Math.abs(daysAgo), 'days')
      date.format 'D-MMM'

    xAxis: categories: datesList
    yAxis: {
      plotLines:[
        value: @personalStore.benchmark
        color: 'red'
        width: 2
        label: 
          text:"Benchmark is #{@personalStore.benchmark}%"
        zIndex: 4
      ]
      min: 0
      minRange: @personalStore.benchmark
    }
    series: [{
      name: 'Chats with Good Waitnig Time'
      data: @personalStore.wt
      color: '#76d466'
    }, {
      name: 'Chats with Good Response Time'
      data: @personalStore.rt
      color: '#53a8ff'
    }]
    chart: type: 'column'
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: '% of chats with good RT and WT'
    yTitle: 'Percents'
    tooltip: 
      valueSuffix: '%'
      shared: true

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'GoodRtWt',  \
                  "options": (buildOptions @getChartOptions())
                })
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )

module.exports = PercentOfGoodRTAndWTComponent
