moment = require 'moment'

timeToDay = (time) -> moment(time).format('YYYY-MM-DD')

sortScheduledItems = (itemsMap, itemType) ->
  sortedByDates = new Map(Array.from(itemsMap).sort (a, b) ->
    moment(a[0]) - moment(b[0]))
  sortedByDates.forEach (value, key) ->
    if value[itemType]
      sorted = value[itemType].sort (a, b) ->
        moment(a.time) - moment(b.time)
      sortedByDates.set(key, {
        "#{itemType}": sorted
        total: sorted.length
      })
  sortedByDates

updateItems = (items, itemType, updatableItem, newItem) ->
  updatableItemTime = timeToDay(updatableItem.time)
  day = timeToDay(newItem.time)
  items.forEach (value, key) ->
    if updatableItemTime == day && key == day
      newItems = value[itemType].map (item) =>
        if item.uuid == newItem.uuid
          return Object.assign({}, item, {
            text: newItem.text
            time: newItem.time
            updated_at: newItem.updated_at
            id: newItem.id
          })
        else return item
      newValue =
        "#{itemType}": newItems
        total: newItems.length
      items.set(key, newValue)
    if updatableItemTime != day && key == updatableItemTime
      newItems = items.get(updatableItemTime)[itemType].filter (item) => item.uuid != updatableItem.uuid
      newValue =
        "#{itemType}": newItems
        total: newItems.length
      items.set(key, newValue)
    if updatableItemTime != day && key == day
      if value[itemType]
        newItems = value[itemType].slice()
        newItems.push(Object.assign({}, updatableItem, {
          text: newItem.text
          updated_at: newItem.updated_at
          time: newItem.time
          id: newItem.id
        }))
        newValue =
          "#{itemType}": newItems
          total: newItems.length
      else
        newValue =
          total: value.total + 1
      items.set(key, newValue)
  items.forEach (value, key) ->
    items.delete(key) if value.total <= 0
  if updatableItemTime != day && !items.has(day)
    newValue =
      "#{itemType}": [Object.assign({}, updatableItem, {
        text: newItem.text
        updated_at: newItem.updated_at
        time: newItem.time
        id: newItem.id
      })]
      total: 1
    items.set(day, newValue)
  items = sortScheduledItems(items, itemType)
  items

destroyItem = (items, itemType, itemDay, itemId) ->
  items.forEach (value, key) ->
    if key == itemDay
      filteredItems = value[itemType].filter (filteredItem) ->
        filteredItem.id != itemId
      newValue =
        "#{itemType}": filteredItems
        total: filteredItems.length
      if newValue.total > 0
        items.set(itemDay, newValue)
      else
        items.delete(itemDay)
        return
      items.delete(key) if value.total <= 0
  items

pauseCustomerioEvents = (eventsMap, data) ->
  { text, event_id } = data if data
  eventsMap.forEach (value, key) ->
    if value.events
      if !text
        textEvents = value.events.map (textEvent) ->
          Object.assign({}, textEvent, {status: 'pause'})
        eventsMap.set(key, { events: textEvents, total: value.total })
      else if text && key == text
        textEvents = value.events.map (textEvent) ->
          if event_id && textEvent.id != event_id
            return textEvent
          else
            Object.assign({}, textEvent, {status: 'pause'})
        eventsMap.set(key, {
          events: textEvents
          total: value.total
          created_at: value.created_at
        })
  eventsMap

deleteCustomerioEvents = (eventsMap, data) ->
  { text, event_id } = data
  eventsMap.forEach (value, key) ->
    if event_id
      if key == text && value.events
        textEvents = value.events.filter (textEvent) -> textEvent.id != event_id
        eventsMap.set(key, {
          events: textEvents
          total: textEvents.length
          created_at: value.created_at
        })
    else
      eventsMap.delete(key) if key == text
    eventsMap.delete(key) if eventsMap.has(key) && eventsMap.get(key).total <= 0
  eventsMap

module.exports = {
  updateItems
  destroyItem
  pauseCustomerioEvents
  deleteCustomerioEvents
}