ActionTypes = require 'constants/ActionTypes'
BaseStore = require './BaseStore'


class AgentsDashboardStore extends BaseStore
  constructor: ->
    super()
    @agents = {}
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_DASHBOARD_USERS_REQUEST
        @loading = true
        @agents = {}
        @emitChange()

      when ActionTypes.MQTT_DASHBOARD_USERS_UPDATING_INITIAL
        @loading = true
        @agents = {}
        @emitChange()

      when ActionTypes.MQTT_DASHBOARD_USERS_UPDATING
        Object.keys(payload.message).forEach (id) =>
          if payload.message[id]
            @agents[id] ||= {}
            Object.keys(payload.message[id]).forEach (key) =>
              @agents[id][key] = payload.message[id][key]
            delete @agents[id] unless @agents[id].id
          else
            delete @agents[id]
        @loading = false if payload.initial
        @emitChange()

module.exports = new AgentsDashboardStore()
