classnames = require 'classnames'

styles = require './save-button'

propTypes = require 'prop-types'

class SaveButtonComponent extends React.Component
  @propTypes:
    type: propTypes.oneOf(['normal', 'tryAgain'])
    process: propTypes.bool
    popup: propTypes.shape(
      type: propTypes.oneOf(['success', 'error'])
      message: propTypes.string.isRequired
    )

  @defaultProps:
    type: 'normal'
    process: false

  onClick: (e) =>
    e.preventDefault() if @props.process

  labelByType: (type) ->
    switch type
      when 'normal' then 'Save'
      when 'tryAgain' then 'Try again'

  iconClassByType: (type) ->
    switch type
      when 'normal' then 'fa-floppy-o'
      when 'tryAgain' then 'fa-repeat'

  render: ->
    btnClassName = classnames(
      styles.button_common, styles["button_type_#{@props.type}"],
      "#{styles.process}": @props.process
    )

    React.createElement("div", {"className": styles.root},
      React.createElement("button", {"className": btnClassName, "onClick": @onClick},
        React.createElement("i", {"className": "#{styles.button_icon} fa #{@iconClassByType(@props.type)}"}),
        ( @labelByType(@props.type) ),
        (
          if @props.process
            React.createElement("img", {"src": (require("!file-loader!../../../assets/images/processing.svg"))})
        )
      ),
      (
        if @props.popup?
          type = @props.popup.type || 'success'
          React.createElement("div", {"className": ( classnames(styles.tooltip, styles["tooltip_#{type}"]) )},
            ( @props.popup.message )
          )
      )
    )

module.exports = SaveButtonComponent
