classnames = require 'classnames'
{ Link } = require 'react-router-dom'
browserHistory = require './../../../history'
DateTimePicker = require 'react-widgets/lib/DateTimePicker'

RemindersStore = require 'stores/admin/RemindersStore'
RemindersActionCreator = require 'actions/admin/RemindersActionCreator'
styles = require './reminder-edit-component.styl'
modalStyles = require 'components/shared/styles/modal.styl'
BaseComponent = require 'components/BaseComponent'

class ReminderEditComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @backUrl = "/admin/#{@props.match.params.type}"

    item = RemindersStore.reminders.get(@props.match.params.date)
    @reminder = item?.reminders.find (r) => r.id == +@props.match.params.reminderId

    @state =
      text: @reminder?.text || ''
      time: new Date(@reminder?.time)
    @initComponent()

  dependsOnStores: [RemindersStore]

  componentWillMount: =>
    item = RemindersStore.reminders.get(@props.match.params.date)
    if !item?.reminders
      setTimeout(
        => RemindersActionCreator.get(@props.match.params.date)
        10
      )

  getState: =>
    item = RemindersStore.reminders.get(@props.match.params.date)
    @reminder = item?.reminders.find (r) => r.id == +@props.match.params.reminderId

    if @reminder
      text: @reminder.text
      time: new Date(@reminder?.time)

  onTextChange: (e) =>
    @setState
      text: e.target.value

  onSetDate: (newTime) =>
    { time } = @state
    time.setFullYear(newTime.getFullYear())
    time.setDate(newTime.getDate())
    time.setMonth(newTime.getMonth())
    @setState
      time: time

  onSetTime: (newTime) =>
    { time } = @state
    time.setHours(newTime.getHours())
    time.setMinutes(newTime.getMinutes())
    @setState
      time: time

  getInitialTime: ->
    time = new Date()
    time.setSeconds(0)
    time

  onPresetClick: (presetedHours) ->
    presetedTime = @getInitialTime()
    presetedTime.setMinutes(presetedTime.getMinutes() + (presetedHours * 60))
    @setState time: presetedTime

  onSaveReminder: =>
    RemindersActionCreator.update(
      @props.match.params.reminderId
      Object.assign({}, @reminder, @state)
      @reminder
      @onSuccess
    )

  onSuccess: =>
    browserHistory.default.push @backUrl

  render: =>
    (
      React.createElement("div", null,
        React.createElement(Link, {"className": (modalStyles.cross), "to": (@backUrl)}),
        React.createElement("div", {"className": (modalStyles.title)}, """
          Edit reminder
"""),
        React.createElement("div", {"className": (modalStyles.content)},
          React.createElement("div", {"className": (styles.reminder)},
            React.createElement("textarea", { \
              "className": (styles.textarea),  \
              "value": (@state.text),  \
              "onChange": (@onTextChange),  \
              "placeholder": "Enter text here"
            }),
            React.createElement("div", {"className": (styles.datepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set date",  \
                "time": (false),  \
                "onChange": (@onSetDate),  \
                "value": (@state.time)
              })
            ),
            React.createElement("div", {"className": (styles.timepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set time",  \
                "date": (false),  \
                "onChange": (@onSetTime),  \
                "value": (@state.time)
              })
            ),
            React.createElement("div", {"className": (styles.preset_options)},
              React.createElement("button", { \
                "className": (styles.preset_option),  \
                "onClick": (@onPresetClick.bind @, 0.5)
              }, """
                30m
"""),
              React.createElement("button", { \
                "className": (styles.preset_option),  \
                "onClick": (@onPresetClick.bind @, 2)
              }, """
                2h
"""),
              React.createElement("button", { \
                "className": (styles.preset_option),  \
                "onClick": (@onPresetClick.bind @, 4)
              }, """
                4h
"""),
              React.createElement("button", { \
                "className": (styles.preset_option),  \
                "onClick": (@onPresetClick.bind @, 12)
              }, """
                12h
"""),
              React.createElement("button", { \
                "className": (styles.preset_option),  \
                "onClick": (@onPresetClick.bind @, 24)
              }, """
                24h
""")
            ),
            (if RemindersStore.error
              React.createElement("p", {"className": (styles.error)}, (RemindersStore.error))
            ),
            React.createElement("div", {"className": (styles.save_reminder_wrapper)},
              React.createElement("div", {"className": (styles.save_reminder), "onClick": (@onSaveReminder)}, """
                Save reminder
""")
            )
          )
        )
      )
    )

module.exports = ReminderEditComponent
