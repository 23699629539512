Auth = require 'j-toker'
{ urls } = require '../config'
# window.Cookies = Cookies = require 'js-cookie'

AuthAPI =
  validateToken: (successCallback, errorCallback) ->
    $.ajax
      url: urls.current
      type: 'GET'
      success: successCallback
      error: errorCallback
      timeout: 20000

module.exports = AuthAPI
