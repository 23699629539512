BaseComponent = require 'components/BaseComponent'
CustomerioEventsActionCreator = require 'actions/admin/CustomerioEventsActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
confirm = require 'components/shared/ConfirmDialog'
CustomerioAccordionByText = require 'components/admin/CustomerioAccordionByText'
CustomerioEventsStore = require 'stores/admin/CustomerioEventsStore'
styles = require 'components/admin/assets/common-styles.styl'
customerioStyle = require './customerio-events-component.styl'

class CustomerioEventsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [CustomerioEventsStore]

  componentWillMount: =>
    setTimeout(
      -> CustomerioEventsActionCreator.getList()
      10
    )

  onStopCampaigns: =>
    confirm(
      'This action will cancel all scheduled activities.'
      {
        confirmTitle: 'Cancel all campaigns'
      }
    ).then(
      => CustomerioEventsActionCreator.pause()
      ->
    )

  render: ->
    eventsKeys = Array.from(CustomerioEventsStore.events.keys())
    React.createElement("section", {"className": (styles.container)},
      (if CustomerioEventsStore.error
        React.createElement("span", {"className": (styles.error)}, (CustomerioEventsStore.error))
      ),
      React.createElement(LoadableWrapperComponent, {"loading": (CustomerioEventsStore.loading)},
        (eventsKeys.map (key) =>
          React.createElement(CustomerioAccordionByText, { \
            "key": (key),  \
            "text": (key),  \
            "data": (CustomerioEventsStore.events.get(key)),  \
            "itemsStore": (CustomerioEventsStore),  \
            "itemsActionCreator": (CustomerioEventsActionCreator)
          })
        )
      ),
      React.createElement("button", { \
        "className": (customerioStyle.pause_all_button),  \
        "onClick": (@onStopCampaigns)
      }, """
        Stop all campaigns
""")
    )

module.exports = CustomerioEventsComponent
