Dispatcher = require 'root/dispatcher/Dispatcher'
RemindersAPI = require 'root/api/admin/RemindersAPI'
ActionTypes = require 'constants/ActionTypes'

RemindersActionCreator =
  getList: (scope) ->
    Dispatcher.dispatch type: ActionTypes.ADMIN_REMINDERS_GET_REQUESTING
    RemindersAPI.getList(
      scope
      (days) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_GET_SUCCESS
          payload: days
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_GET_FAILURE
          payload: 'An error occurred while get reminders'
    )

  get: (data) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_REQUESTING
      payload: data.date
    RemindersAPI.getList(
      data
      (reminders) ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_SUCCESS
          payload:
            data: reminders
            day: data.date
      ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_FAILURE
          payload:
            error: 'An error occurred while get reminders'
            day: data.date
    )

  update: (id, attributes, previousReminder, successCallback, errorCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_REMINDERS_UPDATE_REQUESTING
      payload: previousReminder
    RemindersAPI.update(
      id
      attributes
      (data) ->
        if data.errors
          return Dispatcher.dispatch
            type: ActionTypes.ADMIN_REMINDERS_UPDATE_FAILURE
            payload:
              error: data.responseJSON?.errors?[0]
              time: previousReminder.time
        successCallback?(data)
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_UPDATE_SUCCESS
          payload:
            data: data
            time: previousReminder.time
      (data) ->
        errorCallback?(data.responseJSON?.errors?[0])
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_UPDATE_FAILURE
          payload:
            error: data.responseJSON?.errors?[0] || 'An error occurred while updating reminder'
            time: previousReminder.time
    )

  destroy: (id, day, successCallback) ->
    Dispatcher.dispatch
      type: ActionTypes.ADMIN_REMINDERS_DESTROY_REQUESTING
      payload: day
    RemindersAPI.destroy(
      id
      () ->
        successCallback?()
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_DESTROY_SUCCESS
          payload:
            id: id
            day: day
      () ->
        Dispatcher.dispatch
          type: ActionTypes.ADMIN_REMINDERS_DESTROY_FAILURE
          payload:
            error: 'An error occurred while deletting reminder'
            day: day
    )

module.exports = RemindersActionCreator
