ORDER_FIELDS = [
  'key',
  'is_draft',
  'title',
  'payment_status',
  'created_at',
  '_id',
  'number',
  'client',
  'deadline',
  'main_status',
  'size',
  'tags',
  'type',
]

module.exports = {
  ORDER_FIELDS
}