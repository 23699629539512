ActionTypes = require '../constants/ActionTypes'
BaseStore = require './BaseStore'


class RankingMessageStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @error = null
    @conversation = null
    @stats =
      total: 0
      ranked: 0
      to_rank: 0
    @messages = {}
    @messagesGroups = null
    @messagesTagged = {}
    @messagesUntagged = {}
    @messagesSkipped = {}
    @currentMessageId = null
    @registerActions()

  nextMessage: =>
    firstUntaggedId = null
    @messagesTagged = {}
    @messagesUntagged = {}
    for m in @conversation.message_tags
      @messages[m.message_id] = m
      if m.tag_name
        @messagesTagged[m.message_id] = m
      else
        firstUntaggedId ||= m.message_id unless @messagesSkipped[m.message_id]
        @messagesUntagged[m.message_id] = m
    @currentMessageId = firstUntaggedId


  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.RANKING_MESSAGE_REQUESTING
        @loading = true
        @messages = {}
        @messagesTagged = {}
        @messagesUntagged = {}
        @messagesSkipped = {}
        @error = null
        @emitChange()
      when ActionTypes.RANKING_MESSAGE_SUCCESS
        @loading = false
        @conversation = payload
        if payload.stats
          @stats = payload.stats
        if @conversation.conversation_id
          @nextMessage()
        else
          @error = "All conversations has beed ranked"
        @emitChange()
      when ActionTypes.RANKING_MESSAGE_GROUPS_SUCCESS
        @messagesGroups = payload.groups
        if payload.stats
          @stats = payload.stats
        @emitChange()
      when ActionTypes.RANKING_SELECT_MESSAGE
        @currentMessageId = payload.messageId
        @emitChange()
      when ActionTypes.RANKING_MESSAGE_RANK_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.RANKING_MESSAGE_SKIP
        @messagesSkipped[payload] = @messages[payload]
        @nextMessage()
        @emitChange()

module.exports = new RankingMessageStore()
