BaseComponent = require '../../BaseComponent'
styles = require './single-column.styl'
ModalComponent = require "../ModalComponent/ModalComponent"

class SingleColumnComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  render: =>
    { col1, modal } = @props
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.col1)},
        ( col1 )
      ),
      (
        React.createElement(ModalComponent, null, " ", ( modal ), " ") if modal
      )
    )

module.exports = SingleColumnComponent
