Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
GroupsAPI = require '../api/GroupsAPI'
AgentsAPI = require '../api/AgentsAPI'
ChannelsAPI = require '../api/ChannelsAPI'

module.exports = {
  getGroups: (page, perPage) ->
    Dispatcher.dispatch type: ActionTypes.GROUPS_GET_REQUESTING

    errorMsg = 'An error occurred while getting groups'
    GroupsAPI.getGroups(
      {
        page
        per_page: perPage
      }
      _successCallback(ActionTypes.GROUPS_GET_SUCCESS, ActionTypes.GROUPS_GET_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUPS_GET_FAILURE, errorMsg)
    )

  getGroupPages: (perPage) ->
    Dispatcher.dispatch type: ActionTypes.GROUPS_PAGES_REQUESTING

    errorMsg = 'An error occurred while getting group pages'
    GroupsAPI.getGroupPages(
      per_page: perPage
      _successCallback(ActionTypes.GROUPS_PAGES_SUCCESS, ActionTypes.GROUPS_PAGES_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUPS_PAGES_FAILURE, errorMsg)
    )

  createGroup: (title, successCallback) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_CREATE_REQUESTING
    errorMsg = 'An error occurred while creating new group'
    GroupsAPI.createGroup(
      {title}
      _successCallback(ActionTypes.GROUP_CREATE_SUCCESS, ActionTypes.GROUP_CREATE_FAILURE, errorMsg, successCallback)
      _failureCallback(ActionTypes.GROUP_CREATE_FAILURE, errorMsg)
    )

  deleteGroup: (id, successCallback) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_DELETE_REQUESTING
    errorMsg = 'An error occurred while deleting group'
    GroupsAPI.deleteGroup(
      id
      _successCallback(ActionTypes.GROUP_DELETE_SUCCESS, ActionTypes.GROUP_DELETE_FAILURE, errorMsg, successCallback)
      _failureCallback(ActionTypes.GROUP_DELETE_FAILURE, errorMsg)
    )

  getAllGroupOptions: ->
    Dispatcher.dispatch type: ActionTypes.GROUP_OPTIONS_REQUESTING
    errorMsg = 'An error occurred while getting all agents and channels'
    GroupsAPI.getAllGroupOptions(
      _successCallback(ActionTypes.GROUP_OPTIONS_SUCCESS, ActionTypes.GROUP_DELETE_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUP_DELETE_FAILURE, errorMsg)
    )

  getGroupComposition: (groupId) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_COMPOSITION_REQUESTING
    errorMsg = 'An error occurred while getting group composition'
    GroupsAPI.getGroupComposition(
      groupId
      _successCallback(ActionTypes.GROUP_COMPOSITION_SUCCESS, ActionTypes.GROUP_COMPOSITION_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUP_COMPOSITION_FAILURE, errorMsg)
    )

  saveSettings: (groupId, data, successCallback) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_UPDATE_REQUESTING
    errorMsg = 'An error occurred while saving group settings'

    GroupsAPI.updateGroup(
      groupId
      data
      (json) ->
        if json.success
          successCallback(json.data)
          Dispatcher.dispatch(
            type: ActionTypes.GROUP_UPDATE_SUCCESS
            payload:
              group: json.data
              additionalData: data
          )
        else
          console.error json.error
          Dispatcher.dispatch(
            type: ActionTypes.GROUP_UPDATE_FAILURE
            payload: errorMsg
          )
      _failureCallback(ActionTypes.GROUP_UPDATE_FAILURE, errorMsg)
    )

  getUsersForGroup: (data) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_GET_USERS_REQUESTING
    errorMsg = 'An error occurred while getting users for group'
    AgentsAPI.getUsersForGroup(
      data
      _successCallback(ActionTypes.GROUP_GET_USERS_SUCCESS, ActionTypes.GROUP_GET_USERS_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUP_GET_USERS_FAILURE, errorMsg)
    )

  getChannelsForGroup: (data) ->
    Dispatcher.dispatch type: ActionTypes.GROUP_GET_CHANNELS_REQUESTING
    errorMsg = 'An error occurred while getting channels for group'
    ChannelsAPI.getChannelsForGroup(
      data
      _successCallback(ActionTypes.GROUP_GET_CHANNELS_SUCCESS, ActionTypes.GROUP_GET_CHANNELS_FAILURE, errorMsg)
      _failureCallback(ActionTypes.GROUP_GET_CHANNELS_FAILURE, errorMsg)
    )
}

_successCallback = (successEvent, failureEvent, errorMsg, successCallback) ->
  (json) ->
    if json.success
      successCallback(json.data) if successCallback
      Dispatcher.dispatch(
        type: successEvent
        payload: json.data
      )
    else
      console.error errorMsg
      Dispatcher.dispatch(
        type: failureEvent
        payload: errorMsg
      )

_failureCallback = (failureEvent, errorMsg) ->
  ->
    console.error errorMsg
    Dispatcher.dispatch(
      type: failureEvent
      payload: errorMsg
    )