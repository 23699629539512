EMOJI_IMAGE_PATTERN = /<img class="emojione".+?>/g

ConversationItemUtils =
  getTypeIdIdentifier: (item) ->
    "#{item.type}#{item.id}"

  buildParagraphWithEmoji: (paragraph, key, className) ->
    text = paragraph.split(EMOJI_IMAGE_PATTERN)
    if text.length == 1
      return React.createElement("div", {"key": (key), "className": (className)}, (paragraph))

    emojies = paragraph.match(EMOJI_IMAGE_PATTERN)
    React.createElement("div", {"key": (key), "className": (className)},
      (emojies.map (emoji, i) ->
        React.createElement("span", {"key": (i)},
          React.createElement("span", null, (text[i])),
          React.createElement("span", { \
            "dangerouslySetInnerHTML": (
              __html: emoji
            )
          })
        )
      ),
      React.createElement("span", null, (text[text.length - 1]))
    )
  
module.exports = ConversationItemUtils
