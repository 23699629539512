classnames = require 'classnames'
{calculateTimerValue} = require '../../../../lib/utils'
styles = require './additions'
{IN_PROGRESS_STATUS} = require '../../../../constants/Orders'

propTypes = require 'prop-types' 

orderTagAddition = (props) ->
  {status, completeTime} = props.params
  isInProgress = status
    .toLowerCase()
    .indexOf(IN_PROGRESS_STATUS) != -1
  React.createElement("div", {"className": (classnames(styles.container,
    "#{styles.status_progress}": isInProgress 
  ))},
    React.createElement("span", {"className": (classnames(styles.status_text,
      "#{styles.status_text_progress}": isInProgress
    ))},
      (status)
    ),
    (if isInProgress
      timerValue = calculateTimerValue(completeTime)
      React.createElement("span", {"className": (styles.status_timer)},
        (timerValue)
      )
    )
  )

orderTagAddition.propTypes =
  params: propTypes.shape(
    # completeTime: propTypes.number.isRequired
    status: propTypes.string.isRequired
  ).isRequired

module.exports = orderTagAddition 
