GuidedActionCreator = require 'actions/abstract/GuidedActionCreator'
ActionTypes = require 'constants/ActionTypes'
DashboardAPI = require 'api/DashboardAPI'

class WaitingTimeActionCreator extends GuidedActionCreator
  get: (data, onSuccessCallback, onErrorCallback) =>
    @dispatch type: ActionTypes.WAITING_TIME_STAT_REQUESTING

    DashboardAPI.getWaitingTime(
      data
      (json) =>
        if json.success
          onSuccessCallback?()
          @dispatch
            type: ActionTypes.WAITING_TIME_STAT_SUCCESS
            payload: json.data
        else
          onErrorCallback?()
          @dispatch
            type: ActionTypes.WAITING_TIME_STAT_ERROR
            payload: json.error
      (response) =>
        onErrorCallback?()
        msg = 'An error occurred while getting waiting time statistic'
        @dispatch
          type: ActionTypes.WAITING_TIME_STAT_ERROR
          payload: msg
    )

module.exports = WaitingTimeActionCreator