config = require '../config'

module.exports = 
  get: (success, error) ->
    $.ajax {
      url: config.urls.csvExport.get
      contentType: 'application/json'
      type: 'GET'
      success
      error
      timeout: 5000
    }