Isvg = require 'react-inlinesvg'
repliesIcon = require '!file-loader!../../../assets/images/icons/replies.svg'

BaseComponent = require 'components/BaseComponent'
RankingTemplatePopup = require 'components/ranking/RankingTemplatePopup'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'

styles = require './styles'

class RankingTemplateSelector extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      opened: false
    @initComponent()

  onClose: =>
    if @state.opened
      setTimeout(
        ->
          InterfaceActionCreator.closePopup('rankingTemplate')
        10
      )
      @setState({ opened: !@state.opened })

  onPick: (replyTemplate) =>
    @props.onPick(replyTemplate)
    @onClose()

  onOpenButtonClick: (e) =>
    if @state.opened
      @onClose()
    else
      InterfaceActionCreator.openPopup(RankingTemplatePopup, {
        onPick: @onPick
        searchQuery: @props.searchQuery
        templateType: @props.templateType
        type: 'rankingTemplate'
        coordinates: e.target.getBoundingClientRect()
      })
    @setState({ opened: !@state.opened })

  componentWillUnmount: ->
    setTimeout(
      ->
        InterfaceActionCreator.closePopup('rankingTemplate')
      10
    )
    super()

  render: ->
    React.createElement("div", {"className": (styles.root), "ref": "root"},
      React.createElement("div", {"className": (styles.open_button), "onClick": (@onOpenButtonClick)},
        React.createElement(Isvg.default, {"src": (repliesIcon)})
      )
    )

module.exports = RankingTemplateSelector
