styles = require './customer_comment'
{ findDOMNode } = require 'react-dom'
BaseComponent = require 'components/BaseComponent'
InlineTextareaInputUnclickable = require 'components/shared/InlineTextareaInputUnclickable'
Isvg = require 'react-inlinesvg'
CustomersActionCreator = require 'actions/CustomersActionCreator.coffee'
MAX_HEIGHT = 65

class CustomerCommentComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      isSmall: true
      showArrow: false
      editing: false

  # todo move to props
  updateCustomerAttribute: (attribute, value) =>
    comment = value
    comment = '' if comment == ' '
    setTimeout(@enableArrow, 100)
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, {"#{attribute}": comment})
      10
    )

  onToggle: =>
    @setState({isSmall: !@state.isSmall})

  onOpen: =>
    @setState({isSmall: false})

  onEdit: =>
    @setState editing: true

  onBlur: =>
    @setState editing: false

  enableArrow: =>
    if findDOMNode(@refs.textInput)?.clientHeight > MAX_HEIGHT
      @setState({showArrow: true})
    else
      @setState({showArrow: false})

  componentDidMount: ->
    @enableArrow()

  render: ->
    { customer } = @props
    { editing } = @state
    containerStyle = styles.container_full
    if @state.isSmall && @state.showArrow
      containerStyle = styles.container_small
    else
    React.createElement("div", {"className": (styles.container_all)},
      (!editing &&
        React.createElement("button", { \
          "className": (styles.edit_button),  \
          "onClick": (@onEdit)
        },
          React.createElement("span", {"className": (styles.title_icon)},
            React.createElement(Isvg.default, {"src": (require 'components/shared/images/pancil.svg')})
          )
        )
      ),
      React.createElement("div", {"className": ( containerStyle ), "onClick": (@onOpen)},
        (if customer
          React.createElement(InlineTextareaInputUnclickable, { \
            "ref": "textInput",  \
            "className": (styles.textarea),  \
            "value": (customer.comment),  \
            "editing": (editing),  \
            "onBlur": (@onBlur),  \
            "onSubmit": (R.partial(@updateCustomerAttribute, ['comment']))
          })
        )
      ),
      (if @state.showArrow
        React.createElement("div", {"onClick": (@onToggle), "className": (styles.toggle)},
          (
            if @state.isSmall
              React.createElement("i", {"className": "fa fa-angle-down"})
            else
              React.createElement("i", {"className": "fa fa-angle-up"})
          )
        )
      )
    )

module.exports = CustomerCommentComponent
