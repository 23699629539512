# only with reactMixin.onClass

propTypes = require 'prop-types'

MultiselectMixin =
  propTypes:
    onMultiselectItemChange: propTypes.func
    multiselect: propTypes.shape(
      enabled: propTypes.bool.isRequired
      list: propTypes.arrayOf(propTypes.number).isRequired
    )

  getInitialState: ->
    multiselect:
      enabled: false
      list: []

  onMultiselectItemChange: (element, selected) ->
    list = @state.multiselect.list
    contains = R.contains(element, list)
    if selected && !contains
      list.push(element)
      @forceUpdate()
    else if !selected && contains
      @state.multiselect.list = R.without([element], list)
      @forceUpdate()

  onMultiselectItemChangeIfEnabled: (element, selected) ->
    @onMultiselectItemChange(element, selected) if @state.multiselect.enabled

  toggleMultiselect: ->
    @state.multiselect.enabled = !@state.multiselect.enabled
    @multiselectClearList() unless @state.multiselect.enabled
    @forceUpdate()

  multiselectClearList: ->
    @setState(multiselect: R.merge(@state.multiselect, list: []))

module.exports = MultiselectMixin