{ Link } = require 'react-router-dom'
browserHistory = require 'root/history'
BaseComponent = require 'components/BaseComponent'
LandingsListComponent = require './LandingsListComponent'
LandingsStore = require 'stores/LandingsStore'
LandingsActionCreator = require 'actions/LandingsActionCreator'
AuthStore = require 'stores/AuthStore'
dashboardStyles = require 'components/DashboardComponent/dashboard'
{ menus } = require 'root/config'
{ ROLES } = require 'constants/Agents'

styles = require './landings.styl'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'landings'
    accessConfig =
      toPage: item.accessToPage || null

class LandingsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      page: 0
      perPage: 30
    @initComponent()

  dependsOnStores: [LandingsStore]

  componentDidMount: ->
    super()
    if (ROLES[AuthStore.user.role].value in accessConfig.toPage) && AuthStore.user.system_editor
      setTimeout(
        =>
          LandingsActionCreator.getList
            page: @state.page
            per_page: @state.perPage
        10
      )
    else
      browserHistory.default.push '/'

  onPaginateClick: ({ selected }) =>
    page = selected
    LandingsActionCreator.getList
      page: page
      per_page: @state.perPage
    @setState { page }

  render: ->
    { landings, loading, error, total } = LandingsStore
    { perPage } = @state
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.header)},
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement(Link, {"to": '/landings/new', "className": (dashboardStyles.button_add)},
            React.createElement("i", {"className": ("#{dashboardStyles.button_icon} fa fa-plus-circle")}),
            React.createElement("span", {"className": (dashboardStyles.button_label)}, "Add Landing")
          )
        )
      ),
      React.createElement(LandingsListComponent, { \
        "error": (error),  \
        "loading": (loading),  \
        "landings": (landings),  \
        "pagesCount": (Math.ceil(total / perPage)),  \
        "paginateClickHandler": (@onPaginateClick)
      })
    )

module.exports = LandingsComponent
