{ urls } = require '../config'

RankingAPI =
  postConversationEstimate: (data, success, error) ->
    $.ajax
      url: urls.ranking.postConversationEstimate
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify(data)
      success: success
      error: error
      timeout: 5000

  getClosedConversations: (data, success, error) ->
    $.ajax {
      url: urls.ranking.getClosedConversations
      type: 'GET'
      contentType: 'application/json'
      data
      success
      error
    }

module.exports = RankingAPI
