PersonalizedStore = require '../../../stores/abstract/PersonalizedStore'
DashboardTabsActionCreator = require './DashboardTabsActionCreator'
ActionTypes = require '../../../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.DASHBOARD_REQUESTING}": ->
    @loading = true
    @emitChange()
  "#{ActionTypes.DASHBOARD_SUCCESS}": (data) ->
    @all = data.all.map (c) ->
      c.created_at = new Date c.created_at
      c
    @waiting = data.waiting.map (c) ->
      c.created_at = new Date c.created_at
      c
    @analytics = data.analytics.map (a) ->
      a.created_at = new Date a.created_at
      a.answer_times = a.answer_times.map (at) -> 
        at.created_at = new Date at.created_at
        at
      a
    @loading = false
    @emitChange()
  "#{ActionTypes.DASHBOARD_ERROR}": (error) ->
    @error = error
    @loading = false
    @emitChange()

class DashboardTabsStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @all = null
    @waiting = null
    @analytics = null
    @error = null
    @registerActions()

  getActionCreator: -> new DashboardTabsActionCreator()

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = DashboardTabsStore
