classnames = require 'classnames'

styles = require './conversations-component'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
ConversationsComponent = require '../abstract/ConversationsComponent'
ConversationsListComponent = require '../ConversationsListComponent/ConversationsListComponent'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
ConversationsMyStore = require '../../../stores/ConversationsMyStore'
FlagsStore = require 'stores/FlagsStore'
ConversationsUnassignedStore = require '../../../stores/ConversationsUnassignedStore'
AuthStore = require 'stores/AuthStore'
ConversationsActionCreator = require '../../../actions/ConversationsActionCreator'
AgentAssignActionCreator = require 'actions/AgentAssignActionCreator'
{ ASSIGN_BUTTON_ACCESS } = require 'constants/Agents'

ConversationsActionCreator = new ConversationsActionCreator()

class ConversationsMyComponent extends ConversationsComponent
  constructor: (props) ->
    super(props)
    @folder = 'my_conversations'
    @initComponent()

  dependsOnStores: [ConversationsMyStore, ConversationsUnassignedStore, FlagsStore]

  onSearch: (query, searchType) =>
    if query
      @search.query = query
      @search.searchType = searchType
      @search.pageSize = @props.pageSize
      ConversationsActionCreator.getMyList(
        queryString: @search.query,
        searchType: @search.searchType,
        size: @search.pageSize
      )
    else
      ConversationsActionCreator.searchMyReset()

  showMore: =>
    @search.pageSize += @props.pageSize
    ConversationsActionCreator.getMyList(
      queryString: @search.query,
      searchType: @search.searchType,
      size: @search.pageSize
    )

  componentWillUpdate: (props, state) =>
    searchEnabled = ConversationsMyStore.state.searchEnabled
    if !searchEnabled
      conversations = ConversationsMyStore.conversations
      unassignedConversations = ConversationsUnassignedStore.conversations
      @handleUpdatedConversations(conversations.concat(unassignedConversations))

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeMyConversations(AuthStore.user.id)
        ConversationsActionCreator.subscribeUnassignedConversations()
        if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
          AgentAssignActionCreator.subscribe()
      0
    )

  componentWillUnmount: ->
    super()
    ConversationsActionCreator.unSubscribeMyConversations(AuthStore.user.id)
    ConversationsActionCreator.unSubscribeUnassignedConversations()
    ConversationsActionCreator.setDefaultMyState()
    if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
      AgentAssignActionCreator.unSubscribe()

  render: ->
    conversations = ConversationsMyStore.conversations
    loading = ConversationsMyStore.state.loading
    searchEnabled = ConversationsMyStore.state.searchEnabled

    React.createElement("div", null,
      React.createElement("div", {"className": (styles.toolbarContainer)},
        React.createElement(SearchInputComponent, { \
          "key": (@folder),  \
          "onChange": (@onSearch),  \
          "onClear": (R.partial(@onSearch, [null])),  \
          "placeholder": "Search by keywords, #tags, @agents, *credentials",  \
          "clearCross": (true),  \
          "folder": (@folder),  \
          "autofocus": true
        }),
        (if searchEnabled && !loading
          React.createElement("div", {"className": (styles.search_result_amount)}, """
            Results: """, (conversations.length)
          )
        )
      ),
      React.createElement(LoadableWrapperComponent, { \
        "containerClassName": (styles.list_container),  \
        "loading": (loading)
      },
        React.createElement(ConversationsListComponent, { \
          "flags": (FlagsStore.flags || {}),  \
          "folder": (@folder),  \
          "conversations": (conversations),  \
          "order": (if searchEnabled then 'updated_at' else 'default'),  \
          "limit": (@searchPageSize()),  \
          "searchEnabled": (searchEnabled),  \
          "showMoreLoading": (loading && searchEnabled),  \
          "loadMore": (@showMore),  \
          "loading": (loading)
        })
      )
    )

module.exports = ConversationsMyComponent
