styles         = require './double-column.styl'
classnames     = require 'classnames'
BaseComponent  = require '../../BaseComponent'
ModalComponent = require "../ModalComponent/ModalComponent"

class DoubleColumnComponent extends React.Component
  render: =>
    { col1, col2, modal } = @props
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.col1)},
        ( col1 )
      ),
      React.createElement("div", {"className": (styles.col2_overflow)},
        ( col2 )
      ),
      (
        React.createElement(ModalComponent, null, " ", ( modal ), " ") if modal
      )
    )

module.exports = DoubleColumnComponent
