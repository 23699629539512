Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'
CustomersAPI = require 'api/CustomersAPI'
GuidedActionCreator = require './abstract/GuidedActionCreator'

browserHistory = require './../history'

module.exports =
  getList: (scope, isNewQuery) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_LIST_REQUESTING, payload: { scope }
    CustomersAPI.getList scope,
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMERS_GET_LIST_SUCCESS
          payload:
            customers: data.customers
            total: data.total
            isNewQuery: isNewQuery
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_LIST_FAILURE, payload: error

  new: -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_NEW

  get: (id, data) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_GET_REQUESTING, payload: id
    CustomersAPI.get(
      id
      data
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMER_GET_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMER_GET_FAILURE, payload: error
    )

  # CREATE
  create: (attributes, onSuccessCallback, onFailCallback) ->
    # Dispatcher.dispatch type: ActionTypes.UPDATE_EDITING_PARAMS, payload: attributes
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_CREATE_REQUESTING, payload: attributes
    CustomersAPI.create attributes,
      (data) ->
        Dispatcher.dispatch { type: ActionTypes.CUSTOMER_CREATE_SUCCESS, payload: data }
        onSuccessCallback? data
      (error) ->
        Dispatcher.dispatch type: ActionTypes.CUSTOMER_CREATE_FAILURE, payload: error.responseJSON
        onFailCallback? error

  # UPDATE
  update: (id, attributes) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_UPDATE_REQUESTING, payload: { id, attributes }
    CustomersAPI.update { id, attributes },
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMER_UPDATE_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMER_UPDATE_FAILURE, payload: error.responseJSON

  # DESTROY
  batchDestroy: (ids, menu) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_BATCH_DESTROY_REQUESTING, payload: { ids, menu }
    CustomersAPI.batchDestroy { ids, menu },
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMERS_BATCH_DESTROY_SUCCESS
          payload: data
        browserHistory.default.push('/')
        browserHistory.default.push('/customers')
      ,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_BATCH_DESTROY_FAILURE, payload: error.responseJSON

  getPagesCount: (per_page) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_PAGES_COUNT_REQUESTING, payload: { per_page }
    CustomersAPI.getPagesCount { per_page },
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_PAGES_COUNT_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_GET_PAGES_COUNT_FAILURE, payload: error

  mergeCustomers: (id, replica_ids, success, failure) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_MERGE_REQUEST, payload: { id, replica_ids }
    CustomersAPI.mergeCustomers {id, replica_ids},
      (data) ->
        Dispatcher.dispatch type: ActionTypes.CUSTOMERS_MERGE_SUCCESS, payload: data
        success?()
      (error) ->
        Dispatcher.dispatch type: ActionTypes.CUSTOMERS_MERGE_FAILURE, payload: error.responseJSON
        failure?()

  unmergeCustomers: (id, replica_ids) =>
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UNMERGE_REQUEST, payload: { id, replica_ids }
    CustomersAPI.unmergeCustomers {id, replica_ids},
      (data) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UNMERGE_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_UNMERGE_FAILURE, payload: error.responseJSON

  clearSearch: -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_SEARCH_CLEAR

  search: (scope, isNewQuery, currentCustomerId) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMERS_SEARCH_REQUESTING
    CustomersAPI.getList scope,
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMERS_SEARCH_SUCCESS
          payload:
            customers: data.customers
            total: data.total
            isNewQuery: isNewQuery
            currentCustomerId: currentCustomerId
      (error) -> Dispatcher.dispatch type: ActionTypes.CUSTOMERS_SEARCH_FAILURE, payload: error
