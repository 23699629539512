classnames = require 'classnames'
styles = require './multistate-switch.styl'

propTypes = require 'prop-types'

class MultistateSwitchComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = value: props.values.indexOf(props.defaultValue)

  componentWillReceiveProps: (props) ->
    if @state.switch == null
      value = props.value
      value = !value if props.reverse
      @setState switch: value

  setControle: (e) =>
    position = Number.parseInt e.currentTarget.dataset.position
    @setState value: position
    @props.onToggle @props.values[position]

  onCellHover: (e) =>
    position = Number.parseInt e.currentTarget.dataset.position
    if @refs["title_#{position}"]
      @refs["title_#{position}"].classList.add(
        styles.switcher_cell_title_full
      )

  onCellBlur: (e) =>
    position = Number.parseInt e.currentTarget.dataset.position
    if @refs["title_#{position}"]
      @refs["title_#{position}"].classList.remove(
        styles.switcher_cell_title_full
      )

  render: ->
    return null if !Array.isArray @props.values || @props.values.length < 2
    bottomMarginClass = classnames(
      "#{styles.one_line_margin}": @props.values.length == 3
      "#{styles.two_line_margin}": @props.values.length > 3
    )
    React.createElement("div", null,
      React.createElement("span", { \
        "ref": "title_0",  \
        "className": (classnames @props.fontStyle, styles.title)
      },
        (@props.values[0])
      ),
      React.createElement("div", {"className": (classnames styles.switcher, bottomMarginClass)},
        React.createElement("div", { \
          "className": (styles.switcher_overlay),  \
          "onClick": (@toggleSwitch)
        },
          (@props.values.map (val, i) =>
            React.createElement("span", { \
              "key": (i),  \
              "data-position": (i),  \
              "className": (styles.switcher_cell),  \
              "onClick": (@setControle),  \
              "onMouseEnter": (@onCellHover),  \
              "onMouseLeave": (@onCellBlur)
            },
              (if i == @state.value
                React.createElement("div", {"className": (styles.switcher_control)})
              ),
              (if i > 0 && i < @props.values.length - 1
                React.createElement("span", { \
                  "ref": ("title_#{i}"),  \
                  "className": (classnames(
                    styles.switcher_cell_title
                    @props.fontStyle
                  ))
                },
                  (val)
                )
              )
            )
          )
        )
      ),
      React.createElement("span", { \
        "ref": ("title_#{@props.values.length - 1}"),  \
        "className": (classnames @props.fontStyle, styles.title)
      },
        (@props.values[@props.values.length - 1])
      )
    )

MultistateSwitchComponent.propTypes =
  defaultValue: propTypes.string.isRequired
  values: propTypes.arrayOf(propTypes.string).isRequired
  onToggle: propTypes.func.isRequired
  fontStyle: propTypes.string

module.exports = MultistateSwitchComponent
