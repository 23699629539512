ConversationsAPI = require 'api/ConversationsAPI.coffee'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

module.exports =
  selectAll: (id) ->
    ConversationsAPI.urls id,
      (data) =>
        if data.urls && data.urls.length > 0
          Dispatcher.dispatch
            type: ActionTypes.IMAGES_PREVIEW_CHECK
            payload:
              data: data.urls.reduce(
                      (prev, next) ->
                        prev[next.real_location] = next.real_location
                        prev
                      {}
                    )
        else 
          console.error 'system error no data from ConversationsAPI urls'
            
  imageCheck: (data) ->
    if !data
      data = {}
    setTimeout(
      ->
        Dispatcher.dispatch
          type: ActionTypes.IMAGES_PREVIEW_CHECK
          payload: {
            data
          }
      0
    )
