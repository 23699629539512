classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
Checkbox = require 'components/shared/Checkbox'
SearchInputComponent = require('components/shared/SearchInputComponent/SearchInputComponent.cjsx')
CredentialsFormComponent = require '../../CredentialsFormComponent/CredentialsFormComponent'
Button = require 'components/shared/Button'
CustomersStore = require 'stores/CustomersStore'
CustomersActionCreator = require 'actions/CustomersActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

styles = require './customer-merge-modal.styl'

class CustomerMergeModalComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      query: ''
      offset: 0
      limit: 10
      selectedCustomerIds: []
      customerId: props.data.customerId
    }
    @initComponent()

  dependsOnStores: [CustomersStore]

  onCustomerClick: (customerId) =>
    { selectedCustomerIds } = @state
    indexOfCustomer = selectedCustomerIds.indexOf customerId 
    if indexOfCustomer == -1
      selectedCustomerIds.push customerId
    else
      selectedCustomerIds.splice indexOfCustomer, 1
    @setState { selectedCustomerIds }

  onSearchClear: =>
    @setState { query: '', offset: 0 }
    CustomersActionCreator.clearSearch()

  onSearch: (query) =>
    CustomersActionCreator.search(
      {
        query_string: query
        limit: @state.limit
        offset: @state.offset
      }
      true
      @state.customerId
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      CustomersActionCreator.clearSearch
      0
    )

  loadMore: =>
    offset = @state.offset + @state.limit
    @setState { offset }
    CustomersActionCreator.search(
      {
        query_string: @state.query
        limit: @state.limit
        offset
      }
      false
      @state.customerId
    )

  onMergeClick: =>
    { customerId, selectedCustomerIds } = @state
    CustomersActionCreator.mergeCustomers(customerId, selectedCustomerIds) 
    @props.onClick()

  render: ->
    { selectedCustomerIds } = @state
    { onCustomerClick } = @

    React.createElement("div", {"className": (styles.container)},
      React.createElement("section", {"className": (styles.customers)},
        React.createElement("h2", {"className": (styles.title)}, "Merge accounts"),

        React.createElement("div", {"className": (styles.search)},
          React.createElement(SearchInputComponent, { \
            "onChange": (@onSearch),  \
            "onClear": (@onSearchClear),  \
            "placeholder": "Find accounts by email, phone, facebook etc",  \
            "clearCross": (true),  \
            "autofocus": (true),  \
            "defaultValue": (@state.query),  \
            "autofocus": true
          })
        ),
        (if CustomersStore.searchError
          React.createElement("p", {"className": (styles.error)}, (CustomersStore.searchError))
        else
          React.createElement("ul", {"className": (styles.customers_container)},
            (CustomersStore.search.map (customer) ->
              React.createElement("li", {"key": (customer.id), "className": (styles.customers_item)},
                React.createElement("div", { \
                  "onClick": (-> onCustomerClick(customer.id)),  \
                  "className": (styles.customer_data)
                },
                  React.createElement("span", {"className": (styles.checkbox)},
                    React.createElement(Checkbox, { \
                      "checked": (!!~selectedCustomerIds.indexOf(customer.id))
                    })
                  ),
                  React.createElement("div", {"className": (styles.customer_info)},
                    React.createElement("h3", {"className": (styles.customer_name)}, (customer.name)),
                    React.createElement(CredentialsFormComponent, { \
                      "credentials": (customer.credentials),  \
                      "disabled": (true)
                    })
                  )
                )
              )
            ),
            React.createElement("li", null,
              (if CustomersStore.searchLoading || CustomersStore.searchTotal && CustomersStore.searchTotal > @state.offset
                React.createElement("div", {"className": (styles.more_container)},
                  React.createElement(LoadableWrapperComponent, {"loading": (CustomersStore.searchLoading)},
                    React.createElement("button", { \
                      "className": (styles.more_button),  \
                      "onClick": (@loadMore)
                    }, """
                      load more
""")
                  )
                )
              )
            )
          )
        )
      ),
      React.createElement("section", {"className": (styles.controls)},
        React.createElement(Button, { \
          "className": (styles.cancel_button),  \
          "onClick": (@props.onClick),  \
          "title": "Cancel",  \
          "white": true
        }),
        React.createElement(Button, { \
          "onClick": (@onMergeClick),  \
          "title": "Merge",  \
          "disabled": (!selectedCustomerIds.length)
        })
      )
    )

module.exports = CustomerMergeModalComponent
