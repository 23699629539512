classnames = require 'classnames'
styles = require './button'
BaseComponent = require '../../BaseComponent'

class ButtonComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  render: =>
    React.createElement("div", { \
      "onClick": ( @props.onClick ),  \
      "className": ( classnames(styles.button, @props.className) )},
      React.createElement("span", {"className": ( styles.label )}, ( @props.label ))
    )

module.exports = ButtonComponent
