ActionTypes = require '../constants/ActionTypes'
BaseStore = require './BaseStore'

actionsMap =
  "#{ActionTypes.RANKING_CONVERSATIONS_REQUESTING}": ->
    @_clear()
    @loading = true
    @error = null
    @conversations = []
    @emitChange()
  "#{ActionTypes.RANKING_CONVERSATIONS_SUCCESS}": (data) ->
    { conversations, total, page_count: pageCount, ranking_stats: rankingStats, users } = data
    @total = total
    @pageCount = pageCount
    @conversations = conversations
    @agents = users || []
    @isAgentsDownloaded = true
    @loading = false

    if rankingStats
      @rankingStats =
        total: rankingStats.total
        ranked: rankingStats.ranked
        toBeRanked: rankingStats.to_rank

    @emitChange()
  "#{ActionTypes.RANKING_CONVERSATIONS_FAILURE}": (msg) ->
    @error = msg
    @loading = false
    @emitChange()

  "#{ActionTypes.RANKING_SUBMIT_REQUESTING}": ->
    @saveConversationIndex = true
    @emitChange()
  "#{ActionTypes.RANKING_SUBMIT_SUCCESS}": ->
    @shouldUpdateCounts = true
    @emitChange()

  "#{ActionTypes.TAGS_GET_LIST_SUCCESS}": (tags) ->
    conversationTags = {}
    clientTags = {}
    tags.map (tag) ->
      if tag.tag_type == 'customer'
        clientTags[tag.name] =
          label: tag.name
          value: tag.name
      if tag.tag_type == 'conversation'
        conversationTags[tag.name] =
          label: tag.name
          value: tag.name
    @conversationTags = conversationTags
    @clientTags = clientTags
    @emitChange()

  "#{ActionTypes.TAGS_GET_GROUP_LIST_SUCCESS}": (groups) ->
    groupTags = {}
    groups.map (group) ->
      groupTags[group.id] =
        label: group.name
        value: group.id
    @groupTags = groupTags
    @emitChange()

class RankingConversationsStore extends BaseStore
  constructor: ->
    super()
    @agents = []
    @conversationTags = {}
    @clientTags = {}
    @groupTags = {}
    @isAgentsDownloaded = false
    @shouldUpdateCounts = false
    @saveConversationIndex = false
    @rankingStats =
      total: 0
      ranked: 0
      toBeRanked: 0
    @error = null
    @registerActions()

    @loading = false
    @conversations = []
    @pageCount = 0
    @total = 0

  _clear: =>
    @agents = []
    @isAgentsDownloaded = false

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new RankingConversationsStore
