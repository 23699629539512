classnames = require 'classnames'

RankingMessageActionCreator = require 'actions/RankingMessageActionCreator'
AuthStore = require 'stores/AuthStore'
RankingMessageStore = require 'stores/RankingMessageStore'
BaseComponent = require 'components/BaseComponent'
styles = require './ranking-status'

class MessagesRankingStatusComponent extends BaseComponent

  constructor: (props) ->
    super(props)

    @currentMessageId = null
    @state =
      activeGroup: null
      tagName: null
    @initComponent()

  dependsOnStores: [RankingMessageStore]

  componentDidUpdate: (prevProps, prevState) ->
    super(prevProps, prevState)
    if @currentMessageId != RankingMessageStore.currentMessageId
      @currentMessageId = RankingMessageStore.currentMessageId
      if @currentMessageId
        message = RankingMessageStore.messages[@currentMessageId]
        if message
          @setState
            messageId: @currentMessageId
            conversationId: message.conversation_id
            activeGroup: message.tag_group
            tagName: message.tag_name
      else
        setTimeout(
          ->
            RankingMessageActionCreator.getMessages()
          0
        )

  componentWillMount: ->
    unless AuthStore.user.messages_ranker
      browserHistory.default.push '/'

  setGroup: (group) ->
    () =>
      @setState
        activeGroup: group

  onChangeTag: (tag) ->
    () =>
      @setState
        tagName: tag


  setTagName: (e, tag) ->
    if e && e.charCode == 32
      @rank(tag)

  rank: (tag) =>
    if tag
      data = JSON.stringify({
        message_id: @state.messageId
        conversation_id: @state.conversationId
        tag_name: tag
      })
      setTimeout(
        ->
          RankingMessageActionCreator.rank(data)
        0
      )
    else
      setTimeout(
        =>
          RankingMessageActionCreator.skip(@state.messageId)
        0
      )
      if RankingMessageStore.messages[@state.messageId]?.tag_name
        data = JSON.stringify({
          message_id: @state.messageId
          conversation_id: @state.conversationId
        })
        setTimeout(
          ->
            RankingMessageActionCreator.rank(data)
          0
        )
    @refs.skipInput.focus()

  render: ->
    React.createElement("form", {"onSubmit": ((e) => e.preventDefault())},
      React.createElement("ul", {"className": (styles.container)},
        React.createElement("li", {"key": 'Skip'},
          React.createElement("label", {"htmlFor": 'Skip'},
            React.createElement("input", { \
              "ref": "skipInput",  \
              "id": 'Skip',  \
              "onKeyPress": ((e) => @setTagName(e, null)),  \
              "onChange": (@onChangeTag(null)),  \
              "type": "radio",  \
              "name": "tag_name",  \
              "value": '',  \
              "checked": (if @state.tagName then '' else 'checked'),  \
              "autoFocus": true
            }),
            React.createElement("span", null, "Skip")
          )
        ),
        (if RankingMessageStore.messagesGroups
          Object.keys RankingMessageStore.messagesGroups
            .map (group) =>
              React.createElement("li", {"key": (group)},
                React.createElement("button", {"type": "button", "onClick": (@setGroup(group)), "value": (group)}, (group)),
                React.createElement("ul", null,
                  (RankingMessageStore.messagesGroups[group].map (element) =>
                    React.createElement("li", {"key": (element)},
                      React.createElement("label", {"htmlFor": (group+element)},
                        React.createElement("input", { \
                          "id": (group+element),  \
                          "onKeyPress": ((e) => @setTagName(e, element)),  \
                          "onChange": (@onChangeTag(element)),  \
                          "type": "radio",  \
                          "name": "tag_name",  \
                          "value": (element),  \
                          "checked": (if element == @state.tagName then 'checked' else '')
                        }),
                        (element)
                      )
                    )
                  )
                )
              )
        )
      ),
      React.createElement("input", {"type": "submit", "className": (classnames(styles.btn, styles.hide)), "value": "Rank"})
    )

module.exports = MessagesRankingStatusComponent
