config = require '../config'

AnomalyAPI =
  sendAnomaly: (attributes) ->
    $.ajax
      url: config.urls.anomalySend
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify(attributes)
      timeout: 5000

module.exports = AnomalyAPI
