BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionMap =
  "#{ActionTypes.SETTINGS_GET_REQUESTING}": ->
    @loading = true
    @error = undefined
    @errors = {}
    @emitChange()
  "#{ActionTypes.SETTINGS_GET_SUCCESS}": (settings) ->
    @settings = settings
    for key of @settings
      @values[key] = @settings[key].value
    @dataByType = @settings.crm_info.value
    @loading = false
    @error = undefined
    @emitChange()
  "#{ActionTypes.SETTINGS_GET_FAILURE}": (res) ->
    @loading = false
    # @error = res.params?.error
    @emitChange()

  "#{ActionTypes.SETTINGS_UPDATE_REQUESTING}": ->
    @loading = true
    @error = undefined
    @errors = {}
    @emitChange()
  "#{ActionTypes.SETTINGS_UPDATE_SUCCESS}": (settings) ->
    @settings = settings
    for key of @settings
      @values[key] = @settings[key].value
    @dataByType = @settings.crm_info.value
    @loading = false
    @error = undefined
    @emitChange()
  "#{ActionTypes.SETTINGS_UPDATE_FAILURE}": (errors) ->
    @loading = false
    @errors = errors
    @emitChange()

  "#{ActionTypes.SETTINGS_GET_BY_TYPE_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.SETTINGS_GET_BY_TYPE_SUCCESS}": (data) ->
    @loading = false
    @dataByType = data
    @emitChange()
  "#{ActionTypes.SETTINGS_GET_BY_TYPE_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

class SettingsStore extends BaseStore

  constructor: ->
    super()
    @settings = {}
    @values = {}
    @loading = false
    @errors = {}
    @dataByType = null
    @registerActions()

  _registerToActions: ({type, payload}) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new SettingsStore()
