TOKEN_KEY = 'token';

setToken = (token) ->
  localStorage.setItem TOKEN_KEY, token

getToken = () ->
  localStorage.getItem TOKEN_KEY

clearToken = () ->
  localStorage.removeItem TOKEN_KEY

module.exports = {
  setToken,
  getToken,
  clearToken
};