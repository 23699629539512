confirmable = (Component) ->
  class extends React.Component
    constructor: (props) ->
      super props
      @state =
        show: true

    dismiss: =>
      @setState(
        { show: false },
        => @props.dispose()
        )

    cancel: (value) =>
      @setState(
        { show: false }
        => @props.reject value
      )


    confirm: (value) =>
      @setState(
        { show: false }
        => @props.resolve value
        )

    render: ->
      React.createElement(Component, { \
        "confirm": (@confirm),  \
        "cancel": (@cancel),  \
        "dismiss": (@dismiss),  \
        "show": (@state.show),  \
        "confirmation": (@props.confirmation),  \
        "options": (@props.options)
      })

module.exports = confirmable