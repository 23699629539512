config = require '../config'
{ underscoreParams } = require '../lib/utils'

MessagesAPI =
  getList: (scope, success, error) ->
    $.ajax {
      url: config.urls.messages.getList
      type: 'GET'
      data: underscoreParams(scope)
      success
      error
    }

  send: (message, success, error) ->
    $.ajax {
      url: config.urls.messages.send
      contentType: 'application/json'
      type: 'POST'
      data: JSON.stringify underscoreParams(message)
      success
      error
    }

module.exports = MessagesAPI