uuid = require 'uuid'
BaseFormComponent = require 'components/shared/BaseFormComponent/BaseFormComponent'
RotationStore = require 'stores/RotationStore'
RotationsActionCreator = require 'actions/RotationsActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
WarningPopupComponent = require './WarningPopupComponent'
SaveButtonComponent = require 'components/shared/SaveButtonComponent/SaveButtonComponent'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'
ChannelsStore = require 'stores/ChannelsStore'
Button = require 'components/shared/Button'
styles = require 'components/shared/BaseFormComponent/base-form'
rotationStyles = require 'components/rotations/RotationsComponent/rotations'

class RotationsFormComponent extends BaseFormComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [RotationStore, ChannelsStore]

  attributes: ['id', 'name', 'main_condition', 'messages']

  defaults: ->
    name: ''
    main_condition: ''
    messages: []

  getState: ->
    attrs = _.extend({}, @defaults(), RotationStore.rotation)
    attributes: attrs

  getDataKey: =>
    @props.match.params.id

  requestData: =>
    id = @props.match.params.id
    if id
      _.defer RotationsActionCreator.get, id
    else
      _.defer RotationsActionCreator.new

  handlerValues: (e, attr) =>
    attributes = @state.attributes
    attributes[attr] = e.target.value

    @setState attributes: attributes

  handlerMessage: (e, index) =>
    attributes = @state.attributes
    attributes.messages[index] = e.target.value

    @setState attributes: attributes

  deleteMessage: (index) =>
    attributes = @state.attributes
    delete attributes.messages[index]
    @setState attributes: attributes

  getterMessage: (index) =>
    @state.attributes.messages[index]

  addMessage: =>
    attributes = @state.attributes
    attributes.messages.unshift('')

    @setState attributes: attributes

  getterValues: (attr) =>
    @state.attributes[attr]

  onSubmitForm: (e) =>
    e.preventDefault()
    attributes = @state.attributes
    if @state.attributes.id
      RotationsActionCreator.update(attributes)
    else
      RotationsActionCreator.create(attributes)

  removeRotation: () =>
    @setState deleting: true

  onCancelDeleting: () =>
    @setState deleting: false

  onApproveDeleting: () =>
    RotationsActionCreator.delete(@state.attributes.id)
    @setState deleting: false

  render: ->
    { loading } = RotationStore
    { attributes, deleting } = @state
    React.createElement("div", {"className": ( styles.container )},
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        (if deleting
          React.createElement(WarningPopupComponent, { \
            "text": "Are you sure you want to delete that rotation?",  \
            "onCancel": (@onCancelDeleting),  \
            "onDelete": (@onApproveDeleting)
          })
        ),
        React.createElement("form", {"onSubmit": ( @onSubmitForm )},
          ( @renderInput('name', 'Name',
              handler: _(@handlerValues).partial(_, 'name')
              value: _(@getterValues).partial('name')
              key: 'name'
          ) ),
          ( @renderTextarea('main_condition', 'Main Condition',
              handler: _(@handlerValues).partial(_, 'main_condition')
              value: _(@getterValues).partial('main_condition')
              key: 'main_condition'
          ) ),
          React.createElement(Button, { \
            "type": ('button'),  \
            "title": ('Add message'),  \
            "onClick": (@addMessage),  \
            "className": (rotationStyles.add_message_button)}),
          (attributes.messages.map((message, index) =>
            @renderTextarea('messages', "Message #{index + 1}",
              handler: _(@handlerMessage).partial(_, index)
              value: _(@getterMessage).partial(index)
              delete: _(@deleteMessage).partial(index)
              key: "message_#{index}"
            )
          )),
          React.createElement("div", {"className": ( styles.form_group )},
            React.createElement(SaveButtonComponent, { \
              "type": ('normal'),  \
              "process": (RotationStore.loading)
            }),
            (if attributes.id
              React.createElement(Button, { \
                "type": ('button'),  \
                "title": ('Delete'),  \
                "red": (true),  \
                "onClick": (@removeRotation),  \
                "className": (rotationStyles.delete_message_button)})
            )
          )
        )
      )
    )

module.exports = RotationsFormComponent
