GuidedActionCreator = require '../../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
DashboardAPI = require '../../../../api/DashboardAPI'
{ buildQueryParamsString } = require '../../../../lib/utils'

class SLATrackingActionCreator extends GuidedActionCreator
  getSLATrackingStatistic: (params) =>
    @dispatch type: ActionTypes.SLA_TRACKING_REQUESTING

    data =
      page: params.page
      per_page: params.perPage
      # sort_order: params.sortOrder,
      # order_by: params.orderBy
      date: params.date
    queryParams = "?#{buildQueryParamsString data}"
    window.history.replaceState null, null, queryParams
    data.is_lead = true if params.isLead

    DashboardAPI.getSLATrackingStatistic(
      data
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.SLA_TRACKING_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.SLA_TRACKING_FAILURE
            payload: json.error
      (response) =>
        msg = 'An error occurred while getting SLA Tracking Statistic'
        @dispatch
          type: ActionTypes.SLA_TRACKING_FAILURE
          payload: msg
    )

module.exports = SLATrackingActionCreator
