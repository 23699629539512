config = require '../config'

module.exports = {
  getGroups: (data, success, error) ->
    $.ajax {
      url: config.urls.groups.getGroups
      type: 'GET'
      data
      success
      error
    }

  getGroupPages: (data, success, error) ->
    $.ajax {
      url: config.urls.groups.getGroupPages
      type: 'GET'
      data
      success
      error
    }

  createGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.groups.createGroup
      type: 'POST'
      data
      success
      error
    }

  deleteGroup: (id, success, error) ->
    $.ajax {
      url: config.urls.groups.deleteGroup(id)
      type: 'DELETE'
      success
      error
    }

  getAllGroupOptions: (success, error) ->
    $.ajax {
      url: config.urls.groups.getAllOptions
      type: 'GET'
      success
      error
    }

  getGroupComposition: (id, success, error) ->
    $.ajax {
      url: config.urls.groups.getGroupComposition(id)
      type: 'GET'
      success
      error
    }

  updateGroup: (id, data, success, error) ->
    $.ajax {
      url: config.urls.groups.updateGroup(id)
      type: 'PUT'
      data
      success
      error
    }
}