CONVERSATION_STATUS =
  NEW_MESSAGE: 'new_message'
  OPENED: 'opened'
  CLOSED: 'closed'
  DORMANT: 'dormant'

CONVERSATION_STATUS._ALL = [
  CONVERSATION_STATUS.NEW_MESSAGE,
  CONVERSATION_STATUS.OPENED,
  CONVERSATION_STATUS.CLOSED,
  CONVERSATION_STATUS.DORMANT
]

CONVERSATION_STATUS._SHOWING = [
  CONVERSATION_STATUS.NEW_MESSAGE,
  CONVERSATION_STATUS.OPENED,
  CONVERSATION_STATUS.DORMANT
]

module.exports = CONVERSATION_STATUS
