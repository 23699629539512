BaseComponent = require '../../BaseComponent'
styles        = require './conversations-list.styl'
IScroll       = require 'iscroll/build/iscroll-probe'
stylesLoader  = require '../../shared/styles/loader.styl'
{ Link }      = require 'react-router-dom'
classnames            = require 'classnames'
AwaitingTimeComponent = require '../../shared/AwaitingTimeComponent/AwaitingTimeComponent'

class ConversationsListWithAverageResponseTimeComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @state = searchString: ""
    @initComponent()

  onChange: (e) =>
    @setState searchString: e.target.value

  searchToggle: (e) =>
    # $( "'#conversations_list_search_input'" ).toggle( display );

  formatTags: (tags = [], className=styles.tag) =>
    tags.map (tag, index) ->
      if tag
        React.createElement("span", {"className": ( className ), "key": ( index )}, ( "##{tag.name}" ))

  formatAgents: (agents = []) =>
    agents.map (agent, index) ->
      React.createElement("span", {"className": ( styles.agent ), "key": ( index )}, ( agent.last_name ))

  getSorted: (conversations) ->
    if @state.searchString != ""
      conversations = _.filter conversations, (val) =>
        objectStr = val.customer.name +
        "\n" + _.reduce( val.tags, (memo, tag) =>
            memo + '#' + tag.name + "\n"
          , '') +
        "\n" + _.reduce( val.agents, (memo, agent) =>
            memo + '@' + agent.first_name + "\n"
          , '')
        if objectStr.match(new RegExp(@state.searchString, 'i'))
          true
        else
          false
    _.sortBy(conversations, (val)=>val.awaiting_from)

  render: ->
    React.createElement("div", {"className": ( styles.conversations_list_with_average_response_time)},
      React.createElement("div", {"className": ( styles.header )},
        React.createElement("div", {"className": ( styles.title )}, "Conversation Delay Stats"),
        React.createElement("input", {"type": 'text', "id": 'conversations_list_search_input', "className": ( styles.search_input ), "value": ( @state.searchString ), "onChange": @onChange}),
        React.createElement("div", {"className": ( styles.search_button ), "onClick": @searchToggle})
      ),
      React.createElement("div", {"className": styles.scroll},
        (
          if @props.loading
            React.createElement("div", {"className": ( stylesLoader.container )},
              React.createElement("img", {"className": ( styles.loader ), "src": ( require('!file-loader!../../../assets/images/loader.svg') )})
            )
          else
            conversations = @getSorted(@props.conversations)
            if conversations.length > 0
              for conversation in conversations
                React.createElement(Link, { \
                  "key": ( conversation.id ),  \
                  "to": ( "/conversations/all_conversations/#{conversation.id}" ),  \
                  "onClick": ( @onClick )},
                  React.createElement("div", {"className": ( styles.row )},
                    React.createElement("div", {"className": ( styles.marker_div )},
                      React.createElement("div", {"className": ( classnames(styles.status_marker, styles.marker_status_new) )})
                    ),
                    React.createElement("div", {"className": ( styles.item_container )},
                      React.createElement("div", {"className": ( styles.full_width_block)},
                        React.createElement("div", {"className": ( styles.credential_value )}, ( conversation.customer?.name )),
                        React.createElement("div", {"className": ( styles.tags_container )},
                          React.createElement("div", {"className": ( styles.tags )}, ( @formatTags(conversation?.tags, styles.tag_conversation) )),
                          React.createElement("div", {"className": ( styles.white_gradient )})
                        ),
                        React.createElement("div", {"className": ( styles.agents )}, ( @formatAgents(conversation.agents) ))
                      ),
                      React.createElement("div", {"className": ( styles.awaiting_time_container )},
                        React.createElement(AwaitingTimeComponent, {"className": ( styles.awaiting_time  ), "awaitingFrom": ( conversation.awaiting_from  )}),
                        React.createElement("div", {"className": ( styles.sent_button )})
                      )
                    )
                  )
                )
            else
              React.createElement("div", {"className": ( styles.no_results )}, ('Nothing to show'))
        )
      )
    )

module.exports = ConversationsListWithAverageResponseTimeComponent
