styles = require './customer-list'
IScroll = require 'iscroll'
stylesLoader = require 'components/shared/styles/loader.styl'
BaseComponent = require 'components/BaseComponent'
InterfaceStore = require 'stores/InterfaceStore'
paginationStyles = require 'components/shared/styles/pagination.styl'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
CustomerListItemComponent = require '../CustomerListItemComponent/CustomerListItemComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

propTypes = require 'prop-types'

class CustomerListComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    customers: propTypes.arrayOf(propTypes.object).isRequired
    loading: propTypes.bool
    error: propTypes.arrayOf(propTypes.object).isRequired

  @defaultProps:
    loading: false

  render: ->
    loadingClass = if @props.loading then 'loading' else ''

    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
        (if !_.isEmpty @props.error
          React.createElement("div", {"className": (styles.error)}, """
            Can not connect to the server.
""")
        ),
        (if _.isEmpty @props.error
          if !@props.loading
            @props.customers.map (customer, index) ->
              React.createElement(CustomerListItemComponent, { \
                "key": (customer.id),  \
                "index": (index),  \
                "customer": (customer)
              })
          else if @props.customers.length == 0
            React.createElement("div", {"className": (styles.no_results)}, ('You don\'t have any customers'))
        ),
        (unless @props.finish
          React.createElement("div", {"className": (stylesLoader.load_more), "onClick": (@props.loadMore)}, "+ SHOW MORE")
        ),
        (
          if @props.showMoreLoading
            React.createElement("div", {"className": (stylesLoader.load_more)}, "loading...")
        )
      )
    )

module.exports = CustomerListComponent
