ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'
LOAD_STATE = require 'constants/LoadStates'

actionsMap =
  "#{ActionTypes.CHANNELS_GET_LIST_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.CHANNELS_GET_LIST_SUCCESS}": ({ channels, total }) ->
    @loading = false
    @channels = channels
    @total = total
    @emitChange()
  "#{ActionTypes.CHANNELS_GET_LIST_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.CHANNELS_CREATE_SUCCESS}": (channel) ->
    @channels.push channel
    @emitChange()
  "#{ActionTypes.CONVERSATION_UPDATE_SUCCESS}": ->
    @updating = false
    @updatingError = undefined
    @emitChange()

class ChannelsStore extends BaseStore
  constructor: ->
    super()
    @channels = []
    @loading = false
    @total = 0
    @error = null
    @registerActions()
  
  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new ChannelsStore
