reactMixin = require 'react-mixin'

AgentsAssignStore = require '../../../stores/AgentsAssignStore'
BaseComponent = require '../../BaseComponent'
ConversationsActionCreator = require '../../../actions/ConversationsActionCreator'
AssignModuleMixin = require '../../mixin/AssignModuleMixin'
ConversationsActionCreator = new ConversationsActionCreator()

class AllocateComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [AgentsAssignStore]

  onAllocateClick: (e, userId) =>
    e.preventDefault()
    ConversationsActionCreator.assignToAgent([@props.conversationId], [userId])

  render: ->
    agents = @handleAgents
      reservedAgent: @props.reserveUserId
      highlightedAgentId: @props.reserveUserId
    user = agents[0]
    if user
      #TODO: need to fix css of props
      React.createElement("span", {"className": (@props.allocateClass), "onClick": ((e) => @onAllocateClick(e, user.id))},
        ("Allocate: #{user.first_name} #{user.last_name}")
      )
    else
      React.createElement("span", null)

reactMixin.onClass(AllocateComponent, AssignModuleMixin)
module.exports = AllocateComponent
