BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

class ConversationTemplatesStore extends BaseStore

  constructor: ->
    super()
    @initialState =
      template: ''
      clientId: null
    @state = R.merge {}, @initialState
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.CONVERSATION_TEMPLATES_SET_TEMPLATE
        @state = R.merge @state, payload
        @emitChange()
      when ActionTypes.CONVERSATION_TEMPLATES_CLEAR_TEMPLATE
        @state = R.merge @state, @initialState

module.exports = new ConversationTemplatesStore()
