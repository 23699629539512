classnames = require 'classnames'
deepcopy = require 'deepcopy'
ReactDnD = require 'react-dnd'

dndUtils = require 'lib/dnd-utils'
ItemTypes = require './../ItemTypes'
AbstractListItem = require './AbstractListItem'
Checkbox = require '../../shared/Checkbox'
styles = require './hash-tag-list-item'
OrderTagAddition = require './tags-additions/OrderTagAddition'

propTypes = require 'prop-types'
additionalTypes =
  'order': OrderTagAddition

itemSource =
  canDrag: (props) ->
    props.isReadyToDrag
  beginDrag: (props) ->
    index: props.index
    tag: props.tag
  endDrag: (props, monitor) ->
    dragId = monitor.getItem().tag.id
    props.updatePosition(dragId) if monitor.didDrop()

itemTarget =
  hover: dndUtils.standartHoverHandler
  canDrop: (props, monitor) ->
    itemType = monitor.getItemType()
    itemTagType = monitor.getItem()[itemType]['tag_type']
    return props[itemType] && props[itemType]['tag_type'] == itemTagType

class HashTagItem extends AbstractListItem
  constructor: (props) ->
    super(props)
    @type = 'tag'
    @state =
      edit: props.onlyEdit
      preinstalledChecked: props[@type].is_popup || false
      sla: props[@type].sla || false
      hidden: props[@type].hidden || false
      duplicate: props[@type].duplicate || false

  onSave: =>
    newItem = deepcopy @props[@type]
    newItem.name = @refs.input.value
    newItem.is_popup = @state.preinstalledChecked
    newItem.sla = @state.sla
    newItem.hidden = @state.hidden
    newItem.duplicate = @state.duplicate
    newItem.category = @refs.category?.value
    @props.onSave newItem
    @setState edit: false

  onPreinstalledCheckbox: (e) =>
    @setState preinstalledChecked: !@state.preinstalledChecked

  onSlaCheckbox: (e) =>
    @setState sla: !@state.sla

  onHiddenCheckbox: (e) =>
    @setState hidden: !@state.hidden

  onDuplicateCheckbox: (e) =>
    @setState duplicate: !@state.duplicate

  generateInput: ->
    React.createElement("div", {"className": (styles.input_container)},
      React.createElement("input", { \
        "ref": "input",  \
        "onKeyPress": (@onKeyPress),  \
        "defaultValue": (@props[@type].name),  \
        "className": (styles.input)
      }),
      React.createElement("span", { \
        "onClick": (@onCancel),  \
        "className": (styles.cancel)},
        React.createElement("i", {"className": (styles.cancel_icon)})
      ),
      React.createElement("span", { \
        "onClick": (@onSave),  \
        "className": (styles.apply)
      },
        React.createElement("i", {"className": (styles.apply_icon)})
      ),
      (
        if @props.isConversationTags
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.preinstalledChecked),  \
                "onClick": (@onPreinstalledCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "preinstall tag for converstaion")
            ),
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.sla),  \
                "onClick": (@onSlaCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "SAL")
            ),
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.hidden),  \
                "onClick": (@onHiddenCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "hidden")
            ),
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.duplicate),  \
                "onClick": (@onDuplicateCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "duplicate")
            ),
            React.createElement("input", { \
              "ref": "category",  \
              "placeholder": "Category ...",  \
              "defaultValue": (@props[@type].category),  \
              "className": (classnames styles.input, styles.input_category)
            })
          )
      ),
      (
        if !@props.isConversationTags
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.checkbox_preinstalled)},
              React.createElement(Checkbox, { \
                "checked": (@state.hidden),  \
                "onClick": (@onHiddenCheckbox)
              }),
              React.createElement("span", {"className": (styles.checkbox_preinstalled_label)}, "hidden")
            )
          )
      )
    )

  replace: (name) ->
    return name unless @props.searchQuery
    { searchQuery } = @props
    searchQuery = searchQuery.replace(/[^\w\s]/gi, '')
    parts = name.split(new RegExp("(#{searchQuery})", 'gi'))
    for i in [1...parts.length] by 2
      parts[i] = React.createElement("span", {"className": (styles.search), "key": (i)}, (parts[i]))
    parts

  generateData: ->
    {tag} = @props
    @props.connectDragSource(
      @props.connectDropTarget(
        React.createElement("li", {"className": (classnames(
          @props.className,
          "#{styles.dropable}": @props.canDrop,
          "#{styles.hide}": @props.isDragging
        ))},
          React.createElement("div", { \
            "onClick": (@onItemClick unless @props.editable),  \
            "className": (classnames(styles.data,
              "#{styles.data_duplicate}": tag.duplicate
            ))
          },
            React.createElement("div", null,
              (
                if @props.editable && @props.isConversationTags && tag.is_popup
                  React.createElement("span", { \
                    "title": "Preinstalled tag for conversation",  \
                    "className": (styles.tag_preinstalled)
                  })
              ),
              React.createElement("span", { \
                "className": (classnames(styles.tag_name,
                  "#{styles.tag_name_editing}": @props.editable
                ))
              },
                (@replace(tag.name))
              ),
              (React.createElement("span", {"className": (styles.flag_d)}, "D") if @props.editable && tag.duplicate),
              (React.createElement("span", {"title": (tag.category), "className": (styles.flag_c)}, "C") if @props.editable && tag.category),
              (React.createElement("span", {"className": (styles.flag_s)}, "S") if @props.editable && tag.sla),
              (React.createElement("span", {"className": (styles.flag_h)}, "H") if @props.editable && tag.hidden),
              (
                if @props.editable
                  [
                    React.createElement("i", { \
                      "key": (1),  \
                      "className": (classnames styles.edit, styles.edit_tag),  \
                      "onClick": (@onEditClick)
                    })
                    React.createElement("i", { \
                      "key": (2),  \
                      "className": (classnames styles.delete, styles.delete_tag),  \
                      "onClick": (@onDeleteClick)
                    })
                  ]
                else
                  if tag.customer_checked
                    [
                      React.createElement("span", {"className": (styles.checkbox_container)},
                        (
                          if @props.tagTargetLoading
                            React.createElement("img", { \
                              "className": (styles.tag_target_loading),  \
                              "src": (require '../../../assets/images/loader.svg')
                            })
                          else
                            React.createElement(Checkbox, {"checked": (tag.checked)})
                        )
                      )
                      React.createElement("span", {"className": (styles.checkbox_container)},
                        (
                          React.createElement(Checkbox, {"green_checked": (tag.customer_checked)})
                        )
                      )
                    ]
                  else
                    React.createElement("span", {"className": (styles.checkbox_container)},
                      (
                        if @props.tagTargetLoading
                          React.createElement("img", { \
                            "className": (styles.tag_target_loading),  \
                            "src": (require '../../../assets/images/loader.svg')
                          })
                        else
                          React.createElement(Checkbox, {"checked": (tag.checked)})
                      )
                    )
              )
            ),
            (if tag.additional && additionalTypes[tag.additional.type]
              Additional = additionalTypes[tag.additional.type]
              React.createElement(Additional, {"params": (tag.additional)})
            )
          )
        )
      )
    )

DragSourceDecorator = ReactDnD.DragSource(
  ItemTypes.TAG_ITEM,
  itemSource,
  (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()
)

DropTargetDecorator = ReactDnD.DropTarget(
  ItemTypes.TAG_ITEM,
  itemTarget,
  (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    canDrop: monitor.canDrop()
)
HashTagItem.propTypes =
  tag: propTypes.shape(
    id: propTypes.number
    name: propTypes.string.isRequired
    checked: propTypes.bool
    tags_group_id: propTypes.number
  ).isRequired
  onClick: propTypes.func.isRequired
  editable: propTypes.bool
  onSave: propTypes.func
  onEditCancel: propTypes.func
  onDelete: propTypes.func
  onlyEdit: propTypes.bool
  tagTargetLoading: propTypes.bool
  connectDragSource: propTypes.func.isRequired
  connectDropTarget: propTypes.func.isRequired
  isDragging: propTypes.bool.isRequired
  swapItems: propTypes.func.isRequired
  updatePosition: propTypes.func

module.exports = DropTargetDecorator(DragSourceDecorator(HashTagItem))
