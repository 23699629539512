classnames = require 'classnames'
onClickOutside = require 'react-onclickoutside'

styles = require 'components/admin/assets/item-activities.styl'

class CustomerioItemActivities extends React.Component
  constructor: (props) ->
    super(props)
    @state = open: false

  handleClickOutside: =>
    @setState open: false if @state && @state.open

  onOpen: (e) =>
    e.stopPropagation()
    @setState open: !@state.open

  onClose: =>
    @setState open: false

  onDelete: (e) =>
    @props.onDelete()
    @onClose()

  onPause: (e) =>
    @props.onPause()
    @onClose()

  render: =>
    (
      React.createElement("div", {"className": (classnames(
        @props.className,
        styles.container
      ))},
        React.createElement("button", { \
          "className": (classnames(
            styles.open_button,
            "#{styles.open_button_is_open}": @state.open
          )),  \
          "onClick": (@onOpen)
        },
          React.createElement("span", null),
          React.createElement("span", null),
          React.createElement("span", null)
        ),
        (if @state.open
          React.createElement("div", {"className": (styles.activities)},
            React.createElement("ul", {"className": (styles.activities_list)},
              React.createElement("li", {"onClick": (@onPause)}, "Pause"),
              React.createElement("li", {"onClick": (@onDelete)}, "Delete")
            )
          )
        )
      )
    )

module.exports = onClickOutside.default(CustomerioItemActivities)