classnames = require 'classnames'
{ NavLink } = require 'react-router-dom'

BaseComponent = require 'components/BaseComponent'
CustomerCardCredentialsFormComponent = require '../CustomerCardComponent/CustomerCardCredentialsFormComponent/CustomerCardCredentialsFormComponent'
styles = require './list-item'

module.exports = (props) ->
  React.createElement(NavLink, { \
    "to": ("/customers/#{props.customer.id}"),  \
    "className": (styles.container),  \
    "activeClassName": (styles.selected)},
    React.createElement("div", { \
      "className": (classnames styles.status_marker, styles["marker_status_"+props.customer.status])
    }),
    React.createElement("div", {"className": (styles.name_value)}, (props.customer.name)),
    (if props.customer.replicas_count > 0
      React.createElement("div", {"className": (styles.merged_value)},
        ("+ " + props.customer.replicas_count + " merged")
      )
    ),
    React.createElement(CustomerCardCredentialsFormComponent, { \
      "credentials": (props.customer.credentials),  \
      "disabled": true})
  )
