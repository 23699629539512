SORT_LIST =
  updated_at:
    label: 'Last updated'
    value: 'updated_at'
  name:
    label: 'Alphabetical'
    value: 'name'
  position:
    label: 'Custom order'
    value: 'position'
  created_at:
    label: 'Last created'
    value: 'created_at'

SORT_ORDER =
  updated_at: 'DESC'
  name: 'ASC'
  position: 'asc'
  created_at: 'asc'

SORT_LIST_PERSONAL =
  Object.assign({
    user_name:
      label: 'User name'
      value: 'user_name'
  }, SORT_LIST)

SORT_ORDER_PERSONAL =
  Object.assign({
    user_name: 'asc'
  }, SORT_ORDER)

module.exports = {
	SORT_LIST
	SORT_ORDER
	SORT_LIST_PERSONAL
	SORT_ORDER_PERSONAL
}
