CREDENTIAL_TYPE =
  PHONE: 'phone'
  EMAIL: 'email'
  BILLING_EMAIL: 'billing_email'
  TWITTER: 'twitter'
  FACEBOOK: 'facebook'
  FACEBOOK_UNACCESSIBLE: 'facebook_unaccessible'
  CUSTOM: 'custom'

CREDENTIAL_TYPE._ALL = [
  CREDENTIAL_TYPE.PHONE
  CREDENTIAL_TYPE.EMAIL
  CREDENTIAL_TYPE.TWITTER
  CREDENTIAL_TYPE.BILLING_EMAIL
  CREDENTIAL_TYPE.FACEBOOK
  CREDENTIAL_TYPE.FACEBOOK_UNACCESSIBLE
  CREDENTIAL_TYPE.CUSTOM
]

CREDENTIAL_TYPE._EDITABLE =
  "#{CREDENTIAL_TYPE.PHONE}": true
  "#{CREDENTIAL_TYPE.EMAIL}": true
  "#{CREDENTIAL_TYPE.BILLING_EMAIL}": true
  "#{CREDENTIAL_TYPE.TWITTER}": true
  "#{CREDENTIAL_TYPE.FACEBOOK}": false
  "#{CREDENTIAL_TYPE.FACEBOOK_UNACCESSIBLE}": false
  "#{CREDENTIAL_TYPE.CUSTOM}": true

module.exports = CREDENTIAL_TYPE