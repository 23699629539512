_ = require 'underscore'

FIELD_NAME_MATCHES = '_matched'

tidyHtmlPart = (text) ->
  d = document.createElement('div')
  d.innerHTML = text
  d.innerHTML

SearchUtils = {
  FIELD_NAME_MATCHES

  tidyHtmlPart: tidyHtmlPart

  # options:
  #   truncate - type: number
  #
  renderObjectFieldWithHghtlight: (object, field, options = {}) ->
    truncate = options.truncate
    value = object[field]
    range = object[FIELD_NAME_MATCHES]?[field]
    if range?
      @renderValueWithHightlight(value, range, { truncate })
    else
      value = _str(value).truncate(truncate).value() if truncate?
      React.createElement("span", null, " ", ( value ), " ")

  # options:
  #   truncate - type: number
  #
  renderValueWithHightlight: (value, range, options = {}) ->
    truncate = options.truncate
    truncateSize = if truncate? then Math.round(truncate / 2) else undefined

    leftPrefix = value.substring(0, range[0])
    rightPrefix = value.substring(range[1])

    if truncateSize?
      if leftPrefix.length > truncateSize
        leftPrefix = "..." + leftPrefix.substring(leftPrefix.length - truncateSize, leftPrefix.length - 1)
      rightPrefix = _str(rightPrefix).truncate(truncateSize).value()

    React.createElement("span", null,
      ( leftPrefix ),
      React.createElement("em", null, " ", ( value.substring(range[0], range[1]) ), " "),
      ( rightPrefix )
    )

  # options:
  #   ignoreCase - type: boolean, default: true
  #
  filterCollectionByQuery: (collection, fields, query, options = {}) ->
    propagatedOptions = R.pick(['ignoreCase', options])
    results = []
    for item in collection
      matches = SearchUtils.searchInObject(item, fields, query, propagatedOptions)
      if !_.isEmpty(matches)
        results.push Object.assign({}, item, "#{FIELD_NAME_MATCHES}": matches)
    results

  # options:
  #   ignoreCase - type: boolean, default: true
  #
  searchInObject: (object, fields, query, options = {})->
    propagatedOptions = R.pick(['ignoreCase', options])
    matches = {}
    for field in fields
      range = SearchUtils.searchInValue(object[field], query, propagatedOptions)
      matches[field] = range if range
    matches

  # options:
  #   ignoreCase - type: boolean, default: true
  #
  searchInValue: (value, query, options = {}) ->
    ignoreCase = options.ignoreCase || true
    if ignoreCase
      query = query.toLowerCase()
      value = value.toLowerCase()
    position = value.search query
    if position != -1 then [position, position + query.length] else null

  # input - string with em tags
  decodeElasticsearchHighlighting: (input) ->
    return input unless input?
    parts = input.split(/<em>|<\/em>/)
    if parts.length > 1
      React.createElement("span", {"key": Math.random()},
        (
          for i in [0...parts.length]
            if i % 2 == 0
              React.createElement("span", {"key": i,   \
                "dangerouslySetInnerHTML": (
                  __html: tidyHtmlPart parts[i]
                )
              })
            else
              React.createElement("em", {"key": i}, (parts[i]))
        )
      )
    else
      input

}

module.exports = SearchUtils
