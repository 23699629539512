moment = require 'moment'
BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
{ updateItems, destroyItem} = require 'lib/admin-utils'

timeToDay = (time) ->
  moment(time).format 'YYYY-MM-DD'

actionMap =
  "#{ActionTypes.ADMIN_REMINDERS_GET_REQUESTING}": ->
    @loading = true
    @loadingByDay = {}
    @reminders = new Map()
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_GET_SUCCESS}": (reminders) ->
    @loading = false
    @reminders = new Map(reminders.map((reminder) -> Object.entries(reminder)[0]))
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_GET_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_REQUESTING}": (day) ->
    @loadingByDay[day] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_SUCCESS}": ({day, data}) ->
    @loadingByDay[day] = false
    @reminders.set(Object.keys(data[0])[0], Object.values(data[0])[0])
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_BY_DAY_GET_FAILURE}": ({day, error}) ->
    @loadingByDay[day] = false
    @error = error
    @emitChange()

  "#{ActionTypes.ADMIN_REMINDERS_UPDATE_REQUESTING}": (reminder) ->
    @loadingByDay[timeToDay(reminder.time)] = true
    @error = null
    @updatableReminder = reminder
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_UPDATE_SUCCESS}": ({time, data}) ->
    @loadingByDay[timeToDay(time)] = false
    @reminders = updateItems(
      @reminders
      'reminders'
      @updatableReminder
      data
    )
    @updatableReminder = {}
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_UPDATE_FAILURE}": ({time, error}) ->
    @loadingByDay[timeToDay(time)] = false
    @error = error
    @updatableReminder = {}
    @emitChange()

  "#{ActionTypes.ADMIN_REMINDERS_DESTROY_REQUESTING}": (day) ->
    @loadingByDay[day] = true
    @error = null
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_DESTROY_SUCCESS}": ({day, id}) ->
    @loadingByDay[day] = false
    @reminders = destroyItem(
      @reminders
      'reminders'
      day
      id
    )
    @emitChange()
  "#{ActionTypes.ADMIN_REMINDERS_DESTROY_FAILURE}": ({day, error}) ->
    @loadingByDay[day] = false
    @error = error
    @emitChange()

class RemindersStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @loadingByDay = {}
    @updatableReminder = {}
    @reminders = new Map()
    @error = null
    @registerActions()

  _registerToActions: ({type, payload}) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new RemindersStore()
