moment = require 'moment'
deepcopy = require 'deepcopy'
browserHistory = require './../../../history'

BaseComponent = require 'components/BaseComponent'
styles = require './qa-dashboard'
QAStatisticComponent = require '../QAStatisticComponent'
OperatorFilterComponent = require '../OperatorFilterComponent'
QADashboardActionCreator = require 'actions/QADashboardActionCreator'
QADashboardStore = require 'stores/QADashboardStore'
{ getQueryParams } = require 'lib/utils'
{ ROLES, ACTIVITIES } = require 'constants/Agents'
{ getWeekByDate,
generateNewRangeByType } = require 'lib/calendar-calculator'
AuthStore = require 'stores/AuthStore'
{ menus } = require 'root/config'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'qa-dashboard'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

RANGE_TYPES = ['monthly', 'weekly', 'daily']
SALES_RANGE_TYPES = ['monthly', 'weekly', 'daily']

ORDER_FIELDS = [
  'full_name'
  'unique_clients_count'
  'conversations_count'
  'rankable'
  'ranked'
  'ranked_percent'
  'to_rank'
  'striked'
  'striked_percent'
]

ORDER_TYPES = ['asc', 'desc']

class QADashboardComponent extends BaseComponent

  dependsOnStores: [QADashboardStore]

  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1
    filters = {}
    filters.role = params.role if ROLES[params.role]
    filters.activity = params.activity if ACTIVITIES[params.activity]

    query = params.query || ''

    @state = {
      filters
      query
    }
    if ORDER_FIELDS.indexOf(params.sort_by) == -1
      @state.orderBy = ORDER_FIELDS[0]
    else
      @state.orderBy = params.sort_by

    if ORDER_TYPES.indexOf(params.sort_order) == -1
      @state.order = ORDER_TYPES[0]
    else
      @state.order = params.sort_order
    RANGE = if filters.role == 'sale' then SALES_RANGE_TYPES else RANGE_TYPES
    rangeType = RANGE[1]
    unless RANGE.indexOf(params.range_type) == -1
      rangeType = params.range_type

    beginDate = new moment(params.begin_date)
    endDate = new moment(params.end_date)
    dateRange = generateNewRangeByType rangeType, beginDate, endDate, true
    @state.rangeType = rangeType
    @state.beginDate = dateRange.beginDate
    @state.endDate = dateRange.endDate
    @initComponent()

  downloadStatistic: (query, filters, beginDate, endDate, rangeType, orderBy, order) =>
    trimmingString = query.replace(/\s+/, ' ')
    params = Object.assign deepcopy(filters),
      search_query: trimmingString
      range_type: rangeType
      begin_date: beginDate
      end_date: endDate
      sort_by: orderBy
      sort_order: order

    setTimeout(
      ->
        QADashboardActionCreator.getList(params)
      10
    )

    @setState {
      filters
      query
      beginDate
      endDate
      rangeType
      orderBy
      order
    }

  onFiltersChange: (query, filters, beginDate, endDate, dateRange) =>
    correctType = dateRange
    @setState page: 0
    @downloadStatistic(
      query, filters, beginDate, endDate, correctType, @state.orderBy, @state.order
    )

  componentWillMount: ->
    if accessConfig.toPage
      if accessConfig.toPage.indexOf(AuthStore.user.role) == -1
        browserHistory.default.push '/'

  componentDidMount: ->
    super()
    @downloadStatistic(
      @state.query, @state.filters, @state.beginDate, @state.endDate
      @state.rangeType, @state.orderBy, @state.order
    )

  onRangeTypeChange: (type) =>
    correctType = type
    dates = generateNewRangeByType correctType, @state.beginDate, @state.endDate, true
    @downloadStatistic(
      @state.query, @state.filters, dates.beginDate, dates.endDate
      correctType, @state.orderBy, @state.order
    )
    @setState
      rangeType: correctType
      beginDate: dates.beginDate
      endDate: dates.endDate

  onOrderChange: (orderBy, order) =>
    params = Object.assign deepcopy(@state.filters),
      search_query: @state.query
      range_type: @state.rangeType
      begin_date: @state.beginDate
      end_date: @state.endDate
      order_by: orderBy
      order: order

    QADashboardActionCreator.changeOrder QADashboardStore.statistic, params
    @setState {orderBy, order}

  isSales: ->
    @state.filters.role == 'sales'

  render: ->
    React.createElement("div", {"className": (styles.content)},
      React.createElement(OperatorFilterComponent, { \
        "filters": (@state.filters),  \
        "query": (@state.query),  \
        "beginDate": (@state.beginDate),  \
        "endDate": (@state.endDate),  \
        "onChangeHandler": (@onFiltersChange),  \
        "onRangeTypeChangeHandler": (@onRangeTypeChange),  \
        "rangeType": (@state.rangeType),  \
        "weeksTwo": (@isSales)
      }),
      React.createElement("div", {"className": (styles.table)},
        React.createElement(QAStatisticComponent, { \
          "onOrderChange": (@onOrderChange),  \
          "orderBy": (@state.orderBy),  \
          "order": (@state.order),  \
          "endDate": (@state.endDate),  \
          "isSales": (@isSales),  \
          "rangeType": (@state.rangeType)
        })
      )
    )

module.exports = QADashboardComponent
