React = require 'react'

InterfaceActionCreator = require 'actions/InterfaceActionCreator'
ReplyTemplatesManager = require 'components/replyTemplates/ReplyTemplatesManager'

styles = require './styles'

class RankingTemplatePopup extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      containerStyle:
        right: 0
        bottom: 0
        display: 'none'
    }

  onClose: =>
    setTimeout(
      ->
        InterfaceActionCreator.closePopup('rankingTemplate')
      10
    )

  componentDidMount: ->
    @setState({
      containerStyle:
        right: window.innerWidth - @props.data.coordinates.left + 7
        bottom: window.innerHeight - @props.data.coordinates.bottom - 10
        display: 'block'
    })

  render: ->
    React.createElement("div", { \
      "className": (styles.container),  \
      "style": (@state.containerStyle),  \
      "ref": "container"
    },
      React.createElement(ReplyTemplatesManager, { \
        "onReplyTemplatePick": (@props.data.onPick),  \
        "searchQuery": (@props.data.searchQuery),  \
        "templateType": (@props.data.templateType),  \
        "isRanking": true
      })
    )

module.exports = RankingTemplatePopup
