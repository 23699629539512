Croppie = require 'croppie'
Dropzone = require 'dropzone'
classnames = require 'classnames'

styles = require './change-avatar'
{MAX_UPLOADED_PICTURE_SIZE} = require '../../../constants/base'

ESCAPE_KEYCODE = 27

class ChangeAvatarComponent extends React.Component

  constructor: ->
    super()
    @state = uploadedImage: null

  componentDidMount: ->
    @initDropzone()
    @initCropper()
    @refs.container.addEventListener 'mousedown', @onOverlayClick
    document.addEventListener 'keyup', @onEscapePress

  componentWillUnmount: ->
    @refs.container.removeEventListener 'mousedown', @onOverlayClick
    document.removeEventListener 'keyup', @onEscapePress

  onOverlayClick: (e) =>
    @props.closeModal() if e.target == @refs.container

  onEscapePress: (e) =>
    @props.closeModal() if e.keyCode == ESCAPE_KEYCODE

  initDropzone: =>
    Dropzone.autoDiscover = false
    that = @
    @dropzone = new Dropzone 'form#avatar',
      maxFilesize: MAX_UPLOADED_PICTURE_SIZE
      addRemoveLinks: true
      maxFiles: 1
      paramName: 'file'
      acceptedFiles: 'image/*'
      accept: (file, done) ->
        @imageType = file.type
        file._removeLink.click()
        that.handleFileSelect file

  initCropper: =>
    @cropper = new Croppie @refs.avatar,
      viewport:
        width: 161
        height: 180
      customClass: styles.cropper

  handleFileSelect: (file) =>
    if file
      that = @
      reader = new FileReader()

      reader.onload = (readerEvt) ->
        binaryString = readerEvt.target.result
        imageUrl = "data:#{@imageType};base64,#{btoa binaryString}"
        that.setState uploadedImage: imageUrl
        that.bindUrlToCropper imageUrl
      reader.readAsBinaryString file

  bindUrlToCropper: =>
    @cropper.bind url: @state.uploadedImage if @cropper

  onCropperBack: =>
    @setState uploadedImage: null

  onCropperSave: =>
    onSaveClick = @props.onSaveClick
    closeModal = @props.closeModal
    @cropper.result('base64')
      .then (image) ->
        onSaveClick {image}
        closeModal()

  render: ->
    React.createElement("div", {"id": 'container', "ref": "container", "className": (styles.container)},
      React.createElement("form", { \
        "id": "avatar",  \
        "action": "/",  \
        "className": (classnames('dropzone', styles.dropzone,
          "#{styles.hide}": !!@state.uploadedImage
        )),  \
        "method": 'POST'
      }),
      React.createElement("div", {"className": (classnames(styles['cropper-container'],
        "#{styles.hide}": !@state.uploadedImage)
      )},
        React.createElement("div", { \
          "ref": "avatar"
        }),
        React.createElement("button", { \
          "className": (styles['cropper-container__button']),  \
          "onClick": (@onCropperBack)
        }, """
          Back
"""),
        React.createElement("button", { \
          "className": (classnames(
            styles['cropper-container__button']
            styles['cropper-container__button_save']
          )),  \
          "onClick": (@onCropperSave)
        }, """
          Save
""")
      )
    )

module.exports = ChangeAvatarComponent
