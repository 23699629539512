classnames = require 'classnames'
browserHistory = require './../../../history'
ReactPaginate = require 'react-paginate'

paginationStyles = require '../../shared/styles/pagination.styl'
BaseComponent = require '../../BaseComponent'
GroupListComponent = require '../GroupListComponent'
styles = require './groups'
GroupsStore = require '../../../stores/GroupsStore'
GroupsActionCreator = require '../../../actions/GroupsActionCreator'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
Button = require '../../shared/Button'
{menus} = require '../../../config'
AuthStore = require '../../../stores/AuthStore'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'groups'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

GROUPS_PER_PAGE = 30

class GroupsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      createNew: false
      page: 0
      perPage: GROUPS_PER_PAGE
    @initComponent()

  dependsOnStores: [GroupsStore]

  componentWillMount: ->
    if accessConfig.toPage
      if accessConfig.toPage.indexOf(AuthStore.user.role) == -1
        browserHistory.default.push '/'

  componentDidMount: ->
    super()
    {page, perPage} = @state
    setTimeout(
      () ->
        GroupsActionCreator.getGroups(page, perPage)
      10
    )

  onPageChange: ({selected}) =>
    return if selected == @state.page
    GroupsActionCreator.getGroups(selected, @state.perPage)
    @setState page: selected

  createNew: =>
    @setState createNew: true

  onInputChange: (e) =>
    @setState value: e.target.value

  onInputKeypress: (e) =>
    # Enter is pressed
    if e.charCode == 13 && @state.value
      @onSave()

  onSave: =>
    GroupsActionCreator.createGroup @state.value, @onSuccessCreation

  onSuccessCreation: ({id}) =>
    browserHistory.default.push "/groups/#{id}"
    @setState createNew: false, value: null

  clearNew: =>
    GroupsStore.error = null
    @setState value: null, createNew: false

  onGroupDelete: (groupId) =>
    GroupsActionCreator.deleteGroup groupId, @onSuccessDeleting(groupId)

  onSuccessDeleting: (groupId) =>
    if groupId == parseInt(@props.match.params.groupId)
      -> browserHistory.default.push '/groups'

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.header)},
        React.createElement("span", {"className": (styles.title)}, "All groups"),
        (unless @state.createNew
          React.createElement(Button, { \
            "title": 'Create new',  \
            "className": (styles.button_right),  \
            "onClick": (@createNew)
          })
        ),
        (if @state.createNew
          React.createElement("div", {"className": (styles.position_relative)},
            React.createElement(LoadableWrapperComponent, {"loading": (GroupsStore.creationLoading)},
              React.createElement("div", {"className": (styles.new_controls)},
                React.createElement("input", { \
                  "name": 'title',  \
                  "value": (@state.value),  \
                  "onChange": (@onInputChange),  \
                  "onKeyPress": (@onInputKeypress),  \
                  "className": (styles.input),  \
                  "placeholder": 'Group title'
                }),
                React.createElement(Button, { \
                  "title": 'remove',  \
                  "className": (styles.button_right),  \
                  "white": true,  \
                  "onClick": (@clearNew)
                }),
                React.createElement(Button, { \
                  "title": 'save',  \
                  "className": (styles.button_right),  \
                  "onClick": (@onSave if @state.value),  \
                  "disabled": (!@state.value)
                })
              )
            )
          )
        ),
        React.createElement("div", {"className": (styles.error)}, (GroupsStore.error))
      ),
      React.createElement("div", null,
        React.createElement("div", {"className": (styles.position_relative)},
          React.createElement(LoadableWrapperComponent, {"loading": (GroupsStore.groupsLoading)},
            React.createElement(GroupListComponent, { \
              "groups": (GroupsStore.groups),  \
              "onGroupDelete": (@onGroupDelete)
            })
          )
        ),
        (if GroupsStore.groupsPageCount > 1
          React.createElement("div", {"className": (styles.text_center)},
            React.createElement(ReactPaginate.default, { \
              "previousLabel": 'previous',  \
              "nextLabel": 'next',  \
              "pageCount": (GroupsStore.groupsPageCount),  \
              "marginPagesDisplayed": (2),  \
              "pageRangeDisplayed": (4),  \
              "onPageChange": (@onPageChange),  \
              "initialPage": (@state.page),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container),  \
              "breakClassName": (paginationStyles.page)
            })
          )
        )
      )
    )

module.exports = GroupsComponent
