moment = require 'moment'
classnames = require 'classnames'

RankingActionCreator = require 'actions/RankingActionCreator'
SearchUtils = require 'lib/SearchUtils'
styles = require './scheduled-message.styl'

class ScheduledMessageComponent extends React.Component
  messageForRanking: =>
    RankingActionCreator.selectRankedMessage @props.message.id, 'scheduled_message'

  buildTextBlock: (text) ->
    {
      isRankSelectedMessage,
      rankedBy,
      isClickableForRank
    } = @props.rankingOptions
    React.createElement("div", { \
      "className": (classnames(styles.message,
        "#{styles.message_estimate}": isRankSelectedMessage
      )),  \
      "onClick": (@messageForRanking if @props.rankingOptions.isClickableForRank)
    },
      (if isClickableForRank
        React.createElement("span", {"className": (styles['message__ranking-hover'])})
      ),
      (if rankedBy
        React.createElement("div", {"className": (styles.rank_blame)}, """
          Ranked by """, React.createElement("b", null, (rankedBy))
        )
      ),
      React.createElement("div", {"className": (styles.text)},
        (text)
      )
    )

  render: ->
    createdAt = moment(@props.message.created_at)
    text = SearchUtils.decodeElasticsearchHighlighting(@props.message.text)
    containerClassName = classnames(styles.container,
      "#{styles.selected}": @props.selected
    )
    React.createElement("div", {"className": (containerClassName)},
      (@buildTextBlock(text)),
      React.createElement("div", {"className": (styles.info)},
        React.createElement("span", {"className": (styles.agentName)}, (@props.message.user?.last_name)),
        React.createElement("b", null, (createdAt.format('H:mm')), ", "),
        (createdAt.format('D MMM YY')), " [", (@props.message?.channel?.name), """]
""")
    )

module.exports = ScheduledMessageComponent
