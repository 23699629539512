BaseComponent = require 'components/BaseComponent'
ScheduledItemHeader = require './ScheduledItemHeader'

styles = require './scheduled-item.styl'

class ScheduledItem extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      agent: {}
    @initComponent()

  getState: =>
    agent: @props.agents.get(@props.item['user_id'])
  
  getConversationLink: (id) ->
    host = "#{window.location.origin}/conversations/all_conversations/#{id}"

  render: =>
    (
      React.createElement("section", {"className": (styles.container)},
        React.createElement(ScheduledItemHeader, { \
          "className": (styles.header),  \
          "item": (@props.item),  \
          "operator": (@state.agent),  \
          "onEdit": (() => @props.onEdit(@props.item.id)),  \
          "onDelete": (() => @props.onDelete(@props.item.id)),  \
          "conversationLink": (@getConversationLink(@props.item.conversation_id))
        }),
        (if @props.item.text
          React.createElement("div", {"className": (styles.text_block)},
            (@props.item.text)
          )
        )
      )
    )

module.exports = ScheduledItem
