BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'

actionsMap =
  "#{ActionTypes.CUSTOMER_TICKETS_GET_REQUESTING}": (clientId) ->
    @clientId = clientId
    @tickets = null
    @error = null
    @loading = true
    @emitChange()
  "#{ActionTypes.CUSTOMER_TICKETS_GET_SUCCESS}": ({clientId, tickets}) ->
    if @clientId == clientId
      @tickets = tickets
      @clientId = clientId
      @loading = false
      @emitChange()
  "#{ActionTypes.CUSTOMER_TICKETS_GET_FAILURE}": (error) ->
    @error = error
    @loading = false
    @emitChange()

class CustomerTicketsStore extends BaseStore
  constructor: ->
    super()
    @tickets = null
    @loading = false
    @error = null
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new CustomerTicketsStore()
