GuidedActionCreator = require '../../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
DashboardAPI = require '../../../../api/DashboardAPI'
{ buildQueryParamsString } = require '../../../../lib/utils'

class MessageErrorsActionCreator extends GuidedActionCreator
  getMessageErrors: (params) =>
    @dispatch type: ActionTypes.MESSAGE_ERRORS_REQUESTING

    data =
      page: params.page
      per_page: params.perPage
      sort_order: params.sortOrder
      order_by: params.orderBy
      begin_date: params.beginDate
      end_date: params.endDate
      error_code: params.errorCode
      to: params.to
      from: params.from
      operator: params.operator
      provider: params.provider
      conversation_id: params.conversation_id
      channel_name: params.channel_name
      type: params.type

    DashboardAPI.getMessageErrors(
      data
      (json) =>
        @dispatch
          type: ActionTypes.MESSAGE_ERRORS_SUCCESS
          payload: json
      (response) =>
        msg = 'An error occurred while getting Message Errors'
        @dispatch
          type: ActionTypes.MESSAGE_ERRORS_FAILURE
          payload: msg
    )

  mapGlossaryData: (data) ->
    if !data.rows 
      return data

    return Object.assign(data, { rows: data.rows.map (item) -> 
      if item.type == 'twilio' && item.docs
        return Object.assign(item, { failed_info: { info: docs: item.docs } })

      if item.type == 'bandwidth'
        item = Object.assign(item, { code: item.Code })

      if item.type == 'bandwidth' && item['Explanation Of Error'] && item['Friendly Description']
        return Object.assign(item, { failed_info: { info: {
          'Explanation Of Error': item['Explanation Of Error'],
          'Friendly Description': item['Friendly Description']
        }}})

      return item
    })

  getGlossaryMessageErrors: (params) =>
    @dispatch type: ActionTypes.MESSAGE_GLOSSARY_ERRORS_REQUESTING

    DashboardAPI.getGlossaryMessageErrors(
      params
      (json) =>
        @dispatch
          type: ActionTypes.MESSAGE_GLOSSARY_ERRORS_SUCCESS
          payload: @mapGlossaryData(json)
      (response) =>
        msg = 'An error occurred while getting glossary Message Errors'
        @dispatch
          type: ActionTypes.MESSAGE_ERRORS_FAILURE
          payload: msg
    )

module.exports = MessageErrorsActionCreator
