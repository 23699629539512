uuid = require 'uuid'
Dispatcher = require '../dispatcher/Dispatcher'

initializeTypes = (types, guid) =>
  result = {}
  for type, value of types
    result[type] = "#{value}__#{guid}"
  result

class BaseStore
  constructor: () ->
    @emitter = new EventEmitter()
    @emitter.setMaxListeners(100)

    # todo deprecate
    if @TYPES
      @types = initializeTypes(@TYPES, uuid.v4())

  registerActions: () =>
    Dispatcher.register @_registerToActions if @_registerToActions

  emitChange: () =>
    @emitter.emit 'CHANGE'

  addChangeListener: (cb) =>
    @emitter.on 'CHANGE', cb

  removeChangeListener: (cb) =>
    @emitter.removeListener 'CHANGE', cb

module.exports = BaseStore
