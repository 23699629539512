LoaderComponent = require '../../shared/LoaderComponent/LoaderComponent'
styles = require './loadable-wrapper'

propTypes = require 'prop-types'

STATUS_LOADING = 'loading'
STATUS_ERROR = 'error'
STATUS_CONTENT = 'content'

getStatus = (props) ->
  return STATUS_LOADING if props.loading
  return STATUS_ERROR if props.error
  STATUS_CONTENT

class LoadableWrapperComponent extends React.Component
  @propTypes:
    loading: propTypes.bool.isRequired
    error: propTypes.string
    loader: propTypes.object
    onLoad: propTypes.func

  componentWillMount: ->
    @previousStatus = null
    @status = getStatus @props

  componentWillUpdate: (nextProps, nextState)->
    @previousStatus = @status
    @status = getStatus nextProps

  componentDidUpdate: ->
    @props.onLoad?() if @previousStatus == STATUS_LOADING && @status == STATUS_CONTENT

  getContentClassName: (status) ->
    return styles.hidden if status in [null, STATUS_LOADING, STATUS_ERROR]
    return styles.success

  render: ->
    contentClassName = @getContentClassName(@status)
    React.createElement("div", {"className": (@props.containerClassName || '')},
      (
        switch @status
          when STATUS_LOADING
            React.createElement(LoaderComponent, Object.assign({},  @props.loader ))
          when STATUS_ERROR
            React.createElement("div", {"className": (styles.error)},
              (@props.error)
            )
      ),
      React.createElement("div", {"className": (contentClassName)}, (@props.children))
    )

module.exports = LoadableWrapperComponent
