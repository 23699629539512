React = require 'react'
classnames = require 'classnames'
moment = require 'moment'
browserHistory = require './../../../../history'

BaseComponent = require '../../../BaseComponent'
SLATrackingStore = require '../SLATrackingComponent/SLATrackingStore'
Calendar = require '../../../shared/CalendarComponent'
{ RANGE_DAILY } = require '../../../../constants/calendar'
{ getQueryParams } = require '../../../../lib/utils'
SLATrackingTableComponent = require '../SLATrackingComponent/SLATrackingTableComponent'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
AuthStore = require '../../../../stores/AuthStore'
{ ROLES } = require '../../../../constants/Agents'

styles = require '../dashboard.styl'

fieldsMap =
  average_wating_time: 'shift waiting time'
  average_response_time: 'shift response time'
  unique_clients_count: '# unique leads'
  conversations_count: '# unique conversations'
  overload: "% average overload"

DEFAULT_PER_PAGE = 20

ALLOW_ACCESS_ROLES = [ROLES.manager.value]

class SLALeadTrackingComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    params = getQueryParams location.search.substring(1)
    @state =
      params:
        date: moment(params.date).format('YYYY-MM-DD')
        page: params.page || 0
        perPage: params.per_page || DEFAULT_PER_PAGE
        sortOrder: params.sort_order
        orderBy: params.order_by
        isLead: true
    @initComponent()

  getPersonalStoreClass: -> SLATrackingStore

  componentDidMount: ->
    super()
    if ALLOW_ACCESS_ROLES.indexOf(AuthStore.user.role) == -1
      browserHistory.default.push '/'
    else
      setTimeout(
        @actionCreator.getSLATrackingStatistic.bind(null, @state.params)
        10
      )

  onDateChange: (dateRange) =>
    { params } = @state
    params.date = dateRange.startDate.format 'YYYY-MM-DD'
    @setState params
    @actionCreator.getSLATrackingStatistic params

  render: ->
    React.createElement("div", {"className": (classnames(styles.grid, styles.grid__full_height))},
      React.createElement("div", {"className": (styles.full_row_of_grid)},
        React.createElement(Calendar, { \
          "type": (RANGE_DAILY),  \
          "endDate": (@state.params.date),  \
          "disableRangeChanging": true,  \
          "onSelectHandler": (@onDateChange)
        }),
        React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
          (if @personalStore.statistic
            React.createElement(SLATrackingTableComponent, { \
              "statistic": (@personalStore.statistic),  \
              "fieldsMap": (fieldsMap)
            })
          )
        )
      )
    )

module.exports = SLALeadTrackingComponent
