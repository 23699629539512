classnames = require 'classnames'
Checkbox = require '../../shared/Checkbox'
styles = require './channel-list'

propTypes = require 'prop-types'

channelItemComponent = (props) ->
  {channel, onChannelClick} = props
  React.createElement("div", { \
    "className": (styles.item),   \
    "onClick": (-> 
      onChannelClick(channel.id, props.checked) if onChannelClick
    )
  },
    React.createElement("div", {"className": (styles.name_container)},
      React.createElement("div", {"className": (styles.name)},
        (channel.name)
      ),
      React.createElement("div", {"className": (styles.service_name)},
        (channel.service_name)
      )
    ),
    React.createElement(Checkbox, {"checked": (props.checked)})
  )

channelItemComponent.propTypes = 
  channel: propTypes.shape({
    id: propTypes.number.isRequired
    name: propTypes.string.isRequired
    service_name: propTypes.string.isRequired  
  }).isRequired
  checked: propTypes.bool
  onChannelClick: propTypes.func

module.exports = channelItemComponent