ScheduledMessagesAPI = require 'api/ScheduledMessagesAPI'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'

module.exports =
  getUncompletedMessagesByCustomerId: (customerId, data) ->
    Dispatcher.dispatch({
      type: ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_REQUESTING
    })
    ScheduledMessagesAPI.uncompletedByCustomerId(
      customerId, data,
      (json) ->
        if json.success
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_SUCCESS
            payload: json.data
          })
        else
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_FAILURE
            payload: json.error
          })
      ->
        Dispatcher.dispatch({
          type: ActionTypes.SCHEDULED_MESSAGES_UNCOMPLETED_FAILURE
          payload: 'An error occurred while getting scheduled messages list'
        })
    )

  create: (data, successCallback, errorCallback) ->
    Dispatcher.dispatch type: ActionTypes.SCHEDULED_MESSAGE_CREATE_REQUESTING
    ScheduledMessagesAPI.create(
      data,
      (json) ->
        if json.success
          successCallback?()
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_CREATE_SUCCESS
            payload: json.data
          })
        else
          errorCallback?()
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_CREATE_FALIURE
            payload: json.error
          })
      ->
        errorCallback?()
        Dispatcher.dispatch({
          type: ActionTypes.SCHEDULED_MESSAGE_CREATE_FALIURE
          payload: 'An error occurred while creating a scheduled message'
        })
    )

  destroy: (id) ->
    Dispatcher.dispatch type: ActionTypes.SCHEDULED_MESSAGE_DESTROY_REQUESTING
    ScheduledMessagesAPI.destroy(
      id,
      (json) ->
        if json.success
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_DESTROY_SUCCESS
            payload: id
          })
        else
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_DESTROY_FALIURE
            payload: json.error
          })
      ->
        Dispatcher.dispatch({
          type: ActionTypes.SCHEDULED_MESSAGE_DESTROY_FALIURE
          payload: 'An error occurred while destroy a scheduled message'
        })
    )

  update: (id, data) ->
    Dispatcher.dispatch type: ActionTypes.SCHEDULED_MESSAGE_UPDATE_REQUESTING
    ScheduledMessagesAPI.update(
      id, data,
      (json) ->
        if json.uuid == data.uuid
          data.id = json.id
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_UPDATE_SUCCESS
            payload: data
          })
        else
          Dispatcher.dispatch({
            type: ActionTypes.SCHEDULED_MESSAGE_UPDATE_FALIURE
            payload: json.error
          })
      ->
        Dispatcher.dispatch({
          type: ActionTypes.SCHEDULED_MESSAGE_UPDATE_FALIURE
          payload: 'An error occurred while updating a scheduled message'
        })
    )