Dispatcher  = require '../../../dispatcher/Dispatcher'
ChannelsAPI = require '../../../api/ChannelsAPI'
GuidedActionCreator = require '../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../constants/ActionTypes'

class ChannelFormActionCreator extends GuidedActionCreator
  new: =>
    @dispatch type: ActionTypes.CHANNEL_FORM_NEW

  get: (id) =>
    @dispatch type: ActionTypes.CHANNEL_FORM_GET_REQUESTING, payload: id
    ChannelsAPI.get id,
      (data) => @dispatch type: ActionTypes.CHANNEL_FORM_GET_SUCCESS, payload: data,
      # (error) => @dispatch type: ActionTypes.CHANNEL_FORM_GET_FAILURE, payload: error

  create: (attributes, success, error) =>
    @dispatch type: ActionTypes.CHANNELS_CREATE_REQUESTING, payload: attributes
    ChannelsAPI.create attributes,
      (data) =>
        @dispatch type: ActionTypes.CHANNELS_CREATE_SUCCESS, payload: data
        success?()
      (response) =>
        { errors } = response.responseJSON
        @dispatch type: ActionTypes.CHANNELS_CREATE_FAILURE, payload: { errors, attributes }
        error?()

  update: (id, attributes, success, error) =>
    @dispatch type: ActionTypes.CHANNELS_UPDATE_REQUESTING, payload: { id, attributes }
    ChannelsAPI.update { id, attributes },
      (data) =>
        @dispatch type: ActionTypes.CHANNELS_UPDATE_SUCCESS, payload: data
        success?()
      (response) =>
        { errors } = response.responseJSON
        @dispatch type: ActionTypes.CHANNELS_UPDATE_FAILURE, payload: { errors, attributes }
        error?()

  removeErrors: =>
    @dispatch type: ActionTypes.CHANNELS_REMOVE_ERRORS

module.exports = ChannelFormActionCreator
