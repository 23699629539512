BaseStore = require './BaseStore'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
{ localStorageKey } = require 'constants/CRMPlugin'

onConfigClose = (configKey) ->
  allConfigs = window.localStorage.getItem(localStorageKey)    
  try
    allConfigs = JSON.parse(allConfigs) || {}
  catch err
    allConfigs = {}
  Object.keys(allConfigs).forEach((k) -> delete allConfigs[k][configKey])
  window.localStorage.setItem(localStorageKey, JSON.stringify(allConfigs))

class CRMPluginStore extends BaseStore
  constructor: ->
    super()
    @onConfigClose = onConfigClose

module.exports = new CRMPluginStore()
