styles = require './assigned-agents.styl'

propTypes = require 'prop-types'

# Dumb component
AssignedAgentsComponent = (props) ->
  React.createElement("div", {"className": ( styles.container )},
    (
      if props.agents && props.agents.length > 0
        React.createElement("ul", {"className": ( styles.list )},
          (
            props.agents.map (agent, index) ->
              React.createElement("li", { \
                "key": ( index ),  \
                "onClick": ( R.partial props.unassignAgent, [agent.id] ),  \
                "className": ( styles.item )}, """
                @""", ( agent.last_name )
              )
          )
        )
      else
        React.createElement("span", {"className": styles.unassigned}, "No assigned agents")
    )
  )

AssignedAgentsComponent.propTypes =
  agents: propTypes.arrayOf(propTypes.object)
  unassignAgent: propTypes.func.isRequired

module.exports = AssignedAgentsComponent
