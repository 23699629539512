moment = require 'moment'
classnames = require 'classnames'

BaseComponent = require '../../../BaseComponent'
LastDaysByShiftStore = require './LastDaysByShiftStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
{buildOptions} = require '../../../../lib/dashboard-utils'
styles = require '../dashboard.styl'
LoadableWrapperComponent = require '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent'

chartsMap = 
  uniq_clients:
    title: 'Unique clients per shift'
    yTitle: 'Clients'
  conversation:
    title: 'Unique conversations per shift'
    yTitle: 'Conversations'
  waiting_time:
    title: 'Waiting time per shift'
    yTitle: 'Minutes'
    benchmark: 'good_wt'
  answer_times:
    title: 'Response time per shift'
    yTitle: 'Minutes'
    benchmark: 'good_rt'

class LastDaysByShiftComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> LastDaysByShiftStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  getObjectsByShiftDays: (object)=>
    {title, yTitle, benchmark} = chartsMap[object]
    prewiewData = @personalStore[object]
    datesList = _.map [-@personalStore.analyticDaysInterval+1..0], (daysAgo) =>
      date = moment(@props.now)
        .utcOffset(@props.offset)
        .subtract(Math.abs(daysAgo), 'days')
      date.format 'D-MMM'

    yAxis = {}
    if benchmark
      yAxis = {
        plotLines:[
          value: @personalStore[benchmark]
          color: 'red'
          width: 2
          label: 
            text:"Benchmark is #{@personalStore[benchmark]} minutes"
          zIndex: 4
        ]
        min: 0
        minRange: @personalStore[benchmark]
      }

    xAxis: categories: datesList
    yAxis: yAxis
    series: [{
      name: 'Morning'
      data: prewiewData[2]
    }, {
      name: 'Day'
      data: prewiewData[1]
    }, {
      name: 'Night'
      data: prewiewData[0]
    }]
    chart: type: 'column'
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: title
    yTitle: yTitle

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.left_cell_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'ConversationsByShiftDays',  \
                  "options": (buildOptions @getObjectsByShiftDays 'conversation')
                })
              )
            ),
            React.createElement("div", {"className": (styles.right_cell_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'UniqCustomersByShiftDays',  \
                  "options": (buildOptions @getObjectsByShiftDays 'uniq_clients')
                })
              )
            ),
            React.createElement("div", {"className": (styles.left_cell_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'AnswerTimeByShiftDays',  \
                  "options": (buildOptions @getObjectsByShiftDays 'answer_times')
                })
              )
            ),
            React.createElement("div", {"className": (styles.right_cell_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'WaitingTimeByShiftDays',  \
                  "options": (buildOptions @getObjectsByShiftDays 'waiting_time')
                })
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )

module.exports = LastDaysByShiftComponent
