browserHistory = require('root/history').default
ActionTypes = require 'constants/ActionTypes'
BaseStore = require 'stores/BaseStore'

class LandingStore extends BaseStore
  constructor: ->
    super()
    @landing = {}
    @loading = false
    @error = null
    @registerActions()
  
  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.LANDING_FORM_CLEAR
        @loading = false
        @error = null
        @landing = {}
        @emitChange()
      when ActionTypes.LANDING_GET_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.LANDING_GET_SUCCESS
        @loading = false
        @landing = payload
        @emitChange()
      when ActionTypes.LANDING_GET_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.LANDING_CREATE_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.LANDING_CREATE_SUCCESS
        @loading = false
        @landing = payload
        browserHistory.push({ pathname: "/landings/#{payload.id}" })
      when ActionTypes.LANDING_CREATE_FAILURE
        @loading = false
        @error = payload
        @emitChange()
      when ActionTypes.LANDING_UPDATE_REQUESTING
        @loading = true
        @error = null
        @emitChange()
      when ActionTypes.LANDING_UPDATE_SUCCESS
        @loading = false
        @landing = payload
        @emitChange()
      when ActionTypes.LANDING_UPDATE_FAILURE
        @loading = false
        @error = payload
        @emitChange()

module.exports = new LandingStore()
