browserHistory = require './../../../history'
RankingConversationsComponent = require '../RankingConversationsComponent'
AuthStore = require 'stores/AuthStore'
RankingMessageStore = require 'stores/RankingMessageStore'
RankingConversationItemComponent = require '../RankingConversationItemComponent'
RankingMessageActionCreator = require 'actions/RankingMessageActionCreator'
BaseComponent = require 'components/BaseComponent'
ConversationStore = require 'components/conversations/ConversationComponent/ConversationStore'

styles = require './messages-ranking'

class MessagesRankingConversationsComponent extends BaseComponent
  constructor: (props) ->
    super(props)

    @currentConversationId = undefined
    @initComponent()

  dependsOnStores: [RankingMessageStore, ConversationStore]

  componentDidUpdate: (prevProps, prevState) ->
    super(prevProps, prevState)
    if @currentConversationId != RankingMessageStore.conversation?.conversation_id
      prevId = @currentConversationId
      @currentConversationId = RankingMessageStore.conversation?.conversation_id
      if @currentConversationId
        setTimeout(
          =>
            browserHistory.default.push "/messages_ranking/#{@currentConversationId}/"
          0
        )
      else if prevId
        setTimeout(
          =>
            browserHistory.default.push "/messages_ranking"
          0
        )

  componentDidMount: ->
    super()
    conversationId = @props.match.params.id
    if conversationId
      setTimeout(
        ->
          RankingMessageActionCreator.getConversation(conversationId)
        0
      )
    setTimeout(
      ->
        RankingMessageActionCreator.getMessagesGroups()
      0
    )

  componentWillMount: ->
    unless AuthStore.user.messages_ranker
      browserHistory.default.push '/'

  getConversation: ->
    setTimeout(
      ->
        RankingMessageActionCreator.getMessages()
      0
    )
    
  render: ->
    {to_rank, ranked, total} = RankingMessageStore.stats
    if RankingMessageStore.error
      React.createElement("div", {"className": (styles.container)}, (RankingMessageStore.error))
    else
      React.createElement("div", {"className": (styles.container)},
        React.createElement("div", {"className": (styles.stats_container)},
          React.createElement("div", {"className": (styles.stats_field)},
            React.createElement("div", {"className": (styles.stats_number)},
              (total)
            ),
            React.createElement("div", {"className": (styles.stats_name)}, """
              Total
""")
          ),
          React.createElement("div", {"className": (styles.stats_field)},
            React.createElement("div", {"className": (styles.stats_number)},
              (ranked)
            ),
            React.createElement("div", {"className": (styles.stats_name)}, """
              Ranked
""")
          ),
          React.createElement("div", {"className": (styles.stats_field)},
            React.createElement("div", {"className": (styles.stats_number)},
              (to_rank)
            ),
            React.createElement("div", {"className": (styles.stats_name)}, """
              To rank
""")
          )
        ),
        React.createElement("div", null,
          React.createElement("button", {"className": (styles.btn), "onClick": (@getConversation)}, "take new")
        ),
        (if @currentConversationId && ConversationStore.conversation
          React.createElement("div", {"className": (styles.conv_container)},
            React.createElement(RankingConversationItemComponent, {"conversation": (ConversationStore.conversation)})
          )
        )
      )

module.exports = MessagesRankingConversationsComponent
