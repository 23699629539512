{ urls } = require 'root/config'

CustomerioEventsAPI =
  getList: (text, successCallback, errorCallback) ->
    $.ajax
      url: urls.admin.customerioEvents.getList
      type: 'GET'
      data: text
      success: successCallback
      error: errorCallback
      timeout: 5000

  pause: (data, successCallback, errorCallback) ->
    $.ajax
      url: urls.admin.customerioEvents.pause
      data: JSON.stringify(data)
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  destroy: (data, successCallback, errorCallback) ->
    $.ajax
      url: urls.admin.customerioEvents.destroy
      contentType: 'application/json'
      data: JSON.stringify(data)
      type: 'POST'
      success: successCallback
      error: errorCallback

module.exports = CustomerioEventsAPI
