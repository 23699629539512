BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'

actionsMap =
  "#{ActionTypes.REPLY_GROUPS_GET_REQUESTING}": ->
    @groupsLoading = true
    @groups = []
    @groupsError = null
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_GET_FAILURE}": (error) ->
    @groupsError = error
    @groupsLoading = false
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_GET_SUCCESS}": ({ rows, total }) ->
    @groups = rows
    @groupsLoading = false
    @groupsTotal = total
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_MORE_GET_REQUESTING}": ->
    @groupsLoading = true
    @allGroups = @allGroups || []
    @groupsError = null
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_MORE_GET_FAILURE}": (error) ->
    @groupsLoading = false
    @groupsError = error
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_MORE_GET_SUCCESS}": ({ rows, total }) ->
    @groupsLoading = false
    @allGroups = [...@allGroups, ...rows]
    @groupsTotal = total
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_CREATE_REQUESTING}": ->
    @groupsLoading = true
    @groupsError = null
    @lastNewGroup = null
    @lastNewSubGroup = null
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_CREATE_SUCCESS}": (group) ->
    @groupsLoading = false
    if group.original_id
      @lastNewSubGroup = group
      groups = @groups.map (g) ->
        if (g.id == group.original_id)
          g.categories.push group
        g
      @groups = groups
      if @groups.length < @allGroups.length
        allGroups = @allGroups.map (allGr) ->
          if (allGr.id == group.original_id)
            hasSubGroup = allGr.categories.find (g) -> g.id == group.id
            if !hasSubGroup
              allGr.categories.push group
          allGr
        @allGroups = allGroups
      else
        @allGroups = groups

    else
      @groups.push group
      @lastNewGroup = group
      if @groupsTotal <= @allGroups.length
        @allGroups.push group
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_CREATE_FAILURE}": (error) ->
    @groupsError = error
    @groupsLoading = false
    @lastNewGroup = null
    @lastNewSubGroup = null
    @emitChange()

  "#{ActionTypes.REPLY_GROUPS_RESET}": ->
    @lastNewGroup = null
    @lastNewSubGroup = null
    @emitChange()

  "#{ActionTypes.REPLY_GROUPS_UPDATE_REQUESTING}": ->
    @groupsLoading = true
    @groupsError = null
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_UPDATE_SUCCESS}": (group) ->
    @groupsLoading = false
    @groups = @groups.map (g) -> if g.id == group.id then group else g
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_UPDATE_FAILURE}": (error) ->
    @groupsError = error
    @groupsLoading = false
    @emitChange()

  "#{ActionTypes.REPLY_GROUPS_DELETE_REQUESTING}": ->
    @groupsLoading = true
    @groupsError = null
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_DELETE_SUCCESS}": ({groupId, originalId}) ->
    @groupsLoading = false
    if originalId
      original = @groups.filter((g) -> g.id == originalId)[0]
      if original
        original.categories = original.categories.filter (a) -> a.id != groupId
    else
      @groups = @groups.filter (g) -> g.id != groupId
    @emitChange()
  "#{ActionTypes.REPLY_GROUPS_DELETE_FAILURE}": (error) ->
    @groupsError = error
    @groupsLoading = false
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_GET_REQUESTING}": ->
    @answersLoading = true
    @answers = []
    @error = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_GET_SUCCESS}": ({ rows, total }) ->
    @answersLoading = false
    @answers = rows
    @answersTotal = total
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_GET_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_CREATE_REQUESTING}": ->
    @answersLoading = true
    @error = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_CREATE_SUCCESS}": (answer) ->
    @answersLoading = false
    @answers.push answer
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_CREATE_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_DELETE_REQUESTING}": ->
    @answersLoading = true
    @error = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_DELETE_SUCCESS}": (answerId) ->
    @answersLoading = false
    @answers = @answers.filter (a) -> a.id != answerId
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_DELETE_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_UPDATE_REQUESTING}": ->
    @answersLoading = true
    @error = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_UPDATE_SUCCESS}": (answer) ->
    @answersLoading = false

    stateAnswer = @answers.find (a) -> a.id == answer.id
    if stateAnswer.reply_group_id != answer.reply_group_id && !answer.user_id
      @answers = @answers.filter (a) -> a.id != answer.id
    else
      @answers = @answers.map (a) ->
        return if a.id == answer.id then answer else a

    # answer.reply_group_id = null unless answer.reply_group_id
    # if @answers[0] && @answers[0].reply_group_id && @answers[0].reply_group_id != answer.reply_group_id
    #   @answers = @answers.filter (a) -> a.id != answer.id
    # else
    #   @answers = @answers.map (a) ->
    #     return a unless a.id == answer.id
    #     answer
    # console.log '@answers: ', @answers

    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_UPDATE_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_FAVORITE_REQUESTING}": ->
    @answersLoading = false
    @answersError = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_FAVORITE_SUCCESS}": ({ answerId, is_favorite }) ->
    @answersLoading = false
    @answers = @answers.map (a) ->
      return a unless a.id == answerId
      a.is_favorite = is_favorite
      a
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_FAVORITE_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

  "#{ActionTypes.REPLY_ANSWERS_VISIBILITY_REQUESTING}": ->
    @answersLoading = false
    @answersError = null
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_VISIBILITY_SUCCESS}": (data) ->
    @answersLoading = false
    if data
      @answers = @answers.map (a) ->
        return a unless a.id == data.answerId
        a.status = data.status
        a
    @emitChange()
  "#{ActionTypes.REPLY_ANSWERS_FAVORITE_FAILURE}": (error) ->
    @answersLoading = false
    @answersError = error
    @emitChange()

class ReplyAnswersStore extends BaseStore
  constructor: ->
    super()
    @groupsLoading = false
    @groups = []
    @allGroups = []
    @lastNewGroup = null
    @lastNewSubGroup = null
    @groupsError = null
    @groupsTotal = 0
    @answersLoading = false
    @answers = []
    @answersError = null
    @answersTotal = 0
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new ReplyAnswersStore
