moment = require 'moment'

getWeekByDate = (date, isShiftsMode, isUnlimit) ->
  now = new moment()
  startWeek = new moment date
  if startWeek > now && !isUnlimit
    startWeek = now
  if startWeek.day() != 1
    delta = startWeek.day() - 1
    delta = 6 if startWeek.day() == 0
    startWeek.subtract delta, 'd'

  endWeek = new moment(startWeek).add 6, 'd'

  {
    startDate: startWeek,
    endDate: endWeek
  }

getTwoWeekByDate = (date, isShiftsMode, isUnlimit) ->
  now = new moment()
  startWeek = new moment date
  if startWeek > now && !isUnlimit
    startWeek = now
  if startWeek.day() != 1
    delta = startWeek.day() - 1
    delta = 6 if startWeek.day() == 0
    startWeek.subtract delta, 'd'

  endWeek = new moment(startWeek).add 13, 'd'

  {
    startDate: startWeek,
    endDate: endWeek
  }

generateMonthForShift = (date) ->
  beginningOfMonth = moment(date)
    .startOf('isoWeek')
    .startOf('month')
    .add(1, 'w')
    .subtract(1, 'd')
    .startOf('isoWeek')
  {
    startDate: moment(beginningOfMonth),
    endDate: beginningOfMonth.endOf('month').endOf('isoWeek')
  }

getMonthByDate = (date, isShiftsMode = false) ->
  if isShiftsMode
    generateMonthForShift date
  else
    {
      startDate: moment(date).startOf 'month'
      endDate: moment(date).endOf 'month'
    }

generateNewRangeByType = (type, beginDate, endDate, isShiftsMode = false) ->
  data = {}
  newBeginDate = new moment beginDate
  newEndDate = new moment endDate

  if type == 'weekly'
    if newEndDate.isValid()
      dateRange = getWeekByDate newEndDate
    else if newBeginDate.isValid()
      dateRange = getWeekByDate newBeginDate
    else
      dateRange = getWeekByDate(new moment)
    data =
      beginDate: dateRange.startDate.format 'YYYY-MM-DD'
      endDate: dateRange.endDate.format 'YYYY-MM-DD'

  if type == 'weekly2'
    if newEndDate.isValid()
      dateRange = getTwoWeekByDate newEndDate
    else if newBeginDate.isValid()
      dateRange = getTwoWeekByDate newBeginDate
    else
      dateRange = getTwoWeekByDate(new moment)
    data =
      beginDate: dateRange.startDate.format 'YYYY-MM-DD'
      endDate: dateRange.endDate.format 'YYYY-MM-DD'

  else if type == 'daily'
    if newEndDate.isValid()
      dateRange = newEndDate
    else if newBeginDate.isValid()
      dateRange = newBeginDate
    else
      dateRange = new moment()
    now = new moment()
    if dateRange > now
      dateRange = now
    data =
      beginDate: dateRange.format 'YYYY-MM-DD'
      endDate: dateRange.format 'YYYY-MM-DD'

  else if type == 'monthly'
    if newEndDate.isValid()
      dateRange = getMonthByDate newEndDate, isShiftsMode
    else if newBeginDate.isValid()
      dateRange = getMonthByDate newBeginDate, isShiftsMode
    else
      dateRange = getMonthByDate(new moment, isShiftsMode)

    data =
      beginDate: dateRange.startDate.format 'YYYY-MM-DD'
      endDate: dateRange.endDate.format 'YYYY-MM-DD'

  data


generateMonthDates = (date) ->
  weeks = []
  days = []
  copyDate = new moment date
  # Find start of current month
  temp = new moment(date).date 1

  # Find monday of that week
  if temp.day() != 1
    delta = temp.day() - 1
    delta = 6 if temp.day() == 0
    temp.subtract delta, 'd'

  # Feel first week for normalize month
  _feelWeek days, weeks, temp
  days = []

  while temp.month() == copyDate.month()
    _feelWeek days, weeks, temp
    days = []

  weeks


_feelWeek = (days, weeks, date) ->
  while days.length != 7
    days.push new moment date
    date.add 1, 'd'
  weeks.push days

module.exports = {
  getWeekByDate
  getTwoWeekByDate
  getMonthByDate
  generateMonthDates
  generateNewRangeByType
}
