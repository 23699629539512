uuid                       = require 'uuid'

ActionTypes                = require '../constants/ActionTypes'
MessagesAPI                = require '../api/MessagesAPI'
GuidedActionCreator = require './abstract/GuidedActionCreator'
MessageSendConstants = require '../constants/MessageSend'
RequestUtils = require '../lib/RequestUtils'

class MessagesActionCreator extends GuidedActionCreator
  getList: (scope) =>
    @dispatch type: ActionTypes.MESSAGES_GET_LIST_REQUESTING
    MessagesAPI.getList scope,
      (messages) => @dispatch type: ActionTypes.MESSAGES_GET_LIST_SUCCESS, payload: { scope, messages }
      (error) => @dispatch type: ActionTypes.MESSAGES_GET_LIST_FAILURE, error: true, payload: { scope, error }

  send: (message) =>
    throw new Error('Send message error: conversationId can not be empty') unless message.conversationId?
    throw new Error('Send message error: text can not be empty') unless message.text?

    message.uuid = uuid.v4() unless message.uuid
    message.status = 'waiting' unless message.status
    message[MessageSendConstants.FIELD_NAME_SEND_STATUS] = MessageSendConstants.SEND_STATUS_SENDING

    @dispatch type: ActionTypes.MESSAGES_SEND_REQUESTING, payload: { message }
    MessagesAPI.send message,
      (messageFromServer) => @dispatch type: ActionTypes.MESSAGES_SEND_SUCCESS, payload: { message: messageFromServer }
      (response) =>
        errors = RequestUtils.extractErrorsFromResponse(response)
        message[MessageSendConstants.FIELD_NAME_SEND_STATUS] = MessageSendConstants.SEND_STATUS_FAILURE
        message[MessageSendConstants.FIELD_NAME_SEND_ERROR] = errors.join("\n")
        @dispatch type: ActionTypes.MESSAGES_SEND_FAILURE, payload: { message, errors }

module.exports = MessagesActionCreator
