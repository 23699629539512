BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'

class CountersStore extends BaseStore
  constructor: ->
    super()
    @counters = {}
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_COUNTERS_UPDATING
        Object.keys(payload.message).forEach (key) =>
          @counters[key] = payload.message[key]
        @emitChange()
      when ActionTypes.MQTT_COUNTERS_UPDATING_INITIAL
        @counters = {}
        @emitChange()
      when ActionTypes.MQTT_COUNTERS_REQUEST
        @counters = {}
        @emitChange()

module.exports = new CountersStore()
