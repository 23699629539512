BaseStore   = require '../../../stores/BaseStore'
RemindersActionCreator = require './RemindersActionCreator'
R = require 'ramda'

class RemindersStore extends BaseStore
  TYPES: {
    "REMINDERS_LOAD_REQUESTING",
    "REMINDERS_LOAD_SUCCESS"

    "REMINDER_CREATE_REQUESTING"
    "REMINDER_CREATE_SUCCESS"
    "REMINDER_CREATE_ERROR"

    "REMINDER_UPDATE_REQUESTING"
    "REMINDER_UPDATE_SUCCESS"
    "REMINDER_UPDATE_FAILURE"

    "REMINDER_DESTROY_REQUESTING"
    "REMINDER_DESTROY_SUCCESS"
  }

  constructor: ->
    super()
    @reminders = []
    @reminderId = null
    @errors = []
    @loading =
      reminders: false
    @registerActions()

  getActionCreator: =>
    new RemindersActionCreator @types

  _registerToActions: ({ type, payload }) =>
    switch type
      when @types.REMINDERS_LOAD_REQUESTING
        @loading.reminders = true
        @emitChange()
      when @types.REMINDERS_LOAD_SUCCESS
        @loading.reminders = false
        @reminders = payload.reminders
        @emitChange()
      when @types.REMINDER_UPDATE_REQUESTING
        @reminderId = payload
      when @types.REMINDER_UPDATE_SUCCESS
        @errors = []
        @reminders = @reminders.filter (reminder) =>
          reminder.id != @reminderId
        @reminders.unshift payload.reminder
        @reminders = R.sortBy R.prop('time'), @reminders
        @emitChange()
      when @types.REMINDER_UPDATE_FAILURE
        @errors = payload.errors?.responseJSON?.errors || []
        @emitChange()
      when @types.REMINDER_CREATE_SUCCESS
        @errors = []
        @reminders.unshift payload.reminder
        @reminders = R.sortBy R.prop('time'), @reminders
        @emitChange()
      when @types.REMINDER_CREATE_ERROR
        @errors = payload.errors?.responseJSON?.errors || []
        @emitChange()
      when @types.REMINDER_DESTROY_REQUESTING
        id = payload.id
        @reminders = _.filter @reminders, (reminder) -> reminder.id != id
        @emitChange()

module.exports = RemindersStore
