BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'

class DownloadAllStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actions = @getActions()
    actions[type].call(@, payload) if actions[type]

  getActions: =>
    "#{ActionTypes.DOWNLOAD_ALL_REQUESTING}": @onDownload
    "#{ActionTypes.DOWNLOAD_ALL_SUCCESS}": @onSuccess
    "#{ActionTypes.DOWNLOAD_ALL_FAILURE}": @onFailure

  onDownload: =>
    @loading = true
    @error = null
    @emitChange()

  onSuccess: =>
    @loading = false
    @emitChange()

  onFailure: (payload) =>
    @error = payload.error
    @loading = false
    @emitChange()

module.exports = new DownloadAllStore()
