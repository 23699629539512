config = require '../config'

PromptsAPI =
  update: (params, success, error) ->
    id = params.id
    $.ajax {
      url: config.urls.prompts.update(id)
      contentType: 'application/json'
      type: 'PUT'
      data: JSON.stringify params
      success
      error
    }

module.exports = PromptsAPI