ActionTypes  = require '../constants/ActionTypes'
GuidedActionCreator = require './abstract/GuidedActionCreator'
CredentialsAPI = require '../api/CredentialsAPI'

class CredentialsActionCreator extends GuidedActionCreator
  setList: (credentials) =>
    @dispatch type: ActionTypes.CREDENTIALS_SET_LIST, payload: { credentials }

  getList: (scope) =>
    @dispatch type: ActionTypes.CREDENTIALS_GET_LIST_REQUESTING, payload: { scope }
    CredentialsAPI.index scope,
      (response) => @dispatch type: ActionTypes.CREDENTIALS_GET_LIST_SUCCESS, payload: {credentials: response }
      (error) => @dispatch type: ActionTypes.CREDENTIALS_GET_LIST_FAILURE, payload: { error }

  get: (id, successCallback) =>
    @dispatch type: ActionTypes.CREDENTIAL_GET_DECRYPTED_REQUESTING
    CredentialsAPI.get id,
      (resource) =>
        @dispatch type: ActionTypes.CREDENTIAL_GET_DECRYPTED_SUCCESS, payload: {credential: resource }
        successCallback?(resource)
      (error) => @dispatch type: ActionTypes.CREDENTIAL_GET_DECRYPTED_FAILURE, payload: { error }

  create: (attributes) =>
    @dispatch type: ActionTypes.CREDENTIAL_CREATE_REQUESTING
    CredentialsAPI.create attributes,
      (resource) => @dispatch type: ActionTypes.CREDENTIAL_CREATE_SUCCESS, payload: { credential: resource }
      (error) => @dispatch type: ActionTypes.CREDENTIAL_CREATE_FAILURE, payload: { error }

  update: (id, attributes) =>
    @dispatch type: ActionTypes.CREDENTIAL_UPDATE_REQUESTING
    CredentialsAPI.update id, attributes,
      (resource) => @dispatch type: ActionTypes.CREDENTIAL_UPDATE_SUCCESS, payload: { credential: resource }
      (error) => @dispatch type: ActionTypes.CREDENTIAL_UPDATE_FAILURE, payload: { error }

  batchDestroy: (ids) =>
    @dispatch type: ActionTypes.CREDENTIAL_DESTROY_REQUESTING, payload: { ids }
    CredentialsAPI.batchDestroy ids,
      (response) => @dispatch type: ActionTypes.CREDENTIAL_DESTROY_SUCCESS, payload: { ids }
      (errors) => @dispatch type: ActionTypes.CREDENTIAL_DESTROY_FAILURE, payload: { errors }

module.exports = CredentialsActionCreator
