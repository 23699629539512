CustomerTicketsAPI = require 'api/CustomerTicketsAPI'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require 'root/dispatcher/Dispatcher'

module.exports =
  getTickets: (clientId) =>
    data =
      'by_parent_object[client][]': clientId

    Dispatcher.dispatch
      type: ActionTypes.CUSTOMER_TICKETS_GET_REQUESTING
      payload: clientId
    CustomerTicketsAPI.getTickets(
      data,
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMER_TICKETS_GET_SUCCESS
          payload:
            tickets: json.tickets
            clientId: clientId
      () ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMER_TICKETS_GET_FAILURE
          payload: 'An error occured while getting tickets'
    )
