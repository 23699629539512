{ Link } = require 'react-router-dom'
classnames = require 'classnames'
browserHistory = require './../../../history'


styles            = require './tags-selector.styl'
TagsStore         = require '../../../stores/TagsStore'
BaseComponent     = require '../../BaseComponent'
TagsActionCreator = require '../../../actions/TagsActionCreator'
SelectorComponent = require '../../SelectorComponent/SelectorComponent.cjsx'
TagTargetsAPI     = require '../../../api/TagTargetsAPI'

R = require 'ramda'

propTypes = require 'prop-types'

tagToOption = (tag) ->
  if tag
    label: tag.name
    value: tag.id

class TagsSelectorComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [TagsStore]

  @propTypes:
    tagType: propTypes.oneOf(['conversation', 'customer']).isRequired
    selectedTags: propTypes.array
    dropdownDirection: propTypes.oneOf(['top', 'bottom'])
    frequentTagsCount: propTypes.number
    displayFrequentTags: propTypes.bool
    onAdd: propTypes.func
    onRemove: propTypes.func
    inline: propTypes.bool

  @defaultProps:
    dropdownDirection: 'bottom'
    frequentTagsCount: 4
    displayFrequentTags: true
    selectedTags: []
    inline: false

  getState: ->
    sortByFrequency = R.compose(
      R.reverse,
      R.sortBy(R.prop('usage_frequency'))
    )

    tags = TagsStore.tags?[@props.tagType] || []
    tags = tags.filter (tag) -> !tag.removed
    tags: sortByFrequency(tags)

  requestData: =>
    _.defer(TagsActionCreator.getList) if R.isEmpty(@state.tags)

  onLinkCLick: (e) =>
    browserHistory.default.push '/tags'

  renderOption: (option) ->
    React.createElement("span", null,
      ( if option.link then option.link else option.label )
    )

  onChange: (newOptions = []) =>
    newSelectedTagIds = R.pluck 'value', newOptions
    oldSelectedTagIds = R.pluck 'id', @props.selectedTags
    addedIds = R.difference newSelectedTagIds, oldSelectedTagIds
    @props.onAdd? addedIds
    removedIds =  R.difference oldSelectedTagIds, newSelectedTagIds
    @props.onRemove? removedIds

  addFastTag: (name) =>
    TagTargetsAPI.createByName @props.tagType.charAt(0).toUpperCase() + @props.tagType.slice(1), @props.targetId, name

  render: =>
    { dropdownDirection } = @props

    className = styles.container
    className += ' ' + styles["direction-#{dropdownDirection}"] if dropdownDirection

    options = if @props.displayFrequentTags
      splitTags = ([frequentTags, otherTags], tag) =>
        if tag.usage_frequency > 0 && frequentTags.length <= @props.frequentTagsCount
          [R.concat(frequentTags, tag), otherTags]
        else
          [frequentTags, R.concat(otherTags, tag)]

      [frequentTags, otherTags] = R.reduce(splitTags, [[], []], @state.tags)

      frequentTagOptions = R.pipe(
        R.map(R.compose(R.assoc('className', styles.frequent), tagToOption)),
        R.adjust(R.assoc('className', "#{styles.frequent} #{styles.frequentLastChild}"), -1)
      )(frequentTags)

      otherTagOptions = R.map(tagToOption, otherTags)
      R.concat(frequentTagOptions, otherTagOptions)
    else
      R.map(tagToOption, @state.tags)

    if @props.selectedTags
      selectedOptions = (R.reject(R.isNil))(@props.selectedTags).map(tagToOption) || []

    selectClassName = classnames(styles.input, 'Select-inline': @props.inline )

#    inputProps = {}
#    inputProps.style = {display: 'none'} if @props.inline

    React.createElement("div", null,
      React.createElement(SelectorComponent, {"values": (selectedOptions), "options": (options), "placeholder": ("Add tag"), "onChange": ( @onChange ), "onCustomTagAdd": ( @addFastTag ), "underinput": (if @props.underinput then true else false)})
    )

    # <div className={ classnames(className, styles.direction_top) }>
    #   <Select
    #     multi={ true }
    #     value={ selectedOptions }
    #     options={ options }
    #     onChange={ @onChange }
    #     placeholder={ 'Tags' }
    #   />
    # </div>

module.exports = TagsSelectorComponent
