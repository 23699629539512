RANGE_DAILY = 'daily'
RANGE_WEEKLY = 'weekly'
RANGE_TWO_WEEKLY = 'weekly2'
RANGE_MONTHLY = 'monthly'
CALENDAR_RANGE_TYPES = [RANGE_DAILY, RANGE_WEEKLY, RANGE_MONTHLY]
CALENDAR_TWO_RANGE_TYPES = [RANGE_DAILY, RANGE_WEEKLY, RANGE_MONTHLY]

module.exports = {
  CALENDAR_RANGE_TYPES
  CALENDAR_TWO_RANGE_TYPES
  RANGE_DAILY
  RANGE_WEEKLY
  RANGE_TWO_WEEKLY
  RANGE_MONTHLY
}
