config               = require '../config'

UtcOffsetAPI =
  getOffset: (success, error) ->
    $.ajax {
      url: config.urls.utc.getOffset
      type: 'GET'
      success
      error
    }

module.exports = UtcOffsetAPI
