GuidedActionCreator = require '../../../../actions/abstract/GuidedActionCreator'
ActionTypes = require '../../../../constants/ActionTypes'
DashboardAPI = require '../../../../api/DashboardAPI'

class AgentChatsActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.AGENT_CHATS_REQUESTING

    DashboardAPI.getAgentChatsStatistic(
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.AGENT_CHATS_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.AGENT_CHATS_ERROR
            payload: json.error
      (response) =>
        msg = "An error occurred while getting agent's chats statistic"
        @dispatch
          type: ActionTypes.AGENT_CHATS_ERROR
          payload: msg
    )

module.exports = AgentChatsActionCreator