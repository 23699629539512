PromptsAPI = require '../api/PromptsAPI'
Dispatcher  = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

PromptsActionCreator =
  update: (attributes, successCallback, errorCallback) ->
    Dispatcher.dispatch type: ActionTypes.PROMPTS_UPDATE_REQUESTING, payload: {attributes}
    PromptsAPI.update attributes,
      (data) ->
        Dispatcher.dispatch type: ActionTypes.PROMPTS_UPDATE_SUCCESS, payload: data
        successCallback?()
      (data) ->
        Dispatcher.dispatch type: ActionTypes.PROMPTS_UPDATE_FAILURE, payload: data.responseJSON
        errorCallback?(data.responseJSON.errors)

  edit: (attributes, successCallback, errorCallback) ->
    Dispatcher.dispatch type: ActionTypes.PROMPTS_EDIT, payload: {attributes}
    PromptsAPI.update attributes,
      (data) ->
        Dispatcher.dispatch type: ActionTypes.PROMPTS_UPDATE_SUCCESS, payload: data
        successCallback?()
      (data) ->
        Dispatcher.dispatch type: ActionTypes.PROMPTS_UPDATE_FAILURE, payload: data.responseJSON
        errorCallback?(data.responseJSON.errors)

module.exports = PromptsActionCreator
