ActionTypes = require '../constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'
RankingMessageAPI = require '../api/RankingMessageAPI'

module.exports =
  getMessages: ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_MESSAGE_REQUESTING

    RankingMessageAPI.getMessages(
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_SUCCESS
          payload: json
      ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_FAILURE
          payload: 'An error occured while getting conversations list'
    )

  getConversation: (id) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_MESSAGE_REQUESTING

    RankingMessageAPI.getConversation(
      id,
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_SUCCESS
          payload: json
      ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_FAILURE
          payload: 'An error occured while getting conversations list'
    )

  getMessagesGroups: ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_MESSAGE_GROUPS_REQUESTING

    RankingMessageAPI.getMessagesGroups(
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_GROUPS_SUCCESS
          payload: json
      ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_GROUPS_FAILURE
          payload: 'An error occured while getting conversations list'
    )

  rank: (data) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_MESSAGE_RANK_REQUESTING

    RankingMessageAPI.rank(
      data,
      (json) ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_SUCCESS
          payload: json
      ->
        Dispatcher.dispatch
          type: ActionTypes.RANKING_MESSAGE_FAILURE
          payload: 'An error occured while getting conversations list'
    )

  skip: (messageId) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_MESSAGE_SKIP
      payload: messageId
