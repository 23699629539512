Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes   = require '../constants/ActionTypes'
TagTargetsAPI = require '../api/TagTargetsAPI'

TagTargetActionCreator =
  create: (tag, targetId) ->
    payload = {
      targetId
      targetType: tag.tag_type
      tagId: tag.id
    }
    dataForStore = Object.assign({}, payload, {isInGroup: !!tag.tags_group_id, tagName: tag.name})
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_CREATE_REQUESTING, payload: dataForStore
    TagTargetsAPI.create payload,
      (response) ->
        tag.customer_checked = true if tag.duplicate
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_CREATE_SUCCESS
          payload: dataForStore
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_CREATE_FAILURE
          payload: Object.assign(
            dataForStore
            { error: error.responseJSON }
          )

  destroy: (tag, targetId) ->
    payload = {
      targetId
      targetType: tag.tag_type
      tagId: tag.id
    }
    dataForStore = Object.assign({}, payload, {isInGroup: !!tag.tags_group_id, tagName: tag.name})
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_DESTROY_REQUESTING, payload: dataForStore
    TagTargetsAPI.destroy payload,
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_DESTROY_SUCCESS
          payload: dataForStore
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_DESTROY_FAILURE
          payload: Object.assign(dataForStore, {error: error.responseJSON})

  createOrder: (tagName, targetId, groupId, targetType) ->
    payload = { tagName, targetId, groupId, targetType}
    dataForStore = Object.assign({}, payload, {isInGroup: true, tagName})
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_CREATE_REQUESTING, payload: dataForStore
    TagTargetsAPI.createOrder payload,
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_TARGET_CREATE_SUCCESS
            payload: Object.assign dataForStore, {tagId: json.data.tag_id}
        else
          console.error json.error
          Dispatcher.dispatch
            type: ActionTypes.TAG_TARGET_CREATE_FAILURE
            payload: Object.assign(dataForStore, {error: json.error})
      (error) ->
        console.error error.responseJSON
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_CREATE_FAILURE
          payload: Object.assign(dataForStore, {error: error.responseJSON})

  destroyOrder: (tag, targetType, targetId) ->
    payload = {
      targetId
      targetType
      tagId: tag.id
      groupId: tag.tags_group_id
    }
    dataForStore = Object.assign({}, payload, {isInGroup: true, tagName: tag.name})
    Dispatcher.dispatch type: ActionTypes.TAG_TARGET_DESTROY_REQUESTING, payload: dataForStore
    TagTargetsAPI.destroyOrder payload,
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.TAG_TARGET_DESTROY_SUCCESS
            payload: dataForStore
        else
          console.error json.error
          Dispatcher.dispatch
            type: ActionTypes.TAG_TARGET_DESTROY_FAILURE
            payload: Object.assign(
              dataForStore
              {error: json.error}
            )
      (error) ->
        console.error error.responseJSON
        Dispatcher.dispatch
          type: ActionTypes.TAG_TARGET_DESTROY_FAILURE
          payload: Object.assign(
            dataForStore
            {error: error.responseJSON}
          )

module.exports = TagTargetActionCreator
