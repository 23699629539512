uuid = require 'uuid'

Dispatcher = require '../../dispatcher/Dispatcher'

class GuidedActionCreator
  constructor: (guid = null) ->
    @guid = guid || uuid.v1()

  dispatch: (object) =>
    Dispatcher.dispatch Object.assign({guid: @guid}, object)

module.exports = GuidedActionCreator