Isvg = require 'react-inlinesvg'
classnames = require 'classnames'
moment = require 'moment'

LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
TabOptionsComponent = require '../TabOptionsComponent'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
CONVERSATION_STATUS = require 'constants/ConversationStatuses'

styles = require './customer-communication.styl'

onAllThreadsClick = (roleId, entityKey) =>
  () ->
    config = [{
      plugins:
        ThreadsListPlugin:
          data:
            role: 'client'
            roleId: roleId
    }]
    CRMPluginActionCreator.setConfig config, entityKey, "#{entityKey}-allThreads"

onThreadClick = (entity, entityId, entityKey, withContext) =>
  () ->
    config = [{
      plugins:
        ThreadsPlugin:
          data:
            entity: entity
            entityId: entityId
            options:
              isSMSTool: true
    }]
    CRMPluginActionCreator.setConfig(
      config, entityKey, "#{entityKey}-thread-#{entityId}", withContext
    )

module.exports = (props) ->
  withContext = props.conversation?.status != CONVERSATION_STATUS.CLOSED

  React.createElement(LoadableWrapperComponent, {"loading": (props.loading)},
    (if !props.error
      React.createElement(TabOptionsComponent, { \
        "all": (
          text: 'All threads'
          onClick: onAllThreadsClick(props.externalClientId, props.entityKey, withContext)
        )
      })
    ),
    (
      if props.error
        React.createElement("p", {"className": (styles.error)}, (props.error))
      else unless (props.data || []).length
        React.createElement("p", {"className": (styles.no_data)}, "No data")
      else
        React.createElement("ul", {"className": (styles.container)},
          (props.data.map (thread) ->
            React.createElement("li", {"key": (thread.number), "className": (styles.thread_item)},
              React.createElement("div", {"onClick": (onThreadClick('orders', thread.entityId, props.entityKey, withContext))},
                React.createElement("div", null,
                  React.createElement("span", {"className": (styles.number)}, (thread.number)),
                  React.createElement("span", {"className": (classnames(styles.message, "#{styles.message_new}": thread.communicationStatus == 'new'))},
                    React.createElement(Isvg.default, {"src": (require 'components/shared/images/message.svg')})
                  )
                ),
                React.createElement("div", {"className": (styles.thread_info)},
                  React.createElement("span", null, (thread.lastMessageFrom), " "),
                  React.createElement("span", null, React.createElement("img", {"src": (require 'components/shared/images/arrow.svg')}), " "),
                  React.createElement("span", null, (thread.lastMessageTo)),
                  React.createElement("span", {"className": (styles.time)},
                    (moment(thread.lastMessageCreatedAt).format('MMM D, h:mm A'))
                  )
                )
              )
            )
          )
        )
    )
  )
