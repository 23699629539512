_ = require 'underscore'
BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.GROUPS_GET_REQUESTING}": ->
    @groupsLoading = true
    @error = null
    @emitChange()
  "#{ActionTypes.GROUPS_GET_SUCCESS}": (data) ->
    @groupsLoading = false
    @groups = data.groups
    @groupsPageCount = data.pages_count
    @emitChange()
  "#{ActionTypes.GROUPS_GET_FAILURE}": (error) ->
    @error = error
    @groupsLoading = false
    @emitChange()

  "#{ActionTypes.GROUP_CREATE_REQUESTING}": ->
    @creationLoading = true
    @error = null
    @emitChange()
  "#{ActionTypes.GROUP_CREATE_SUCCESS}": (group) ->
    @groups.unshift group
    @creationLoading = false
    @emitChange()
  "#{ActionTypes.GROUP_CREATE_FAILURE}": (error) ->
    @creationLoading = false
    @error = error
    @emitChange()

  "#{ActionTypes.GROUP_DELETE_REQUESTING}": ->
    @error = null
    @groupsLoading = true
    @emitChange()
  "#{ActionTypes.GROUP_DELETE_SUCCESS}": (deletedId) ->
    @groupsLoading = false
    @groups = @groups.filter ({id}) -> id != deletedId
    @emitChange()
  "#{ActionTypes.GROUP_DELETE_FAILURE}": (error) ->
    @groupsLoading = false
    @error = error
    @emitChange()

  "#{ActionTypes.GROUP_COMPOSITION_REQUESTING}": ->
    @compositionLoading = true
    @group = {
      agents: []
      channels: []
    }
    @emitChange()
  "#{ActionTypes.GROUP_COMPOSITION_SUCCESS}": (group) ->
    @compositionLoading = false
    @group.title = group.title
    @group.id = group.id
    @group['group_type'] = group['group_type'] || 'empty'
    @emitChange()
  "#{ActionTypes.GROUP_COMPOSITION_FAILURE}": (error) ->
    @compositionLoading = false
    @groupError = error
    @emitChange()

  "#{ActionTypes.GROUP_UPDATE_REQUESTING}": ->
    @compositionLoading = true
    @groupError = null
    @emitChange()
  "#{ActionTypes.GROUP_UPDATE_SUCCESS}": ({group, additionalData}) ->
    @compositionLoading = false

    union = _.union(@group.agents, additionalData.added_user_ids)
    @group.agents = _.without(union, additionalData.deleted_user_ids...)

    union = _.union(@group.channels, additionalData.added_channel_ids)
    @group.channels = _.without(union, additionalData.deleted_channel_ids...)

    @group['group_type'] = group['group_type'] || 'empty'

    if @group.title != group.title
      @group.title = group.title
      @groups = @groups.map (g) ->
        g.title = group.title if g.id == group.id
        g
    @emitChange()
  "#{ActionTypes.GROUP_UPDATE_FAILURE}": (error) ->
    @compositionLoading = false
    @groupError = error
    @emitChange()

  "#{ActionTypes.GROUP_GET_USERS_REQUESTING}": ->
    @agentsLoading = true
    @agentsError = null
    @options.agents = []
    @group.agents = []
    @emitChange()
  "#{ActionTypes.GROUP_GET_USERS_SUCCESS}": (data) ->
    @options.agents = data.users
    @agentsPageCount = data.count.pages_count
    data.users.forEach (user) =>
      @group.agents.push(user.id) if user.checked
    @agentsLoading = false
    @emitChange()
  "#{ActionTypes.GROUP_GET_USERS_FAILURE}": (error) ->
    @agentsError = error
    @agentsLoading = false
    @emitChange()

  "#{ActionTypes.GROUP_GET_CHANNELS_REQUESTING}": ->
    @channelsLoading = true
    @channelsError = null
    @options.channels = []
    @group.channels = []
    @emitChange()
  "#{ActionTypes.GROUP_GET_CHANNELS_SUCCESS}": (data) ->
    @options.channels = data.channels
    @channelsPageCount = data.count.pages_count
    data.channels.forEach (channel) =>
      @group.channels.push(channel.id) if channel.checked
    @channelsLoading = false
    @emitChange()
  "#{ActionTypes.GROUP_GET_CHANNELS_FAILURE}": (error) ->
    @channelsError = error
    @channelsLoading = false
    @emitChange()


class GroupsStore extends BaseStore
  constructor: ->
    super()
    @groupsLoading = false
    @creationLoading = false
    @optionsLoading = false
    @compositionLoading = false
    @groups = []
    @options = {
      agents: []
      channels: []
    }
    @group = {
      agents: []
      channels: []
    }
    @error = null
    @groupError = null
    @groupsPageCount = 0
    @groupsPageLoading = false
    @agentsPageCount = 0
    @channelsPageCount = 0
    @agentsLoading = false
    @channelsLoading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new GroupsStore()
