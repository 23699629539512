ConversationsAPI = require 'api/ConversationsAPI.coffee'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

FlagsActionCreator =
  change: (id, color) ->
    Dispatcher.dispatch
      type: ActionTypes.FLAGS_CHANGE
      payload:
        id: id
        color: color

  sync: (flags) ->
    Dispatcher.dispatch
      type: ActionTypes.FLAGS_SYNC
    ConversationsAPI.flag
      conversations: flags

module.exports = FlagsActionCreator
