ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'


OnlineActionCreator =
  subscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/online_users"
        actionType: ActionTypes.MQTT_ONLINE_USERS_UPDATING
        initial: true

module.exports = OnlineActionCreator
