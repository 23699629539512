{ Link } = require 'react-router-dom'
browserHistory = require './../../../history'

ReactPaginate = require 'react-paginate'
deepcopy = require 'deepcopy'
classnames = require 'classnames'

styles = require './agent-list'
BaseComponent = require 'components/BaseComponent'
AgentListItemComponent = require '../AgentListItemComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
paginationStyles = require 'components/shared/styles/pagination.styl'
{ AGENTS_PER_PAGE } = require 'constants/Agents'
AgentSearchComponent = require '../AgentSearchComponent'
AgentListActionCreator = require 'actions/AgentListActionCreator'
{ getQueryParams, buildQueryParamsString } = require 'lib/utils'
{ ROLES, ACTIVITIES, STATUSES } = require 'constants/Agents'
AuthStore = require 'stores/AuthStore'
AgentListStore = require 'stores/AgentListStore'
{ menus, reconnectUrl } = require 'root/config'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'agents'
    accessConfig =
      toEdit: item.accessToEdit
      another: item.anotherAccess
      reconnect: item.reconnectAccess


class AgentListComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1

    filters = {}
    filters.status = params.status if STATUSES[params.status]
    filters.role = params.role if ROLES[params.role]
    filters.activity = params.activity if ACTIVITIES[params.activity]

    page = Number.parseInt params.page
    if !page || page < 0
      page = 0

    query = ''
    query = params.search_query if params.search_query

    perPage = Number.parseInt params.per_page
    if !perPage || perPage < 0
      perPage = AGENTS_PER_PAGE

    @state =
      filters: filters
      page: page
      perPage: perPage
      query: query
    @initComponent()

  componentWillMount: ->
    userRole = ROLES[AuthStore.user.role].value
    if reconnectUrl && !(userRole in accessConfig.reconnect)
      browserHistory.default.push '/'
    unless userRole in accessConfig.another
      browserHistory.default.push "/agents/#{AuthStore.user.id}/shifts"

  dependsOnStores: [AgentListStore]

  componentDidMount: ->
    super()
    @setSearchFilters()

  setSearchFilters: =>
    params = Object.assign deepcopy(@state.filters),
      search_query: @state.query
      page: @state.page
      per_page: @state.perPage

    queryParams = "?#{buildQueryParamsString params}"
    window.history.replaceState null, null, queryParams
    AgentListActionCreator.getList(params)

  onPageChange: ({selected}) =>
    if @state.page == selected
      return
    @setState(
      { page: selected }
      @setSearchFilters
    )

  onFiltersChange: (query, filters) =>
    @setState(
      {
        query: query
        filters: filters
        page: 0
      }
      @setSearchFilters
    )

  render: ->
    rawCount = AgentListStore.pageCount
    pageCount = Number.parseInt rawCount
    pageCount += 1 if pageCount < rawCount
    React.createElement("div", {"className": (styles.container)},
      React.createElement(AgentSearchComponent, { \
        "filters": (@state.filters),  \
        "query": (@state.query),  \
        "onChangeHandler": (@onFiltersChange)
      }),
      React.createElement("div", {"className": (styles.agents__head)},
        React.createElement("span", { \
          "className": (classnames(
            styles['agents__head-item']
            styles.agents__head_name
          ))
        }, """
          Operator Name
"""),
        React.createElement("span", {"className": (classnames(
            styles['agents__head-item']
            styles.agents__head_role
          ))
        }, """
          Role
"""),
        React.createElement("span", {"className": (classnames(
            styles['agents__head-item']
            styles.agents__head_status
          ))
        }, """
          Status
"""),
        React.createElement("span", {"className": (styles['agents__head-item'])}, """
          Activity
""")
      ),
      React.createElement("div", {"className": (styles.agents)},
        React.createElement(LoadableWrapperComponent, {"loading": (AgentListStore.loading)},
          React.createElement("table", {"className": (styles.agents__container)},
            React.createElement("tbody", null,
              (AgentListStore.agents.map (agent, i) ->
                React.createElement(AgentListItemComponent, { \
                  "key": (i),  \
                  "agent": (agent)
                })
              )
            )
          )
        ),
        (if pageCount > 1
          React.createElement("div", {"className": (styles.agents__pages)},
            React.createElement(ReactPaginate.default, { \
              "previousLabel": 'previous',  \
              "nextLabel": 'next',  \
              "pageCount": (pageCount),  \
              "marginPagesDisplayed": (2),  \
              "pageRangeDisplayed": (4),  \
              "onPageChange": (@onPageChange),  \
              "initialPage": (@state.page),  \
              "pageClassName": (paginationStyles.page),  \
              "nextClassName": (paginationStyles.page),  \
              "previousClassName": (paginationStyles.page),  \
              "activeClassName": (paginationStyles.active),  \
              "pageLinkClassName": (paginationStyles.link),  \
              "nextLinkClassName": (paginationStyles.link),  \
              "previousLinkClassName": (paginationStyles.link),  \
              "containerClassName": (paginationStyles.container),  \
              "breakClassName": (paginationStyles.page)
            })
          )
        )
      )
    )

module.exports = AgentListComponent
