import React, { Fragment } from 'react';
import classnames from 'classnames';
import uuid from 'uuid';
import _ from 'underscore';
import ReactPaginate from 'react-paginate';

import DashboardAPI from 'api/DashboardAPI';

import { getQueryParams, buildQueryParamsString } from 'lib/utils';

import { HOVER_INFO_FAILED } from 'constants/Agents';

import SearchInputComponent from 'components/shared/SearchInputComponent/SearchInputComponent';


import LoadableWrapperComponent from '../../../shared/LoadableWrapperComponent/LoadableWrapperComponent';

import BaseComponent from '../../../BaseComponent';

import MessageErrorsStore from './MessageErrorsStore';
import ErrorTabs from './ErrorTabs';


import paginationStyles from 'components/shared/styles/pagination.styl';
import styles from '../dashboard.styl';

const DEFAULT_PER_PAGE = 25;
const mark = (keyword, text) => {
 if (!text) return;

 return text.split(' ').map(item => {
   const __keywordArr = keyword.split(' ');
   const __keywordIndex = __keywordArr.reduce((prev, next, index) => {
     if (item.toLocaleLowerCase().includes(next.toLocaleLowerCase())) prev = index;
     return prev
   }, -1);

  if (__keywordIndex >= 0) {
      const __keyword = __keywordArr[__keywordIndex];
      if (item.length === __keyword.length) return `<mark>${item}</mark>`;

      const _item = item.toLocaleLowerCase();
      const indexKeyword = _item.indexOf(__keyword.toLocaleLowerCase());
      const fSub = _item.substr(0, indexKeyword);
      const kSub = _item.substr(indexKeyword, __keyword.length);
      const lSub = _item.substr(indexKeyword + __keyword.length);

      return `${fSub}<mark>${kSub}</mark>${lSub}`;
  }
  return item
}).join(' ')
}

const COLUMNS = {
  'type': false,
  'code': ((message, code) => {
    if (message.failed_info) return (<span title={HOVER_INFO_FAILED[message.type](message.failed_info)}>{code}</span>);
    return code
  }),
  'description': (message, code, searchQuery) => {
    if (!searchQuery) return message.description;
    return <span dangerouslySetInnerHTML={{ __html: mark(searchQuery, message.description) }} />
  }
};

const COLUMN_NAMES = {
  'type': 'Provider',
  'code': 'Error code',
  'description': 'Description',
}

class ErrorsGlossary extends BaseComponent {
  constructor(props) {
    super(props);
    
    this.dependsOnStores = [new MessageErrorsStore()];
    const params = getQueryParams(location.search.substring(1));
    
    this.state = {
      page: params.page || 0,
      perPage: params.per_page || DEFAULT_PER_PAGE,
      search_query: params.search_query || '',
    };

    this.getPersonalStoreClass = this.getPersonalStoreClass.bind(this);
    this.renderData = this.renderData.bind(this);
    this.setSearchFilters = this.setSearchFilters.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchClear = _.debounce(this.onSearchClear.bind(this), 700);

    this.initComponent();
  }

  componentDidMount() {
    this.setSearchFilters();
    super.componentDidMount();
  }

  onPageChange({ selected }) {
    const { page } = this.state;

    if (page === selected) return;

    this.setState({ page: selected }, this.setSearchFilters);
  }

  getPersonalStoreClass() {
    return MessageErrorsStore;
  }

  setSearchFilters() {
    const {
      page,
      perPage,
      search_query,
    } = this.state;
    const params = {
      page,
      per_page: perPage,
      search_query,
    };
    const queryParams = `?${buildQueryParamsString(params)}`;
    window.history.replaceState(null, null, queryParams);
    setTimeout(() => {
      this.personalStore.actionCreator.getGlossaryMessageErrors({...params, ...{ search_query: search_query.toLocaleLowerCase() }})
    }, 10);
  }

  onSearchClear() {
    this.setState({ search_query: '', page: 0 }, this.setSearchFilters);
  }

  onSearchChange(value) {
    const _value = value.trim();

    if (_value.length < 3) return;

    this.state.search_query = _value;
    this.state.page = 0;
    this.setSearchFilters();
  }

  renderData() {
    const { search_query, perPage, page, isLoading } = this.state;
    const { statisticGlossary = {}, loading, error } = this.personalStore;
    const { rows, total } = statisticGlossary;
    const pageCount = Math.ceil(total / perPage);

    if (error) {
      return (
        <div className={styles.error_glossary_data}>Error while loading data</div>
      );
    }
    

    return (
      <Fragment>
        <SearchInputComponent
            placeholder="Search for error code or description"
            onChange={this.onSearchChange}
            onClear={this.onSearchClear}
            className={styles.search_field_glossary}
            clearCross
            defaultValue={search_query}
          />
          <LoadableWrapperComponent loading={loading}>
            <table className={styles.mesages_table}>
                  <thead className={styles.mesages_table_head}>
                    <tr>
                      {Object.keys(COLUMNS).map(key => (
                        <td className={classnames(styles.mesages_table_td, {[styles.mesages_table_td_10]: key !== 'description' })} key={key}>{COLUMN_NAMES[key] || key}</td>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {rows && rows.map(message => (
                      <tr key={uuid.v4()}>
                        {Object.keys(COLUMNS).map(key => (
                          <td
                            className={classnames(styles.mesages_table_td, {[styles.mesages_table_td_capitalize]: key === 'type' })}
                            key={uuid.v4() + key}
                          >
                            {
                              COLUMNS[key] ? COLUMNS[key](message, message[key], search_query) : message[key]
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                { !rows && <div className={styles.error_glossary_data}>No data</div>}
                {pageCount > 1 && 
                  <div className={styles.failed_messages_pages}>
                    <ReactPaginate
                      previousLabel='previous'
                      nextLabel='next'
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={4}
                      onPageChange={this.onPageChange}
                      initialPage={page}
                      pageClassName={paginationStyles.page}
                      nextClassName={paginationStyles.page}
                      previousClassName={paginationStyles.page}
                      activeClassName={paginationStyles.active}
                      pageLinkClassName={paginationStyles.link}
                      nextLinkClassName={paginationStyles.link}
                      previousLinkClassName={paginationStyles.link}
                      containerClassName={paginationStyles.container}
                      breakClassName={paginationStyles.page}
                    />
                  </div>
                }
          </LoadableWrapperComponent>
      </Fragment>
    )
  }

  render() {
    return (
      <div className={classnames(styles.grid, styles.grid__full_height)}>
        <div className={classnames(styles.full_row_of_grid, styles.full_row_of_grid_glossary)}>
          <ErrorTabs />
          {this.renderData()}
        </div>
      </div> 
    );
  }
}

export default ErrorsGlossary;
