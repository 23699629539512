AuthStore = require 'stores/AuthStore'
AuthActionCreator = require 'actions/AuthActionCreator'
{ getToken } = require 'lib/tokenStorage'

ping = ->
  AuthActionCreator.ping(AuthStore.user.id, getToken()) if AuthStore.authorized

infinityPing = _.once ->
  ping()
  setInterval ping, 30 * 1000

module.exports = infinityPing
