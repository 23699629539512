BaseComponent = require '../../../BaseComponent'
styles = require './customer-card-credentials-form'
CREDENTIAL_TYPE = require 'constants/CredentialTypes'
InlineTextInput = require 'components/shared/InlineTextInput/InlineTextInput'
CredentialTypeListComponent = require 'components/shared/CredentialTypeListComponent/CredentialTypeListComponent'
CustomerCardCredentialFormComponent = require 'components/shared/CustomerCardCredentialFormComponent'
onClickOutside = require 'react-onclickoutside'


propTypes = require 'prop-types'

class CustomerCardCredentialsFormComponent extends React.Component
  @propTypes:
    credentials: propTypes.arrayOf(propTypes.object).isRequired
    onCreate: propTypes.func
    onUpdate: propTypes.func
    onDestroy: propTypes.func
    onDecryptCredential: propTypes.func

  constructor: ->
    super()
    @state =
      newCredentials: []
      openedTypesList: false

  handleClickOutside: =>
    @setState openedTypesList: false

  toggleTypeList: =>
    @setState(openedTypesList: !@state.openedTypesList)

  onTypeListItemClick: (type, e) =>
    newCredentials = @state.newCredentials
    newCredentials.push { credential_type: type } #if newCredentials.length == 0
    @setState
      openedTypesList: false
      newCredentials: newCredentials

  saveNewCredential: (index, credential) =>
    @props.onCreate(credential)
    @state.newCredentials.splice index, 1
    @forceUpdate()

  removeNewCredential: (index) =>
    @state.newCredentials.splice index, 1
    @forceUpdate()

  render: ->
    React.createElement("div", null,
      (
        !@props.disabled &&
        React.createElement("div", {"className": styles.button_new_container},
          React.createElement("div", {"className": styles.button_new, "onClick": @toggleTypeList}
            
          ),
          (
            @state.openedTypesList &&
              React.createElement(CredentialTypeListComponent, { \
                "onItemClick": @onTypeListItemClick,  \
                "handleClickOutside": @handleClickOutside,  \
                "classList": styles.new_type_list,  \
                "classListItem": styles.new_type_list_item
              })
          )
        )
      ),
      (
        if @props.credentials?
          for credential, index in @props.credentials
            React.createElement(CustomerCardCredentialFormComponent, { \
              "key": index,  \
              "credential": credential,  \
              "onChange": @props.onUpdate,  \
              "onDestroy": @props.onDestroy,  \
              "disabled": @props.disabled,  \
              "editByIcon": true,  \
              "onDecryptCredential": @props.onDecryptCredential
            })
      ),
      (
        for credential, index in @state.newCredentials
          React.createElement(CustomerCardCredentialFormComponent, { \
            "key": index,  \
            "credential": credential,  \
            "onChange": ( _.partial(@saveNewCredential, index) ),  \
            "onDestroy": ( _.partial(@removeNewCredential, index) ),  \
            "editByIcon": true,  \
            "disabled": @props.disabled
          })
      )
    )

module.exports = onClickOutside.default(CustomerCardCredentialsFormComponent)
