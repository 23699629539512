{ Link } = require 'react-router-dom'
browserHistory = require 'root/history'
BaseComponent = require 'components/BaseComponent'
RotationsListComponent = require './RotationsListComponent'
RotationsStore = require 'stores/RotationsStore'
RotationsActionCreator = require 'actions/RotationsActionCreator'
AuthStore = require 'stores/AuthStore'
dashboardStyles = require 'components/DashboardComponent/dashboard'
{ menus } = require 'root/config'
{ ROLES } = require 'constants/Agents'

styles = require './rotations.styl'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'rotations'
    accessConfig =
      toPage: item.accessToPage || null

class RotationsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      page: 0
      perPage: 30
    @initComponent()

  dependsOnStores: [RotationsStore]

  componentDidMount: ->
    super()
    if (ROLES[AuthStore.user.role].value in accessConfig.toPage) && AuthStore.user.system_editor
      setTimeout(
        =>
          RotationsActionCreator.getList
            page: @state.page
            per_page: @state.perPage
        10
      )
    else
      browserHistory.default.push '/'

  onPaginateClick: ({ selected }) =>
    page = selected
    RotationsActionCreator.getList
      page: page
      per_page: @state.perPage
    @setState { page }

  render: ->
    { rotations, loading, error, total } = RotationsStore
    { perPage } = @state
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.header)},
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement(Link, {"to": '/rotations/new', "className": (dashboardStyles.button_add)},
            React.createElement("i", {"className": ("#{dashboardStyles.button_icon} fa fa-plus-circle")}),
            React.createElement("span", {"className": (dashboardStyles.button_label)}, "Add Rotation")
          )
        )
      ),
      React.createElement(RotationsListComponent, { \
        "error": (error),  \
        "loading": (loading),  \
        "rotations": (rotations),  \
        "pagesCount": (Math.ceil(total / perPage)),  \
        "paginateClickHandler": (@onPaginateClick)
      })
    )

module.exports = RotationsComponent
