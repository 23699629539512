Dispatcher = require 'root/dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

CountersActionCreator =
  subscribe: (userId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/counters/#{userId}"
        actionType: ActionTypes.MQTT_COUNTERS_UPDATING
        initial: true
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/counters/unassigned"
        actionType: ActionTypes.MQTT_COUNTERS_UPDATING
  unSubscribe: (userId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/counters/#{userId}"
        actionType: ActionTypes.MQTT_COUNTERS_UPDATING
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/counters/unassigned"
        actionType: ActionTypes.MQTT_COUNTERS_UPDATING

module.exports = CountersActionCreator
