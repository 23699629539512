{urls} = require '../config'

module.exports =
  getList: (params, success, error) ->
    $.ajax
      url: urls.qaDashboard.getList
      data: params
      type: 'GET'
      contentType: 'application/json'
      success: success
      error: error
  
  getCSVList: (success, error) ->
    $.ajax
      url: urls.qaDashboard.getCSVList
      type: 'GET'
      contentType: 'application/json'
      success: success
      error: error

  updateUser: (params, success, error) ->
    $.ajax
      url: urls.qaDashboard.updateUser
      data: JSON.stringify(params)
      type: 'POST'
      contentType: 'application/json'
      success: success
      error: error
