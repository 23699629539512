{ urls } = require '../config'
{ underscoreParams } = require '../lib/utils'

ConversationsAPI =
  show: (conversationId, data, success, error) ->
    $.ajax {
      url: urls.conversationItems.show(conversationId)
      type: 'GET'
      data: underscoreParams(data)
      success
      error
    }

module.exports = ConversationsAPI
