Button = require 'components/shared/Button'

styles = require './warning-popup.styl'

module.exports = (props) ->
  React.createElement("div", {"onClick": ((e) -> e.stopPropagation()), "className": (styles.container)},
    React.createElement("i", {"className": (styles.arrow)}),
    React.createElement("p", {"className": (styles.text)}, (props.text)),
    
    React.createElement(Button, {"title": "Cancel", "onClick": (props.onCancel), "white": true}),
    React.createElement(Button, { \
      "title": "Delete",  \
      "onClick": (props.onDelete),  \
      "className": (styles.delete_button),  \
      "red": true
    })
  )